import React, { Component } from 'react';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import '../../rc_menu_css/index.css';
import PropTypes from 'prop-types';
import './CPAddMenu.css';


class CPAddMenu extends Component {

  constructor() {
    super()
    this.state = {

    }
  }

  static propTypes = {
    langue: PropTypes.array,
    Visible: PropTypes.bool,
    CPCreate: PropTypes.func,
  };

  render() {
    if (this.props.Visible) {
      return (
        <Menu mode="horizontal" triggerSubMenuAction="click" selectable={false}>
          <SubMenu title={<div id="D_CP_Manage_centre_gauche_add_cp">New user field</div>} key="1" className="SM_CPAddMenu">
            <MenuItem key="1-1" onClick={() => this.props.CPCreate('LST')} className="SM_CPAddMenu_item" >
            {this.props.langue[88].LIB}
          </MenuItem>
            <MenuItem key="1-2" onClick={() => this.props.CPCreate('CLA')} className="SM_CPAddMenu_item">
            {this.props.langue[89].LIB}
          </MenuItem>
            <MenuItem key="1-3" onClick={() => this.props.CPCreate('DAT')} className="SM_CPAddMenu_item">
            {this.props.langue[90].LIB}
          </MenuItem>
            <MenuItem key="1-4" onClick={() => this.props.CPCreate('TXT')} className="SM_CPAddMenu_item">
            {this.props.langue[91].LIB}
          </MenuItem>
            <MenuItem key="1-5" onClick={() => this.props.CPCreate('LNK')} className="SM_CPAddMenu_item">
            {this.props.langue[92].LIB}
          </MenuItem>
            <MenuItem key="1-6" onClick={() => this.props.CPCreate('NUM')} className="SM_CPAddMenu_item">
            {this.props.langue[93].LIB}
          </MenuItem>
          </SubMenu>
        </Menu>
      );
    }
    return(
      <div style={{display:'none'}}></div>
    )
  }
}

CPAddMenu.defaultProps = {
  langue: null
}

export default CPAddMenu; 