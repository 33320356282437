import 'whatwg-fetch';
import _                       from 'lodash';
import Constante               from './Constante.js';
import MD5                     from 'MD5';
import { navigateToLoginPage, getSession } from './utils/auth';
import { trigger } from './helpers/notification/notification.js';


/**
 * Manage XHR requests for Express
 *
 * @param {*} action
 * @param {*} variable
 */
const AjaxGestion = (action, variable) => {
    const { ticket, shard, tokenNPL } = getSession();

    let L_url = '';
    let L_data_post = {};
    let L_token = '';
    let L_mode = 0; // 0 -> POST  ||   1 -> GET ||     2 -> DELETE   || 3 -> PUT

    switch (action) {
        case Constante.AjaxChoix.InnoAnalyse:
            L_mode = 0;
            L_token = tokenNPL;
            L_url = process.env.REACT_APP_OINNO_ROOT + '/data-api/queries';
            L_data_post = { mode: "express",
                            concept: [
                                JSON.stringify({
                                        type         : "orbitSearch", // Reimplement a variable for other usecases
                                        query        : variable.query,
                                        selection    : variable.selection,
                                        semanticQuery: variable.semantique,
                                        similarityIds:   variable.similarite.split(',').filter(Boolean)
                                }),
                                JSON.stringify({
                                        type         : 'npl',
                                        query        : variable.query,
                                        selection    : variable.selection,
                                        semanticQuery: variable.semantique,
                                        similarityIds: variable.similarite.split(',').filter(Boolean)
                                })
                            ]};
            break;
        case Constante.AjaxChoix.cpAddValue:
            L_mode = 0;
            L_url = 'https://' + shard + Constante.AjaxShardFields + '/' + variable.id + '/values;ticket=' + ticket + ';';
            L_data_post = variable.value
            break;
        case Constante.AjaxChoix.cpGetAll:
            L_mode = 1;
            L_url = 'https://' + shard + Constante.AjaxShardFields + ';ticket=' + ticket + ';';
            L_data_post = {};
            break;
        case Constante.AjaxChoix.cpAdd:
            L_mode = 0;
            L_url = 'https://' + shard + Constante.AjaxShardFields + '/create;ticket=' + ticket + ';';
            L_data_post = variable.value
            break;
        case Constante.AjaxChoix.cpUpdate:
            L_mode = 0;
            L_url = 'https://' + shard + Constante.AjaxShardFields + '/create;ticket=' + ticket + ';';
            L_data_post = variable.value
            break;
        case Constante.AjaxChoix.cpDelete:
            L_mode = 2;
            L_url = 'https://' + shard + Constante.AjaxShardFields + '/delete/' + variable.id + ';ticket=' + ticket + ';';
            break;
        case Constante.AjaxChoix.cpDocValueUpdate:
            L_mode = 3;
            L_url = 'https://' + shard + Constante.AjaxShardFields + '/' +
                variable.emplacement + variable.idrecherche + '/' + variable.id + ';' +
                'ticket=' + ticket + ';';
            L_data_post = {
                "ticket": ticket,
                "field": variable.field,
                "values": variable.value
            };
            break;
        case Constante.AjaxChoix.GetConceptLastSearch:
            L_mode = 0;
            L_token = tokenNPL;
            L_url = process.env.REACT_APP_IORBIT_STAT;
            L_data_post = {
                "user": {
                    "idGroup": "A100003",
                    "id": "A100003"
                },
                "indexName": "patent", //family ou patent
                "qpQuery": variable.query,
                "statistics": {
                    "axis": [
                        {
                            "nbEntries": 3,
                            "field": "CLKC",//"NPAN"
                            "type": "top"
                        }
                    ],
                    "computeOthers": "false"
                }
            };
            /* {
                "qpQuery": "IBM/PA",//"$sel 1, ($rk 200)",
                "indexName": "family",
                "statistics": {
                    "axis": [
                        {
                            "type": "top",
                            "field": "CLKC",
                            "nbEntries": 10
                        }
                    ]
                }
            };*/
            break;
        case Constante.AjaxChoix.userFieldGet:
            L_mode = 1;
            L_url = 'https://' + shard + Constante.AjaxShard + '/fields;' +
                'ticket=' + ticket + ';'
            break;
        case Constante.AjaxChoix.NoteAdd:
            L_mode = 3;
            L_url = 'https://' + shard + Constante.AjaxShard + '/rank/' +
                variable.emplacement + variable.idrecherche + '/' + variable.id + ';' +
                'ticket=' + ticket + ';uuid=' + variable.idrecherche.substring(1) + ';id=' + variable.id + ';score=' + variable.note;
            L_data_post = {
                "ticket": ticket,
                "score": variable.note
            };
            break;
        case Constante.AjaxChoix.commentGet:
            L_mode = 1;
            L_url = 'https://' + shard + Constante.AjaxShard + '/note/' +
                variable.emplacement + variable.idrecherche + '/' + variable.id + ';' +
                'ticket=' + ticket + ';'
            break;
        case Constante.AjaxChoix.commentAdd:
            L_mode = 3;
            L_url = 'https://' + shard + Constante.AjaxShard + '/notev2/' +
                variable.emplacement + variable.idrecherche + '/' + variable.id_doc + ';' +
                'ticket=' + ticket + ';';
            L_data_post = {
                "title": variable.name,
                "text": variable.comment
            };
            break;
        case Constante.AjaxChoix.commentUpdate:
            console.info("Disabled function: AjaxChoix.commentUpdate");
            // Not Working => using delete and create new on instead
            /*
            L_mode = 0;
            L_url = 'https://' + shard + Constante.AjaxShard + '/note/' +
                variable.emplacement + variable.idrecherche + '/' + variable.id_doc + ';' +
                'ticket=' + ticket + ';noteId=' + variable.id_comment + ';uid=' + variable.id_comment + ';';
            L_data_post = {
                "title": variable.name,
                "text": variable.comment,
                "noteId": variable.id_comment
            };
            */
            break;
        case Constante.AjaxChoix.commentDelete:
            L_mode = 2;
            L_url = 'https://' + shard + Constante.AjaxShard + '/note/' +
                variable.emplacement + variable.idrecherche + '/' + variable.id_doc + ';uid=' + variable.id + ';' +
                'ticket=' + ticket + ';';
            break;
        case Constante.AjaxChoix.ProjetsGet:
            L_mode = 1;
            L_url = 'https://' + shard + Constante.AjaxShardRessources + ";" +
                'ticket=' + ticket + ";" +
                'type=' + variable.type + ";" +
                'deep=*;' +
                'startid=' + variable.id;
            break;
        case Constante.AjaxChoix.ProjetsDelete:
            L_mode = 2;
            L_url = 'https://' + shard + Constante.AjaxShardRessources + "/" + variable.emplacement + variable.IdRecherche + ";" +
                'ticket=' + ticket + ";";
            break;
        case Constante.AjaxChoix.ProjetsAdd:
            L_url = 'https://' + shard + Constante.AjaxShardRessources + '/create;' +
                'ticket=' + ticket + ";";
            L_data_post = {
                "type": variable.type_folder_list,
                "parentID": variable.parentID,
                "resourceName": variable.name,
                "share": "PRIVATE",
                "shareMode": "RWS",
                "shareMembers": ""
            };
            break;
        case Constante.AjaxChoix.ProjetsRename:
            L_mode = 3;
            L_url = 'https://' + shard + Constante.AjaxShardRessources + '/' + variable.type_folder_list + '/edit/' +
                variable.id + ';ticket=' + ticket;
            L_data_post = {
                "resourceName": variable.name,
                "share": "PRIVATE",
                "shareMode": "RWS",
                "shareMembers": "",
                "description": "",
                "sortKey": "ASC"
            };
            break;
        case Constante.AjaxChoix.getDroit:
            L_mode = 1;
            L_url = 'https://' + shard + Constante.AjaxShard + "/services;" +
                'ticket=' + ticket + ";";
            break;
        case Constante.AjaxChoix.ExportDoc:
            L_url = 'https://' + shard + Constante.AjaxShard + "/export/" + Constante.EmplacementRecherche.FAMPAT + ';ticket=' + ticket;
            L_data_post = {
                "apids": variable.apids,
                "profile": variable.profil,
                "format": variable.format,
                "asynchronous": "false",
                "ticket": ticket
            };//{"apids":["104233257","30682661"],"profile":"EXPRESS_FIRST_DEFAULT","format":"PDF","asynchronous":false,"ticket":"10cfbf85-5169-46f4-8ea7-3abdbe10323e"}
            break;
        case Constante.AjaxChoix.Connexion:
            L_url = variable.protocol + "://" + variable.host + '/rest/iorbit/user/session/';
            L_data_post = {
                "logon": variable.user.logon,
                "password": MD5(variable.user.password),
                "b2blogon": Constante.AjaxB2B,
                "hash": MD5(variable.user.logon + "|" + MD5(variable.user.password) + "|" + Constante.AjaxB2B)
            };
            break;
        case Constante.AjaxChoix.Deconnexion:
            L_mode = 2;
            L_url = 'https://' + shard + Constante.AjaxShard + '/session?' +
                'ticket=' + ticket + ";";
            break;
        case Constante.AjaxChoix.PatentCitation:
            L_mode = 1;
            let L_ordre_PatentCitation = variable.Ordre.split(' ');
            L_url = 'https://' + shard + Constante.AjaxSearch + Constante.EmplacementRecherche.FAMPAT + ";" +
                'ticket=' + ticket + ";" +
                'range=1-200;' +
                'query=' + encodeURIComponent(encodeURIComponent(variable.query)) + ";" +
                'sorting=' + L_ordre_PatentCitation[0] + ';' +
                'sort_order=' + L_ordre_PatentCitation[1] + ';' +
                'fields= TI PA PD APID PN REP EPRD FPN;';//FAN TI ETI AB PN FPN PR IN PA IC CPC EC FI PCL CT OBJ ADB IMG ACT ECLM XCT CTGN FCOS COST PTCC XPN APID;';
            break;
        case Constante.AjaxChoix.unDoc:
            L_mode = 1;
            //L_fields = 'AB ACT APID CLM CLMS CTN CTGN DESC ECLM EPRD FPN IC ICLM IMG IN NOTE PA PDF PN REP STAR STG TI UFLD V_APL V_FACT';
            let L_fields_undoc = ' AB ACT ADB APID CLMS CPC CTN CTGN DESC EPRD FPN IC ICLM IMG IN NOTE OBJ ODES PA PDF PERMALINK V_PTCC REGISTER REP STAR TI UFLD ';
            if (variable.emplacement !== Constante.EmplacementRecherche.WORKFILE) {
                L_fields_undoc += 'V_APL V_FACT ';
            }
            L_url = 'https://' + shard + Constante.AjaxSearch + Constante.EmplacementRecherche.FAMPAT + ";";
            if (variable.emplacement === Constante.EmplacementRecherche.WORKFILE) {
                L_url = 'https://' + shard + Constante.AjaxSearch + variable.emplacement + variable.idrecherche + ";";
            }
            let L_ordre_unDoc = Constante.RequeteOrdre.Relevance.split(' ');
            L_url += 'ticket=' + ticket + ";" +
                'range=1-2;' +
                'query=' + encodeURIComponent(encodeURIComponent(variable.docID + '/APID')) + ";" +
                'sorting=' + L_ordre_unDoc[0] + ';' +
                'sort_order=' + L_ordre_unDoc[1] + ';' +
                'fields=' + L_fields_undoc + ';';
            break;
        case Constante.AjaxChoix.permalink:
            L_mode = 1;
            L_url = 'https://rest.orbit.com/rest/iorbit/user/permalink/' + variable.base + '/' +
                variable.xpn + ";" +
                'fields=AB ACT APID CLMS CTN CTGN DESC EPRD PRD FPN IC ICLM IMG IN NOTE PA PDF PN REP STAR STG TI UFLD V_APL V_FACT V_PTCC REGISTER';
            break;
        case Constante.AjaxChoix.listeDocNPL:
            L_mode = 1;
            L_token = tokenNPL;
            let L_date_npl = '';
            if (variable.date !== null && typeof variable.date !== 'undefined') {
                if (variable.date[0] !== null) {
                    L_date_npl += '&fromDate=' + variable.date[0];
                }
                if (variable.date[1] !== null) {
                    L_date_npl += '&toDate=' + variable.date[1];
                }
            }
            let L_ordre_listeDoc_npl = variable.Ordre.toLowerCase().split(' ');
            if (L_ordre_listeDoc_npl[1] === "desc") {
                L_ordre_listeDoc_npl[1] = 'dsc';
            }
            else {
                L_ordre_listeDoc_npl[1] = 'asc';
            }
            if (L_ordre_listeDoc_npl[0] === "eprd") {
                L_ordre_listeDoc_npl[0] = 'date';
            }
            else {
                L_ordre_listeDoc_npl[0] = 'score';
            }
            let L_range_npl = '0';
            if (variable.page !== null && typeof variable.page !== 'undefined') {
                L_range_npl = (variable.page * variable.NBResultat - variable.NBResultat).toString();
            }
            else if (variable.position !== null && typeof variable.position !== 'undefined') {
                L_range_npl = (variable.position - 1).toString();
            }
            const nplQuery = !variable.concept.length ? variable.query_npl.query : ( variable.query_npl.query < 1 ? variable.concept  : '('+ variable.concept+') and '+ variable.query_npl.query );
            L_url = Constante.AjaxAdresseNPL + 'searchFromConcepts?view=full&enableFilters=true&facet=full&sort=' + L_ordre_listeDoc_npl[0] + '&order=' + L_ordre_listeDoc_npl[1] +
                '&offset=' + L_range_npl + '&length=' + variable.NBResultat + L_date_npl +
                '&query=' + encodeURIComponent(nplQuery);
                if (variable.query_npl.types){
                    variable.query_npl.types.forEach(typeInfo=>{
                        L_url+="&types="+encodeURIComponent(typeInfo);
                    });
                }
                if (variable.query_npl.subtypes){
                    variable.query_npl.subtypes.forEach(typeInfo=>{
                        L_url+="&subtypes="+encodeURIComponent(typeInfo);
                    });
                }
            break;
        case Constante.AjaxChoix.listeDoc:
            let L_range = '';
            let L_fields = '';
            let L_ordre_listeDoc = ['', ''];
            if (variable.query === '') {
                variable.query = '*';
            }
            if (variable.page !== null && typeof variable.page !== 'undefined') {
                L_range = (variable.page * variable.NBResultat - variable.NBResultat + 1).toString() + '-' + (variable.page * variable.NBResultat).toString();
                L_fields = ' TI AB PA APID PN REP EPRD IMG STAR ';
            }
            else if (variable.position !== null && typeof variable.position !== 'undefined') {
                L_range = variable.position.toString() + '-' + (variable.position).toString();
                //L_fields = 'AB ACT APID CLM CLMS CTN CTGN DESC ECLM EPRD FPN IC ICLM IMG IN NOTE PA PDF PN REP STAR STG TI UFLD V_APL V_FACT';
                L_fields = ' AB ACT ADB APID CLMS CPC CTN CTGN DESC EPRD FPN IC ICLM IMG IN NOTE OBJ ODES PA PDF PERMALINK V_PTCC REGISTER REP STAR TI UFLD ';
                if (variable.emplacement !== Constante.EmplacementRecherche.WORKFILE) {
                    L_fields += 'V_APL V_FACT ';
                }
            }
            if (variable.reduit !== '' && variable.reduit !== null && typeof variable.reduit !== 'undefined') {
                L_fields = variable.reduit;
            }
            if (variable.emplacement === Constante.EmplacementRecherche.SEMANTIQUE) {
                L_mode = 0;
                L_url = 'https://' + shard + Constante.AjaxShard + '/' + variable.emplacement + "/FAMPAT;ticket=" + ticket + ";";
                L_data_post = {
                    text: variable.query,
                    range: L_range,
                    fields: L_fields,
                    ticket: ticket
                }
            }
            else if (variable.emplacement === Constante.EmplacementRecherche.SIMILARITY || variable.emplacement === Constante.EmplacementRecherche.PRIOR_ART) {
                if (variable.query === '*') {
                    variable.query = '';
                }
                L_mode = 1;
                L_ordre_listeDoc = variable.Ordre.split(' ');
                L_url = 'https://' + shard + Constante.AjaxShard + '/' + Constante.EmplacementRecherche.SIMILARITY + "/FAMPAT;" +
                    'ticket=' + ticket + ";" +
                    'range=' + L_range + ';' +
                    'selection=' + variable.similarite.replaceAll(' ', '_') + ";" +
                    'limit=' + encodeURIComponent(variable.query) + ";" +
                    'sorting=' + L_ordre_listeDoc[0] + ';' +
                    'sort_order=' + L_ordre_listeDoc[1] + ';' +
                    'fields=' + L_fields + ';';
            }
            else {
                L_mode = 1;
                L_ordre_listeDoc = variable.Ordre.split(' ');
                L_url = 'https://' + shard + Constante.AjaxSearch + variable.emplacement + variable.idrecherche + ";" +
                    'ticket=' + ticket + ";" +
                    'range=' + L_range + ';' +
                    'query=' + encodeURIComponent(encodeURIComponent(variable.query)) + ";" +
                    'selection=;text=;' +
                    'sorting=' + L_ordre_listeDoc[0] + ';' +
                    'sort_order=' + L_ordre_listeDoc[1] + ';' +
                    'fields=' + L_fields + ';';
            }

            L_url = L_url + (L_mode !== 0 ? "anonymous=true" : ""); // Force to avoid the use of Orbit history
            break;
        case Constante.AjaxChoix.TestConnexion:
            L_mode = 1;
            L_url = 'https://' + shard + Constante.AjaxSearch + ";" + 'ticket=' + ticket + ";" +
                'range=1-1;' +
                'query=elephants;' +
                'selection=;text=;' +
                'sorting=RELEVANCE;' +
                'sort_order=DESC;' +
                'fields=TI;';
            break;
        case Constante.AjaxChoix.alertes:
            L_mode = 1;
            L_url = 'https://' + shard + Constante.AjaxShard + "/alerts;" +
                'ticket=' + ticket + ";";
            break;
        case Constante.AjaxChoix.AlerteUpdate:
            L_mode = 3;
            L_url = 'https://' + shard + Constante.AjaxShard + "/alerts/" + variable.id + ';ticket=' + ticket;
            L_data_post = {
                "name": variable.name.toUpperCase(),
                "title": variable.name.toUpperCase(),
                "bases": ["FAMPAT"],
                "surveyCode": variable.period,
                "sort": "",
                "query": variable.alertObj.queries[0],
                "fields": "",
                "template": "HTML_FORMAT",
                "deliveryMode": "EMAIL",
                "deliveryFormat": "HTML",
                "emails": variable.alertObj.emails
            };
            // param angular
            /*  {"name":"HIPPOPO","title":"HIPPO","emails":"JMROUAND@QUESTEL.COM","bases":["FAMPAT"],"surveyCode":"QM","template":"HTML_FORMAT","fields":"PN XPN TI AB PA IN IMG","sort":"","deliveryMode":"EMAIL","deliveryFormat":"HTML","query":"HIPPOPOTAMUS AND AUTOMOBILE/BI/SA/IN/PA/BI/SA/IN/PA/BI/SA/IN/PA/BI/SA/IN/PA"}
            */
            //console.log("AlerteUpdate:" + JSON.stringify(L_data_post));
            break;
        case Constante.AjaxChoix.AlerteAdd:
            L_mode = 0;
            L_url = 'https://' + shard + Constante.AjaxShard + '/alerts;ticket=' + ticket;
            L_data_post = {
                "name": variable.name,
                "title": variable.name,
                "bases": ["FAMPAT"],
                "surveyCode": variable.period,
                "query": variable.query,
                "fields": "",
                "template": "HTML_FORMAT",
                "deliveryMode": "EMAIL",
                "deliveryFormat": "HTML",
                "emails": variable.email
            };
            //console.log("AlerteAdd:" + JSON.stringify(L_data_post));
            break;
        case Constante.AjaxChoix.AlerteDelete:
            L_mode = 2;
            L_url = 'https://' + shard + Constante.AjaxShard + "/alerts/" + variable.id + ';ticket=' + ticket;
            break;
        case Constante.AjaxChoix.AlerteMonitor:
            L_mode = 1;
            L_url = 'https://' + shard + Constante.AjaxSearch + "list/" + variable.id + ";" +
                'ticket=' + ticket + ";" +
                'fields=FPN;' +
                'sorting=RELEVANCE;query=*;range=1-25;';
            break;
        case Constante.AjaxChoix.listAdd:
            L_mode = 3;
            L_url = 'https://' + shard + Constante.AjaxShard + '/document/' + variable.EmplacementRecherche + '/import/' + variable.id + ';ticket=' + ticket;
            L_data_post = {
                "apids": variable.DocumentsSelect.map(name => `${name}`),
                "ticket": ticket
            };
            // param angular
            //  {"apids":["66640864","98476605"],"ticket":"fdd31abf-8e1b-4f5c-b98e-a3caed3c230d"}
            break;
        case Constante.AjaxChoix.listDelete:
            L_mode = 0;
            L_url = 'https://' + shard + Constante.AjaxShard + '/document/' + variable.emplacement + '/delete;ticket=' + ticket;
            L_data_post = {
                "uuid": variable.IdRecherche.substring(1),
                "apids": variable.DocumentsSelect,
                "ticket": ticket
            };
            break;
        case Constante.AjaxChoix.getStat:
            L_mode = 0;
            L_url = 'https://' + shard + Constante.AjaxShard + '/getStats;ticket=' + ticket;
            L_data_post = {
                "query": variable.query,
                "cluster": "FAMPAT",
                "stats": variable.stats
            };
            //console.log(JSON.stringify(L_data_post));
            break;
        default:
            return {};
    }
    let L_param_post = {};
    let L_proxyUrl = '';

    /************ FETCH ************************/
    if (L_mode === 0) {
        if (L_token === '') {
            L_param_post = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'application': 'Express'
                },
                body: JSON.stringify(L_data_post)
            };
        }
        else {
            L_param_post = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'application': 'Express',
                    'Authorization': `Bearer ${L_token}`
                },
                body: JSON.stringify(L_data_post)
            };
        }
    }
    else if (L_mode === 1) {
        if (L_token === '') {
            L_param_post = {
                method: 'get',
                headers: {
                    'Content-Type'                  : 'application/json',
                    'application'                   : 'Express',
                    'access-Control-Request-Headers': 'content-type, accept '
                },
                body: null
            };
        }
        else {
            L_param_post = {
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    'application': 'Express',
                    'Authorization': `Bearer ${L_token}`
                },
                body: null
            };
        }
    }
    else if (L_mode === 2) {
        L_param_post = {
            method: 'delete',
            headers: {
                'Content-Type': 'application/json',
                'application': 'Express'
            },
            body: ''
        };
    }
    else if (L_mode === 3) {
        L_param_post = {
            method: 'put',
            //mode : 'no-cors',
            headers: {
                'application' : 'Express',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(L_data_post)
        };
    }

    return fetch(L_proxyUrl + L_url, L_param_post).then((response) => {

        // Prevent non authorized member.
        preventUnauthorizedMember(response);
        return statusParse(response);
    }).then((responseJson) => {

        if (responseJson === null || typeof responseJson === 'undefined') {
            return {
                erreur: -3,
                log: ''
            }
        }

        const errorMsg  = responseJson.cause || responseJson.message || false,
            errorRegExp = /^com(\.*[^:]*)* ?:/i;

        if (errorMsg && errorMsg.match(errorRegExp)) {
            trigger('error', errorMsg.replace(errorRegExp, ''));
            return {
                erreur: -4,
                log: errorMsg
            }
        }
        return responseJson;
    }).catch((error) => {
        console.error(L_param_post.method, L_url, JSON.stringify(L_param_post),  error);
        return {
            erreur: -2,
            log: error
        }
    });
};


/**
 * Decorate the fetch method with token, nplToken etc..
 *
 * @param {string} url      The url to fetch data
 * @param {object} settings Some settings to perform the get
 *
 * @return Promise
 */
export const get = (url, settings = {}) => {
    const { tokenNPL } = getSession();

    return new Promise(resolve => {

        // Create an array of setting to be provided with the request.
        const requestSettings = {
            ...settings,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'application': 'Express',
                'Authorization': `Bearer ${tokenNPL}`
            },
        };

        // Send the request
        return window.fetch(url, requestSettings)
                     .then((response) => {

                        // Prevent non authorized member.
                        preventUnauthorizedMember(response);

                        if (response.ok) {
                            return response.json();
                        } else {
                            return {
                                erreur: -1,
                                log: response
                            }
                        }
                    }).then((responseJson) => {
                        resolve(responseJson);
                    }).catch((error) => {
                        return {
                            erreur: -2,
                            log: error
                        }
                     });
    })
};


/**
 * Get from orbit API
 *
 * @param {string} url      The url to fetch data
 * @param {object} settings Some settings to perform the get
 *
 * @return Promise
 */
export const getOrbit = (url) => {
    const { ticket, shard } = getSession();

    return new Promise(resolve => {

        // Create an array of setting to be provided with the request.
        const urlToFetch = `https://${shard}${Constante.AjaxShard}${url};ticket=${ticket}`,
            requestSettings = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'application': 'Express'
                }
            };

        // Send the request
        return window.fetch(urlToFetch, requestSettings)
                     .then((response) => {
                        // Prevent non authorized member.
                        preventUnauthorizedMember(response);

                        return  statusParse(response);
                    }).then((responseJson) => {
                        resolve(responseJson);
                    }).catch((error) => {
                        return {
                            erreur: -2,
                            log: error
                        }
                     });
    })
};

/**
 * Navigate to home if the user is unautorized
 *
 */
const preventUnauthorizedMember = ({ status }) => {
    // Prevent non authorized member.
    if (status === 401) {
        navigateToLoginPage();
    }
}

const statusParse = (response) => {
    // Prevent non authorized member.
   if (!response.ok){
       return {
           erreur : -1,
           log: "return incorrect"
       }
   }
   if (response.status===204){
       return {};
   }
   return response.json();
}


export default AjaxGestion;
