import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React                    from 'react';
import ReactDOM                 from 'react-dom';
import { Provider }             from 'react-keep-alive';
import './index.css';
import * as serviceWorker       from './serviceWorker';
import Express                  from './Express';
import {fetch as fetchPolyfill} from 'whatwg-fetch'

const { userAgent } = window.navigator,
  { REACT_APP_ENV } = process.env;

global.isIE = /MSIE|Trident|Edge/.test(userAgent);
if(/MSIE|Trident|Edge/.test(userAgent)) {
    window.fetch = fetchPolyfill;
}

// Polyfill matches for IE11
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

/**
* closestFn
* Polyfill closest for IE11
*/
if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) { // ES5 to not autobinding this !!
        let el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
   NodeList.prototype.forEach = Array.prototype.forEach;
}

// Disable console for PRD envirooneent
if (REACT_APP_ENV === "PRD") {
    console.log   = () => {};
    console.info  = () => {};
    console.error = () => {};
}

// Initialize the whole App
ReactDOM.render(
    <Provider>
        <Express />
    </Provider>
    , document.getElementById('root'));
serviceWorker.unregister();
