import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'rc-dialog';
import '../../../node_modules/rc-dialog/assets/index.css';
import './DialogProjetDeleteDoc.css';

class DialogProjetDeleteDoc extends Component {

    constructor() {
        super()
        this.state = {
            visible: false,
        }
    }

    static propTypes = {
        type: PropTypes.number,
        deleteDocListWorkfileValider: PropTypes.func
    }

    dialogVisible = (P_visible) => {
        this.setState({
            visible: P_visible
        });
    }

    dialogFermer = () => {
        this.setState({
            visible: false,
        });
    }

    render() {
        return (
            <Dialog title={this.props.langue[11].LIB} visible={this.state.visible} className="D_DialogProjetDeleteDoc"  onClose={()=>this.dialogFermer()}>
                <div>
                    <span className='S_lib_delete'>{this.props.langue[10].LIB} ?</span>
                </div>

                <div className="D_buttons">
                    <div  className="B_cancel_projet" onClick={() => this.dialogFermer()}>
                        {this.props.langue[6].LIB}
                    </div>
                    <div className="B_valider_projet" onClick={() => this.props.deleteDocListWorkfileValider()}>
                        {this.props.langue[7].LIB}
                    </div>
                </div>
            </Dialog>
        );
    }

}

export default DialogProjetDeleteDoc;
