import React, { Component } from 'react';
//import { contextMenu, Menu, Item } from 'react-contexify';
//import 'react-contexify/dist/ReactContexify.min.css';
import PropTypes from 'prop-types';
import Constante from '../Constante.js';
import './TreebeardMenu.css';


class TreebeardMenu extends Component {

  constructor() {
    super()
    this.state = {
      visible: false,
      posX: 0,
      posY: 0
    }
  }

  static propTypes = {
    langue: PropTypes.array,
    node: PropTypes.object,
    user: PropTypes.object,
    nodeDelete: PropTypes.func,
    nodeRename: PropTypes.func,
    nodeAdd: PropTypes.func,
    voirListe: PropTypes.func
  };

  openMenu = (e) => {
    e.preventDefault();
    this.setState({
      visible: true
    });
    this.positionne(e.target);
    setTimeout(() => {
      document.getElementById('TreebeardMenuAction' + this.props.idmenu).focus();
      this.calculateWidth()
    }, 100);
  };

  calculateWidth = () => {
    this.setState({
      posX: this.state.posX - document.getElementById('TreebeardMenuAction' + this.props.idmenu).clientWidth + 180
    });
  }

  positionne = (target) => {
    const scrollContainer = document.getElementById('D_centre_projet_bas'),
      dialogContainerTreeList = document.getElementsByClassName('rc-dialog D_dialog_projet_treebeard_global'),
      dialogContainerTree = dialogContainerTreeList.length >0 ? dialogContainerTreeList[0]: null;


    var targetY = 0;
    var targetX = 0;
    do { //find the top of target relatively to the container
      if (target === scrollContainer || target === dialogContainerTree) {
        break;
      }
      targetY += target.offsetTop;
      targetX += target.offsetLeft;
      target = target.offsetParent;

    } while (target !== null);

    this.setState({
      posX: targetX - 150,
      posY: targetY + 30
    });

  }


  render() {
    if (!this.state.visible) {
      return (
        <div style={{ display: "none" }}></div>
      )
    }
    let L_visibleEdit = '';
    let L_visibleAddFolder = '';
    let L_visibleAddList = '';
    let L_visibleDelete = '';
    let L_visibleVoir = '';
    switch (this.props.node.type) {
      case Constante.EmplacementRecherche.LIST:
        if (this.props.node.name.toUpperCase() === '_ORBITEXPRESSPATENTWATCHING_') {
          L_visibleEdit = 'hidden';
          L_visibleDelete = 'hidden';
        }
        L_visibleAddFolder = 'hidden';
        L_visibleAddList = 'hidden';
        if (!this.props.user.acces.liste.write) {
          L_visibleEdit = 'hidden';
          L_visibleDelete = 'hidden';
        }
        break;
      case Constante.EmplacementRecherche.WORKFILE:
        L_visibleAddFolder = 'hidden';
        L_visibleAddList = 'hidden';

        if (!this.props.user.acces.workfile.write) {
          L_visibleEdit = 'hidden';
          L_visibleDelete = 'hidden';
        }
        break;
      case Constante.EmplacementRecherche.FOLDER:
        L_visibleVoir = 'hidden';
        if (this.props.node.children.length > 0) {
          L_visibleDelete = 'hidden';
        }
        if (!this.props.user.acces.folder.write) {
          L_visibleAddFolder = 'hidden';
          L_visibleEdit = 'hidden';
          L_visibleDelete = 'hidden';
        }
        if (!this.props.user.acces.liste.write) {
          L_visibleAddList = 'hidden';
        }
        break;
      default:
        L_visibleVoir = 'hidden';
        L_visibleEdit = 'hidden';
        L_visibleAddFolder = 'hidden';
        L_visibleAddList = 'hidden';
        L_visibleDelete = 'hidden';
        break;
    }
    return (
      <div
        id={'TreebeardMenuAction' + this.props.idmenu}
        className="treebeardmenu"
        style={{
          top: this.state.posY,
          left: this.state.posX
        }}
        tabIndex="1"
        onBlur={(e) => {
          e.preventDefault();
          setTimeout(() => {
            this.setState({
              visible: false
            });
          }, 200);
        }}
      >
        <div key="TreebeardMenuAction_0" className={"treebeardmenu_item " + L_visibleVoir} onClick={() => this.props.voirListe(this.props.node)} >
          <img src=".\images\open.svg" className="TreebeardMenuIcon" alt={this.props.langue[19].LIB} />
          {this.props.langue[19].LIB}
        </div>
        <div key="TreebeardMenuAction_1" className={"treebeardmenu_item " + L_visibleEdit} onClick={() => {
          this.props.nodeRename(this.props.node);
        }
        } >
          <img src=".\images\edit.svg" className="TreebeardMenuIcon" alt={this.props.langue[0].LIB} />
          {this.props.langue[0].LIB}
        </div>
        <div key="TreebeardMenuAction_2" className={"treebeardmenu_item " + L_visibleAddFolder} onClick={() => this.props.nodeAdd(this.props.node, Constante.EmplacementRecherche.FOLDER)}>
          <img src=".\images\folderclose.svg" className="TreebeardMenuIcon" alt={this.props.langue[1].LIB} />
          {this.props.langue[3].LIB}
        </div>
        <div key="TreebeardMenuAction_3" className={"treebeardmenu_item " + L_visibleAddList} onClick={() => this.props.nodeAdd(this.props.node, Constante.EmplacementRecherche.LIST)}>
          <img src=".\images\list.svg" className="TreebeardMenuIcon" alt={this.props.langue[1].LIB} />
          {this.props.langue[4].LIB}
        </div>
        <div key="TreebeardMenuAction_4" className={"treebeardmenu_item " + L_visibleDelete} onClick={() => this.props.nodeDelete(this.props.node)}>
          <img src=".\images\delete.svg" className="TreebeardMenuIcon" alt={this.props.langue[2].LIB} />
          {this.props.langue[2].LIB}
        </div>
      </div>
    );
  }
}

TreebeardMenu.defaultProps = {
  langue: null,
  nodeid: ''
}

export default TreebeardMenu; 