import React, { Component } from 'react';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import '../rc_menu_css/index.css';
import PropTypes from 'prop-types';
import './AccountMenu.css';


class AccountMenu extends Component {

  constructor() {
    super()
    this.state = {

    }
  }

  static propTypes = {
    langue: PropTypes.array,
    user: PropTypes.object,
    url_academy: PropTypes.string,
    name_academy: PropTypes.string,
    Deconnexion: PropTypes.func,
  };

  render() {
    return (
      <Menu mode="horizontal" triggerSubMenuAction="click" selectable={false}>
        <SubMenu title={<div id="D_account_menu"></div>} key="1" className="SM_treebeard_menu">
          <MenuItem key="1-1" onClick={() => this.props.Deconnexion()} >
            <img src=".\images\logout.svg" className="TreebeardMenuIcon" alt={this.props.langue[8].LIB} />
            {this.props.langue[8].LIB}
          </MenuItem>
          <MenuItem key="1-2" onClick={() => window.open(this.props.url_academy,'_blank')}>
            {this.props.name_academy}
          </MenuItem>
          <MenuItem key="1-3" onClick={() => window.open(this.props.langue[11].LIB,'_blank')}>
            <img src=".\images\help.svg" className="TreebeardMenuIcon" alt={this.props.langue[9].LIB} />
            {this.props.langue[9].LIB}
          </MenuItem>
        </SubMenu>
      </Menu>
    );
  }
}

AccountMenu.defaultProps = {
  langue: null
}

export default AccountMenu; 