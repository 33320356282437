import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'rc-dialog';
import Constante from '../Constante';
import '../../node_modules/rc-dialog/assets/index.css';
import '../Dialog.css';

class DialogProjet extends Component {

    constructor() {
        super()
        this.state = {
            name: '',
            visible: false,
            erreurDisplay: 'none'
        }
    }

    static propTypes = {
        type: PropTypes.number,
        langue: PropTypes.array,
        nodeType: PropTypes.string,
        menuAction: PropTypes.func
    }

    changeInput = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    dialogVisible = (P_visible, P_name) => {
        this.setState({
            visible: P_visible,
            name: P_name,
            erreurDisplay: 'none'
        });

        setTimeout(() => {
            if (this.props.type !== Constante.dialogMode.delete) {
                document.getElementById('I_Dialog_Projet_lib').focus();
            }
            else {
                document.getElementsByClassName('B_valider_projet')[0].focus();
            }
        }, 200);
    }

    dialogFermer = () => {
        this.setState({
            visible: false,
        });
    }

    dialogSave = () => {
        let L_size_name = document.getElementById('I_Dialog_Projet_lib').value.length;
        if (L_size_name < 2 && this.props.type !== Constante.dialogMode.delete) {
            this.setState({
                erreurDisplay: ''
            });
            return;
        }
        this.setState({
            visible: false,
            erreurDisplay: ''
        });
        this.props.menuAction({
            name: document.getElementById('I_Dialog_Projet_lib').value,
            type: this.props.type
        });
    }

    render() {
        let L_nodetype = '';
        //console.log("node:" + this.props.nodeType + " add:" + this.props.addType);
        switch (this.props.nodeType) {
            case Constante.EmplacementRecherche.FOLDER:
                L_nodetype = this.props.langue[13].LIB;
                break;
            case Constante.EmplacementRecherche.WORKFILE:
                L_nodetype = this.props.langue[15].LIB;
                break;
            case Constante.EmplacementRecherche.LIST:
                L_nodetype = this.props.langue[14].LIB;
                break;
            default:
                L_nodetype = '';
                break;
        }
        let L_titre = '';
        switch (this.props.type) {
            case Constante.dialogMode.rename:
                L_titre = this.props.langue[5].LIB + ' ' + L_nodetype + ' ' + this.props.name;
                break;
            case Constante.dialogMode.add:
                L_titre = this.props.langue[6].LIB + ' ';
                if (this.props.addType === Constante.EmplacementRecherche.LIST) L_titre += this.props.langue[14].LIB;
                else L_titre += this.props.langue[13].LIB;
                break;
            case Constante.dialogMode.delete:
                L_titre = this.props.langue[7].LIB + ' ' + L_nodetype + ' ' + this.props.name;
                break;
            default:
                L_titre = '';
                break;
        }

        return (
            <Dialog className='rc-dialog-title-projet' title={L_titre} visible={this.state.visible} closable={false}>
                <div style={this.props.type === Constante.dialogMode.delete ? { display: 'none' } : {}}>
                    <span className="S_lib">{this.props.langue[8].LIB}</span><br />
                    <input
                        type='text'
                        size='50'
                        id='I_Dialog_Projet_lib'
                        className='I_lib'
                        onChange={(event) => this.changeInput(event)} value={this.state.name}
                        placeholder={this.props.langue[9].LIB}
                    />
                    <span className='S_lib_error' style={{ display: this.state.erreurDisplay }}>{this.props.langue[9].LIB}</span><br />
                    <br />
                </div>
                <div style={this.props.type !== Constante.dialogMode.delete ? { display: 'none' } : {}}>
                    <span className='S_lib_delete'>{this.props.langue[10].LIB} ?</span>
                </div>
                <hr className="HR_dialog_projet_buttons"></hr>
                <div className="D_dialog_projet_buttons">
                    <div className="B_cancel_projet" onClick={() => this.dialogFermer()}>
                        {this.props.langue[11].LIB}
                    </div>
                    <div className="B_valider_projet" onClick={() => this.dialogSave()}>
                        {this.props.langue[12].LIB}
                    </div>
                </div>
            </Dialog>
        );
    }

}

export default DialogProjet;
