import React, { Component } from 'react';
import _ from 'lodash';
import AjaxGestion, { setAjaxconfiguration, get } from '../AjaxGestion';
import { getSession } from '../utils/auth';

import './assets/news.css';

/*
see the news of the product
*/
class News extends Component {

    /**
    * Constructor
    * 
    * @returns void
    */
    constructor() {
        super()
        this.state = {
            newsNew: false // true if news are available
        }
    }


    componentDidMount() {
        this.dateCompared();
    }


    /*
    compare the date between local Storage and orbit
    if local is before the orbit date news are available
    */
    dateCompared = () => {
        get(process.env.REACT_APP_OINNO_ROOT + '/public-api/features', {}).then((listDate) => {
            const dateIPBIOrbit = getDateComIPBIOrbit(listDate),
                dateIPBIStorage = getDateComIPBIStorage();

            this.setState({
                newsNew: (dateIPBIStorage < dateIPBIOrbit ? true : false)
            });
        })
    }

    openNewsExpressOrbit = () => {
        const doubleRandom = Math.random().toString(),
            switchEnvProd  = process.env.REACT_APP_ENV === "DEV" ? 'qal' : 'prd',
            { locale }     = getSession(); 
            
        window.open(`https://static.orbit.com/imagination/orbit_welcome/${switchEnvProd}/news/news.htm?product=Express&lang=${locale || 'en'}&id=${doubleRandom}`, "_blank");
        
        setDateComIPBI();
        
        this.setState({
            newsNew: false
        });
    }

    render() {
        return (
            <div id="newsIcone"
                className={this.state.newsNew ? "actif" : "inactif"}
                onClick={this.openNewsExpressOrbit} ></div>
        )
    }
}

/*
from the object listDate i need to extract the last update date from Express
*/
const getDateComIPBIOrbit = (listDate) => {

    const dateLastUpdateExpressObject = listDate ? listDate.find(value => value.name === 'Express') : null,
        dateLastUpdateExpressString = dateLastUpdateExpressObject ? dateLastUpdateExpressObject.update : "01/01/2001",
        dateLastUpdateExpressSplitter = dateLastUpdateExpressString.split("/");

    return new Date(+dateLastUpdateExpressSplitter[2], dateLastUpdateExpressSplitter[1] - 1, +dateLastUpdateExpressSplitter[0]);
}

/*
get the last time where you open the application in string format : mm/dd/yyyy
*/
const getDateComIPBIStorage = () => {
    const now           = new Date(),
        dateComString   = localStorage.getItem('DATECOMM') ? localStorage.getItem('DATECOMM') 
            : `${now.getDate()}/${now.getMonth()+1}/${now.getFullYear()}`,  
        dateComTab      = _.toString(dateComString).match(/(\d{1,2})\/(\d{1,2})\/(\d{4})/);

    return new Date(dateComTab[3], dateComTab[2] - 1, dateComTab[1]);
}

/*
save the today date in the local storage with the format : mm/dd/yyyy
*/
const setDateComIPBI = () => {
    const dateCom = (new Date()).toLocaleString('en-GB', { timeZone: 'UTC' }).split(',')[0];
    localStorage.setItem('DATECOMM', dateCom);
}

export default News;

