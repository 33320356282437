import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { contextMenu, Menu, Item } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import './DocumentParcours.css';
import Constante from '../Constante';

class DocumentParcours extends Component {

    constructor() {
        super()
        this.state = {
        }
    }


    static propTypes = {
        documentsNB: PropTypes.number.isRequired,
        docAfficheposition: PropTypes.number.isRequired,
        documentvisible: PropTypes.number,
        visionneusevisible: PropTypes.number,
        affichage: PropTypes.object,
        user: PropTypes.object,
        langue: PropTypes.array,
        EmplacementRecherche: PropTypes.string,
        NomRecherche: PropTypes.string,
        labelListAlert: PropTypes.string,
        changeSelectOuMode: PropTypes.func,
        addAlert: PropTypes.func,
        addDocListWorkfile: PropTypes.func,
        deleteDocListWorkfile: PropTypes.func
    };

    openMenu(e) {
        e.preventDefault();
        //const {x, y, width, height} = document.getElementById("D_liste_doc_action").getBoundingClientRect();
        contextMenu.show({
            id: 'M_DocumentParcours_export',
            event: e,
            props: {
                posx: 500
            }
        });
    };

    render() {
        // couleur en fonction du contexte (search, projets, alertes)
        let L_class_retour = "D_retour_search"; // Recherche
        if (this.props.EmplacementRecherche !== "FAMPAT") {
            // liste ou alert
            L_class_retour = "D_retour_projet"
            if (this.props.NomRecherche === this.props.labelListAlert) {
                L_class_retour = "D_retour_alert"
            }
        }
        return (
            <div id="D_DocumentParcours"
                style={(this.props.docAfficheposition > 0 && (this.props.documentvisible === 1 || this.props.visionneusevisible === 1)) ? {} : { display: 'none' }}
            >
                <div id='D_DocumentParcours_retour' className={L_class_retour}
                    style={(this.props.affichage.mode === Constante.affichageMode.listedoc) ? {} : { display: 'none' }}
                    onClick={() => this.props.changeSelectOuMode(Constante.AffichageSelect.document, Constante.affichageMode.rechercheavancee)}
                >
                    <div id="D_DocumentParcours_retour_inf"></div>
                    {this.props.langue[16].LIB + " "}
                    {(this.props.NomRecherche === "") ? this.props.langue[17].LIB : this.props.NomRecherche}
                </div>
                <div
                    id="D_DocumentParcours_actions"
                    style={(this.props.affichage.mode === Constante.affichageMode.listedoc) ? {} : { display: 'none' }}
                >
                    <div id="D_DocumentParcours_export" onClick={this.openMenu}></div>
                    <Menu id="M_DocumentParcours_export" className="SM_DocumentParcours_export">
                        <Item
                            key="1-1"
                            onClick={this.props.sendPermaLink}
                        >{this.props.langue[11].LIB}
                        </Item>
                        <Item
                            key="1-2"
                            className={this.props.user.acces.alerte.write ? '' : ' hide'}
                            onClick={() => this.props.addAlert(Constante.dialogMode.addSelect)}
                        >{this.props.langue[2].LIB}
                        </Item>
                        <Item key="1-5"
                            className={(this.props.user.acces.workfile.write || this.props.user.acces.liste.write) ? '' : ' hide'}
                            onClick={() => this.props.addDocListWorkfile()}
                        >
                            {this.props.langue[8].LIB}
                        </Item>
                        <Item
                            key="1-3"
                            className={(this.props.user.acces.liste.write && this.props.EmplacementRecherche === Constante.EmplacementRecherche.LIST) ? '' : ' hide'}
                            onClick={this.props.deleteDocListWorkfile}
                        >{this.props.langue[9].LIB}
                        </Item>
                        <Item
                            key="1-4"
                            className={(this.props.user.acces.workfile.write && this.props.EmplacementRecherche === Constante.EmplacementRecherche.WORKFILE) ? '' : ' hide'}
                            onClick={this.props.deleteDocListWorkfile}
                        >{this.props.langue[10].LIB}
                        </Item>
                    </Menu>
                </div>
                <div id="D_DocumentParcours_pages">
                    <span id="S_DocumentParcours">{this.props.docAfficheposition}/{this.props.documentsNB}</span>
                    <img id="I_DocumentParcours_prec"
                        src=".\images\page_prec.svg"
                        alt='<'
                        style={this.props.docAfficheposition > 1 ? {} : { display: 'none' }}
                        onClick={() => this.props.voirDoc(this.props.docAfficheposition - 1, null, null)}
                    />
                    <img id="I_DocumentParcours_next"
                        src=".\images\page_next.svg"
                        alt='>'
                        style={this.props.docAfficheposition < this.props.documentsNB ? {} : { display: 'none' }}
                        onClick={() => this.props.voirDoc(this.props.docAfficheposition + 1, null, null)}
                    />
                </div>
            </div>
        )
    }
}

export default DocumentParcours;

