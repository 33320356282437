import React, { Component } from 'react';
import { contextMenu, Menu, Item } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import PropTypes from 'prop-types';
import './FamilyMenu.css';


class FamilyMenu extends Component {

    constructor() {
        super()
        this.state = {
            tabPNPDf: []
        }
    }

    static propTypes = {
        langue: PropTypes.array,
    };

    openMenu(e, P_tab_pn_pdf) {
        e.preventDefault();
        this.setState({
            tabPNPDf: P_tab_pn_pdf
        },
            contextMenu.show({
                id: 'familyMenuId',
                event: e,
                props: {
                    foo: 'bar'
                }
            })
        )
    };

    openpdf = (P_pdf_link) => {
        window.open(P_pdf_link, '_blank');
    }

    render() {
        return (
            <Menu id='familyMenuId'>
                {
                    this.state.tabPNPDf.map((P_pn_pdf, P_index) => {
                        return (
                            <Item key={"familypnpdf" + P_index.toString()} onClick={() => this.openpdf(P_pn_pdf.PDF_LINK)} >
                                {P_pn_pdf.PN+P_pn_pdf.KIND}
                            </Item>
                        );
                    })
                }
            </Menu>
        );
    }
}

FamilyMenu.defaultProps = {
    tabPNPDf: []
}

export default FamilyMenu; 