/* eslint-disable */

import _ from "lodash"; 

/**
 * Express query rules from Orbit
 * 
 * @author: ahallili@questel.com / jgourovitch@questel.com
 * 
 * @use: import { decomposeAsConcept } from "./utils/queryRules"
 * 
 */


/***
 * 
 *  From regexp.js 
 * 
 */

//var TERM_CHAR = "a-z0-9\u00e0\u00e1\u00e2\u00e3\u00e4\u00e5\u00e8\u00e9\u00ea\u00eb\u00ec\u00ed\u00ee\u00ef\u00f2\u00f3\u00f4\u00f5\u00f6\u00f9\u00fc\u00fb\u00fc\u00fd\u00e7\u00e6\u0153";
var TERM_CHAR = "a-z0-9\u00A1-\uFFFF";
var EXPANSION_CHAR = "[a-z0-9\u00df-\u00e5\u00e8\u00e9\u00ea-\u00ef\u00f2-\u00f6\u00f9\u00fb-\u00fd\u00e7\u00e6\u0153\/-]";

// Bug 49598 : remove characters \u002F ("/") and \u003C ("<") in the WORD_BOUNDARY
//var WORD_BOUNDARY = "[\u0020-\u002E\u003A\u003B\u003D\u003E\u003F\\u005B\\u005D\u007B-\u007F\u2000-\u206F\n]";

// Bug 53637 et bug 52849 - rollback bug 49598, bug 68258 <p>
var BOUNDARY_CHAR = "\u0020-\u002F\u003A-\u003F\\u005B\\u005D\u007B-\u007F\u00A0\u00AE\u00B7\u1680\u2000-\u206F\u3000-\u303F\uFF01-\uFF0F\uFF1A-\uFF20\uFF3B-\uFF40\uFF5B-\uFF65\n";
var WORD_BOUNDARY = "[" + BOUNDARY_CHAR + "]";
var START_WORD_BOUNDARY = "(^|" + WORD_BOUNDARY + ")";
var END_WORD_BOUNDARY = "(?=\\b)";

var REGEXP = new Array();
REGEXP['A']  = new RegExp("[\u00e0-\u00e5]", "i");
REGEXP['E']  = new RegExp("[\u00e8-\u00eb]", "i");
REGEXP['I']  = new RegExp("[\u00ec-\u00ef]", "i");
REGEXP['O']  = new RegExp("[\u00f2-\u00f6]", "i");
REGEXP['U']  = new RegExp("[\u00f9-\u00fc]", "i");
REGEXP['Y']  = new RegExp("[\u00fd]", "i");
REGEXP['C']  = new RegExp("[\u00e7]", "i");
REGEXP['AE'] = new RegExp("[\u00e6]", "i");
REGEXP['OE'] = new RegExp("[\u0153]", "i");

// REGEXP[TERM] = new RegExp("[a-z0-9-]", "i");
REGEXP['TERM'] = new RegExp("[" + TERM_CHAR + "\/-]", "i");
REGEXP['+']  = new RegExp("([+*])", "g");
REGEXP['?']  = new RegExp("[?]", "g");
REGEXP['#']  = new RegExp("[#]", "g");
//Bug 26103
REGEXP[' ']  = new RegExp(" *[ \/-] *", "g");



/***
 * 
 *  From hightlights.js 
 * 
 */

 /**
  *  The following code has been extracted from Orbit Intelligence, and should be used as it.
  * 
  *  Public (usefull) methods are declared at the bottom of this file.  
  *   
  */
 const  normalize = (s) => {
	var tmp = s;
	tmp = tmp.replace(REGEXP['A'], "a");
	tmp = tmp.replace(REGEXP['E'], "e");
	tmp = tmp.replace(REGEXP['I'], "i");
	tmp = tmp.replace(REGEXP['O'], "o");
	tmp = tmp.replace(REGEXP['U'], "u");
	tmp = tmp.replace(REGEXP['Y'], "y");
	tmp = tmp.replace(REGEXP['C'], "c");
	tmp = tmp.replace(REGEXP['AE'], "ae");
	tmp = tmp.replace(REGEXP['OE'], "oe");
	return tmp;
}


/***
 * 
 *  From rules.js 
 * 
 */
var BEGIN_QUOTE_TAG = "<<";
var END_QUOTE_TAG = ">>";
var BEGIN_PARENTHESIS_TAG = "<#";
var END_PARENTHESIS_TAG = "#>";

// Pattern for grouping the inside of parenthesis
var RULES_PARENTHESIS = /\(([^()]+)\)/gi;
// Pattern for grouping the inside of quotes
var RULES_QUOTES = /\"([^"]+)\"/gi;
// Pattern for grouping terms separated by space or comma
var RULES_TERMS = /([^ ,]+)/gi;

function Rules(operators, truncations, or, space, near, simpleAdjacency,
		prefixTruncation, suffixTruncation) {
	// Attributes
	this.isOp = new RegExp("^(" + operators + ")$", "gi");
	this.hasOp = new RegExp(" (" + operators + ") ", "gi");
	this.truncations = new RegExp(truncations, "gi");
	this.or = or;
	this.near = near;
	this.space = space;
	this.simpleAdjacency = simpleAdjacency;
	this.suffixTruncation = suffixTruncation;
	this.prefixTruncation = prefixTruncation;

	this.hasOperators = false;
	this.hasTruncations = false;
	this.gParenthesis = new Array();
	this.gQuotes = new Array();

	// functions
	this.processRules = processRules;
	this.decomposeQuotesAsGroups = decomposeQuotesAsGroups;
	this.decomposeParenthesisAsGroups = decomposeParenthesisAsGroups;

	this.processGroup = processGroup;
	this.processQuotes = processQuotes;
	this.addTruncationsToTerms = addTruncationsToTerms;
	this.addTruncationsToAllTerms = addTruncationsToAllTerms;
	this.processParenthesisAroundCommas = processParenthesisAroundCommas;

	this.replaceSpacesByProximityOp = replaceSpacesByProximityOp;
	this.replaceSpacesBySimpleAdjacencyInQuotes = replaceSpacesBySimpleAdjacencyInQuotes
	this.replaceCommasByOR = replaceCommasByOR;
	this.replaceNearByProximityOp = replaceNearByProximityOp;
	this.isTag = isTag;
	this.isOperator = isOperator;

	this.decomposeHighlightTerms = decomposeHighlightTerms;
	this.decomposeUnderlineTerms = decomposeUnderlineTerms;
}

function processRules(input) {
	if (input == null || input == "") {
		return input;
	}

	var output = _.trim(input);
	output = removeExtraSpaces(output);
	output = removeSpacesAroundCommas(output);
	output = addParenthesis(output);

	output = this.decomposeQuotesAsGroups(output);
	this.hasOperators = (output.search(this.hasOp) != -1);
	this.hasTruncations = (output.search(this.truncations) != -1);
	output = this.decomposeParenthesisAsGroups(output);

	for (var i = this.gParenthesis.length - 1; i >= 0; i--) {
		var g = this.processGroup(this.gParenthesis[i]);
		g = (i == this.gParenthesis.length - 1) ? g : addParenthesis(g);
		output = output.replace(addParenthesisTags(i), g);
	}
	for (var i = this.gQuotes.length - 1; i >= 0; i--) {
		var q = this.processQuotes(this.gQuotes[i]);
		output = output.replace(addQuotesTags(i), q);
	}
	return output;
}

function decomposeQuotesAsGroups(input) {
	var grp = new Array();
	while ((grp = RULES_QUOTES.exec(input)) != null) {
		input = input.replace(grp[0], addQuotesTags(this.gQuotes.length));
		this.gQuotes.push(grp[1]);
	}
	return input;
}

function decomposeParenthesisAsGroups(input) {
	var output = "";
	while (output != input) {
		output = input;

		var grp = new Array();
		while ((grp = RULES_PARENTHESIS.exec(input)) != null) {
			input = input.replace(grp[0],
					addParenthesisTags(this.gParenthesis.length));
			this.gParenthesis.push(grp[1]);
		}
	}
	return input;
}

function processGroup(group) {
	var s = group;
	if (this.hasTruncations == false) {
		s = this.addTruncationsToTerms(group);
	} else {
		s = replaceStarByPlus(s);
	}

	if (this.hasOperators == false) {
		s = this.processParenthesisAroundCommas(s);
		s = this.replaceSpacesByProximityOp(s);
		s = this.replaceCommasByOR(s);
	} else {
		s = this.replaceNearByProximityOp(s);
	}
	return s;
}

/**
 * Split the string with comma as separator, then add parenthesis to the
 * expressions if they are not already surrounded by parenthesis and if they
 * have more than one term.
 */
function processParenthesisAroundCommas(s) {
	if (s.search(",") == -1) {
		return s;
	}
	var terms = s.split(",");
	for (var i = 0; i < terms.length; i++) {
		if (terms[i].search(/^\(.+\)$/g) == -1 && terms[i].search(" ") != -1) {
			terms[i] = addParenthesis(terms[i]);
		}
	}
	return terms.join(",");
}

/**
 * Add ???? to every term (if not a parenthesis tag or quote tag or operator) in
 * the string
 */
function addTruncationsToTerms(s) {
	s = normalize(s);
	var terms = s.match(RULES_TERMS);
	for (var i = 0; i < terms.length; i++) {
		// alert(terms[i] + ": " + this.isTag(terms[i]) + " - " +
		// this.isOperator(terms[i]));
		if (this.isTag(terms[i]) == false && this.isOperator(terms[i]) == false) {
			// s = s.replace(new RegExp(terms[i]+"(?![?])", "g"), terms[i] +
			// this.suffixTruncation);
			s = s.replace(new RegExp("\\b" + terms[i] + "(?![?])\\b", "gi"),
					this.prefixTruncation + terms[i] + this.suffixTruncation);
		}
	}
	return s;
}

/**
 * Add ???? to every term (if not a parenthesis tag or quote tag) in the string
 */
function addTruncationsToAllTerms(s) {
	s = normalize(s);
	var terms = s.match(RULES_TERMS);
	for (var i = 0; i < terms.length; i++) {
		if (this.isTag(terms[i]) == false) {
			// s = s.replace(new RegExp(terms[i]+"(?![?])", "g"), terms[i] +
			// this.suffixTruncation);
			s = s.replace(new RegExp("\\b" + terms[i] + "(?![?])\\b", "gi"),
					this.prefixTruncation + terms[i] + this.suffixTruncation);
		}
	}
	return s;
}

/**
 * Add ???? to every term between quotes, then replace quotes by parenthesis if
 * there is not
 */
function processQuotes(q) {
	if (q == "") {
		return q;
	}
	var s = this.addTruncationsToAllTerms(q);
	return s.search(" ") == -1 ? s : addParenthesis(this
			.replaceSpacesBySimpleAdjacencyInQuotes(s));
}

// Bug 25371: Replace space by AV/W (in french/english)
function replaceSpacesBySimpleAdjacencyInQuotes(s) {
	if (this.simpleAdjacency == "") {
		return s;
	}
	return s.replace(/ /g, this.simpleAdjacency);
}

function replaceSpacesByProximityOp(s) {
	if (this.space == "") {
		return s;
	}
	return s.replace(/ /g, this.space);
}

function replaceCommasByOR(s) {
	if (this.or == "") {
		return s;
	}
	return s.replace(/ *, */gi, this.or);
}

function replaceNearByProximityOp(s) {
	return s.replace(/(NEAR)/gi, this.near);
}

function replaceStarByPlus(s) {
	return s.replace(/[*]/g, "+");
}

function removeExtraSpaces(s) {
	return s.replace(/\s+/g, " ");
}

function removeSpacesAroundCommas(s) {
	return s.replace(/\s*, */g, ",");
}

function addParenthesis(s) {
	if (s.search(/^\([^()]+\)$/g) != -1) {
		return s;
	}
	return "(" + s + ")";
}

function removeParenthesis(s) {
	if (s.search(/^\([^()]+\)$/g) != -1) {
		return s.substring(1, s.length() - 1);
	}
	return s;
}

function addParenthesisTags(i) {
	return BEGIN_PARENTHESIS_TAG + i + END_PARENTHESIS_TAG;
}

function addQuotesTags(i) {
	return BEGIN_QUOTE_TAG + i + END_QUOTE_TAG;
}

function getTagNumber(s) {
	var tag = /^(?:<<|<#)(\d+)(?:>>|#>)$/g;
	var result = tag.exec(s);
	alert(result);
	if (result != null) {
		return result[1];
	}
	return -1;
}

function isTag(s) {
	// JC: if s = <#2#>/TI or s = <<1>> --> return true
	var tag = /^(<<|<#)\d+(>>|#>)/g;
	return s.search(tag) != -1;
}

function isOperator(s) {
	return s.search(this.isOp) != -1;
}

function decomposeHighlightTerms(input, simpleAdjacency,
		operatorsWithoutSimpleAdjacency) {
	if (input == null || input == "") {
		return input;
	}

	this.gParenthesis = new Array();
	this.gQuotes = new Array();

	var output = _.trim(input);
	output = removeExtraSpaces(output);
	output = removeSpacesAroundCommas(output);
	output = addParenthesis(output);
	output = this.decomposeParenthesisAsGroups(output);

	var tarray = new Array();
	for (var i = 0; i < this.gParenthesis.length; i++) {

		var a = this.gParenthesis[i].split(new RegExp(" (?:"
				+ operatorsWithoutSimpleAdjacency + ") ", "gi"));
		for (var j = 0; j < a.length; j++) {
			a[j] = a[j].replace(new RegExp(" " + simpleAdjacency + " ", "gi"),
					" ");
			var b = a[j].split(new RegExp("[ ]", "gi"));

			tarray.push(new Array());
			for (var k = 0; k < b.length; k++) {
				if (isTag(b[k]) == false) {
					tarray[tarray.length - 1].push(b[k]);
				}
			}
		}
	}

	var terms;
	var listTerms = new Array();
	while ((terms = tarray.shift()) != null) {
		if (terms.length > 0) {
			listTerms.push(terms.join(" "));
		}
	}
	return listTerms;
}

function decomposeUnderlineTerms(input, simpleAdjacency,
		operatorsWithoutSimpleAdjacency) {
	if (input == null || input == "") {
		return input;
	}

	this.gParenthesis = new Array();
	this.gQuotes = new Array();

	var output = _.trim(input);
	output = removeExtraSpaces(output);
	output = removeSpacesAroundCommas(output);
	output = addParenthesis(output);
	output = this.decomposeParenthesisAsGroups(output);

	var tarray = new Array();
	for (var i = 0; i < this.gParenthesis.length; i++) {

		var a = this.gParenthesis[i].split(new RegExp(" (?:"
				+ operatorsWithoutSimpleAdjacency + ") ", "gi"));
		for (var j = 0; j < a.length; j++) {
			a[j] = a[j].replace(new RegExp(" " + simpleAdjacency + " ", "gi"),
					" ");
			var b = a[j].split(new RegExp("[ ]", "gi"));

			tarray.push(new Array());
			for (var k = 0; k < b.length; k++) {
				if (isTag(b[k]) == false) {
					tarray[tarray.length - 1].push(b[k]);
				}
			}
		}
	}

	var terms;
	var listTerms = new Array();
	while ((terms = tarray.shift()) != null) {
		if (terms.length > 0) {
			listTerms.push(terms.join(" "));
		}
	}
	return listTerms;
}

//en
var operators       = "F|P|S|L|NOTF|NOTP|NOTS|NOTL|AND|OR|NOT|(=?[1-9]?[0-9])?[DW]|NEAR|SDOC"; 
var truncations     = "[#|?|+|*]";
var or              = " OR ";
var space           = " P "; 
var near            = "9W";                                              
var prefixTruncate  = ""; 
var suffixTruncate  = "??";
var simpleAdjacency = "W";
var operatorsWithoutSimpleAdjacency = "[1-9]?[0-9]?[DW]";
var rules = new Rules(operators, truncations, or, space, near, prefixTruncate, suffixTruncate);



/**
 *  END of extracted CODE, the following is Express property. 
 * 
 * 
 * 
 */

/**
 * Decompose a query string as an array of concepts 
 * 
 * @param {string} query The query to extract some concepts 
 * @returns {array}
 */
export const decomposeAsConcept = (query) => { 
	const concepts = rules.decomposeHighlightTerms(query, simpleAdjacency, operatorsWithoutSimpleAdjacency); 

	// Prevent concepts to be provided as array 
	if(_.isArray(query)) {
		return _.without(query, '').map(line => {
			return _.flattenDeep(_.without(line, '').map(term    => _.flattenDeep(enforceConceptsRules(rules.decomposeHighlightTerms(term, simpleAdjacency, operatorsWithoutSimpleAdjacency)))))
									  .map(concept => concept.replace(/\/[A-Z]*/g, ''));
		});
	}

	// Decompose the provided query as an array of terms. 
	return _.flattenDeep(concepts.map(enforceConceptsRules));
};


/**
 * Add some rules to enforce the concept splitting
 * 
 * @param {string} concept The concept  
 * @returns {array}
 */
const enforceConceptsRules = (concept) => {
	// Refine concept by applying a final split process 
	const reg          = / AND | OR | NOT | S | F | P /gi, 
		conceptToSplit = _.isArray(concept) ? concept[0] : concept,
		splitedConcept = conceptToSplit.split(reg),
		trimedConcept  = _.isArray(splitedConcept) ? splitedConcept.map(_.trim) : splitedConcept;

	return trimedConcept.map(concept => concept.replace(/\/[A-Z]*/g, '')); 
}

export default {};
