import React, { Component }  from 'react';
import PropTypes             from 'prop-types';
import Constante             from '../Constante.js';
import AccountMenu           from './AccountMenu.js';
import News                  from '../helpers/news.js';

import './Entete.css';


class Entete extends Component {

  constructor() {
    super()
    this.state = {
      requete_fampat: '',
      url_academy   : '',
      name_academy  : '',
    };
  }

  static propTypes = {
    langue                : PropTypes.array,
    langueRechercheavancee: PropTypes.array,
    affichagePage         : PropTypes.string.isRequired,
    affichageMode         : PropTypes.string,
    requete               : PropTypes.string,
    user                  : PropTypes.object,
    RechercheInfo         : PropTypes.object,
    requeteInfosListe     : PropTypes.array,
    clientLiens           : PropTypes.array,
    recherche_click       : PropTypes.func,
    requete_change        : PropTypes.func,
    requeteInfosListeSet  : PropTypes.func,
    changePage            : PropTypes.func,
    Deconnexion           : PropTypes.func,
    retourRechercheFampat : PropTypes.func
  };

  componentDidMount=()=>{
    let L_url_academy=this.props.langue[10].LIB;
    let L_questel_academy_name='Questel Academy';

    let L_client = null;
    let L_parametre = [];
    var items = document.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
      L_parametre = items[index].split("=");
        if (L_parametre[0].toLowerCase() === 'client'){
          L_client = decodeURIComponent(L_parametre[1].toLowerCase());
          break;
        }
    }
    for(let L_parcours_liens=0;L_parcours_liens<this.props.clientLiens.length;L_parcours_liens++){
      if (L_client===this.props.clientLiens[L_parcours_liens].nom){
        L_url_academy=this.props.clientLiens[L_parcours_liens].url_academy;
        L_questel_academy_name=this.props.clientLiens[L_parcours_liens].name_academy;
        break;
      }
    }
    L_url_academy=L_url_academy.replace('EMAILUSER', this.props.user.logon);
    this.setState({
      url_academy:L_url_academy,
      name_academy:L_questel_academy_name
    })
  }

  rechercheKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.props.recherche_click(this.state.requete_fampat);
    }
  }

  requete_change = (P_val) => {
    if (P_val === null || typeof P_val === 'undefined') {
      P_val = document.getElementById('D_recherche_head_input').value.replace('*', '+');
    }
    this.setState({
      requete_fampat: P_val
    });
  }

  reload_express = () => {
    document.location = document.location.origin;
  }


  /**
   *
   * Render entete
   *
   * @return JSX
   */
  render() {

    let L_visible_search = false;
    let L_visible_back_to_search = false;
    if (this.props.user.acces.fampat) {
      L_visible_search = true;
    }
    if (this.props.affichagePage === Constante.affichagePage.recherchesimple) {
      L_visible_search = false;
    }
    if (this.props.affichagePage === Constante.affichagePage.projets || this.props.affichagePage === Constante.affichagePage.alertes) {
      if (this.props.user.acces.fampat) {
        L_visible_back_to_search = true;
      }
      L_visible_search = false;
    }
    if (this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.LIST ||
      this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.WORKFILE) {
      if (this.props.user.acces.fampat) {
        L_visible_back_to_search = true;
      }
      L_visible_search = false;
    }
    if (this.props.affichageMode===Constante.affichageMode.Recherche){
      L_visible_search=false;
    }
    return (
      <div id="D_head">
        <div id="D_gestion_user" style={this.props.user.logon === '' ? { display: 'none' } : {}}>
          <AccountMenu
            Deconnexion={this.props.Deconnexion}
            langue={this.props.langue}
            user={this.props.user}
            url_academy={this.state.url_academy}
            name_academy={this.state.name_academy}
          />
        </div>
        <div id="D_gestion_user_pipe">
        </div>
          <News />
        <div id="D_bouton_mes_alertes"
          onClick={() => this.props.changePage(Constante.affichagePage.alertes)}
          style={this.props.user.acces.alerte.read ? {} : { display: 'none' }}
        >
          <div id="I_mes_alertes"></div>
          <span id="S_mes_alertes">{this.props.langue[2].LIB}</span>
        </div>
        <div id="D_bouton_mes_projets"
          onClick={() => this.props.changePage(Constante.affichagePage.projets)}
          style={(this.props.user.acces.liste.read | - this.props.user.acces.workfile.read) ? {} : { display: 'none' }}
        >
          <div id="I_mes_projets"></div>
          <span id="S_mes_projets" >{this.props.langue[1].LIB}</span>
        </div>
        <div id="D_recherche_pipe">
        </div>
        <div id="D_recherche_back_to_search"
          style={L_visible_back_to_search ? {} : { display: 'none' }}
          onClick={() => this.props.retourRechercheFampat()}
        >
          <div id="D_recherche_back_to_search_img"> </div>
          <div>
            {this.props.langue[5].LIB}
          </div>
        </div>
        <div id="D_recherche_head"
          className=""
          style={L_visible_search ? {} : { display: 'none' }}
        >
          <input type='text'
            id="D_recherche_head_input"
            value={this.state.requete_fampat}
            placeholder={this.props.langue[12].LIB}
            onChange={() => this.requete_change(null)}
            onKeyPress={(e) => this.rechercheKeyPress(e)}
          />
          <div id="D_recherche_lance" onClick={() => this.props.recherche_click(this.state.requete_fampat)}></div>
        </div>
        <span id="S_express" onClick={()=>this.props.changePage(Constante.affichagePage.recherchesimple,null)} >Express</span>
        <span id="S_orbit" onClick={()=>this.props.changePage(Constante.affichagePage.recherchesimple,null)}>Orbit</span>
        <img id="I_logo_questel" src=".\images\orbitexpress_icone.svg" alt="Questel" onClick={()=>this.props.changePage(Constante.affichagePage.recherchesimple,null)} />
      </div>
    );
  }
}

export default Entete;
