import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './RechercheSimple.css';


class RechercheSimple extends Component {

  constructor() {
    super()
    this.state = {
      requete_simple: "",
      url_academy: '',
      name_academy: '',
    }
  }


  static propTypes = {
    langue: PropTypes.array,
    useremail: PropTypes.string,
    recherche_click: PropTypes.func,
    changePage: PropTypes.func,
    goSemantique: PropTypes.func,
    goAdvanceSearch: PropTypes.func
  };

  componentDidMount = () => {
    let L_url_academy = this.props.langue[10].LIB;
    let L_questel_academy_name = 'Questel Academy';

    let L_client = null;
    let L_parametre = [];
    var items = document.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
      L_parametre = items[index].split("=");
      if (L_parametre[0].toLowerCase() === 'client') {
        L_client = decodeURIComponent(L_parametre[1].toLowerCase());
        break;
      }
    }
    for (let L_parcours_liens = 0; L_parcours_liens < this.props.clientLiens.length; L_parcours_liens++) {
      if (L_client === this.props.clientLiens[L_parcours_liens].nom) {
        L_url_academy = this.props.clientLiens[L_parcours_liens].url_academy;
        L_questel_academy_name = this.props.clientLiens[L_parcours_liens].name_academy;
        break;
      }
    }
    L_url_academy = L_url_academy.replace('EMAILUSER', this.props.useremail);
    this.setState({
      url_academy: L_url_academy,
      name_academy: L_questel_academy_name
    })
  }

  openquestelAcademy = () => {
    window.open(this.state.url_academy, '_blank');
  }

  rechercheKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.recherche_send();
    }
  }

  requete_change = (P_val) => {
    this.setState({
      requete_simple: P_val.replace('*', '+')
    });
    this.props.requete_change(P_val);
  }

  recherche_send() {
    if (document.getElementById('IT_recherche_simple_texte').value.length > 0) {
      this.props.recherche_click(document.getElementById('IT_recherche_simple_texte').value);
    }
  }


  render() {
    return (
      <div id="D_recherche_simple">
        <div id="D_recherche_simple_centre">
          <div id="D_recherche_simple_logo_nom">
            <div id="D_recherche_simple_logo"></div>
            <div id="D_recherche_simple_orbit">Orbit</div>
            <div id="D_recherche_simple_express">Express</div>
          </div>
          <div id="D_recherche_simple_elem">
            <input
              type="text"
              id="IT_recherche_simple_texte"
              value={this.state.requete_simple}
              onKeyPress={(event) => this.rechercheKeyPress(event)}
              onChange={(event) => this.requete_change(event.target.value)}
            //placeholder={this.props.langue[3].LIB}
            />
            <div id="D_recherche_simple_lance" onClick={() => this.recherche_send()}></div>
          </div>
          <div id="D_recherche_simple_option">
            <div id="D_recherche_simple_advance" onClick={this.props.goAdvanceSearch}>{this.props.langue[6].LIB}</div>
            <div id="D_recherche_simple_semantic" onClick={() => this.props.goSemantique()}>{this.props.langue[7].LIB}</div>
          </div>
        </div>
        <div id="D_recherche_simple_questel_academy" onClick={this.openquestelAcademy}>
          {this.state.name_academy}
        </div>
      </div >
    );
  }
}

export default RechercheSimple; 