import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'rc-dialog';
import CPAddMenu from './rechercheavanceeparam/CPAddMenu';
import './CPManage.css';
import 'rc-dialog/assets/index.css';
//import { convertFromHTML } from 'draft-convert';
//import {stateFromHTML} from 'draft-js-import-html';

class CPManage extends Component {

    constructor() {
        super()
        this.state = {
            ManageActif: false,
            Comment: null,
            Mode: 0, // 0->liste   1->add CP      2->edit    3->delete      4-> Add Value
            CPSelect: -2,
            CPTemp: {
                VALEUR: [],
                createdBy: "me",
                createdDate: "2020-06-22",
                data: null,
                display: "",
                name: "",
                right: "",
                sample: "",
                type: "NUM",
                visibleInGroups: []
            },
            CPvalueAdd: ""
        }
    }

    static propTypes = {
        langue: PropTypes.array,
        ManageActif: PropTypes.bool,
        CP: PropTypes.array,
        CP_manage_close: PropTypes.func,
        cp_create_valide: PropTypes.func,
        cp_delete_valide: PropTypes.func,
        cp_modif_valide: PropTypes.func,
        cp_value_valide: PropTypes.func
    }

    componentDidMount() {
        let L_CPSelect = -2;
        for (let L_cp_parcours = 0; L_cp_parcours < this.props.CP.length; L_cp_parcours++) {
            if (this.props.CP[L_cp_parcours].right === "read_write") {
                if (L_CPSelect < -1) {
                    L_CPSelect = L_cp_parcours;
                }
            }
        }
        this.setState({
            ManageActif: this.props.ManageActif,
            CPSelect: L_CPSelect,
            CPTemp: this.CP_copy(L_CPSelect)
        });
    }

    componentDidUpdate(prevprops) {
        if (prevprops.ManageActif !== this.props.ManageActif) {
            let L_CPSelect = -2;
            for (let L_cp_parcours = 0; L_cp_parcours < this.props.CP.length; L_cp_parcours++) {
                if (L_CPSelect < -1 && this.state.Mode !== 1) {
                    L_CPSelect = L_cp_parcours;
                }
            }
            this.setState({
                ManageActif: this.props.ManageActif,
                CPSelect: L_CPSelect,
                CPTemp: this.CP_copy(L_CPSelect)
            });
        }
    }

    CP_select = (P_index) => {
        if (this.props.CP.length > P_index && P_index >= 0) {
            this.setState({
                CPSelect: P_index,
                CPTemp: this.CP_copy(P_index)
            })
        }
    }

    CP_copy = (P_index) => {
        if (P_index >= 0) {
            return {
                VALEUR: this.props.CP[P_index].VALEUR,
                createdBy: this.props.CP[P_index].createdBy,
                createdDate: this.props.CP[P_index].createdDate,
                data: JSON.parse(JSON.stringify(this.props.CP[P_index].data)),
                display: this.props.CP[P_index].display,
                name: this.props.CP[P_index].name,
                right: this.props.CP[P_index].right,
                sample: this.props.CP[P_index].sample,
                type: this.props.CP[P_index].type,
                visibleInGroups: this.props.CP[P_index].visibleInGroups
            };
        }
        return {
            VALEUR: [],
            createdBy: "me",
            createdDate: "2020-06-22",
            data: null,
            display: "",
            name: "",
            right: "",
            sample: "",
            type: "NUM",
            visibleInGroups: []
        }

    }

    titre_change = (P_val) => {
        let L_cp_temp = this.state.CPTemp;
        L_cp_temp.display = P_val;
        this.setState({
            CPTemp: L_cp_temp
        })
    }

    value_change = (P_place, P_val) => {
        let L_cp_temp = this.state.CPTemp;
        L_cp_temp.data[P_place].value = P_val;
        this.setState({
            CPTemp: L_cp_temp
        })
    }



    CP_liste = () => {
        return this.props.CP.map((P_cp_valeur, P_index_cp) => {
            let L_select = "";
            if (P_index_cp === this.state.CPSelect) {
                L_select = " select";
            }
            return (
                <div key={'CPListe_' + P_index_cp} className={"D_CP_Manage_centre_gauche_CP_UN" + L_select}
                    onClick={() => { this.CP_select(P_index_cp) }}
                >
                    <div className="D_CP_Manage_centre_gauche_CP_UN_label">
                        {P_cp_valeur.display}
                    </div>
                    <div className="D_CP_Manage_centre_gauche_CP_UN_image"
                        style={P_cp_valeur.visibleInGroups.length > 0 ? {} : { display: '' }}
                    >
                    </div>
                </div>
            );
        });
    }

    CP_manage_close = () => {
        this.setState({
            Mode: 0,
            ManageActif: false
        }, () => {
            this.props.CP_manage_close();
        });
    }

    CP_add_value_open = () => {
        switch (this.state.Mode) {
            case 0:
                this.setState({
                    Mode: 4
                })
                break;
            case 1:
            case 2:
                let L_cp_temp = this.state.CPTemp;
                L_cp_temp.data.push({ value: '' })
                this.setState({
                    CPTemp: L_cp_temp
                })
                break;
            default:
                break;
        }
    }

    CP_add_value_delete = (P_index_valeur) => {
        switch (this.state.Mode) {
            case 1:
            case 2:
                let L_cp_temp = this.state.CPTemp;
                if (L_cp_temp.data.length > P_index_valeur && P_index_valeur >= 0) {
                    L_cp_temp.data.splice(P_index_valeur, 1);
                    this.setState({
                        CPvalueAdd: "",
                        CPTemp: L_cp_temp
                    })
                }
                break;
            case 4:
                this.setState({
                    Mode: 0,
                    CPvalueAdd: ""
                })
                break;
            default:
                break;
        }
    }

    CP_add_value_valide = (P_event) => {
        if (P_event.key === 'Enter') {
            switch (this.state.Mode) {
                case 4:
                    this.props.cp_value_valide(this.state.CPTemp, this.state.CPSelect, P_event.target.value)
                    break;
                default:
                    break;
            }
        }
    }

    CP_add_value_change = (P_val) => {
        this.setState({
            CPvalueAdd: P_val
        })
    }

    CPCancel = () => {
        let L_cp_select = this.state.CPSelect;
        if (this.props.CP.length > 0 && this.state.CPSelect < 0) {
            L_cp_select = 0;
        }
        this.setState({
            Mode: 0,
            CPvalueAdd: "",
            CPSelect: L_cp_select,
            CPTemp: this.CP_copy(L_cp_select)
        })
    }

    CPCreate = (P_type) => {
        let L_CPCreate = this.CP_copy(-1);
        L_CPCreate.right = "read_write";
        L_CPCreate.type = P_type;
        if (P_type === 'CLA' || P_type === 'LST') {
            L_CPCreate.data = [{ value: '' }];
        }
        this.setState({
            Mode: 1,
            CPvalueAdd: "",
            CPSelect: -1,
            CPTemp: L_CPCreate
        })
    }

    cp_create_valide = () => {
        if (this.state.CPTemp.display.length < 1) {
            return;
        }
        this.props.cp_create_valide(this.state.CPTemp);
    }

    cp_modif_valide = () => {
        if (this.state.CPTemp.display.length < 1) {
            return;
        }
        this.props.cp_modif_valide(this.state.CPTemp);
    }

    cp_delete_valide = () => {
        this.props.cp_delete_valide(this.state.CPTemp, this.state.CPSelect);
    }

    CP_manage_valide = (P_valide_choix, P_index_cp) => {
        switch (P_valide_choix) {
            case 1:
                this.setState({
                    Mode: 0,
                    CPvalueAdd: "",
                    CPSelect: P_index_cp
                })
                break;
            case 2:
                this.setState({
                    Mode: 0,
                    CPvalueAdd: ""
                })
                break;
            case 3:
                let L_cp_select = -2;
                if (this.props.CP.length > 0) {
                    L_cp_select = 0;
                }
                this.setState({
                    Mode: 0,
                    CPvalueAdd: "",
                    CPSelect: L_cp_select
                })
                this.CP_select(L_cp_select);
                break;
            case 4:
                this.setState({
                    Mode: 0,
                    CPvalueAdd: ""
                })
                this.CP_select(this.state.CPSelect);
                break;
            default:
                break;
        }
    }

    cp_mode_edit = () => {
        this.setState({
            Mode: 2,
            CPvalueAdd: "",
        })
    }

    cp_mode_delete = () => {
        this.setState({
            Mode: 3,
            CPvalueAdd: "",
        })
    }

    CP_valeur = () => {
        if (this.state.CPSelect < -1) {
            return '';
        }
        if (this.state.CPTemp.type !== "CLA" && this.state.CPTemp.type !== "DIC" &&
            this.state.CPTemp.type !== "LST") {
            return '';
        }
        let L_class_mode = "";
        if (this.state.Mode === 1 || this.state.Mode === 2) {
            L_class_mode = "_edit"
        }
        return (
            <div id="D_CP_Manage_centre_droite_milieu_valeur_values_liste">
                {
                    this.state.CPTemp.data.map((P_cp_valeur, P_index_valeur) => {
                        return (
                            <div key={'CPValeur_' + P_index_valeur} className="D_CP_Manage_centre_droite_milieu_valeur_values_liste_un">
                                <div className="D_CP_Manage_centre_droite_milieu_valeur_values_liste_un_valeur">
                                    <input
                                        type="text"
                                        className={"I_CP_Manage_centre_droite_milieu_valeur_values_liste_un_valeur" + L_class_mode}
                                        onChange={(event) => this.value_change(P_index_valeur, event.target.value)}
                                        value={P_cp_valeur.value}
                                    />
                                </div>
                                <div
                                    className={"D_CP_Manage_centre_droite_milieu_valeur_values_liste_un_delete"}
                                    style={(this.state.Mode === 1 || this.state.Mode === 2) ? {} : { display: 'none' }}
                                    onClick={() => this.CP_add_value_delete(P_index_valeur)}
                                ></div>
                            </div>
                        )
                    })
                }
                <div
                    className="D_CP_Manage_centre_droite_milieu_valeur_values_liste_add_un"
                    style={this.state.Mode === 4 ? {} : { display: 'none' }}
                >
                    <div className="D_CP_Manage_centre_droite_milieu_valeur_values_liste_un_valeur">
                        <input
                            type="text"
                            className="I_CP_Manage_centre_droite_milieu_valeur_values_liste_un_valeur_edit"
                            onKeyPress={(event) => this.CP_add_value_valide(event)}
                            onChange={(event) => this.CP_add_value_change(event.target.value)}
                            defaultValue={this.state.CPvalueAdd}
                        />
                    </div>
                    <div
                        className="D_CP_Manage_centre_droite_milieu_valeur_values_liste_un_delete"
                        onClick={() => { this.CP_add_value_delete() }}
                    ></div>
                </div>
                <div
                    id="D_CP_Manage_centre_droite_milieu_valeur_values_liste_add_value"
                    style={(this.state.Mode === 4 || this.state.CPTemp.right !== "read_write") ? { display: 'none' } : {}}
                    onClick={() => { this.CP_add_value_open() }}
                >
                    {this.props.langue[107].LIB}
                </div>
            </div>
        )
    }

    render() {
        let L_cp_select_type = "";
        let L_cp_select_titre = "";
        let L_cp_select_proprietaire = "";
        if (this.state.CPSelect >= -1 && this.props.CP.length > this.state.CPSelect) {
            L_cp_select_titre = this.state.CPTemp.display;
            L_cp_select_proprietaire = this.state.CPTemp.createdBy;
            switch (this.state.CPTemp.type) {
                case 'CLA':
                case 'DIC':
                    L_cp_select_type = this.props.langue[89].LIB;
                    break;
                case 'LST':
                    L_cp_select_type =this.props.langue[88].LIB;
                    break;
                case 'TXT':
                    L_cp_select_type = this.props.langue[91].LIB;
                    break;
                case 'DAT':
                    L_cp_select_type = this.props.langue[90].LIB;
                    break;
                case 'NUM':
                    L_cp_select_type = this.props.langue[93].LIB;
                    break;
                case 'LNK':
                    L_cp_select_type = this.props.langue[92].LIB;
                    break;
                default:
                    break;
            }
        }
        // ManageActif feature not finished
        if (this.props.ManageActif) {
            return (
                <Dialog
                    title={""}
                    visible={this.state.ManageActif}
                    className="D_CP_Manage">
                    <div id="D_CP_Manage">
                        <div id="D_CP_Manage_Header">
                            <div id="D_CP_Manage_Header_Label" >
                                {this.props.langue[94].LIB}
                            </div>
                            <div id="D_CP_Manage_Header_croix"
                                onClick={this.CP_manage_close}
                            >
                            </div>
                        </div>
                        <div id="D_CP_Manage_NB_Fields">
                            {this.props.CP.length} {this.props.langue[95].LIB}
                        </div>
                        <div id="D_CP_Manage_centre">
                            <div id="D_CP_Manage_centre_gauche"
                                style={this.state.Mode === 0 ? {} : { pointerEvents: 'none' }}
                            >
                                <div id="D_CP_Manage_centre_gauche_liste">
                                    {this.CP_liste()}
                                    <div
                                        style={this.state.Mode === 1 ? {} : { display: 'none' }}
                                        className={"D_CP_Manage_centre_gauche_CP_UN select"} >
                                        <div className="D_CP_Manage_centre_gauche_CP_UN_label">
                                            {this.props.langue[96].LIB}
                                        </div>
                                        <div className="D_CP_Manage_centre_gauche_CP_UN_image"
                                            style={{ display: 'none' }}
                                        >
                                        </div>
                                    </div>
                                </div>
                                <CPAddMenu
                                    langue={this.props.langue}
                                    CPCreate={this.CPCreate}
                                    Visible={this.state.Mode === 1 ? false : true}
                                />
                            </div>
                            <div id="D_CP_Manage_centre_droite">
                                <div id="D_CP_Manage_centre_droite_haut">
                                    <div
                                        id="D_CP_Manage_centre_droite_haut_no_write"
                                        style={this.state.CPTemp.right !== "read_write" ? {} : { display: 'none' }}
                                    >
                                        {this.props.langue[97].LIB}
                                    </div>
                                    <div id="D_CP_Manage_centre_droite_haut_delete"
                                        onClick={this.cp_mode_delete}
                                        style={(this.state.Mode === 0 && this.state.CPTemp.right === "read_write") ? {} : { display: 'none' }}
                                    ></div>
                                    <div id="D_CP_Manage_centre_droite_haut_edit"
                                        onClick={this.cp_mode_edit}
                                        style={(this.state.Mode === 0 && this.state.CPTemp.right === "read_write") ? {} : { display: 'none' }}
                                    > </div>
                                    <div id="D_CP_Manage_centre_droite_haut_apply"
                                        onClick={this.cp_modif_valide}
                                        style={(this.state.Mode === 2 && this.state.CPTemp.right === "read_write") ? {} : { display: 'none' }}
                                    >
                                        {this.props.langue[98].LIB}
                                    </div>
                                    <div id="D_CP_Manage_centre_droite_haut_create"
                                        onClick={this.cp_create_valide}
                                        style={(this.state.Mode === 1 && this.state.CPTemp.right === "read_write") ? {} : { display: 'none' }}
                                    >
                                        {this.props.langue[99].LIB}
                                    </div>
                                    <div id="D_CP_Manage_centre_droite_haut_delete_apply"
                                        onClick={this.cp_delete_valide}
                                        style={(this.state.Mode === 3 && this.state.CPTemp.right === "read_write") ? {} : { display: 'none' }}
                                    >
                                        {this.props.langue[100].LIB}
                                    </div>
                                    <div id="D_CP_Manage_centre_droite_haut_cancel"
                                        onClick={this.CPCancel}
                                        style={(this.state.Mode === 1 || this.state.Mode === 2 || this.state.Mode === 3) ? {} : { display: 'none' }}>
                                        {this.props.langue[101].LIB}
                                    </div>
                                </div>
                                <div
                                    id="D_CP_Manage_centre_droite_milieu"
                                >

                                    <div id="D_CP_Manage_centre_droite_milieu_gauche">
                                        <div id="D_CP_Manage_centre_droite_milieu_titre">
                                            <div id="D_CP_Manage_centre_droite_milieu_titre_label">titre</div>
                                            <div id="D_CP_Manage_centre_droite_milieu_titre_value">
                                                <input
                                                    id="I_CP_Manage_centre_droite_milieu_titre_value"
                                                    className={(this.state.Mode === 1 || this.state.Mode === 2) ? "non_editable" : "editable"}
                                                    type="text"
                                                    onChange={(event) => this.titre_change(event.target.value)}
                                                    value={L_cp_select_titre}
                                                />
                                            </div>
                                        </div>
                                        <div id="D_CP_Manage_centre_droite_milieu_valeur">
                                            <div id="D_CP_Manage_centre_droite_milieu_valeur_label">{this.props.langue[102].LIB}</div>
                                            <div id="D_CP_Manage_centre_droite_milieu_valeur_values">{this.CP_valeur()}</div>
                                        </div>
                                    </div>
                                    <div id="D_CP_Manage_centre_droite_milieu_droite">
                                        <div id="D_CP_Manage_centre_droite_milieu_droite_type">
                                            <div id="D_CP_Manage_centre_droite_milieu_droite_type_label">{this.props.langue[103].LIB}</div>
                                            <div id="D_CP_Manage_centre_droite_milieu_droite_type_value"> {L_cp_select_type}</div>
                                        </div>
                                        <div id="D_CP_Manage_centre_droite_milieu_droite_owner">
                                            <div id="D_CP_Manage_centre_droite_milieu_droite_owner_label">{this.props.langue[104].LIB}</div>
                                            <div id="D_CP_Manage_centre_droite_milieu_droite_owner_value"> {L_cp_select_proprietaire}</div>
                                        </div>
                                        <div id="D_CP_Manage_centre_droite_milieu_droite_lecteurs">
                                            {this.props.langue[105].LIB}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="D_CP_Manage_bas">
                            <div id="D_CP_Manage_bas_close"
                                className={this.state.Mode === 0 ? '' : 'disabled'}
                                onClick={this.CP_manage_close}
                            >
                                {this.props.langue[106].LIB}
                            </div>
                        </div>
                    </div>
                </Dialog >
            )
        }
        return (
            <div></div>
        )
    }
}

export default CPManage;