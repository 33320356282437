import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'rc-dialog';
import Constante from '../Constante';
import '../Dialog.css';
import '../../node_modules/rc-dialog/assets/index.css';

class DialogAlert extends Component {

    constructor() {
        super()
        this.state = {
            visible: false,
            erreurDisplay: 'none',
            textvalue: '',
            selectvalue: 'QW',
        }
    }

    static propTypes = {
        type: PropTypes.number,
        name: PropTypes.string,
        period: PropTypes.string,
        renameAction: PropTypes.func,
        deleteAction: PropTypes.func,
        addAction: PropTypes.func,
        addListAction: PropTypes.func,
        langue: PropTypes.array
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.name !== this.props.name) {
            this.setState({ textvalue: nextProps.name });
        }
        if (nextProps.period !== this.props.period) {
            const period= nextProps.period ? nextProps.period: "QW";
            this.setState({ selectvalue: period });
        }
    }

    dialogVisible = (P_visible, P_name, P_period) => {
        const period= P_period ? P_period : "QW";
        this.setState({
            visible: P_visible,
            textvalue: P_name,
            selectvalue: period
        });
    }

    dialogFermer = () => {
        this.setState({
            visible: false
        });
    }

    dialogSave = () => {
        let L_size_name = document.getElementById('I_Dialog_Alert_lib').value.length;
        if (L_size_name < 2 && (this.props.type !== Constante.dialogMode.delete && this.props.type !== Constante.dialogMode.addSelect)) {
            this.setState({
                erreurDisplay: 'none'
            });
            return;
        }
        this.setState({
            visible: false,
            erreurDisplay: 'none'
        });
        switch (this.props.type) {
            case Constante.dialogMode.rename:
                this.props.renameAction(this.state.textvalue, this.state.selectvalue);
                break;
            case Constante.dialogMode.add:
                this.props.addAction(this.state.textvalue, this.state.selectvalue);
                break;
            case Constante.dialogMode.addSelect:
                this.props.addListAction();
                break;
            case Constante.dialogMode.delete:
                this.props.deleteAction();
                break;
            default:
                break;
        }
    }
    handleChangeText = (event) => {
        this.setState({ textvalue: event.target.value });
    }
    handleChangeSelect = (event) => {
        this.setState({ selectvalue: event.target.value });
    }

    render() {
        let L_titre = '';
        let L_titre2 = '';
        //let L_period = this.props.period;
        switch (this.props.type) {
            case Constante.dialogMode.rename:
                L_titre = this.props.langue[2].LIB + ' ' + this.props.name;
                break;
            case Constante.dialogMode.add:
                L_titre = this.props.langue[12].LIB;
                L_titre2 = this.props.langue[14].LIB;
                break;
            case Constante.dialogMode.addSelect:
                L_titre = this.props.langue[13].LIB;
                L_titre2 = this.props.langue[15].LIB;
                break;
            case Constante.dialogMode.delete:
                L_titre = this.props.langue[3].LIB + ' ' + this.props.name;
                break;
            default:
                L_titre = '';
                break;
        }
        return (
            <Dialog className='rc-dialog-title-alert' title={L_titre} visible={this.state.visible} closable={false}>
                <div className='D_titre2' style={(L_titre2 === '') ? { display: 'none' } : {}}>{L_titre2}<br /><br /></div>
                <div style={(this.props.type === Constante.dialogMode.delete || this.props.type === Constante.dialogMode.addSelect) ? { display: 'none' } : {}}>
                    <div>
                        <span className="S_a_name">{this.props.langue[11].LIB}</span>
                        <span className="S_a_period">{this.props.langue[6].LIB}</span>
                    </div>
                    <div>
                        <span className="S_a_name">
                            <input type='text' size='50' id='I_Dialog_Alert_lib' className='I_lib' onChange={this.handleChangeText} value={this.state.textvalue} />
                        </span>
                        <span className="S_a_period">
                            <select id='I_Dialog_Alert_period' name='I_Dialog_Alert_period' className='I_select' onChange={this.handleChangeSelect} value={this.state.selectvalue}>
                                <option value="QW">{this.props.langue[7].LIB}</option>
                                <option value="QM">{this.props.langue[8].LIB}</option>
                            </select>
                        </span>
                        <span className='S_lib_error' style={{ display: this.state.erreurDisplay }}>Saisissez un nom</span><br />
                    </div>
                </div>
                <div style={this.props.type !== Constante.dialogMode.delete ? { display: 'none' } : {}}>
                    <span className='D_titre2'>{this.props.langue[9].LIB.replace("{TITLE}", this.props.name)}</span>
                </div>

                <div className="D_buttons">
                    <button className="B_cancel B_cancel_alert" onClick={() => this.dialogFermer()}>
                        {this.props.langue[1].LIB}
                    </button>
                    <button className="B_valider B_valider_alert" onClick={() => this.dialogSave()}>
                        {this.props.langue[10].LIB}
                    </button>
                </div>
            </Dialog>
        );
    }

}

export default DialogAlert;
