/**
 * Some utils to manage member authentification
 *
 * Should be moved into a redux store.
 *
 * @use: import { initialize } from 'utils/auth'
 *       initialize(cb);
 */
import _                    from 'lodash';
import CryptoJS             from 'crypto-js';
import AjaxGestion          from '../AjaxGestion';
import Constante            from '../Constante';
import XLSX                 from 'xlsx';
import JWT                  from 'jsonwebtoken';
import { parseQueryString } from './text';
import languageFR           from '../langue/languefr.xlsx';
import languageEN           from '../langue/langueen.xlsx';
import languageJA           from '../langue/langueja.xlsx';
import languageZH           from '../langue/languezh.xlsx';
import languageDE           from '../langue/languede.xlsx';

// Declare user default configuration
const defaultUser = {
    etat: Constante.etat.initial,
    tentative: 0,
    email: '',
    nom: '',
    prenom: '',
    acces: {
        innovation: {
            read: false,
            write: false
        },
        workfile: {
            read: false,
            write: false
        },
        liste: {
            read: false,
            write: false
        },
        note: {
            read: false,
            write: false
        },
        comment: {
            read: false,
            write: false
        },
        cp: {
            read: false,
            write: false
        },
        folder: {
            read: false,
            write: false
        },
        alerte: {
            read: false,
            write: false
        },
        fampat: false,
        fullpat: false,
        npl: true,
        similarite: false,
        export: false,
        graphe: false
    }
};

/**
 * Select the member method XPN &  member informations from IORBIT API
 *
 * @return void
 */
export const initialize = finalize => {
    const currentLocation = window.location.href
                                  .toLowerCase()
                                  .replace('?xpn=renderstaticfirstpage', '?bug=renderstaticfirstpage'),
        xpnPos = currentLocation.indexOf("xpn=");

    // A permalink has been detected, load member from XPN
    if (xpnPos !== -1) {
        initializeMemberFromXpn({
            currentLocation,
            xpnPos,
            finalize
        });
        return;
    }

    // Load member from Iorbit
    initializeMemberFromOrbit({ finalize });
};


/**
 * Initialize the APP from a permalink
 *
 * @param {object} settings Parameters to initialize member from XPN
 *
 * @return void
 */
const initializeMemberFromXpn = ({ currentLocation, xpnPos, finalize }) => {
    const locationAsString = document.location.toString(),
        langue             = loadLanguage('en');
    // Load the language file, then initialize the permalink workflow
    let L_services = Object.assign({}, defaultUser);
    L_services.etat = Constante.etat.permalink;
    let l_xpn = locationAsString.substring(xpnPos + 4);
    let l_pos2 = l_xpn.indexOf(";=");
    if (l_pos2 > 0) l_xpn = l_xpn.substring(0, l_pos2 - 1);
    let l_pos3 = currentLocation.indexOf("base=fullpat");
    let l_base = "fampat";
    if (l_pos3 > 0) l_base = "fullpat";

    finalize({
        langue,
        permalink     : true,
        xpn_permalink : l_xpn,
        base_permalink: l_base,
        user          : L_services,
        affichagePage : Constante.affichagePage.Recherche
    });
};



/**
 * Redirect the user to the Orbit login page
 *
 * @return boolean
 */
export const navigateToLoginPage = () => {
    const { REACT_APP_IORBIT_PROTOCOL, REACT_APP_IORBIT_HOST } = process.env;

    const timestamp = new Date().getTime();

    localStorage.removeItem('OE');

    // eslint-disable-next-line
    window.location = REACT_APP_IORBIT_PROTOCOL + "://" + REACT_APP_IORBIT_HOST + "/?&delegateFor=" + encodeURIComponent(window.location.href) + "&appName=Express&timestamp=" + timestamp;

    return true;
};


/**
* Get ticket value from session
*
* @return string
*/
export const getSession = () => {
    const { REACT_APP_SECRET } = process.env,
        encryptedTicket = localStorage.getItem('OE');

    return encryptedTicket ? JSON.parse(CryptoJS.AES.decrypt(encryptedTicket, REACT_APP_SECRET).toString(CryptoJS.enc.Utf8))
        : false;
}


/**
* Store ticket to session
*
* @param string ticket The ticket to crypt & store
*
* @return void
*/
const setSession = settings => {
    const { REACT_APP_SECRET } = process.env;
    localStorage.setItem('OE', CryptoJS.AES.encrypt(JSON.stringify(settings), REACT_APP_SECRET));
}


/**
* Obtain ticket from url params or from the local storage
*
* @return self
*/
const handleMemberSession = () => {
    const locationSearch = window.location.search.substring(1),
        urlParams = parseQueryString(locationSearch),
        { ticket } = urlParams || {};

    // There's a ticket provided by url
    if (!_.isUndefined(ticket) && ticket !== false) {
        // Store an encrypted ticket in the session storage
        setSession(urlParams);
    }

    // There's no ticket stored in session, re-log the user
    const sessionSettings = getSession();
    if (!sessionSettings || !sessionSettings.ticket || !sessionSettings.shard) {
        navigateToLoginPage();
    }

    return sessionSettings;
};


/**
 * The following method ensure that the member is properlly connected & authenticated.
 *
 * @return void
 */
const initializeMemberFromOrbit = ({ finalize }) => {
    // Manage session from ticket & local storage
    const sessionSettings = handleMemberSession(),
        tokenNPL          = initializeNpl();

    setSession({
        ...sessionSettings,
        tokenNPL
    })

    // Then initialize the APP
    const langue = loadLanguage(sessionSettings.locale);
    fetchMember({ sessionSettings, langue, tokenNPL, finalize });
}


/**
 * Compute the npl token to access Innovation APi
 *
 * @param {string} settings Ticket, Shards, etc..
 *
 * @return void
 */
const initializeNpl = () => {
    const { REACT_APP_OINNO_JWT_KEY, REACT_APP_OINNO_JWT_SECRET } = process.env,
        { ticket } = getSession();

    return JWT.sign({
        ticket: ticket,
        iss: REACT_APP_OINNO_JWT_KEY
    }, REACT_APP_OINNO_JWT_SECRET, { algorithm: 'HS256' });
}


/**
 * Fetch the member from Orbit API
 *
 * @param {object} settings Authentification settings to authorize the member
 *
 * @return void
 */
const fetchMember = ({ sessionSettings: settings, tokenNPL, langue, finalize }) => {
    AjaxGestion(Constante.AjaxChoix.getDroit, settings).then((data) => {

        if (!data || !data.data || !data.data.services || !_.isArray(data.data.services)) {
            navigateToLoginPage();
            return;
        }

        const user = Object.assign({}, defaultUser),
            services =  data.data.services;

        // Set the user
        user.email = data.data.email;
        user.logon = data.data.email;
        user.prenom = data.data.firstname;
        user.nom = data.data.lastname;
        user.acces.alerte.read = true;
        user.acces.alerte.write = true;
        user.etat = Constante.etat.charge;

        // set services for the current user
        services.forEach((service) => {
            switch (service.name) {
                case 'X_INNOVATION':
                    user.acces.innovation.read = true;
                    user.acces.innovation.write = true;
                    break;
                case 'X_SEARCH_R':
                    user.acces.fampat = true;
                    user.acces.fullpat = true;
                    user.acces.similarite = true;
                    user.acces.folder.read = true;
                    user.acces.liste.read = true;
                    user.acces.note.read = true;
                    break;
                case 'X_SEARCH_RW':
                    user.acces.fampat = true;
                    user.acces.fullpat = true;
                    user.acces.similarite = true;
                    user.acces.liste.read = true;
                    user.acces.liste.write = true;
                    user.acces.folder.read = true;
                    user.acces.folder.write = true;
                    user.acces.note.read = true;
                    break;
                case 'X_WKF_R':
                    user.acces.workfile.read = true;
                    user.acces.comment.read = true;
                    user.acces.cp.read = true;
                    break;
                case 'X_WKF_RW':
                    user.acces.workfile.read = true;
                    user.acces.workfile.write = true;
                    user.acces.comment.read = true;
                    user.acces.comment.write = true;
                    user.acces.cp.read = true;
                    user.acces.cp.write = true;
                    user.acces.note.read = true;
                    user.acces.note.write = true;
                    break;
                case 'X_EXPORTS':
                    user.acces.export = true;
                    break;
                case 'X_GRAPHS':
                    user.acces.graphe = true;
                    break;
                case 'X_PRES_R':
                    break;
                case 'X_PRES_RW':
                    break;
                default:
                    break;
            }
        });

        // Select the default page to land the user after his connection
        const affichagePage = user.acces.fampat ? Constante.affichagePage.recherchesimple
            : (
                (user.acces.workfile || user.acces.liste) ? Constante.affichagePage.projets
                    : Constante.affichagePage.alertes
            );

        window.history.replaceState({}, document.title, "/"); // Replace the ticket&shard part without reload

        finalize({
            user,
            langue,
            tokenNPL,
            affichagePage
        });
    });
}



/**
 * Switch language to the provided one as parameters
 *
 * @param {string} language
 */
const loadLanguage = (language) => {
    let ObjectLangue = {};

    // Prevent wrong session filling
    if (!_.isString(language)) {
        navigateToLoginPage();
        return;
    }

    const data = _.cloneDeep(language.toLowerCase() === 'fr' ? languageFR :
        language.toLowerCase() === 'en' ? languageEN :
        language.toLowerCase() === 'de' ? languageDE :
        language.toLowerCase() === 'ja' ? languageJA : languageZH);

    data.map((element) => {
        element.data.shift(); // Remove label of xlsx column
        ObjectLangue[element.name] = element.data.map(element => {
            return { LIB: element[0] };
        });
        return null;
    });

    return ObjectLangue;
}


export default {};
