import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constante from '../Constante';
import AjaxGestion from '../AjaxGestion';
import './Graphe.css';
import 'amcharts3/amcharts/amcharts';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/xy';
import 'amcharts3/amcharts/themes/light';
import 'ammap3/ammap/ammap';
import 'ammap3/ammap/maps/js/worldLow';
import AmCharts from "@amcharts/amcharts3-react";

class Graphe extends Component {

  constructor() {
    super()
    let l_year = new Date().getFullYear();
    this.state = {
      etat: Constante.etat.initial,
      nbgraphs: 0,
      numGraph: 2,
      dataProvider1: [],
      dataProvider2: [],
      dataProvider3: [],
      dataProvider4: [],
      guidex: [],
      guidey: [],
      statArray: [
        { "query": [{ "type": "FROM_TO", "field": "EPD", "from": l_year - 30, "to": l_year }], "type": Constante.TypeGraphe.Chart, "title": 0 },
        { "query": [{ "type": "TOP", "field": "FPAN", "top": 5 }], "type": Constante.TypeGraphe.Chart, "title": 3 },
        { "query": [{ "type": "TOP", "field": "ORI_COUNTRY", "top": 50 }], "type": Constante.TypeGraphe.Map, "title": 1 },
        { "query": [{ "type": "TOP", "field": "PUB_COUNTRY", "top": 50 }], "type": Constante.TypeGraphe.Map, "title": 2 },
        { "query": [{ "type": "FROM_TO", "field": "EPRD", "from": l_year - 10, "to": l_year - 1 }, { "type": "TOP", "field": "FPAN", "top": 15 }], "type": Constante.TypeGraphe.Bubble, "title": 5 },
        { "query": [{ "type": "TOP", "field": "TECT", "top": 3 }], "type": Constante.TypeGraphe.Chart, "title": 4 },
        { "query": [{ "type": "FROM_TO", "field": "EPRD", "from": l_year - 10, "to": l_year - 1 }, { "type": "TOP", "field": "TECT", "top": 15 }], "type": Constante.TypeGraphe.Bubble, "title": 6 },
        { "query": [{ "type": "TOP", "field": "TECT", "top": 10 }, { "type": "TOP", "field": "FPAN", "top": 10 }], "type": Constante.TypeGraphe.Bubble, "title": 7 }
      ]
    }
  }

  static propTypes = {
    langue: PropTypes.array,
    affichage: PropTypes.object,
    nbDoc: PropTypes.number,
    query: PropTypes.object,
    shard: PropTypes.string,
    ticket: PropTypes.string,
  };

  updateGraph() {
    this.setState({
      nbgraphs: 0,
    });
    // les 2 premieres stats
    this.loadStatistic(1, this.state.statArray[0].query, this.state.statArray[0].type, Constante.etat.encours);
    this.loadStatistic(2, this.state.statArray[1].query, this.state.statArray[1].type, Constante.etat.encours);
    this.loadStatistic(3, this.state.statArray[5].query, this.state.statArray[1].type, Constante.etat.encours);
  }

  generateData = (P_data, P_num, P_type) => {
    // console.log("generateData: " + P_num + " type=" + P_type);
    let dataProvider = [];
    let axis = P_data.axis[0];
    let values = P_data.hits;
    let guidex = [];
    let guidey = [];
    // les axes pour diagramme a bulles
    if (P_type === Constante.TypeGraphe.Bubble) {
      let axis1 = P_data.axis[0];
      let axis2 = P_data.axis[1];
      for (let i = 0; i < axis1.length; i++) {
        // filtrer other pour graphe a bulle
        if (axis1[i] !== 'other') {
          guidex.push({
            boldLabel: true,
            value: i + 1,
            label: axis1[i]
          });
        }
      }
      for (let i = 0; i < axis2.length; i++) {
        guidey.push({
          boldLabel: true,
          value: i + 1,
          label: (axis2[i].length > 30) ? axis2[i].substring(0, 30) + '...' : axis2[i],
          fullLabel: axis2[i]
        });
      }
    }
    // recuperation des data
    for (let i = 0; i < values.length; i++) {
      //console.log(JSON.stringify(values[i]));
      //console.log(axis[values[i].v[0]] + "-->" + values[i].c);
      switch (P_type) {
        case Constante.TypeGraphe.Chart:
          // filtrer other pour graphe a bulle
          if (axis[values[i].v[0]] !== 'other') {
            dataProvider.push({
              entity: axis[values[i].v[0]],
              value: values[i].c
            });
          }
          break;
        case Constante.TypeGraphe.Map:
          // carte geo
          dataProvider.push({
            id: axis[values[i].v[0]],
            value: values[i].c,
            //description: axis[values[i].v[0]]
          });
          break;
        case Constante.TypeGraphe.Bubble:
          // {"c":309,"v":[0,0]}
          if (values[i].v[0] < guidex.length) {
            dataProvider.push({
              value: values[i].c,
              x: values[i].v[0] + 1,
              labx: guidex[values[i].v[0]].label,
              laby: guidey[values[i].v[1]].fullLabel,
              y: values[i].v[1] + 1
            });
          }
          break;
        default:
          break;
      }
    }

    // console.log(JSON.stringify(dataProvider));
    let nbg = this.state.nbgraphs + 1;
    switch (P_type) {
      case Constante.TypeGraphe.Chart:
        switch (P_num) {
          case 1:
            // Graphe du haut
            this.setState({
              nbgraphs: nbg,
              dataProvider1: dataProvider
            });
            break;
          case 2:
            this.setState({
              nbgraphs: nbg,
              numGraph: P_num,
              dataProvider2: dataProvider
            });
            break;
          default:
            this.setState({
              nbgraphs: nbg,
              numGraph: P_num,
              dataProvider4: dataProvider
            });
        }
        break;
      case Constante.TypeGraphe.Bubble:
        this.setState({
          nbgraphs: nbg,
          numGraph: P_num,
          dataProvider2: dataProvider,
          guidex: guidex,
          guidey: guidey
        });
        break;
      case Constante.TypeGraphe.Map:
        this.setState({ nbgraphs: nbg });
        this.setState({
          nbgraphs: nbg,
          numGraph: P_num,
          dataProvider3: []
        }, function () { // called by React after the state is updated
          this.setState({
            dataProvider3: dataProvider
          });
        });
        break;
      default:
        break;
    }
    //console.log(JSON.stringify(this.state.dataProvider4));
    // tout est charge, on affiche
    if (nbg > 2) this.setState({ etat: Constante.etat.charge });
  }

  loadStatistic = (P_num, P_query, P_type, P_state) => {
    this.setState({ etat: P_state });
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard,
      query: this.props.query.requete,
      stats: P_query
    }
    AjaxGestion(Constante.AjaxChoix.getStat, L_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Erreur data !');
        this.setState({ etat: Constante.etat.charge });
        return;
      }
      if (typeof data.erreur !== 'undefined') {
        console.log('Erreur code :' + data.erreur + ' info :' + data.log);
        this.setState({ etat: Constante.etat.charge });
        return;
      }
      
      this.generateData(data, P_num, P_type);
    });
  }

  configMap(P_provider, P_Title) {
    var config = {
      "hideCredits": true,
      "type": "map",
      "theme": "light",
      "colorSteps": 10,
      "color": "grey",
      "allowClickOnSelectedObject": false,
      "titles": [{
        "text": P_Title,
        "size": 13,
        "x": 150,
        "color": "#000066",
        "bold": true
      }],
      "dataProvider": {
        "map": "worldLow",
        "areas": P_provider
      },
      "areasSettings": {
        "autoZoom": true,
        "selectedColor": "#CCCC00",
        "rollOverColor": "#CC0000",
        "balloonText": "[[title]]: [[value]] " + this.props.langue[8].LIB
      },
      "listeners": [{
        "event": "clickMapObject",
        "method": function (ev) {
          //alert(ev.mapObject.title);
        }
      }]
    };
    return config;
  }

  configBarGraph(P_rotate, P_showlabel, P_labeltext, P_provider, P_Title, P_bot) {
    var config = {
      "hideCredits": true,
      "type": "serial",
      "theme": "light",
      "marginLeft": 50,
      "marginTop": 0,
      "marginBottom": P_bot,
      "autoMarginOffset": 0,
      "color": "#555555",
      "titles": [{
        "text": "",
        "color": "black",
        "size": 13
      }],
      "valueAxes": [{
        "id": "v1",
        "axisAlpha": 0,
        "gridAlpha": 0,
        "stackType": "regular",
        "position": "left",
        "ignoreAxisWidth": true,
        "title": "",
        "labelsEnabled": P_showlabel
      }],
      "graphs": [{
        "id": "AmGraph-1",
        "type": "column",
        "balloonText": "[[entity]]:[[value]] " + this.props.langue[8].LIB,
        "valueField": "value",
        "fillAlphas": 0.8,
        "lineAlpha": 0.1,
        "fillColors": ["#D7F3FE", "#B6DDEC"],
        "gradientOrientation": "horizontal",
        "labelText": P_labeltext,
      }],
      "chartCursor": {
        "cursorAlpha": 0,
        "valueLineEnabled": false,
        "valueLineBalloonEnabled": false,
        "categoryBalloonEnabled": false,
        "categoryBalloonAlpha": 0,
        "valueLineAlpha": 0,
        "fullWidth": true
      },
      "categoryField": "entity",
      "rotate": P_rotate,
      "categoryAxis": {
        "gridPosition": "start",
        "position": "left",
        "inside": true,
        "boldLabels": true,
        "axisAlpha": 0,
        "fontSize": 12,
        "labelFunction": function (data) {
          if (typeof data == 'undefined') return data;
          if (data.length > 60) return data.substring(0, 60) + '...'; else return data;
        }
      },
      "dataProvider": P_provider
    };
    return config;
  }
  configAreaGraph(P_rotate, P_showlabel, P_labeltext, P_provider, P_Title, P_bot) {
    var config = {
      "hideCredits": true,
      "type": "serial",
      "theme": "light",
      "marginLeft": 50,
      "marginTop": 0,
      "marginBottom": P_bot,
      "autoMarginOffset": 0,
      "color": "grey",
      "titles": [{
        "text": "",
        "color": "black",
        "size": 1
      }],
      "valueAxes": [{
        "id": "v1",
        "axisAlpha": 0,
        "gridAlpha": 0,
        "position": "left",
        "ignoreAxisWidth": true,
        "title": "",
        "labelsEnabled": P_showlabel
      }],
      "graphs": [{
        "id": "AmGraph-1",
        "type": "smoothedLine",
        "balloonText": "[[entity]]:[[value]] " + this.props.langue[8].LIB,
        "valueField": "value",
        "fillAlphas": 0.2,
        "labelText": P_labeltext,
      }],
      "chartCursor": {
        "cursorAlpha": 0,
        "valueLineEnabled": false,
        "valueLineBalloonEnabled": false,
        "valueLineAlpha": 0.5,
        "fullWidth": true
      },
      "categoryField": "entity",
      "rotate": P_rotate,
      "categoryAxis": {
        "position": "left",
        "minorGridEnabled": true,
        "boldLabels": true,
        "labelFunction": function (data) {
          if (typeof data == 'undefined') return data;
          if (data.length > 30) return data.substring(0, 30) + '...'; else return data;
        }
      },
      "dataProvider": P_provider
    };
    return config;
  }
  configBubbleGraph(P_provider, P_guidex, P_guidey, P_Title) {
    let max_x = P_guidex.length + 1;
    let max_y = P_guidey.length + 1;
    var config = {
      "hideCredits": true,
      "type": "xy",
      "titles": [{
        "text": P_Title,
        "color": "#000066",
        "size": 13
      }],
      "dataProvider": P_provider,
      "valueAxes": [{
        "position": "bottom",
        "axisAlpha": 1,
        "gridAlpha": 0,
        "labelsEnabled": false,
        "autoRotateAngle": 30,
        "autoRotateCount": 2,
        "maximum": max_x,
        "strictMinMax": true,
        "color": "grey",
        "guides": P_guidex
      }, {
        "minMaxMultiplier": 1.0,
        "axisAlpha": 1,
        "gridAlpha": 0,
        "position": "left",
        "labelsEnabled": false,
        "maximum": max_y,
        "strictMinMax": true,
        "color": "grey",
        "guides": P_guidey
      }],
      "graphs": [{
        "balloonText": "<b>[[laby]]</b>: [[value]] " + this.props.langue[9].LIB + " [[labx]]",
        "bullet": "circle",
        "bulletBorderAlpha": 0.2,
        "bulletAlpha": 0.8,
        "lineAlpha": 0,
        "fillAlphas": 0,
        "bulletColor": "#67B7DC",
        "valueField": "value",
        "xField": "x",
        "yField": "y",
        "minBulletSize": 5,
        "maxBulletSize": 40,
        "labelText": " [[value]]",
        "fontSize": 9,
        "color": "grey",
        "labelPosition": "inside"
      }],
      "marginLeft": 15,
      "marginRight": 20,
      "marginBottom": 15
    };
    return config;
  }
  nextGraph = (step) => {
    let l_num = this.state.numGraph + step;
    let l_nb_graph = this.state.statArray.length;
    if (l_num > l_nb_graph) l_num = 2;
    if (l_num <= 1) l_num = l_nb_graph;
    // console.log("nextGraph:" + l_num + "/" + l_nb_graph);
    this.loadStatistic(l_num, this.state.statArray[l_num - 1].query, this.state.statArray[l_num - 1].type, Constante.etat.graphe);
  }

  renderGraph = () => {
    if (this.state.etat === Constante.etat.graphe) {
      return (
        <div id="D_viewer_graphes_load" className="loadgif">
        </div>
      );
    }
    let l_num = this.state.numGraph;
    let l_type = this.state.statArray[l_num - 1].type
    let l_title_index = this.state.statArray[l_num - 1].title;
    let l_title = this.props.langue[l_title_index].LIB
    switch (l_type) {
      case Constante.TypeGraphe.Chart:
        return (
          <AmCharts.React
            className="bar-class"
            options={this.configBarGraph(true, false, "[[value]]", this.state.dataProvider2, l_title, 0)}
          />
        )
      case Constante.TypeGraphe.Map:
        return (
          <AmCharts.React
            className="map-class"
            options={this.configMap(this.state.dataProvider3, l_title)}
          />
        )
      case Constante.TypeGraphe.Bubble:
        return (
          <AmCharts.React
            className="bubble-class"
            options={this.configBubbleGraph(this.state.dataProvider2, this.state.guidex, this.state.guidey, l_title)}
          />
        )
      default:
        return (
          <div id="D_droite_graphes" className="loadgif">
          </div>
        );
    }
  }
  renderTable = (P_provider) => {
    let divs = [];
    let trs = [];
    // console.log(JSON.stringify(this.state.dataProvider4));
    for (let i = 0; i < P_provider.length; i++) {
      let obj = P_provider[i];
      let val = (100 * obj.value) / (this.props.nbDoc);
      trs.push(
        <tr key={i} id={i}>
          <td className='td-class-techno' key={100 + i} id={100 + i}>{obj.entity.trim()}</td><td className='td-class-techno' key={200 + i} id={200 + i} align='right'>{val.toFixed(2)}%</td>
        </tr>
      )
    }
    divs.push(
      <table key="t0" className='table-class-techno'>
        <tbody key="t1">
          {trs}
        </tbody>
      </table>
    )
    return divs
  }
  render() {
    if (this.props.nbDoc < 1 || this.state.etat === Constante.etat.initial) {
      return (
        <div id="D_droite_graphes" style={this.props.affichage.graphe === 1 ? {} : { display: 'none' }}>
        </div>
      );
    }
    if (this.state.etat === Constante.etat.encours) {
      return (
        <div id="D_droite_graphes" className="loadgif">
        </div>
      );
    }
    // les 3 graphes
    return (
      <div id="D_droite_graphes" style={this.props.affichage.graphe === 1 ? {} : { display: 'none' }}>
        <div id="D_droite_graphes_1">
          <span className="titre-class-stat">{this.props.langue[4].LIB}</span>
          {this.renderTable(this.state.dataProvider4)}
        </div>
        <div id="D_droite_graphes_2">
          <span className="titre-class-stat">{this.props.langue[3].LIB}</span>
          <AmCharts.React
            className="bar-class-pa"
            options={this.configBarGraph(true, false, "", this.state.dataProvider2, this.props.langue[3].LIB, 40)}
          />
        </div>
        <div id="D_droite_graphes_3">
          <span className="titre-class-stat">{this.props.langue[10].LIB}</span>
          <AmCharts.React
            className="bar-class-tempo"
            options={this.configAreaGraph(false, false, "", this.state.dataProvider1, this.props.langue[0].LIB, 0)}
          />
        </div>
      </div>
    );
  }
}

Graphe.defaultProps = {
  nbDoc: 0,
  affichage: {}
}

export default Graphe; 