import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'rc-dialog';
import AjaxGestion from '../../AjaxGestion';
import { Treebeard } from 'react-treebeard';
import { Decorators } from '../../mes_projets/TreebeardDeco.js';
import DialogProjet from '../../mes_projets/DialogProjet.js';
import TreebeardMenu from '../../mes_projets/Treebeardmenu';
import Constante from '../../Constante';
import '../../../node_modules/rc-dialog/assets/index.css';
import './DialogProjetTreebeard.css';

class DialogProjetTreebeard extends Component {

  constructor() {
    super();
    this.dialogAdd = React.createRef();
    this.treebeardMenuRef = React.createRef();
    this.state = {
      visible: false,
      erreurDisplay: 'none',
      etat: Constante.etat.encours,
      treeData: {},
      treenode: {
        type: Constante.EmplacementRecherche.RACINE,
        id: "0"
      },
      dialogType: 0,
      dialogHidden: '',
      attenteActive: false
    }
  }

  static propTypes = {
    menuAction: PropTypes.func,
    langue: PropTypes.array,
    user: PropTypes.object
  }

  menuAction = (P_variables) => {
    switch (P_variables.type) {
      case Constante.dialogMode.add:
        this.nodeAddValider(P_variables);
        break;
      case Constante.dialogMode.rename:
        this.nodeRenameValider(P_variables);
        break;
      case Constante.dialogMode.delete:
        this.nodeDeleteValider();
        break;
      default:
        break;
    }
  }

  nodeDelete = (P_node) => {
    this.dialogAdd.current.dialogVisible(true, P_node.name);
    this.setState({
      treenode: P_node,
      dialogType: Constante.dialogMode.delete
    });
  }

  nodeDeleteValider = () => {
    this.setState({
      attenteActive: true
    })
    let L_type = this.state.treenode.type.toUpperCase();
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard,
      DocumentsSelect: ["*"],
      IdRecherche: '/' + this.state.treenode.id,
      emplacement: L_type,
    }
    if (L_type === Constante.EmplacementRecherche.FOLDER) {
      this.nodeDeleteValider2(L_variable);
    }
    else {
      AjaxGestion(Constante.AjaxChoix.listDelete, L_variable).then((data) => {
        if (data === null || typeof data === 'undefined') {
          console.log('Erreur data !');
          return;
        }
        if (typeof data.erreur !== 'undefined') {
          console.log('Erreur code :' + data.erreur + ' info :' + data.log);
          return;
        }
        else {
          this.nodeDeleteValider2(L_variable);
        }
      });
    }
  }

  nodeDeleteValider2 = (P_variable) => {
    AjaxGestion(Constante.AjaxChoix.ProjetsDelete, P_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
      }
      else if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
      }
      else if (data.message.indexOf('deleted') > 0) {
        let L_data = { children: this.state.treeData }
        this.nodeDeleteRecursif(L_data.children, P_variable.IdRecherche.substring(1))
        this.forceUpdate();
      }
      this.setState({
        attenteActive: false
      })
    });
  }

  nodeDeleteRecursif = (P_data, P_id) => {
    if (P_data.children === null || typeof P_data.children === 'undefined') {
      return false;
    }
    for (let L_parcours_data = 0; L_parcours_data < P_data.children.length; L_parcours_data++) {
      if (P_data.children[L_parcours_data].id === P_id) {
        P_data.children.splice(L_parcours_data, 1);
        return true;
      }
      if (this.nodeDeleteRecursif(P_data.children[L_parcours_data], P_id)) {
        return true;
      }
    }
    return false;
  }

  nodeRename = (P_node) => {
    this.dialogAdd.current.dialogVisible(true, P_node.name);
    this.setState({
      treenode: P_node,
      dialogType: Constante.dialogMode.rename
    })
  }

  nodeRenameValider = (P_variables) => {
    this.setState({
      attenteActive: true
    })
    P_variables.id = this.state.treenode.id;
    P_variables.type_folder_list = this.state.treenode.type;
    P_variables.ticket = this.props.ticket;
    P_variables.shard = this.props.shard;
    P_variables.parentID = this.state.treenode.parent;
    AjaxGestion(Constante.AjaxChoix.ProjetsRename, P_variables).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
      }
      else if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
      }
      else if (data.data != null) {
        let L_data = { children: this.state.treeData }
        this.nodeRenameRecursif(L_data.children, P_variables.id, P_variables.name);
        this.forceUpdate();
      }
      this.setState({
        attenteActive: false
      })
    });
  }

  nodeRenameRecursif = (P_data, P_id, P_name) => {
    if (P_data.children === null || typeof P_data.children === 'undefined') {
      return false;
    }
    for (let L_parcours_data = 0; L_parcours_data < P_data.children.length; L_parcours_data++) {
      if (P_data.children[L_parcours_data].id === P_id) {
        P_data.children[L_parcours_data].name = P_name;
        return true;
      }
      if (this.nodeRenameRecursif(P_data.children[L_parcours_data], P_id, P_name)) {
        return true;
      }
    }
    return false;
  }

  nodeAdd = (P_node, P_type_folder_list) => {
    this.dialogAdd.current.dialogVisible(true, '');
    this.setState({
      treenode: P_node,
      dialogType: Constante.dialogMode.add,
      dialogHidden: P_type_folder_list
    })
  }

  nodeAddValider(P_variables) {
    this.setState({
      attenteActive: true
    })
    P_variables.parentID = this.state.treenode.id;
    if (this.state.dialogHidden === Constante.EmplacementRecherche.RACINE) {
      P_variables.type_folder_list = Constante.EmplacementRecherche.FOLDER;
    }
    else {
      P_variables.type_folder_list = this.state.dialogHidden;
    }
    P_variables.ticket = this.props.ticket;
    P_variables.shard = this.props.shard;
    AjaxGestion(Constante.AjaxChoix.ProjetsAdd, P_variables).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
      }
      else if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
      }
      else if (data.data != null) {
        data.data.children = [];
        this.state.treenode.children.splice(0, 0, data.data);
        this.forceUpdate();
      }
      this.setState({
        attenteActive: false
      })
    });
  }

  openMenu = (e) => {
    this.treebeardMenuRef.current.openMenu(e);
  }

  activeNode = (P_node) => {
    this.setState({
      treenode: P_node
    });
  }

  treeloadbranche = (P_id, P_node) => {
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard,
      type: "ALL",
      id: P_id
    }
    AjaxGestion(Constante.AjaxChoix.ProjetsGet, L_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
        return;
      }
      if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
        return;
      }
      let L_racine = {
        id: '',
        name: 'Projects',
        numberOfDocument: 0,
        parent: '',
        shared: false,
        type: 'RACINE',
        toggled: true,
        children: data
      }
      this.setState({
        treeData: L_racine,
        etat: Constante.etat.charge,
        visible: true
      }, () => {
        if (document.getElementById('B_Dialog_Projet_Valider') !== null) {
          document.getElementById('B_Dialog_Projet_Valider').focus();
        }
      });
    });
  }

  dialogVisible = (P_visible) => {
    if (P_visible === true) {
      this.treeloadbranche(0);
    }
  }

  dialogFermer = () => {
    this.setState({
      visible: false,
    });
  }

  onToggle = (node, toggled) => {
    if (node.children) {
      if (node.children.length > 0 || node.type === Constante.EmplacementRecherche.FOLDER) {
        node.toggled = toggled;
      }
    }
    this.forceUpdate();
  }

  render() {
    let L_decorator = Decorators;
    L_decorator.nodeDelete = this.nodeDelete;
    L_decorator.nodeRename = this.nodeRename;
    L_decorator.nodeAdd = this.nodeAdd;
    L_decorator.activeNode = this.activeNode;
    L_decorator.voirListe = null;
    L_decorator.user = this.props.user;
    L_decorator.langue = this.props.langue;
    L_decorator.openMenu = this.openMenu;
    if (this.state.treenode !== null && typeof this.state.treenode !== 'undefined') {
      L_decorator.nodeId = this.state.treenode.id;
    }
    else {
      L_decorator.nodeId = ' inconnu';
    }
    return (
      <Dialog
        title={this.props.langue[22].LIB} 
        visible={this.state.visible} 
        closable={false}
        className="D_dialog_projet_treebeard_global"
      >
        <div id="D_dialog_projet_treebeard_info">
              {this.props.langue[21].LIB}
        </div>
        <TreebeardMenu
          ref={this.treebeardMenuRef}
          langue={this.props.langue}
          user={this.props.user}
          node={this.state.treenode}
          idmenu='dialoglistedoc'
          nodeAdd={this.nodeAdd}
          nodeRename={this.nodeRename}
          nodeDelete={this.nodeDelete} />
        <DialogProjet
          ref={this.dialogAdd}
          name={this.state.treenode.name}
          type={this.state.dialogType}
          nodeType={this.state.treenode.type}
          addType={this.state.dialogHidden}
          menuAction={this.menuAction}
          langue={this.props.langue}
        />
        <div id="D_treebeard">
          <Treebeard
            data={this.state.treeData}
            animations={false}
            decorators={L_decorator}
            onToggle={this.onToggle}
          />
        </div>
        <hr id="HR_dialog_projet_buttons"></hr>
        <div id="D_dialog_Projet_buttons">
          <div id="D_dialog_Projet_gauche">
            <div
              id="D_dialog_Projet_add_folder"
              style={(this.state.treenode.type === Constante.EmplacementRecherche.FOLDER ||
                this.state.treenode.type === Constante.EmplacementRecherche.RACINE) ? {} : { pointerEvents: 'none', color: 'grey', borderColor: 'grey' }}
              onClick={() => this.nodeAdd(this.state.treenode, this.state.treenode.type)}
            >
              {this.props.langue[17].LIB}
            </div>
            <div
              id="D_dialog_Projet_add_list"
              style={this.state.treenode.type === Constante.EmplacementRecherche.FOLDER ? {} : { pointerEvents: 'none', color: 'grey', borderColor: 'grey' }}
              onClick={() => this.nodeAdd(this.state.treenode, Constante.EmplacementRecherche.LIST)}
            >
              {this.props.langue[18].LIB}
            </div>
          </div>
          <div id="D_dialog_Projet_droite">
            <div className="B_cancel_projet" onClick={() => this.dialogFermer()}>
              {this.props.langue[11].LIB}
            </div>
            <div
              id="B_Dialog_Projet_Valider"
              className="B_valider_projet"
              style={(this.state.treenode.type === Constante.EmplacementRecherche.LIST ||
                this.state.treenode.type === Constante.EmplacementRecherche.WORKFILE) ? {} : { pointerEvents: 'none', color: 'grey' }}
              onClick={() => this.props.addDocListWorkfileValider(this.state.treenode)}
            >
              {this.props.langue[16].LIB.toUpperCase()}
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

}

export default DialogProjetTreebeard;
