import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'rc-dialog';
import Constante from '../Constante';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs'; //le mieux mais pas parfait sur les bold i
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../node_modules/rc-dialog/assets/index.css';
import './DialogComment.css';
//import { convertFromHTML } from 'draft-convert';
//import {stateFromHTML} from 'draft-js-import-html';

class DialogComment extends Component {

    constructor() {
        super()
        this.state = {
            id: '',
            name: '',
            comment: null,
            erreurDisplay: 'none',
            erreurDisplayComment: 'none'
        }
    }

    static propTypes = {
        type: PropTypes.bool,
        menuAction: PropTypes.func,
        langue: PropTypes.array,
        open: PropTypes.number,
        commentEditFermer: PropTypes.func
    }

    changeInput = (event) => {
        this.setState({
            name: event.target.value
        });
    }

    changeInputComment = (event) => {
        /*this.setState({
            comment: event.target.value
        });*/

        this.setState({
            comment: event
        });
    }

    dialogVisible = (P_visible, P_id, P_name, P_comment) => {
        P_comment = P_comment.replace(/<font color="/gi, '<font style="color:');//.replace(/<\\font>/gi,'<\span>');
        const blocksFromHtml = htmlToDraft(P_comment);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const L_editor = EditorState.createWithContent(contentState);
        /*
        const options = { customInlineFn: (element, { Style,Entity }) => { 
            if (element.style.color) { return Style('CUSTOM_COLOR_' + element.style.color); // this one
         } } };
        const contentState = stateFromHTML(P_comment, options);
        const L_editor = EditorState.createWithContent(contentState);
*/
        this.setState({
            id: P_id,
            name: P_name,
            comment: L_editor /*EditorState.createWithContent(
                ContentState.createFromBlockArray(
                  convertFromHTML(P_comment)
                )
              )*/
        });

        setTimeout(() => {
            if (this.props.open !== Constante.dialogMode.delete) {
                document.getElementById('I_Dialog_lib').focus();
            }
            else {
                // document.getElementById('B_Dialog_Valider').focus();
            }
        }, 200);
    }

    dialogSave = () => {
        let L_erreur = false;
        let L_comment = '';
        let L_lib = '';
        if (this.props.open !== Constante.dialogMode.delete) {
            L_lib = document.getElementById('I_Dialog_lib').value;
            let L_size_name = L_lib.length;
            if (L_size_name < 2) {
                L_erreur = true;
                this.setState({
                    erreurDisplay: ''
                });
            }
            if (this.state.comment !== null) {
                L_comment = draftToHtml(convertToRaw(this.state.comment.getCurrentContent()));
            }
            if (L_comment.length < 2) {
                L_erreur = true;
                this.setState({
                    erreurDisplayComment: ''
                });
            }
        }
        if (L_erreur) {
            return;
        }
        this.setState({
            erreurDisplay: ''
        });
        this.props.menuAction({
            name: L_lib,
            comment: L_comment,
            type: this.props.open,
            id: this.state.id
        });
    }

    render() {
        let L_titre = '';
        switch (this.props.open) {
            case Constante.dialogMode.rename:
                L_titre = this.props.langue[14].LIB + ': ' + this.state.name;
                break;
            case Constante.dialogMode.add:
                L_titre = this.props.langue[13].LIB;
                break;
            case Constante.dialogMode.delete:
                L_titre = this.props.langue[15].LIB + ': ' + this.state.name;
                break;
            default:
                L_titre = '';
                break;
        }
        if (L_titre.length > 70) {
            L_titre = L_titre.substring(68) + '...';
        }

        if (this.props.open === Constante.dialogMode.delete) {
            return (
                <Dialog title={L_titre}
                    visible={this.props.open === Constante.dialogMode.delete ? true : false}
                    onClose={() => this.dialogFermer()}
                    className="D_dialog_comment">
                    <div>
                        <span className='S_lib_delete'>{this.props.langue[5].LIB} ?</span>
                    </div>

                    <div className="D_buttons">
                        <div id="B_Dialog_cancel" className="B_cancel" onClick={() => this.props.commentEditFermer()}>
                            {this.props.langue[6].LIB}
                        </div>
                        <div id="B_Dialog_Valider" className="B_valider" onClick={() => this.dialogSave()}>
                            {this.props.langue[17].LIB}
                        </div>
                    </div>
                </Dialog>
            );
        }
        return (
            <div
                className="D_dialog_comment"
                style={this.props.open !== 0 ? {} : { display: 'none' }}
            >
                <span className="S_titre">{L_titre}</span>
                <br />
                <div className="D_infos" style={this.props.open === Constante.dialogMode.delete ? { display: 'none' } : {}}>
                    <span className="S_lib">{this.props.langue[16].LIB}</span><br />
                    <input
                        type='text'
                        id='I_Dialog_lib'
                        className='I_lib' onChange={(event) => this.changeInput(event)}
                        value={this.state.name}
                        placeholder={this.props.langue[18].LIB}
                    />
                    <span className='S_lib_error' style={{ display: this.state.erreurDisplay }}>{this.props.langue[4].LIB}</span><br />
                    <br />
                    <span className="S_lib">{this.props.langue[8].LIB}</span><br />
                    <Editor
                        wrapperId='I_Dialog_comment'
                        editorState={this.state.comment}
                        placeholder={this.props.langue[19].LIB}
                        toolbar={{
                            options: ['fontSize', 'inline', 'textAlign', 'list', 'link', 'colorPicker', 'image'],
                            link: {
                                defaultTargetOption: '_blank',
                            },
                            inline: {
                                options: ['bold', 'italic', 'underline'],
                            },
                            list: {
                                options: ['unordered', 'ordered']
                            }
                        }}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={this.changeInputComment}
                    />
                    <span className='S_comment_error' style={{ display: this.state.erreurDisplayComment }}>{this.props.langue[9].LIB}</span><br />
                    <div className="D_buttons">
                        <div id="B_Dialog_cancel" className="B_cancel" onClick={() => this.props.commentEditFermer()}>
                            {this.props.langue[6].LIB}
                        </div>
                        <div id="B_Dialog_Valider" className="B_valider" onClick={() => this.dialogSave()}>
                            {this.props.langue[17].LIB}
                        </div>
                    </div>
                </div>
            </div>
            //</Dialog>
        );
    }

}

export default DialogComment;
