import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import 'antd/dist/antd.css';
import './SelectCustom.css';

const { Option, OptGroup } = Select;

class SelectCustom extends React.Component {

    constructor(props) {
        super();

        _.bindAll(this, 'onChange', 'onBlur', 'onDeselect', 'onClear');

        this.state = {
            selectedItems: props.itemSelected ? props.itemSelected : []
        };
    }

    /*
    * call when the item selected change
    *
    * @param {array} selectedItems list of items selected
    */
    onChange = selectedItems => {

        this.setState({
            selectedItems: typeof selectedItems === "string" && this.props.multiple ? [selectedItems] : selectedItems
        });

        if (this.props.onChange) {
            this.props.onChange(selectedItems);
        }
    }


    /**
     * Change is triggered when we close the select 
     * 
     * @return void 
     */
    onClear() {
        const { multiple } = this.props,
            resetVal = multiple ? undefined : [];

        if (this.props.onBlur) {
            this.props.onBlur(resetVal);
        }

        if (this.props.onChange) {
            this.props.onChange(resetVal);
        }
    }


    /**
     * Change is triggered when we close the select 
     * 
     * @return void 
     */
    onBlur() {
        const { selectedItems } = this.state;

        if (this.props.onBlur) {
            this.props.onBlur(selectedItems)
        }
    }


    /**
     * Change is triggered when we remove an entry 
     * 
     * @param string value The value to remove
     * 
     * @return void 
     */
    onDeselect(value) {
        const { selectedItems } = this.state,
            valuesToKeep = _.without(selectedItems, value);

        this.setState({
            selectedItems: valuesToKeep
        });

        if (this.props.onDeselect) {
            this.props.onDeselect(valuesToKeep)
        }
    }

    /*
    *
    * render for an option group
    *
    * @return {Html} Html elements
    */
    renderOptGroup = () => {
        return this.props.options.map((element, index) => {
            if (element.labelVisible) {
                return (
                    <OptGroup key={"select_group_" + index} label={element.labelVisible ? element.label : " "}>
                        {
                            this.renderOption(element.options)
                        }
                    </OptGroup>
                )
            }
            return this.renderOption(element.options);
        }
        )
    }

    /*
    *
    * render each option
    *
    * @param {array} option array of element for each check items
    */
    renderOption = (option) => {
        const { selectedItems } = this.state;

        return option.map((element, P_index) => {
            return (
                <Option
                    title={element.label}
                    value={element.value}
                    label={element.labelShort ? element.labelShort : element.label}
                    className={(element.disable ? " disable" : "") + (this.props.defaultColor ? " defaultColor" : "")}
                    disabled={(element.disable ? true : false)}>
                    <div className={"selectCustom_select_value_un" +
                        (selectedItems && selectedItems.indexOf(element.value) >= 0 ? " selected" : "")}>
                        <div className="selectCustom_select_value_un_label">
                            {element.label}
                        </div>
                        {element.info && (<div className="selectCustom_select_value_un_info">
                            {element.info}
                        </div>)}
                    </div>
                </Option>
            )
        })
    }

    /*
    * filter who search in the title label et value
    * hide the element who not match
    *
    * @param {string} inputValue text to search
    * @param {object} option is the element compared
    */
    filterOption = (inputValue, option) => {
        const label = option.props.label ? option.props.label : "",
            value = option.props.value ? option.props.value : "",
            title = option.props.title ? option.props.title : "";
        return (label.toLowerCase().indexOf(inputValue) >= 0 || value.toLowerCase().indexOf(inputValue) >= 0 || title.toLowerCase().indexOf(inputValue) >= 0) ? true : false;
    }

    render() {
        const { selectedItems } = this.state,
            selectedItemsAsArray = !_.isArray(selectedItems) && this.props.multiple ? [selectedItems] : selectedItems;
        const sizeMultiplicator = (this.props.size === "small") ? 0.55 : 0.85;
        return (
            <div className={"SelectCustom"}>
                <Select
                    dropdownMatchSelectWidth={false}
                    allowClear={this.props.allowClear}
                    mode={this.props.multiple ? "multiple" : "simple"}
                    style={{ width: '100%', minWidth: (this.props.placeHolder.length) * sizeMultiplicator + 'rem' }}
                    size={this.props.size}
                    placeholder={this.props.placeHolder}
                    defaultValue={this.props.defaultValue}
                    value={selectedItemsAsArray}
                    onChange={this.onChange}
                    onClear={this.onClear}
                    onDeselect={this.onDeselect}
                    onBlur={this.onBlur}
                    filterOption={this.filterOption}
                    optionLabelProp="label"
                    showArrow={true}
                >
                    {this.renderOptGroup()}
                </Select>
            </div>
        )
    }
}


SelectCustom.propTypes = {
    options: PropTypes.array,
    placeHolder: PropTypes.string,
    size: PropTypes.string,
    multiple: PropTypes.bool,
    itemCreate: PropTypes.bool,
    defaultColor: PropTypes.bool,
    itemSelected: PropTypes.array,
    defaultValue: PropTypes.array,
    value: PropTypes.object,
    allowClear: PropTypes.bool,
    onChange: PropTypes.func
};

SelectCustom.defaultProps = {
    options: [],
    placeHolder: "",
    size: "default",
    multiple: false,
    itemSelected: [],
    defaultValue: [],
    value: null,
    onChange: null,
    itemCreate: false,
    defaultColor: true,
    allowClear: false
};

export default SelectCustom
