export default {
    separateur: 'xxwwyyqqzz',
    etat: {//permet de connaitre l'etat d'une variable
        initial: 0,
        encours: 1,
        charge: 2,
        graphe: 3,
        permalink: 4,
        erreur: -1
    },
    AppliMode:{
        default: 'default',
        permaliens: 'parmaliens'
    },
    affichagePage: {
        recherchesimple: 'recherchesimple', //sur la page de Recherche simple
        Recherche: 'recherche', //sur la page de Recherche avancee
        alertes: 'alertes', //sur la page des alertes
        projets: 'projets' //sur la page des projets
    },
    affichageMode: { // permet de varier les modes d'affichage : 
        rechercheavancee: 'rechercheavancee', //on est sur la page classique
        listedoc: 'listedoc', //on est sur la page de liste doc
        Recherche: 'rechercheplus', //on est sur la page de Recherche avancee
    },
    AffichageSelect: {
        graphe: 'select0',
        document: 'select1',
        visioneuse: 'select2',
        documentEtVisioneuse: 'select3'
    },
    EmplacementRecherche: {
        SEMANTIQUE: 'semantic',
        SIMILARITY: 'sim',
        PRIOR_ART: 'priorart',
        FAMPAT: 'FAMPAT',
        FULLPAT: 'FULLPAT',
        WORKFILE: 'WORKFILE',
        LIST: 'LIST',
        FOLDER: 'FOLDER',
        RACINE:'RACINE',
        ALERT:'ALERTE',
        NPL: 'NPL'
    },
    Export: {
        XLSX: 1,
        PDF: 2
    },
    dialogMode: {
        add: 1,
        rename: 2,
        delete: 3,
        addSelect: 4,
    },
    AjaxChoix: {
        Connexion: 11,
        Deconnexion:33,
        listeDoc: 0,
        unDoc: 1,
        ProjetsGet: 2,
        ProjetsDelete: 16,
        ProjetsRename: 17,
        ProjetsAdd:18,
        alertes: 3,
        commentGet:23,
        commentAdd: 4,
        commentUpdate: 5,
        commentDelete: 6,
        userFieldGet:24,
        cpAdd:25,
        cpAddValue:38,
        cpUpdate:26,
        cpDelete:27,
        cpDocValueGet:28,
        cpDocValueAdd:29,
        cpDocValueUpdate:30,
        cpDocValuedelete:31,
        cpGetAll:37,
        AlerteAdd: 7,
        AlerteUpdate: 8,
        AlerteDelete: 9,
        NoteAdd: 10,
        PatentCitation: 12,
        getDroit: 13,
        DeleteHistoRecherche: 14,
        ExportDoc: 15,
        AlerteMonitor: 20,
        listAdd: 21,
        listDelete:32,
        getStat: 22,
        listeDocNPL:34,
        GetConceptLastSearch:35,
        TestConnexion:36,
        InnoAnalyse:39, // NB MAX
        ConceptNPLUpdatePrevious:40,
        permalink: 99
    },
    RequeteOrdre: {
        Relevance: 'RELEVANCE DESC',
        priorite: 'EPRD DESC',
        oldest: 'EPRD ASC',
        max: 100000
    },
    TypeGraphe: {//type des graphes
        Chart: 0,
        Map: 1,
        Bubble: 2,
        Cloud: 3
    },
    RequeteAvancee:{
        DateDebut:0,
        DateFin:1,
        OfficeDeBrevet:2,
        Langues:3,
        Status:4,
        DateType:5,
        Inventeur:6,
        Deposant:7,
        valider:8,
        requete:9,
        cancel:10,
        modeSimple:11,
        modeAvancee:19,
        modeSemantique:12,
        state:13,
        cancelSemantique:14,
        validerSemantique:15,
        requete_semantique:16,
        ModeSimilarite:17,
        ModeSimilariteUn:18,
        ConceptNPLUpdate:20,
        ModePriorArt:21
    },
    RequeteAvanceeCode:{
        TERMES_AND:1,
        TERMES_NOT:2,
        DATES:3,
        ASSIGNEE:4,
        CLASSIFICATION:5,
        PN:6,
        LEGAL:7,
        PUBLICATION_COUNTRY:9,
        INVENTOR:10, 
        TYPENPL:11,
        RDNUMBER:12

    },
    ResultatPage       : [10, 25, 50, 100],
    AjaxAdresseNPL     : process.env.REACT_APP_NPL_ADRESSE,
    AjaxSearch         : '/rest/iorbit/user/search/',
    AjaxShardRessources: '/rest/iorbit/user/resources',
    AjaxShardFields    : '/rest/iorbit/user/fields',
    AjaxShard          : '/rest/iorbit/user',
    AjaxB2B            : 'iorbitv1'
}