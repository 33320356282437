import React, { Component, Fragment }                   from 'react';
import ReactTooltip                                     from 'react-tooltip';
import { contextMenu, Menu, Item, Submenu, Separator }  from 'react-contexify';
import PropTypes                                        from 'prop-types';
import Constante                                        from '../Constante.js';
import _                                                from 'lodash';
import 'react-contexify/dist/ReactContexify.min.css';
import './ListeDocAction.css';


class ListeDocAction extends Component {

  constructor() {
    super()
    this.state = {
    }
  }


  static propTypes = {
    alertCount: PropTypes.number,
    langue: PropTypes.array,
    nbSelectionne: PropTypes.number.isRequired,
    affichageMode: PropTypes.string.isRequired,
    affichageSelect: PropTypes.string.isRequired,
    EmplacementRecherche: PropTypes.string.isRequired,
    addDocListWorkfile: PropTypes.func,
    deleteDocListWorkfile: PropTypes.func,
    similariteSearch: PropTypes.func,
    innoAnalyse: PropTypes.func
  };

  openMenu(e) {
    e.preventDefault();
    //const {x, y, width, height} = document.getElementById("D_liste_doc_action").getBoundingClientRect();
    contextMenu.show({
      id: 'MenuListeDocAction',
      event: e,
      props: {
        posx: 500
      }
    });
  };

  /**
   * Render the insight button to open an Innovation dashboard
   * 
   * @return jsx||false
   */
  renderInsightBtn() {
    
    // Prevent to display the INNOLINK button
    if(!(["NPL", "FAMPAT"].includes(this.props.EmplacementRecherche)) || this.props.nbSelectionne > 0) {
      return false; 
    }

    return (
        <Fragment>
          <Item
            key="1-7"
            className={['innoanalyse', !this.props.user.acces.innovation.read ? "disabled" : ""].join(" ")}
            onClick={this.props.openInnovationDashboard}
          >
            <ReactTooltip className="tooltipcsslistedoc" id="insightTooltip" />
            <div
              className="innoanalyse_div"
              data-for = "insightTooltip"
              data-tip={!this.props.user.acces.innovation.read ? this.props.langue[20].LIB : ""}
            >
              Insights
            </div> 
          </Item>
          <Separator />
        </Fragment>
    );
  }


  render() {
    const insightOnly = this.props.EmplacementRecherche === Constante.EmplacementRecherche.NPL; 
    
    return (
      <div id="D_liste_doc_action">
        <div className="B_liste_doc_export" onClick={(e)=>{
          let event=_.cloneDeep(e);
           setTimeout(this.openMenu,50,event);
           }}></div>
        <Menu id='MenuListeDocAction' className="SM_liste_doc_export">
          {insightOnly  && this.renderInsightBtn()}
          {!insightOnly && [
            <Item
              key="1-1"
              className={this.props.user.acces.similarite ? '' : ' hide'}
              disabled={this.props.nbSelectionne > 0 ? false : true}
              onClick={this.props.similariteSearch}
            >{this.props.langue[0].LIB}</Item>,
            <Separator />,
            <Submenu label={this.props.langue[2].LIB} className={this.props.user.acces.alerte.write ? '' : ' hide'}
              title={<span className='submenu-title-wrapper' >{this.props.langue[2].LIB}</span>} key="1-3"
              disabled={this.props.EmplacementRecherche === Constante.EmplacementRecherche.FAMPAT || this.props.nbSelectionne > 0 ? false : true}
            >
              <Item key="1-3-1"
                disabled={(this.props.EmplacementRecherche === Constante.EmplacementRecherche.FAMPAT && this.props.alertCount<20) ? false : true}
                onClick={() => this.props.addAlert(Constante.dialogMode.add)}
              >
                {this.props.langue[3].LIB}</Item>
              <Item key="1-3-2"
                disabled={this.props.nbSelectionne > 0 ? false : true}
                onClick={() => this.props.addAlert(Constante.dialogMode.addSelect)}
              >
                {this.props.langue[4].LIB}
              </Item>
            </Submenu>,
            <Submenu
              className={this.props.user.acces.export ? '' : ' hide'}
              disabled={this.props.nbSelectionne > 0 ? false : true}
              label={this.props.langue[5].LIB + '  '}
              key="1-4"
            >
              <Item key="1-4-1" onClick={() => this.props.exportDoc(Constante.Export.XLSX)}>XLSX</Item>
              <Item key="1-4-2" onClick={() => this.props.exportDoc(Constante.Export.PDF)}>PDF</Item>
            </Submenu>,
            <Item key="1-5"
              className={(this.props.user.acces.workfile.write || this.props.user.acces.liste.write) ? '' : ' hide'}
              disabled={this.props.nbSelectionne > 0 ? false : true}
              onClick={() => this.props.addDocListWorkfile()}
            >
              {this.props.langue[8].LIB}
            </Item>,
            <Item key="1-6"
              className={(this.props.user.acces.workfile.write || this.props.user.acces.liste.write) ? '' : ' hide'}
              style={((this.props.nbSelectionne > 0) && (this.props.EmplacementRecherche === Constante.EmplacementRecherche.LIST || this.props.EmplacementRecherche === Constante.EmplacementRecherche.WORKFILE)) ? {} : { display: 'none' }}
              onClick={() => this.props.deleteDocListWorkfile()}
            >
              {this.props.EmplacementRecherche === Constante.EmplacementRecherche.LIST ? this.props.langue[9].LIB : this.props.langue[10].LIB}
            </Item>,
            <Separator />,
            // Render innovation link 
            this.renderInsightBtn(),
            <Item key="1-8"
              onClick={() => this.props.selectDoc(0, 0, false, true)}
            >
              {this.props.langue[7].LIB}
            </Item>,
            <Item key="1-9"
              disabled={this.props.nbSelectionne > 0 ? false : true}
              onClick={() => this.props.selectDoc(0, 0, true, false)}
            >
              {this.props.langue[6].LIB}
            </Item>
          ]}
          </Menu>
        </div>
        
    );
  }
}

ListeDocAction.defaultProps = {
  langue: null,
  nbSelectionne: 0,
  affichageMode: Constante.affichageMode.rechercheavancee,
  affichageSelect: Constante.AffichageSelect.graphe
}

export default ListeDocAction; 