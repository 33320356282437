import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PARSER from 'html-react-parser';
import { regExpEscape } from '../../utils/text';
import _ from "lodash";
import './autocomplete.css';



class AutoComplete extends React.Component {

    constructor() {
        super();
        this.state = {
            actif: 0,
            SelectElementvalue: "",
            autoCompletevisible: false
        }
        this.search = null;
        this.resizeCall = true;
    }

    componentDidMount() {
        this.resizeSearch();
    }

    componentDidUpdate(prevProps) {
        if (this.resizeCall || this.props.value.length - prevProps.value.length > 1) {
            this.resizeCall = false;
            this.resizeSearch();
        }
    }

    /*
    * resize the textarea width and height with the text in
    *
    *
    */
    resizeSearch = () => {
        let L_elem = document.getElementById(this.props.id).getElementsByTagName("textarea")[0];
        if (L_elem === null) {
            return;
        }
        const value = this.props.value;
        if (value.length < 2) {
            L_elem.style.width = '150px';
            L_elem.style.height = 'auto';
            return;
        }
        let L_parent_width = document.getElementsByClassName("D_avancee_termes")[0].clientWidth;
        let L_width_elem = parseInt(getComputedStyle(L_elem).fontSize);

        let L_span = document.getElementById('S_mesure_texte');
        L_span.innerHTML = value;
        L_width_elem = L_span.offsetWidth * 1.02;
        if (L_width_elem + 20 < L_parent_width - 70) {
            let L_witdh_avance = L_width_elem;
            L_elem.style.width = (L_witdh_avance + 20) + 'px';
            let L_iteration = 0;
            while (L_iteration < 20 && L_elem.clientWidth < L_width_elem && L_witdh_avance + 20 < L_parent_width - 70) {
                L_witdh_avance += 20;
                L_iteration++;
                L_elem.style.width = (L_witdh_avance) + 'px';
            }
        }
        else {
            L_elem.style.width = (L_parent_width - 70) + 'px';
        }
        // set the height to 0 in case of it has to be shrinked
        L_elem.style.height = 'auto';
        L_elem.style.height = Math.max(22, L_elem.scrollHeight) + 'px';
    }

    /*
    * Change is triggered when the focus out
    *
    */
    onBlur = (event) => {
        setTimeout(() => {
            if (this.state.autoCompletevisible) {
                this.resizeSearch();
                this.setState({
                    autoCompletevisible: false,
                    SelectElementvalue: ""
                });
            }
        }, 500);
    }

    /*
    * Change is triggered when the textarea get focus
    *
    */
    onFocusTextarea = (event) => {
        event.preventDefault();
        if (this.state.actif === 1) {
            this.setState({
                actif: 0
            });
        }
        if (this.props.value.length > 1 && this.state.autoCompletevisible == false) {
            this.getOptionsLaunch(this.props.value);
            this.setState({
                autoCompletevisible: true
            }, () => {
                setTimeout(() => {
                    document.getElementById(this.props.id).getElementsByTagName("input")[0].focus();
                }, 100);
            })
        }
    }

    /*
    * Change is triggered when the keydown on the texarea
    *
    */
    onKeyDownTextarea = (event) => {
        if (event.key === 'Enter') {
            this.props.validate();
            this.resizeCall = true;
            event.stopPropagation();
            return false;
        }
        if (this.state.actif === 0 && this.state.autoCompletevisible == false) {
            this.getOptionsLaunch(this.props.value);
            this.setState({
                autoCompletevisible: true
            }, () => {
                setTimeout(() => {
                    document.getElementById(this.props.id).getElementsByTagName("input")[0].focus();
                }, 100);
            })
        }
    }

    /*
    onKeyDown on the input text
    enter: validate text
    down and up : select the element in the list
    escape : close the autcomplete
    */
    onKeyDown = (event) => {
        const value = (this.state.SelectElementvalue !== "") ? this.state.SelectElementvalue : this.props.value
        if (event.key === 'Enter') {
            this.props.onChange(value);
            this.setState({
                autoCompletevisible: false,
                actif: 1,
                SelectElementvalue: ""
            }, () => {
                this.props.ElementNext();
                this.resizeCall = true;
            });
            event.stopPropagation();
            return false;
        }
        if (event.key === 'Escape') {
            document.getElementById(this.props.id).getElementsByTagName("textarea")[0].focus();
            this.setState({
                autoCompletevisible: false,
                actif: 1,
                SelectElementvalue: ""
            }, () => {
                this.resizeCall = true;
            });
            return false;
        }
        if (event.key === 'ArrowDown') {
            this.selectElement(1);
            return false;
        }

        if (event.key === 'ArrowUp') {
            this.selectElement(-1);
            return false;
        }
        return true;
    }

    /*
    click on the element in the autocomplete list to select one
    */
    onClick = (value) => {
        this.setState({
            autoCompletevisible: false,
            actif: 1,
            SelectElementvalue: ""
        });
        this.props.onChange(value);
        this.props.ElementNext();
        this.resizeCall = true;
    }

    /*
    value on the input text change :
    update the value and launch a search
    */
    onChange = (event) => {
        const value = event.target.value.replace(/[\r\n]+/g, "");
        if (this.state.actif === 0) {
            this.setState({
                SelectElementvalue: ""
            });
            this.props.onChange(value);
            clearTimeout(this.search);
            this.search = setTimeout(() => { this.getOptionsLaunch(value) }, 500);
        }
    }

    /*
    value on the textarea change
    */
    OnchangeTextarea = (event) => {
        const value = event.target.value.replace(/[\r\n]+/g, "");
        this.props.onChange(value);
        if (this.state.actif === 1 && this.setState.SelectElementvalue !== "") {
            this.setState({
                SelectElementvalue: ""
            });
        }
        this.resizeCall = true;
        return true;
    }

    /*
    search the element to select une the autocomplete list
    1 -> next element
    -1 -> previous element
    */
    selectElement = (BackOrForward) => {
        if (this.state.SelectElementvalue === "") {
            if (this.props.options) {
                if (this.props.options.length > 0) {
                    if (this.props.options[0].options.length > 0) {
                        this.setState({
                            SelectElementvalue: this.props.options[0].options[0].value
                        });
                    }
                }
            }
            return;
        }
        let SelectElementvaluePrec = null,
            SelectElementvalueNext = null,
            find = false;
        this.props.options.map((element, indexOptGroup) => {
            element.options.map((elementOption, indexOption) => {
                if (this.state.SelectElementvalue === elementOption.value) {
                    find = true;
                    if (indexOption + 1 < element.options.length) {
                        SelectElementvalueNext = element.options[indexOption + 1].value;
                    }
                    if (indexOption + 1 >= element.options.length) {
                        if (indexOptGroup + 1 < this.props.options.length) {
                            if (this.props.options[indexOptGroup + 1].options.length > 0) {
                                SelectElementvalueNext = this.props.options[indexOptGroup + 1].options[0].value;
                            }
                        }
                    }
                }
                if (!find) {
                    SelectElementvaluePrec = elementOption.value;
                }
            });
        });
        if (BackOrForward > 0 && SelectElementvalueNext !== null) {
            this.setState({
                SelectElementvalue: SelectElementvalueNext
            });
        }

        if (BackOrForward < 0 && SelectElementvaluePrec !== null) {
            this.setState({
                SelectElementvalue: SelectElementvaluePrec
            });
        }
    }

    /*
    get the option array
    */
    getOptionsLaunch = (value) => {
        this.props.getOptions(value);
    }

    /*
    render for the option group
    */
    renderOptGroup = () => {
        return this.props.options.map((element, index) => {
            if (element.labelVisible) {
                return (
                    <div key={"select_group_" + index} label={element.labelVisible ? element.label : " "}>
                        {
                            this.renderOption(element.options)
                        }
                    </div>
                )
            }
            return this.renderOption(element.options);
        }
        );
    }

    /*
    render for an option of type organisation
    */
    renderOptionOrg = (element, index) => {
        const SelectElementvalue = this.state.SelectElementvalue ? this.state.SelectElementvalue : "";
        return (
            <div
                key={"option_" + index}
                title={element.label}
                value={element.value}
                label={element.labelShort ? element.labelShort : element.label}
                disabled={(element.disable ? true : false)}
                style={(element.logo !== null ? { backgroundImage: "url(data:image/png;base64," + element.logo + ")" } : {})}
                className={"autcomplete_option_val_un" +
                    (SelectElementvalue === element.value ? " selected" : "") +
                    (element.logo !== null ? " logo" : "") +
                    (element.disable ? " disable" : "") +
                    (this.props.defaultColor ? " defaultColor" : "")}>
                <div className="autcomplete_option_val_un_label">
                    {element.label}
                </div>
                {element.info2 && (<div className="autcomplete_option_val_un_info2">
                    {element.info2}
                </div>)}
                {element.info && (<div className={"autcomplete_option_val_un_info" +
                    (element.country ? " country" : "")}
                    style={{ backgroundImage: 'url(/images/flag/' + element.country.toLowerCase() + '.svg)' }}
                >
                    {element.info}
                </div>)}
            </div>
        );
    }

    /*
    render for default option
    */
    renderOptionDefault = (element, index) => {
        const { SelectElementvalue="" } = this.state,
            labelUnaccented             = (this.props.value.length > 0) ? _.deburr( this.props.value.toLowerCase(), ): "",
            labelClean                  = labelUnaccented.replace(/"[^0-9a-zA-Z]|_/gi, ""),
            label                       = (labelClean < 2) ? element.label : _.deburr(element.label,).toLowerCase().replace(new RegExp(regExpEscape(labelClean), 'gi'), '<font class="autcomplete_option_val_un_label_value">' + labelClean + '</font>');
        return (
            <div
                key={"option_" + index}
                className={"autcomplete_option_val_un" +
                    (SelectElementvalue === element.value ? " selected" : "") +
                    (element.disable ? " disable" : "") + (this.props.defaultColor ? " defaultColor" : "")}
                title={element.label}
                value={element.value}
                label={element.labelShort ? element.labelShort : element.label}
                onClick={(event) => this.onClick(element.value)}
            >
                <div className="autcomplete_option_val_un_label">
                    {PARSER(label)}
                </div>
                {element.info && (<div className="autcomplete_option_val_un_info">
                    {element.info}
                </div>)}
            </div>
        );
    }

    /*
    choose the right option type
    */
    renderOption = (option) => {
        return option.map((element, index) => {
            switch (element.type) {
                case "org":
                    return this.renderOptionOrg(element, index);
                    break;
                default:
                    return this.renderOptionDefault(element, index);
                    break;
            }
        });
    }

    render() {
        const options = this.renderOptGroup();
        return (
            <div id={this.props.id} className="AutoCompleteOrbitD">
                <textarea
                    rows='1'
                    type="text"
                    placeholder={this.props.placeholder}
                    onChange={this.OnchangeTextarea}
                    onKeyDown={this.onKeyDownTextarea}
                    onFocus={this.onFocusTextarea}
                    value={this.props.value}
                    data-resizable="true"
                >
                </textarea>
                {this.state.autoCompletevisible && (<div className="AutoCompleteOrbitDF" id={this.props.id + "_choix"}>
                    <input type="text"
                        className="AutoCompleteOrbitInput"
                        value={this.props.value}
                        onChange={this.onChange}
                        onBlur={this.onBlur}
                        onKeyDown={this.onKeyDown} />
                    <div className="AutoCompleteOrbitOtpionList">
                        {options}
                    </div>
                </div>)}
            </div>
        )
    }
}


AutoComplete.propTypes = {
    id: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array,
    defaultColor: PropTypes.bool,
    getOptions: PropTypes.func,
    onChange: PropTypes.func,
    onChange: PropTypes.func
};

AutoComplete.defaultProps = {
    id: "",
    value: "",
    placeholder: "",
    options: [],
    defaultColor: true,
    getOptions: null,
    onChange: null
};

export default AutoComplete
