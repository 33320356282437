/**
 * Some utils around highlight while waiting store operations around this features
 * 
 * 
 */
import _ from 'lodash';
import { decomposeAsConcept } from './queryRules';
import AjaxGestion, { get, getOrbit } from '../AjaxGestion';


/**
 * Default set of colors for hightlights 
 */
/* TODO: TRANSLATE */
export const HL_COLORS = [
    {
        color: '#FFFF00',
        textColor: '#212121',
        label: 'Surbrillance en jaune',
    },
    {
        color: '#FF00FF',
        textColor: '#212121',
        label: 'Surbrillance en magenta',
    },
    {
        color: '#00FFFF',
        textColor: '#212121',
        label: 'Surbrillance en cyan',
    },
    {
        color: '#00FF00',
        textColor: '#212121',
        label: 'Surbrillance en vert',
    },
    {
        color: '#FF4500',
        textColor: '#212121',
        label: 'Surbrillance en rouge',
    },
    {
        color: '#C0C0C0',
        textColor: '#212121',
        label: 'Surbrillance en gris',
    },
    {
        color: '#FF8802',
        textColor: '#212121',
        label: 'Surbrillance en orange',
    },
    {
        color: '#FF7FD3',
        textColor: '#212121',
        label: 'Surbrillance en rose',
    },
    {
        color: '#0065B8',
        textColor: '#FFFFFF',
        label: 'Surbrillance en bleu',
    },
    {
        color: '#49762f',
        textColor: '#FFFFFF',
        label: 'Surbrillance en vert foncé',
    },
    {
        color: '#891100',
        textColor: '#FFFFFF',
        label: 'Surbrillance en rouge foncé',
    },
    {
        color: '#757575',
        textColor: '#FFFFFF',
        label: 'Surbrillance en gris foncé',
    }
];

// Simple iterator to iterate over highlights color  
let colorIterator = 0;
let previousProfile = null;

/**
 * Will set the color iterator to 0
 * 
 * @return void 
 */
const resetColorIterator = () => {
    colorIterator = 0;
}


/**
 * Create a new highlights profile from search request or concepts array 
 *
 * @param {string|array} searchStringOrConcepts 
 * 
 * @return {promise}
 */
export const generateProfile = ({ searchStringOrConcepts, highlightsProfile, highlightsProfileFromWorkfile = {} }) => new Promise(resolve => {
    const isAString = _.isString(searchStringOrConcepts),
        decomposedConcepts = decomposeAsConcept(searchStringOrConcepts);

    // No search concepts added, by example a search from assignees
    if (!isAString && !_.without(_.flattenDeep(searchStringOrConcepts), "").length) {
        resolve({
            ...highlightsProfile,
            enabled: true,
            hls: []
        });
        return;
    }

    // Start from a fresh array 
    resetColorIterator();

    // Create profiles 
    fetchRegexpsFromConcepts(decomposedConcepts).then(termsAndRegexp => {
        const highlights = isAString ? [termsAndRegexp]
            : decomposedConcepts.map(concepts => {
                return _.pick(termsAndRegexp, concepts);
            });

        resolve(
            {
                ...highlightsProfile,                                     // Ensure to keep previous highlight profile settings
                enabled: true,
                hls: _.concat(
                    highlightsProfileFromWorkfile.hls || [],
                    highlights.map(hl => {
                        const newHl = generateProfileItemFromRegexpAndTerm(hl, highlightsProfileFromWorkfile.hls);
                        return newHl;
                    })
                ) // But, replace with new profile entries
            }
        );
    });
});



/**
 * Create a new highlights profile from search request or concepts array 
 *
 * @param {string|array} searchStringOrConcepts 
 * 
 * @return {promise}
 */
export const generateProfileForWorkfile = workfileId => new Promise(resolve => {
    const url = `/highlight/workfile${workfileId}`;

    getOrbit(url).then(resp => {
        const { data } = resp,
            { hls } = data ? data : { hls: [] };

        resolve({
            enabled: true, // Force highlights to be enabled when the workfile has been open 
            hls: hls.filter(highlight => highlight.text !== "").map((hlsOne, index) => {
                hlsOne.textColor = HL_COLORS[index % HL_COLORS.length].textColor;
                return hlsOne;
            })
        })
    });
});


/**
 * Fetch regexp from query tools
 * 
 * @param {array} concepts An array of concepts 
 * 
 * @return {promise}
 */
const fetchRegexpsFromConcepts = (concepts) => {

    const { REACT_APP_OINNO_ROOT } = process.env,
        flattenConcepts = _.flattenDeep(concepts),
        // Filter multigram.
        filteredConcepts = flattenConcepts.filter(concept => !concept.match(' ') && concept.trim() !== ''),
        multiGram = flattenConcepts.filter(concept => concept.match(' ')),
        conceptsString = `?terms=${filteredConcepts.map(encodeURIComponent).join('&terms=')}`, // Encode special chars, then create string.
        fetchUrl = `${REACT_APP_OINNO_ROOT}/query-tools-api/expand${conceptsString}`;

    return new Promise(resolve => {
        if (filteredConcepts.length === 0) {
            const terms = {};
            multiGram.forEach(concept => {
                terms[concept] = concept;
            })
            resolve(multiGram.length ? terms : []);
            return;
        }

        get(fetchUrl).then(terms => {
            const termsToHighlight = {};
            if (terms.erreur === null || typeof terms === "undefined") {
                resolve(termsToHighlight);
                return;
            }
            // Protect extended forms to be used in Express. (BZ#84179)
            for (const index in terms) {
                termsToHighlight[index] = terms[index].filter(term => {
                    // Match the exact term BZ#84179
                    return index.match(/[^a-z]/gi)
                        ? term
                        : index.toLowerCase().match(term.replace(/[^a-z]/gi, '').toLowerCase());
                });
            }

            // Reinject mutligrams. 
            multiGram.forEach(concept => {
                termsToHighlight[concept] = concept;
            })

            resolve(termsToHighlight);
        });
    });
}


/**
 * Create a new highlights profile from search request or concepts array 
 *
 * @param {array}  regexps A list of radical terms as regexp to hightlight 
 * @param {string} concept Original concept/word 
 * 
 * @return {object}
 */
const generateProfileItemFromRegexpAndTerm = (highlight, hlsFromWorkfiles = []) => {
    const concepts = _.keys(highlight),
        regexps = _.flattenDeep(_.values(highlight)),
        conceptsAsAstring = concepts.join(', '),
        usedColorsFromWF = hlsFromWorkfiles.map(hl => hl.color),
        filteredColor = HL_COLORS.filter(mdl => !usedColorsFromWF.includes(mdl.color));

    // No more colors, reset iterator
    if (!filteredColor[colorIterator]) {
        resetColorIterator();
    }

    const profileItem = {
        enabled: true,
        textColor: filteredColor[colorIterator] ? filteredColor[colorIterator].textColor : filteredColor[0].textColor,
        color: filteredColor[colorIterator] ? filteredColor[colorIterator].color : filteredColor[0].color,
        text: conceptsAsAstring,
        selectedTooltip: conceptsAsAstring,
        unSpecializedText: conceptsAsAstring,
        termRegExp: enforceRegexp(`(${regexps.join(')|(')})`), // Add some superpower to the provided regexp.
        wordsRegExp: null,
    };

    // Update the iterator. 
    colorIterator++;

    return profileItem;
}


/**
 * Get the final regexp from a provided concept 
 *
 * @param {string} concept Original concept/word 
 * 
 * @return {promise}
 */
export const getProfileItemFromConcepts = concepts => {
    return new Promise(resolve => {

        // Create profiles 
        fetchRegexpsFromConcepts(concepts).then(termsAndRegexp => {
            resolve(
                generateProfileItemFromRegexpAndTerm(termsAndRegexp)
            );
        })
    });
}


/**
 * Add some extra-power to the QueryTool provided regexp
 * 
 * @param {string} regexp The weak regexp 
 * 
 * @return string 
 */
const enforceRegexp = (weakString) => {
    const proximityOperators = ['F', 'S', 'P', 'D', '\dD', '=\dD', 'W', '\dW', '=\dW'],
        proximityRegexp = new RegExp(` ${proximityOperators.join(' | ')} `, 'gi');

    return weakString.replace(proximityRegexp, '|')
        .replace(/\*/gi, '[\\w-]*')
        .replace(/\?/gi, '[\\w]?')
        .replace(/\#/gi, '[\\w]+')
        // Manage +concept+, +concept, concept+
        .replace(/\+([a-z-]*)\+/gi, "[a-z]*$1[a-z]*")
        .replace(/\+([a-z-]*)/gi, "[a-z]*$1")
        .replace(/([a-z-]*)\+/gi, "$1[a-z]*")
        // Multigram are possibly surrounded by <b> converted as #_= 
        .replace(/ /gi, '[ #_=]+');
}
