import React, { Component } from 'react';
import { contextMenu, Menu, Item } from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';
import PropTypes from 'prop-types';
import './AlertMenu.css';


class AlertMenu extends Component {

  constructor() {
    super()
    this.state = {
      name:'',
      index:0
    }
  }

  static propTypes = {
    langue: PropTypes.array,
    alertDelete: PropTypes.func,
    alertRename: PropTypes.func,
  };

  openMenu(e,i,P_name) {
    this.setState({
      name:P_name,
      index:i
    });
    e.preventDefault();
    //const {x, y, width, height} = document.getElementById("D_liste_doc_action").getBoundingClientRect();
    contextMenu.show({
      id: 'M_alerte_query',
      event: e,
      props: {
        posx: 500
      }
    });
  };

  render() {
    return (
        <Menu id="M_alerte_query" triggerSubMenuAction="click" >
          <Item key="1-1" className="M_alert_edit" onClick={() => this.props.alertRename(this.state.name, this.state.index)}>{this.props.langue[2].LIB}</Item>
          <Item key="1-2" className="M_alert_delete" onClick={() => this.props.alertDelete(this.state.name, this.state.index)}>{this.props.langue[3].LIB}</Item>
        </Menu>
    );
  }
}

AlertMenu.defaultProps = {
  langue: null,
  name: ''
}

export default AlertMenu; 