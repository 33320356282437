
import Constante from '../../Constante';
import { navigateToLoginPage, getSession } from '../../utils/auth.js'


export const conceptLoad = async (value) => {
    if(value.length<1){
        return [];
    }
    try {
        const elemList = await conceptFetch(value);
        return elemList.map((element, index) => {
            return { label: element, labelShort: element, value: element };
        });
    } catch (error) {
        return [];
    }
}

const conceptFetch = (value) => new Promise((resolve, reject) => {
    const { ticket, shard, tokenNPL } = getSession(),
        { REACT_APP_OINNO_ROOT } = process.env,
        HeaderParam = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'application': 'Express',
                'Authorization': `Bearer ${tokenNPL}`
            },
            body: null
        };

    fetch(REACT_APP_OINNO_ROOT + "/public-data-api/concepts?limit[max]=5&q="+(value.length>0?encodeURIComponent(value):"a"), HeaderParam)
    .then(res => {
        const { status } = res; 
        if(status === 206) {
            setTimeout(
                async () => resolve(await conceptFetch(value)),
                1000
            );
            return false; 
        }
        if(status === 204) {
            return [];
        }
        return res.json();
    })
    .then((responseJson) => {
        // Prevent resolving the promise for 206 status 
        if(!responseJson) {
            return; 
        }
        resolve(responseJson);
    }).catch((error) => {
        reject([]);
    });
}
);