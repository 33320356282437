import React, { Component } from 'react';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import '../rc_menu_css/index.css';
import PropTypes from 'prop-types';
import './CommentaireAction.css';


class CommentaireAction extends Component {

  constructor() {
    super()
    this.state = {
      openKeys:[]
    }
  }


  static propTypes = {
    langue: PropTypes.array,
    editComment: PropTypes.func,
    deleteComment: PropTypes.func
  };

  onOpenChange = (openKeys) => {
    this.setState({ 
      openKeys: openKeys
    });
  }

  editComment= () =>{
    this.props.editComment();
    this.setState({ 
      openKeys: []
    });
  }

  deleteComment= () =>{
    this.props.deleteComment();
    this.setState({ 
      openKeys: []
    });
  }

  render() {
    return (
      <Menu openKeys={this.state.openKeys} mode="horizontal" triggerSubMenuAction="click" selectable={false} onOpenChange={this.onOpenChange}>
        <SubMenu title={<div className="B_comment_action">&bull;&bull;&bull;</div>} key="1" className="SM_comment_action">
          <MenuItem
            key="1-1"
            className={''}
            onClick={this.editComment}
          > 
          <img src=".\images\edit.svg" alt={this.props.langue[8].LIB} onClick={this.editComment} />{this.props.langue[7].LIB}
          </MenuItem>
          <MenuItem
          key="1-2"
          className={''}
          onClick={this.deleteComment}
        >
        <img src=".\images\delete.svg" alt={this.props.langue[7].LIB} onClick={this.deleteComment} />{this.props.langue[8].LIB}
        </MenuItem>
         </SubMenu>
      </Menu>
    );
  }
}

CommentaireAction.defaultProps = {
  langue: null,
  nbSelectionne: 0,
  editComment: ()=>{},
  deleteComment: ()=>{}
}

export default CommentaireAction; 