import { notification } from 'antd';
import 'antd/dist/antd.css';
import './notification.css';


/*
Open a trigger for notifiate the user when a problem occured

*/
export const trigger = (type,title, description) => {
    notification[type]({
        message: title,
        description: description
    });
};