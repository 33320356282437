import React, { Component } from 'react';
import Constante from '../Constante.js';
import PropTypes from 'prop-types';
import './DocumentVisioneuse.css';


class DocumentVisioneuse extends Component {

    constructor() {
        super()
        this.state = {
            rotate: 0,
            zoom: 1,
            movex: 0,
            movey: 0,
            moveActif: false,
            posDepartX: 0,
            posDepartY: 0,
            fullSize: false
        }
    }

    static propTypes = {
        langue: PropTypes.array,
        affichage: PropTypes.object,
        documentUn: PropTypes.object,
        changeSelectOuMode: PropTypes.func,
        layerFront: PropTypes.func
    };

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if(prevProps.documentUn!==this.props.documentUn){
            this.reset();
        }
    }

    change_select_image = (P_pos, P_imgUrl) => {
        this.props.documentUn.data.PosImage = P_pos;
        this.reset();
    }

    fullSize = (P_full) => {
        if (P_full === null) {
            this.props.layerFront(!this.state.fullSize);
            this.setState({
                fullSize: !this.state.fullSize
            });
        }
        else {
            this.props.layerFront(P_full);
            this.setState({
                fullSize: P_full
            });
        }
    }

    imageChoix = (P_avance, P_pos) => {
        if (P_pos > 0) {
            this.props.documentUn.data.PosImage = P_pos;
        }
        else {
            this.props.documentUn.data.PosImage += P_avance;
            if (this.props.documentUn.data.PosImage < 0) {
                this.props.documentUn.data.PosImage = this.props.documentUn.data.IMG.length - 1;
            }
            else if (this.props.documentUn.data.PosImage >= this.props.documentUn.data.IMG.length) {
                this.props.documentUn.data.PosImage = 0;
            }
        }
        this.reset();
    }
    mousewheel = (P_event) => {
        if (P_event.eventPhase === 3) {
            if (P_event.deltaY > 0) {
                this.scale(-1);
            }
            else {
                this.scale(1);
            }
        }
    }

    moveActif = (P_event) => {
        this.setState({
            moveActif: true,
            posDepartX: P_event.clientX - this.state.movex,
            posDepartY: P_event.clientY - this.state.movey
        })
    }


    moveInactif = () => {
        this.setState({
            moveActif: false
        })
    }

    move = (P_event) => {
        if (this.state.moveActif) {
            this.setState({
                moveActif: true,
                movex: P_event.clientX - this.state.posDepartX,
                movey: P_event.clientY - this.state.posDepartY
            })
        }
    }

    rotate = (P_angle) => {
        let L_angle = this.state.rotate + P_angle * 45;
        this.setState({
            rotate: L_angle
        });
    }

    scale = (P_zoom) => {
        let L_zoom = this.state.zoom + P_zoom * 0.2;
        if (L_zoom < 0.2) {
            L_zoom = 0.2;
        }
        this.setState({
            zoom: L_zoom
        });
    }

    reset = () => {
        this.setState({
            rotate: 0,
            zoom: 1,
            movex: 0,
            movey: 0,
            moveActif: false,
            posDepartX: 0,
            posDepartY: 0
        });
    }

    render() {
        if (this.props.documentUn.etat === Constante.etat.encours) {
            return (
                <div id="D_droite_visionneuse">
                </div>
            );
        }
        if (!this.props.documentUn.data.IMG?.length) {
            return (<div id="D_droite_visionneuse" style={(this.props.affichage.visioneuse === 1) ? {} : { display: 'none' }}></div>);
        }
        let L_url_en_cours=this.props.documentUn.data.IMG[this.props.documentUn.data.PosImage];
        L_url_en_cours=L_url_en_cours.replace(/width=[0-9]*&/gi,"width=800&").replace(/height=[0-9]*&/gi,"height=800&")
        return (
            <div id="D_droite_visionneuse" className={this.state.fullSize ? 'fullSize' : ''} style={(this.props.affichage.visioneuse === 1) ? {} : { display: 'none' }}>
                <div id="D_droite_visionneuse_head">
                    <div id="D_droite_visionneuse_head_centre">
                        <div id="D_droite_visionneuse_head_flex">
                        <div id="D_droite_visionneuse_head_centre_prec" onClick={() => this.imageChoix(-1, 0)} ></div>
                        <div id="D_droite_visionneuse_head_tours_next" onClick={() => this.imageChoix(1, 0)}></div>
                        <div id="D_droite_visionneuse_head_centre_pos">{this.props.documentUn.data.PosImage + 1}/{this.props.documentUn.data.IMG.length}</div>
                        </div>
                    </div>
                    <div id="D_droite_visionneuse_head_close"
                    onClick={() => { this.fullSize(false); this.props.changeSelectOuMode(Constante.AffichageSelect.document) }}
                    ></div>
                    <div id="D_droite_visionneuse_head_full_screen" onClick={() => this.fullSize(null)}></div>
                </div>

                <div id="D_droite_visionneuse_milieu">
                    <div id="D_droite_visionneuse_milieu_image"
                        onMouseDown={(event) => this.moveActif(event)}
                        onMouseUp={() => this.moveInactif()}
                        onMouseMove={(event) => this.move(event)}
                        onWheel={(event) => this.mousewheel(event)}
                        style={{
                            backgroundImage: 'url(' + L_url_en_cours + ')',
                            transform: 'translate(' + this.state.movex + 'px,' + this.state.movey + 'px) scale(' + this.state.zoom + ') rotate(' + this.state.rotate.toString() + 'deg)'
                        }}
                    ></div>
                    <div id="D_droite_visionneuse_milieu_rotate">
                    <div id="D_droite_visionneuse_head_tour_moins" onClick={() => this.rotate(-1)}></div>
                    <div id="D_droite_visionneuse_head_tour_plus" onClick={() => this.rotate(1)}></div>
                    </div>
                    <div id="D_droite_visionneuse_milieu_zoom">
                        <div id="D_droite_visionneuse_milieu_zoom_in" onClick={() => this.scale(1)}></div>
                        <div id="D_droite_visionneuse_milieu_zoom_out" onClick={() => this.scale(-1)}></div>
                    </div>
                </div>
                <div id="D_droite_visionneuse_bas">
                    {
                        this.props.documentUn.data.IMG.map((P_img, P_index) => {
                            let L_class='';
                            if(this.props.documentUn.data.PosImage===P_index){
                                L_class='selected';
                            }
                            return (
                                <div className={"D_droite_visionneuse_bas_image "+L_class}
                                    key={P_index}
                                    onClick={() => this.change_select_image(P_index, P_img)}
                                    style={{ backgroundImage: 'url(' + P_img + ')' }}
                                ></div>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
}

DocumentVisioneuse.defaultProps = {
    langue: null,
    affichage: {},
    documentUn: {},
    layerFront: ()=>{ }
}

export default DocumentVisioneuse;