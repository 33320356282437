import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';
import Dialog from 'rc-dialog'
import ContentEditableTerm from './ContentEditableTerm';
import svgPen from './assets/pen.svg';
import svgSwitchOn from './assets/switch-on.svg';
import svgSwitchOff from './assets/switch-off.svg';
import svgAdd from './assets/plus.svg';
import svgRemove from './assets/trash.svg';
import { HL_COLORS, getProfileItemFromConcepts } from '../utils/highlights';

import './assets/main.css';
import '../../node_modules/rc-dialog/assets/index.css';


class HighlightConfigurator extends Component {

    /**
    * Constructor
    * 
    * @returns void
    */
    constructor(props) {
        super(props);
        // Bind function on this
        _.bindAll(this,
            'onMainTooltipShow', 'onMainTooltipHide', 'clearHighlights', 'apply', 'toggleProfileActivation', 'openDialog', 'closeDialog',
            'addHighlight', 'changeHighlightColor', 'updateTerm', 'onSetTerm', 'switchHighlight',
            'onClickMainTooltipContent', 'onColorIndicatorClick', 'onRemoveClick',
        );

        this.uniqueId = _.uniqueId('HighlightConfigurator');

        // Set default state
        this.state = {
            dialogIsOpen: false,
            tooltipIsShowed: false,
            profile: null,
        }

        // Set HL_COLORS with translates
        this.setHlColors(props);

        // Arrays to store all tooltip reference (helpfull to hide tooltip with hacky-style)
        this.colorTooltipsRef = HL_COLORS.map(() => null);
        this.colorTooltipsTriggerRef = HL_COLORS.map(() => null);
    }


    componentDidMount() {
        const { profile } = this.props;
        this.setState({
            profile: profile.hls ? _.cloneDeep(profile) : {
                hls: []
            }
        });
    }


    /**
    * Set hlcolors constante to self
    *  (store translate)
    * 
    * @returns {void}
    */
    setHlColors(props) {
        // Reset label from translations
        HL_COLORS.map((hlColor, index) => {
            const { translations } = props,
                { highlight } = translations || {},
                // First translations of highlight tabs(xls) are the colors labels
                translation = highlight && highlight[index],
                label = translation && translation.LIB || '';

            hlColor.label = label;
            return hlColor;
        })
    }


    /**
    * Open the dialog
    * 
    * @returns void
    */
    openDialog() {
        const { profile } = this.props;

        this.setState({
            dialogIsOpen: true,
            // Copy profile highlights to State
            profile: _.cloneDeep(profile),
        });
    }


    /**
    * On main tooltip show
    * 
    * @returns void
    */
    onMainTooltipShow() {
        const { profile } = this.props;

        this.setState({
            // Copy profile highlights to State
            profile: _.cloneDeep(profile),
            tooltipIsShowed: true,
        });
    }


    /**
    * On main tooltip hide
    * 
    * @returns void
    */
    onMainTooltipHide() {
        this.setState({
            tooltipIsShowed: false,
        });
    }


    /**
    * Close the dialog
    * 
    * @returns void
    */
    closeDialog() {
        this.setState({
            dialogIsOpen: false
        });
    }


    /**
    * Apply
    * 
    * @param {object} options 
    * @returns void
    */
    apply(profile) {
        const { updateProfile } = this.props,
            profileClone = _.clone(profile);

        if (updateProfile) {
            updateProfile(profileClone);
        }

        this.setState({ profile: profileClone });
    }


    /**
    * Clear all highlights
    * 
    * @returns void
    */
    clearHighlights() {
        const { profile } = this.props;

        // Remove higlight
        profile.hls = [];

        // Update highlights to state and call the updateProfile callback
        this.apply(profile);
    }


    /**
    * Add highlight (new highlight line)
    * 
    * @returns void
    */
    addHighlight() {
        const { profile } = this.state,
            { hls } = profile,
            takenColors = hls.map(highlight => highlight.color),
            // Find the first available color (not already taken)
            untakedColors = HL_COLORS.filter(colorObj => takenColors.indexOf(colorObj.color) === -1),
            color = untakedColors[0],
            maxHighlights = HL_COLORS.length,
            hasEmptyHighlight = hls.find(highlight => highlight.text === '');

        if (
            // Max hightlight achevied
            hls.length >= maxHighlights
            // Has already a empty highlight
            || hasEmptyHighlight
        ) {
            return;
        }

        // Add next highlight
        hls.push({
            enabled: false,
            color: color.color,
            textColor: color.textColor,
            text: "",
            selectedTooltip: "",
            unSpecializedText: "",
            termRegExp: "",
            wordsRegExp: null,
            isCustom: true
        });

        // Update hls
        profile.hls = hls;

        // Update highlights to state and call the updateProfile callback
        this.apply(profile);
    }


    /**
    * On remove click
    * 
    * @param {MouseEvent} e Mouse event from clicking on remove element
    * 
    * @returns void
    */
    onRemoveClick(e) {
        const { currentTarget } = e,
            highlightNode = currentTarget.closest('.highlight'),
            highlightIndex = this.getNodeIndex(highlightNode);

        // Remove the highlight
        this.removeHighlight(highlightIndex);
    }


    /**
     * Remove hightlight by his index
     * 
     * @param {integer} highlightIndex The hightlight index to be remove
     */
    removeHighlight(highlightIndex) {
        const { profile } = this.state,
            { hls } = profile;

        // Remove the highlight
        hls.splice(highlightIndex, 1);

        // Update hls
        profile.hls = hls;

        // Update highlights to state and call the updateProfile callback
        this.apply(profile);
    }

    /*
    calculate the position of the window bottom left of the button
    */
    calculatePosition = ({ left, top }, currentEvent, currentTarget, node) => {
        const d = document.documentElement;
        left = Math.max(0, Math.min(left + currentTarget.clientWidth / 2 - node.clientWidth / 2, left));
        top = Math.max(0, Math.min(d.clientHeight - node.clientHeight - 5, top));
        return { top: top, left: left };
    }

    /**
    * Change highlight color
    * 
    * @param {MouseEvent} e Mouse event from clicking on color indicator
    * 
    * @returns void
    */
    changeHighlightColor(e) {
        const { profile } = this.state,
            { hls } = profile,
            { currentTarget } = e,
            highlightNode = currentTarget.closest('.highlight'),
            highlightIndex = this.getNodeIndex(highlightNode),
            colorIndex = this.getNodeIndex(currentTarget);

        // Change the color of the highlight
        hls[highlightIndex].color = HL_COLORS[colorIndex].color;
        hls[highlightIndex].textColor = HL_COLORS[colorIndex].textColor;

        // Update hls
        profile.hls = hls;

        // Update highlights to state and call the updateProfile callback
        this.apply(profile);

        // Hide color changer tooltip
        this.colorTooltipsRef[highlightIndex].tooltipRef = null; // Hacky-style (force tooltip to deasapears)
        ReactTooltip.hide(this.colorTooltipsTriggerRef[highlightIndex]);

        e.stopPropagation();
    }


    /**
    * Update the hightlight model
    *
    * @param {integer} highlightIndex The highlight index to update
    * @param {string}  term           The term to set
    * 
    * @returns void
    */
    updateTerm(highlightIndex, term) {
        const { profile } = this.state,
            { hls } = profile,
            previousHL = hls[highlightIndex];

        getProfileItemFromConcepts(term.split(/,| and | or /).map(string => string.trim()))
            .then(hightlightModel => {
                const termIsEmpty = hightlightModel.text.trim() === '';

                hls[highlightIndex] = {
                    ...hightlightModel,
                    color: previousHL.color,
                    textColor: previousHL.textColor,
                    enabled: termIsEmpty ? false : hightlightModel.enabled,
                    isCustom: true       // Force the updated model to be a custom one by design.
                };

                // Update hls
                profile.hls = hls;

                // Update highlights to state and call the updateProfile callback
                this.apply(profile);
            });
    }


    /**
    * Stop edit term
    *
    * @param {Event}   e           Change event from term text input
    * @param {string}  term        Term to set
    * @param {boolean} termchanged Previous term has not same as the new setted term
    * 
    * @returns void
    */
    onSetTerm(e, term, termchanged) {
        const { profile } = this.state,
            { hls } = profile,
            { currentTarget } = e,
            highlightNode = currentTarget.closest('.highlight'),
            highlightIndex = this.getNodeIndex(highlightNode);

        this.updateTerm(highlightIndex, term);

    }



    /**
    * Switch highlight (enable/disable)
    * 
    * @param {MouseEvent} e Mouse event from clicking checkbox
    * 
    * @returns void
    */
    switchHighlight(e) {
        const { profile } = this.state,
            { hls } = profile,
            { currentTarget } = e,
            highlightNode = currentTarget.parentNode,
            highlightIndex = this.getNodeIndex(highlightNode),
            highlight = hls[highlightIndex],
            termIsEmpty = highlight.text.trim() === '';

        // Do not check empty terms
        if (termIsEmpty) {
            return;
        }

        // Switch enabled / disabled
        highlight.enabled = !hls[highlightIndex].enabled;

        // Update hls
        profile.hls = hls;

        // Update highlights to state and call the updateProfile callback
        this.apply(profile);
    }


    /**
    * Switch profile on/off 
    * 
    * @returns void
    */
    toggleProfileActivation() {
        const { profile } = this.state;

        // Update enabled
        profile.enabled = !profile.enabled;

        // Update highlights to state and call the updateProfile callback
        this.apply(profile);
    }


    /**
    * Get the index of domNode (data-index)
    * 
    * @param {string} highlightNode The node to get data-index
    */
    getNodeIndex(highlightNode) {
        const { dataset } = highlightNode,
            { index } = dataset;

        return parseInt(index);
    }


    /**
    * On color choice tooltip indicator
    * 
    * @param {MouseEvent} e Click event object return 
    */
    onColorChoiceTooltipContentClick(e) {
        // Do not hide tooltip when clicking inside tooltip content area
        e.stopPropagation();
    }


    /**
    * On color indicator click
    * 
    * @param {MouseEvent} e Click event object return 
    */
    onColorIndicatorClick(e) {
        const { currentTarget } = e,
            highlightNode = currentTarget.parentNode,
            higlightIndex = this.getNodeIndex(highlightNode);

        // Hide all color changer tooltip except self tooltip
        this.hideAllColorChangerTooltip(higlightIndex);
    }


    /**
    * On main tooltip content click
    * @param {MouseEvent} e Click event object return 
    */
    onClickMainTooltipContent(e) {
        // Hide all color changer tooltip
        this.hideAllColorChangerTooltip();

        // Do not hide main tooltip when clicking inside tooltip content area
        e.stopPropagation();
    }


    /**
    * Hide all color changer tooltip
    * 
    * @param {integer} excludeHighlightIndex The highlightIndex to keep color changer tooltip open
    */
    hideAllColorChangerTooltip(excludeHighlightIndex) {
        this.colorTooltipsRef.forEach((objRef, highlightIndex) => {
            if (objRef && excludeHighlightIndex !== highlightIndex) {
                ReactTooltip.hide(this.colorTooltipsTriggerRef[highlightIndex]);
            }
        });
    }


    /**
    * Render the content of the dialog
    * 
    * @returns {jsx}
    */
    renderMainContent() {
        const { width } = this.props,
            { profile } = this.state,
            { hls, enabled } = profile,
            presetHighlights = hls.filter(highlight => _.isUndefined(highlight.isCustom)),
            customHighlights = hls.filter(highlight => highlight.isCustom),
            renderedPresetHighlights = presetHighlights.map(highlight => this.renderHighlightLine(highlight)),
            renderedCustomHighlights = customHighlights.map(highlight => this.renderHighlightLine(highlight)),
            maxHighlightsAchieved = hls.length >= HL_COLORS.length;

        return (
            <div className="highlight-configurator-content" onClick={this.onClickMainTooltipContent} style={{ width }}>
                <ReactTooltip
                    id='highlight-configurator-content_reacttooltip'
                />
                <div className="header">
                    <div
                        data-tip={enabled ? this.props.translations.highlight[14].LIB : this.props.translations.highlight[13].LIB}
                        className={`switch-all${enabled ? ' enabled' : ''}`}
                        onClick={this.toggleProfileActivation}
                    >
                    </div>
                    <div className="header_title">
                        {this.props.translations.highlight[16].LIB}
                    </div>
                </div>
                {presetHighlights.length > 0 ?
                    (
                        <div className="highlights-block">
                            <h3>
                                {this.props.translations.highlight[17].LIB}
                            </h3>
                            {renderedPresetHighlights}
                        </div>
                    ) : null}
                <div className="highlights-block custom">
                    <h3>
                        {this.props.translations.highlight[18].LIB}
                        {!maxHighlightsAchieved ? (
                            <span className="add-highlight" onClick={this.addHighlight}>
                                <img src={svgAdd} height="16" title={this.props.translations.highlight[19].LIB} />
                            </span>
                        ) : null}
                    </h3>
                    {renderedCustomHighlights}
                </div>
            </div>
        );
    }


    /**
    * Render the color indicator
    * 
    * @param {integer} line   The line to render
    * @param {integer} nbLine Total line
    * 
    * @returns {jsx}
    */
    renderColorChoiceLine(line, nbLine) {
        const nbPerLine = Math.ceil(HL_COLORS.length / nbLine),  // Nb color indicator per line
            start = line * nbPerLine,
            end = start + nbPerLine;

        return (
            <div className="color-choice-line" onClick={this.onColorChoiceTooltipContentClick}>
                {HL_COLORS.slice(start, end).map((hlColor, relIndex) => {
                    const { color, label } = hlColor,
                        style = {
                            backgroundColor: color
                        };

                    return (
                        <div
                            key={"color_indicator_" + relIndex}
                            className="color-indicator"
                            style={style}
                            title={label}
                            onClick={this.changeHighlightColor}
                            data-index={start + relIndex}
                        />
                    )
                })}
            </div>
        );
    }


    /**
    * Render the color indicator
    * 
    * @param {object} highlight The highlight object
    * 
    * @returns {jsx}
    */
    renderColorIndicator(highlight) {
        const { profile } = this.state,
            { hls } = profile,
            highlightIndex = hls.indexOf(highlight),
            tooltipId = `${this.uniqueId}-chooseColor-${highlightIndex}`,
            { color } = highlight,
            style = {
                backgroundColor: color,
            },
            nbLine = 2;

        return (
            <React.Fragment>
                <div
                    className="color-indicator"
                    style={style}
                    data-tip
                    data-for={tooltipId}
                    data-event="click"
                    ref={ref => this.colorTooltipsTriggerRef[highlightIndex] = ref}
                />
                <ReactTooltip
                    id={tooltipId}
                    className="color-choice"
                    ref={ref => this.colorTooltipsRef[highlightIndex] = ref}
                    place="bottom"
                    type="light"
                    effect="solid"
                    border
                    borderColor="#aaaaaa"
                    globalEventOff="click" // with onColorChoiceTooltipContentClick, only clicks OUTSIDE tooltip content will close tooltip
                    afterShow={this.onColorIndicatorClick}
                    multiline={true}
                >
                    {
                        // Range array to make each line
                        [...Array(nbLine).keys()].map(index => this.renderColorChoiceLine(index, nbLine))
                    }

                </ReactTooltip>
            </React.Fragment>
        );
    }


    /**
    * Render a highlight line
    * 
    * @param {object}  highlight The highlight object
    * 
    * @returns {jsx}
    */
    renderHighlightLine(highlight) {
        const { profile } = this.state,
            { hls } = profile,
            { text, enabled, isCustom, color, textColor } = highlight,
            termIsEmpty = text === '',
            index = hls.indexOf(highlight);

        return (
            <div className="highlight" data-index={index}>
                <input className="enabled" checked={enabled} type="checkbox" onChange={this.switchHighlight} />
                <ContentEditableTerm
                    translations={this.props.translations}
                    term={text}
                    color={color}
                    textColor={textColor}
                    onSetTerm={this.onSetTerm}
                    editable={isCustom}
                    disabled={!enabled}
                />
                <div className="remove" onClick={this.onRemoveClick}>
                    <img src={svgRemove} width="12" />
                </div>
                {this.renderColorIndicator(highlight)}
            </div>
        );
    }


    /**
     *  Render configurator in a tooltip
     * 
     * @param {string} textTitle The title of button (base html tooltip)
     * 
     * @returns {jsx} 
     */
    renderTooltip(textTitle) {
        const {
            tooltipPosition, tooltipOffset
        } = this.props,
            tooltipId = `${this.uniqueId}-highlight-configurator-box`;

        return (
            <React.Fragment>
                <div
                    className="highlight-configurator-button"
                    data-tip
                    data-for={tooltipId}
                    title={textTitle}
                    data-event="click"
                >
                    Highlight
                </div>
                <ReactTooltip
                    id={tooltipId}
                    //place={tooltipPosition}
                    offset={tooltipOffset}
                    type="light"
                    effect="solid"
                    border
                    borderColor="#aaaaaa"
                    afterShow={this.onMainTooltipShow}
                    afterHide={this.onMainTooltipHide}
                    delayUpdate={500}
                    globalEventOff="click" // with onColorChoiceTooltipContentClick, only clicks OUTSIDE tooltip content will close tooltip
                    multiline={true}
                    resizeHide={true}
                    arrowColor="transparent"
                    overridePosition={this.calculatePosition}
                >
                    {this.renderMainContent()}
                </ReactTooltip>
            </React.Fragment>
        );
    }


    /**
     *  Render configurator in a dialog
     * 
     * @param {string} textTitle     The title of button (base html tooltip)
     * 
     * @returns {jsx} 
     */
    renderDialog(textTitle) {
        const { width } = this.props,
            { dialogIsOpen } = this.state;

        return (
            <React.Fragment>
                <div
                    className="highlight-configurator-button"
                    title={textTitle}
                    onClick={this.openDialog}
                >
                    Highlight
                </div>
                <Dialog
                    class="highlight-configurator-box"
                    title="Highlight configuration"
                    visible={dialogIsOpen}
                    onClose={this.closeDialog}
                    style={{ width }}
                >
                    {this.renderMainContent()}
                </Dialog>
            </React.Fragment>
        );
    }


    /**
    * Render the content of the button
    * 
    */
    renderButtonContent() {
        const textLabel = "Highlight";            // TODO: TRADUCTION

        return (
            <React.Fragment>
                <img src={svgPen} height="13" className="edit" />
                {textLabel}
            </React.Fragment>
        );
    }


    /**
    *  Render the highlight button and the dialog componant
    * 
    * @returns {jsx} 
    */
    render() {
        const { useDialog } = this.props,
            { tooltipIsShowed, dialogIsOpen, profile } = this.state,
            className = `highlight-configurator${tooltipIsShowed || dialogIsOpen ? ' is-opened' : ''}`,
            textTitle = "Configure highlights";  // TODO: TRADUCTION

        // Prevent display when there's no profile. 
        if (!profile) {
            return false;
        }

        return (
            <div className={className}>
                { useDialog
                    ? this.renderDialog(textTitle)
                    : this.renderTooltip(textTitle)
                }
            </div>
        );
    }

}

HighlightConfigurator.propTypes = {
    useDialog: PropTypes.bool,
    width: PropTypes.number,
    tooltipOffset: PropTypes.object,
    updateProfile: PropTypes.func
};

HighlightConfigurator.defaultProps = {
    useDialog: false,
    width: 300,
    tooltipPosition: 'bottom',
    tooltipOffset: null,
};

export default HighlightConfigurator;

