import React, { Component }  from 'react';
import _                     from 'lodash';
import ContentEditable       from "react-contenteditable";
import PropTypes             from 'prop-types';
import { hexToRgb }          from '../utils/text'; 


class ContentEditableTerm extends Component {

    /**
    * Constructor
    * 
    * @returns void
    */
    constructor(props) {
        super(props);

        // Set default state
        this.state = {};

        _.bindAll(this,
            'onBlur', 'onFocus', 'placeholderSelectAll', 'onKeyUp', 'onKeyPress', 'getContentEditableNode'
        )

        // Create Refs
        this.ref = React.createRef();

        // TODO: TRANSLATE 
        this.placeholder  = this.props.translations.highlight[20].LIB;
    }


    /**
    * Componant did mount
    * 
    * @returns {void}
    */
    componentDidMount() {
        // Select all in the content editable
        this.placeholderSelectAll();
    }


   /**
    * Componant did update
    * 
    * @returns {void}
    */
    componentDidUpdate() {
        // Select all if is needed
        this.placeholderSelectAll();
    }


    /**
    * Select all if term is empty
    * 
    * @returns {void}
    */
    placeholderSelectAll() {
        const { term }    = this.props,              
              termIsEmpty = term.trim() === '';

        if (termIsEmpty) {
            // requestAnimationFrame for waiting focus ready (onfocus event)
            requestAnimationFrame(() => {
                const isFocused   = this.isFocused();
                if(!isFocused) {
                    this.focusOnContentEditableNode(); 
                }
            });
        }
    }


    /**
     * Return the REF content editable
     *
     * @return DOMElement
     */
    getContentEditableNode() {
        const { current }                = this.ref,
        { el }                           = current || {},
        { current: contentEditableNode } = el || {};

        return contentEditableNode;
    }


    /**
     * Select all 
     * 
     * @returns {void}
     */
    focusOnContentEditableNode() {
        const { term }                         = this.props,
              termIsEmpty                      = term.trim() === '',
              { current }                      = this.ref,
              { el }                           = current || {},
              { current: contentEditableNode } = el || {};

        if (termIsEmpty && contentEditableNode) {
            contentEditableNode.focus();
        }
    }

    /**
    * Clear selection
    * 
    * @returns {void}
    */
    clearSelection() {
        if (window.getSelection) {
            window.getSelection().removeAllRanges();
            return;
        }

        if (document.selection) {
            document.selection.empty();
            return;
        }
    }

    /**
    * On content editable blur
    * 
    * @param {Event} e 
    */
    onBlur(e){
        const { onSetTerm, term } = this.props,
              { currentTarget }   = e,
              { textContent }     = currentTarget,
              newTerm             = textContent.trim();

        this.clearSelection();

        // Trigger parent callback
        if (newTerm !== this.placeholder) {
            onSetTerm(e, newTerm, newTerm !== term)
        }

    }


    /**
    * On content editable focus
    * 
    * @param {Event} e 
    */
    onFocus(e){
        const node = this.getContentEditableNode();
        
        console.log('focus', node);
        
        if(node.textContent.trim() === this.placeholder) {
            node.textContent = '';
        }
    }


    /**
    * On keypress editable focus
    * 
    * @param {KeyboardEvent} e 
    */
    onKeyPress(e){
        const { onPressEnter }       = this.props,
              { currentTarget, key } = e;

        if (key === 'Enter') {
            // Exit adn save term
            currentTarget.blur();
            onPressEnter();
        }        
    }


    /**
    * On keyUp editable focus
    * 
    * @param {KeyboardEvent} e 
    */
    onKeyUp(e){
        const { onSetTerm ,term, onPressEscape } = this.props,
              { currentTarget, key }             = e;

        if (key === 'Escape') {
            // Roll back term
            onSetTerm(e, term, false);

            // Exit term
            requestAnimationFrame(() => {
                currentTarget.blur();
                onPressEscape(currentTarget, term);
            });
        }        
    }


    /**
    * Check if the node is focused (using document.activeElement)
    * 
    * @returns {boolean}
    */
    isFocused() {
        const { current }                      = this.ref || {},
              { el }                           = current || {},
              { current: contentEditableNode } = el || {},
              isFocused                        = document.activeElement === contentEditableNode;

        return isFocused;
    }


    /**
    * Main render
    * 
    * @returns {jsx}
    */
   //background: linear-gradient(0deg,#ffffff 1%, #FF0000 1%, #FF0000 96%, #ffffff 1%);
    render() { 
        const {
                term, className, color, textColor,
                tagName, editable, disabled
            }                        = this.props,
            isFocused                = this.isFocused(),
            contentEditableClassName = `${className}${isFocused ? ' focused' : ''}`,
            termIsEmpty              = term.trim() === '',
            rgbColor                 = hexToRgb(color),
            //luminance                = (0.299 * rgbColor.r + 0.587 * rgbColor.g + 0.114 * rgbColor.b),
            // Set highlight textColor and background-color
            textColorSpan                = termIsEmpty ? '#757575' : textColor,//luminance < 128 ? '#FFFFFF': '#000000',
            // Construct highligth colors style
            backgroundColor          = termIsEmpty ? null : color,
            textColorStyle           = textColorSpan ? `color:${textColorSpan}` : null,
            backgroundStyle          = backgroundColor ? `background: linear-gradient(0deg,#ffffff 1%, ${backgroundColor} 1%, ${backgroundColor} 96%, #ffffff 1%)` : null,            
            styles                   = [textColorStyle, backgroundStyle],
            highlightStyle           = styles.filter(style=> style !== null).join(';'),
            // Fake higlight TODO: Plug highlight componant             
            editableText             = !isFocused && termIsEmpty
                ? `
                    <span style="color: #757575">
                        ${this.placeholder}
                    </span>
                `
                :`
                    <span style="${!disabled ? highlightStyle : ''}">
                        ${term.trim() || this.placeholder}
                    </span>
                `;

        // TODO: ellipsis
        return ( 
             <ContentEditable
                className  = {contentEditableClassName}
                ref        = {this.ref}
                tagName    = {tagName}
                html       = {editableText}
                onClick    = {this.placeholderSelectAll}
                onFocus    = {this.onFocus}
                onBlur     = {this.onBlur}
                onKeyPress = {this.onKeyPress}
                onKeyUp    = {this.onKeyUp}
                disabled   = {!editable}
                spellCheck ="false"
            />
         );
    }
}

ContentEditableTerm.propTypes = {
    translations : PropTypes.object,
    className    : PropTypes.string,
    tagName      : PropTypes.string,
    term         : PropTypes.string,
    color        : PropTypes.string,
    onSetTerm    : PropTypes.func,
    onPressEnter : PropTypes.func,
    onPressEscape: PropTypes.func,
    editable     : PropTypes.bool,
    disabled     : PropTypes.bool
};

ContentEditableTerm.defaultProps = {
    className    : 'highlight-content-editable',
    tagName      : 'div',
    color        : '#757575',
    term         : '',
    onSetTerm    : _.noop,
    onPressEnter : _.noop,
    onPressEscape: _.noop,
    editable     : false,
    disabled     : false
};

export default ContentEditableTerm;