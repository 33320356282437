import React from 'react';
import ReactTooltip from 'react-tooltip';
import decorators from "react-treebeard/dist/components/Decorators";
import './TreebeardDeco.css';
import Constante from '../Constante';

//react-treebeard/lib/components/decorators
const CustomHeader = ({ node, style, prefix }) => {
    let L_name=node.name.toUpperCase();
    if(L_name.indexOf('INBOX - ')>=0){
        L_name='INBOX';
    }
    return (
        <div className="TreeNodeHeader">
            <span className="S_TreeNodeHeader">
                {L_name}
            </span>
            {(node.type !== Constante.EmplacementRecherche.FOLDER && node.type !== Constante.EmplacementRecherche.RACINE) ? ' (' + node.numberOfDocument + ')' : ''}
        </div>
    );
}

export class CustomContainer extends decorators.Container {

    constructor() {
        super()
        this.state = {
            ticket: '',
            shard: ''
        }
    }

    static propTypes = {
    }

    nodeRename = () => {
        this.props.decorators.nodeRename(this.props.node);
    }

    nodeDelete = () => {
        this.props.decorators.nodeDelete(this.props.node);
    }

    nodeAdd = (P_type_folder_list) => {
        this.props.decorators.nodeAdd(this.props.node, P_type_folder_list);

    }

    activeNode = () => {
        this.props.decorators.activeNode(this.props.node);
    }

    activeOuVoirNode = () => {
        if ((this.props.node.type === Constante.EmplacementRecherche.LIST || this.props.node.type === Constante.EmplacementRecherche.WORKFILE) &&
            typeof this.props.decorators.voirListe === 'function') {
            this.props.decorators.voirListe(this.props.node);
        }
        else {
            this.props.decorators.activeNode(this.props.node);
        }
    }


    render() {
        const { style, decorators, onClick, node } = this.props;
        let L_display = 'flex';
        let L_classname = '';
        let L_classname_general = '';
        let L_open_close = '';
        let L_is_list_or_workfile=false;
        switch (node.type) {
            case Constante.EmplacementRecherche.FOLDER:
                if (node.toggled) {
                    L_open_close = 'fleche_tree_open';
                    L_classname = 'icone_folderopen';
                }
                else {
                    L_open_close = 'fleche_tree_close';
                    L_classname = 'icone_folderclose';
                }
                break;
            case Constante.EmplacementRecherche.LIST:
                L_classname = 'icone_liste';
                L_classname_general = 'is_liste';
                L_is_list_or_workfile=true;
                if (!this.props.decorators.user.acces.liste.read) {
                    L_display = 'none';
                }
                if (node.name.toUpperCase() === '_ORBITEXPRESSPATENTWATCHING_') {
                    L_display = 'none';
                }
                break;
            case Constante.EmplacementRecherche.WORKFILE:
                L_classname = 'icone_workfile';
                L_classname_general = 'is_workfile';
                L_is_list_or_workfile=true;
                if (!this.props.decorators.user.acces.workfile.read) {
                    L_display = 'none';
                }
                break;
            case Constante.EmplacementRecherche.RACINE:
                if (node.toggled) {
                    L_open_close = 'fleche_tree_open';
                    L_classname = 'icone_folderopen';
                }
                else {
                    L_open_close = 'fleche_tree_close';
                    L_classname = 'icone_folderclose';
                }
                break;
            default:
                break;
        }
        return (
            <div className={"TreeNodeContainerGeneralInfo " + L_classname_general}>
                {/*!terminal ? this.renderToggle() : null*/}
                <ReactTooltip className="tooltipcssprojet" />
                <div className={'TreeNodeToogle ' + L_open_close} onClick={onClick} ></div>
                <div
                    ref={ref => (this.clickableRef = ref)}
                    style={{ display: L_display }}
                    className={"TreeNodeContainerInfo" + (node.id === this.props.decorators.nodeId ? ' selectnode' : '')}
                >
                    <div className='D_clickdiv'>
                        <div
                            className={'TreeNodeIconeHearder'}
                            data-tip={(L_is_list_or_workfile===true && typeof this.props.decorators.voirListe === 'function')? this.props.decorators.langue[23].LIB:""}
                            onClick={(node.id === this.props.decorators.nodeId) ? null : this.activeNode}
                            onDoubleClick={(node.type === Constante.EmplacementRecherche.FOLDER) ? onClick : this.activeOuVoirNode}
                        >
                            <div className={'TreeNodeIcone ' + L_classname}></div>
                            <decorators.Header node={node} style={style.header} />
                        </div>
                    </div>
                    <div className="TreeNodePoint" onClick={this.activeNode}>
                        <span
                            onClick={this.activeOuVoirNode}
                            style={(L_classname_general.length > 100000 &&
                                node.id === this.props.decorators.nodeId &&
                                typeof this.props.decorators.voirListe === 'function') ? {} : { display: 'none' }}
                            className="S_TreeNodePoint"
                        >
                            {this.props.decorators.langue[19].LIB}
                        </span>
                        <div
                            className="B_treebeard_menu"
                            onClick={this.props.decorators.openMenu}
                            style={node.type === Constante.EmplacementRecherche.RACINE ? { display: 'none' } : {}}>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export const Decorators = {
    Loading: (props) => {
        return (
            <div className='treeloadgif'>
            </div>
        );
    },
    Toggle: (props) => {
        return null;
        /*return (
            <div className="TreeNodeToogle" style={props.style}>
                <svg height={props.height} width={props.width}>
                </svg>
            </div>
        );*/
    },
    Header: CustomHeader,
    Container: CustomContainer,
    openMenu: null,
    nodeDelete: null,
    nodeRename: null,
    nodeAdd: null,
    activeNode: null,
    voirListe: null,
    user: null,
    langue: null,
    nodeid: ''
};

export default {
    Decorators
}