import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constante from '../Constante.js';
import './SelectAffichage.css';


class SelectAffichage extends Component {

  constructor() {
    super()
    this.state = {
      Recherche: "test"
    }
  }


  static propTypes = {
    langue: PropTypes.array,
    affichageMode: PropTypes.string.isRequired,
    affichage: PropTypes.object,
    user: PropTypes.object,
    EmplacementRecherche: PropTypes.string,
    NomRecherche: PropTypes.string,
    changeSelectOuMode: PropTypes.func
  };

  render() {
    const { currentDoc } = this.props,
        { data }         = currentDoc || {},
        { IMG }          = data || {};

    // couleur en fonction du contexte (search, projets, alertes)
    let L_select = "selectSearch"; // Recherche
    return (
      <div
        id="D_droite_select_affichage"
        className={this.props.affichage.graphe === 1 ? '' : 'borderbottom'}
      >
        <div id="D_droite_select_affichage_document"
          onClick={() => this.props.changeSelectOuMode(Constante.AffichageSelect.document, null)}
          className={(this.props.affichage.document === 1) ? L_select : ''}
          style={this.props.EmplacementRecherche===Constante.EmplacementRecherche.NPL?{visibility:'hidden'}:{}}
        >{this.props.langue[1].LIB}</div>
        {
            IMG?.length > 0 && (
                <div id="D_droite_select_affichage_pictures"
                    onClick={() => this.props.changeSelectOuMode(Constante.AffichageSelect.visioneuse, null)}
                    className={this.props.affichage.visioneuse === 1 ? L_select : ''}
                    style={this.props.EmplacementRecherche===Constante.EmplacementRecherche.NPL?{visibility:'hidden'}:{}}
                >
                    {this.props.langue[2].LIB}
                </div>
            )
        }
      </div>
    );
  }
}

SelectAffichage.defaultProps = {
  langue: null,
  affichageMode: Constante.affichageMode.rechercheavancee,
  affichage : {},
  currentDoc: {},
  changeSelectOuMode: () => null
}

export default SelectAffichage;