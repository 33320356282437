import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import PARSER from 'html-react-parser';
import { hexToRgb } from '../utils/text';


const tag = "#_=_#",
    BOUNDARY_CHAR = "\u0020-\u002F\u003A-\u003F\\u005B\\u005D\u007B-\u007F\u00A0\u00AE\u00B7\u1680\u2000-\u206F\u3000-\u303F\uFF01-\uFF0F\uFF1A-\uFF20\uFF3B-\uFF40\uFF5B-\uFF65\n",
    WORD_BOUNDARY = "[" + BOUNDARY_CHAR + "]";


/**
 * From a provided profile, this component will add some hightlights to its children
 *
 * @param {array} profile The configuration profile containing regexp, color, etc...
 *
 * @use The text to be hightlighted
 */
class Highlights extends Component {

    /**
    * Constructor
    * 
    * @returns void
    */
    constructor() {
        super()
        this.state = {
            hightlightedContent: null
        }
    }


    componentDidMount() {
        const { profile } = this.props;

        this.setHighlight();
    }


    componentDidUpdate() {
        const { profile } = this.props,
            { currentProfile } = this.state;

        // Prevent useless loop
        if (profile === currentProfile) {
            return;
        }

        // Reset highlight from new props 
        this.setHighlight();
    }


    /**
     * Set state text with colors define in profile
     *  
     * 
     * @returns {void}
     */
    setHighlight = () => {
        const { children, profile } = this.props;

        if (!profile) {
            return;
        }

        let TextHL = this.replaceHtmlTags(children);
        TextHL.html = this.setHighlightAll(TextHL.html);
        TextHL.html = this.putBackTags(TextHL.html, TextHL.inverseMapOfTokens);

        this.setState({
            currentProfile: profile,
            hightlightedContent: TextHL.html
        })
    }


    /**
     * for each profils parse text to put the highlight
     *  
     * 
     * @returns {string}
     */
    setHighlightAll = (text) => {
        const { hls = [] } = this.props.profile;

        let siLenHlis = [];

        hls.forEach((highlight, index) => {
            if (!highlight.enabled || highlight.termRegExp === "()") {
                return;
            }

            let ProfilRegExp = new RegExp(highlight.termRegExp, 'gi'),
                // var s = replaceResult.html.replace(new RegExp("</?b>", "gi"), ""); inutile doit être fait de base
                boundary = new RegExp(WORD_BOUNDARY, "gi"),
                found, sub, subf, start = [];
            if (highlight.wordsRegExp !== null) {
                let ProfilWordsRegexp = new RegExp(highlight.wordsRegExp);
                while ((found = ProfilRegExp.exec(text)) != null) {
                    sub = text.substr(found.index, found[0].length);
                    while ((subf = ProfilWordsRegexp.exec(sub)) != null) {
                        start = subf[1].length;
                        siLenHlis.push([found.index + subf.index + start, subf[0].length - start, index]);
                    }
                }
                return;
            }
            while ((found = ProfilRegExp.exec(text)) != null) {
                if (text[found.index].match(boundary)) {
                    siLenHlis.push([found.index + 1, found[0].length - 1, index]);
                    continue;
                }
                siLenHlis.push([found.index, found[0].length, index]);
            }
        });
        siLenHlis.sort(function (a, b) {
            return a[0] !== b[0] ? a[0] - b[0] : a[1] !== b[1] ? b[1] - a[1] : b[2] - b[1];
        });
        let siLenHli, si, len, hlId, out = "",
            j = undefined;
        for (let i = 0; i < siLenHlis.length; i++) {
            siLenHli = siLenHlis[i];
            const color = hls[siLenHli[2]].color,
                rgbColor = hexToRgb(color),
                /*luminance = (0.299 * rgbColor.r + 0.587 * rgbColor.g + 0.114 * rgbColor.b),*/
                // Set highlight textColor and background-color
                textColor = hls[siLenHli[2]].textColor;// luminance < 128 ? '#FFFFFF': '#000000';

            si = siLenHli[0];
            if (j === undefined || si >= j) {
                len = siLenHli[1];
                //hl = siLenHli = HL[siLenHli[2]];
                if (j === undefined) {
                    out += text.substring(0, si);
                }
                if (j !== undefined) {
                    out += text.substring(j, si);
                }
                j = si + len;
                hlId = "HL" + siLenHli[2];
                out += "<b><span class='" + color + "' id='" + hlId
                    + "'></span><font class='" + color + "' id='" + hlId
                    + "1' style='background:linear-gradient(0deg,#ffffff 3%, " + color + " 3%, " + color + " 91%, #ffffff 3%); color:" + textColor + ";'>"
                    + text.substr(si, len) + "</font></b>";
            }
        }
        if (j === undefined) {
            return text;
        }
        if (j < text.length) {
            out += text.substring(j, text.length);
        }
        return out;
    }

    /**
    *  replace all tags in a text by a small string and create a table with the tags
    * 
    * @returns {object} html -> text without tag 
    *                   inverseMapOfTokens -> table of tags
    */
    replaceHtmlTags = (html) => {
        let longString = html;
        let target = "";
        let inverseMapOfTokens = [];
        for (let i = 0; i < longString.length; i++) {
            if (longString.charAt(i) === "<") {
                let toToTest = longString.substring(i, i + 100);
                let match = /<\/?(?:table|p|br|td|tr|a|b).*?>/im.exec(toToTest);
                if (match != null && match.index === 0) {
                    let htmltag = match[0];
                    inverseMapOfTokens.push(htmltag);
                    target += tag;
                    i += htmltag.length - 1;
                    continue;
                }
                target += longString.charAt(i);
                continue;
            }
            target += longString.charAt(i);
        }
        return {
            "html": target,
            "inverseMapOfTokens": inverseMapOfTokens
        };
    }


    /**
    *  Take a text and the table of tags and put back the tags in the text
    * 
    * @returns {string} html text
    */
    putBackTags = (html, inverseMapOfTokens) => {
        var replacedTarget = "";
        for (var i = 0; i < html.length; i++) {
            if (html.charAt(i) === "#") {
                var toToTest = html.substring(i, i + 100);
                var match = new RegExp(tag, "i").exec(toToTest);
                if (match != null && match.index === 0) {
                    var htmltag = match[0];
                    var replacement = inverseMapOfTokens.shift();
                    replacedTarget += replacement;
                    i += htmltag.length - 1;
                    continue;
                }
                replacedTarget += html.charAt(i);
                continue;
            }
            replacedTarget += html.charAt(i);
        }
        return replacedTarget;
    }

    /**
     * Render the hightlighted content. 
     * 
     * return JSX
     */
    render() {
        const { profile, children } = this.props,
            { hightlightedContent } = this.state;

        // Profile is not enabled return the children 
        if (!profile.enabled) {
            return <span classname="highlight-disabled" dangerouslySetInnerHTML={{ __html: children }} />;
        }

        // Prevent ugly display
        if (_.isNull(hightlightedContent)) {
            return false;
        }

        // Return the hightlighted content
        return (
            <span className="hightlight" style={{ height: '100%' }} dangerouslySetInnerHTML={{ __html: hightlightedContent }} />
        );
    }
}

Highlights.propTypes = {
    text: PropTypes.string,
    profile: PropTypes.object
};

Highlights.defaultProps = {
    text: '',
    profile: []
};

export default Highlights;