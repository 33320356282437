import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs'; //le mieux mais pas parfait sur les bold i
import { Editor } from 'react-draft-wysiwyg';
import PARSER from 'html-react-parser';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichComment.css';
//import { convertFromHTML } from 'draft-convert';
//import {stateFromHTML} from 'draft-js-import-html';

class RichComment extends Component {

    constructor() {
        super()
        this.state = {
            Comment: null
        }
    }

    static propTypes = {
        Id: PropTypes.string,
        PlaceHolder: PropTypes.string,
        CommentHtml: PropTypes.string,
        EditMode: PropTypes.bool,
        OptionMode: PropTypes.bool,
        ListOption: PropTypes.number // 0 -> full  1-> light 
    }
    componentDidMount(){
        this.set_comment();
    }

    componentDidUpdate(prevProps) {
        if(prevProps.CommentHtml!==this.props.CommentHtml){
            this.set_comment();
        }
    }

    set_comment = () => {
        let L_editor = null;
        let L_comment = this.props.CommentHtml;
        L_comment = L_comment.replace(/<font color="/gi, '<font style="color:');//.replace(/<\\font>/gi,'<\span>');
        const blocksFromHtml = htmlToDraft(L_comment);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        L_editor = EditorState.createWithContent(contentState);
        this.setState({
            Comment: L_editor
        });
    }


    get_comment = () => {
        if (this.state.Comment !== null) {
            return draftToHtml(convertToRaw(this.state.Comment.getCurrentContent()));
        }
        return "";
    }

    changeInputComment = (event) => {
        this.setState({
            Comment: event
        });
    }

    render() {
        if (this.props.EditMode) {
            let L_toolbar={
                options: ['fontSize','inline','textAlign', 'blockType',  'fontFamily', 'list',  'link', 'colorPicker',  'image'],
                link: {
                    defaultTargetOption: '_blank',
                },
                inline: {
                    options: ['bold', 'italic', 'underline'],
                },
                list: {
                    options: ['unordered', 'ordered']
                }
            };
            if(this.props.ListOption===1){
                L_toolbar={
                    options: [ 'fontSize','inline', 'textAlign', 'list', 'link', 'colorPicker'],
                    link: {
                        defaultTargetOption: '_blank',
                        options: ['link'],
                    },
                    inline: {
                        options: ['bold', 'italic', 'underline'],
                    },
                    list: {
                        options: ['unordered', 'ordered']
                    }
                };
            }
            return (
                <Editor
                    wrapperId={'RichComment_' + this.props.Id}
                    editorState={this.state.Comment}
                    placeholder={this.props.PlaceHolder}
                    toolbar={L_toolbar}
                    toolbarClassName={this.props.OptionMode ? '' : 'hide'}
                    wrapperClassName="wrapperClassName"
                    editorClassName="RichComment"
                    onEditorStateChange={this.changeInputComment}
                />
            )
        }
        return (
            <div className="placeholder">
                {this.props.CommentHtml === '' ? this.props.PlaceHolder : PARSER(this.props.CommentHtml)}
            </div>
        )
    }
}

export default RichComment;