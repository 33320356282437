import React from 'react';
import PropTypes from 'prop-types';
import { Popover, Button } from 'antd';
import 'antd/dist/antd.css';
import './PophoverOrbit.css';

class PophoverOrbit extends React.Component {

    constructor() {
        super();
        this.state = {
            ChangeOccured: false
        }
    }

    /*
    * function call when the component change to visible or hide
    *
    * @parem {boolean} isVisible :new state of the component :
    *  true -> visible
    *  false -> hide
    */
    onVisibleChange = (isVisible) => {
        // propagate the event
        this.props.onVisibleChange(isVisible, this.state.ChangeOccured);
        // reset change state on hide
        if (!isVisible) {
            this.setState({
                ChangeOccured: false
            })
        }
    }

    /*
    *function call when an element is clicked
    *
    * @param {object} event is send by the onclick event
    * @param {number} index is the position in the option group
    * @param {number} indexOption is the position in the option list
    * @param {boolean} check indicate if the element is selected or not
    */
    onClick = (event, index, indexOption, check) => {
        this.props.onClick(event, index, indexOption, check);
        if (this.state.ChangeOccured === false) {
            this.setState({
                ChangeOccured: true
            })
        }
    }

    /*
    * function call when the select ou Deselect all elements
    *
    * @param {boolean} check indicate if we need to ckeck or unckeck
    */
    onClickToggleAll = (check) => {
        let changeOccured = false;
        this.props.options.forEach((groupOption, indexGroupOption) => {
            groupOption.options.forEach((Option, indexOption) => {
                if (Option.check !== check) {
                    this.props.onClick(null, indexGroupOption, indexOption, check);
                    changeOccured = true;

                }
            });
        });
        if (this.state.ChangeOccured === false && changeOccured) {
            this.setState({
                ChangeOccured: true
            })
        }
    }

    /*
    * function call for render each Option Group
    *
    * @return {HtmlElements} render the html design front
    */
    renderMenu = () => {
        if (!this.props.loaded) {
            return (<>
                <div className="lds-ripple-container">
                    <div className="lds-ripple"><div></div><div></div></div>
                </div>
            </>);
        }
        if (this.props.options.length==1) {
            if(this.props.options[0].options.length<1){
                return (<>
                    <div className="PophoverOrbit_empty">
                        {this.props.empty}
                    </div>
                </>);
            }
        }
        return (
            <div>
                {this.props.options.map((element, index) => {
                    return (
                        <div key={"PophoverOrbit_" + index} className={"popHoverSection" + (element.disabled ? " disabled" : "")} >
                            {element.labelVisible && (<div className={"PophoverOrbit_section"}>{element.label}</div>)}
                            {
                                this.renderMenuInfo(element.options, index)
                            }
                            {(this.props.selectAllEnable || this.props.deselectAllEnable) &&
                                (<div
                                    className="PophoverOrbit_section_bas"
                                >
                                    {(this.props.selectAllEnable && element.options.length>0) && (
                                        <div
                                            className="PophoverOrbit_section_bas_select"
                                            onClick={() => this.onClickToggleAll(true)}
                                        >
                                            select all
                                        </div>
                                    )}
                                    {(this.props.deselectAllEnable && element.options.length>0) && (
                                        <div
                                            className="PophoverOrbit_section_bas_deselect"
                                            onClick={() => this.onClickToggleAll(false)}
                                        >
                                            deselect all
                                        </div>
                                    )}
                                </div>)

                            }
                        </div>
                    )
                }
                )
                }
            </div>
        )
    }

    /*
   * function call for render each Option 
   *
   * @return {HtmlElements} render the html design frontdevelop
   */
    renderMenuInfo = (option, index) => {
        return option.map((element, indexOption) => {
            return (
                <div
                    key={'menuinfo' + indexOption}
                    className={"PophoverOrbit_select_value_un" +
                        (this.props.defaultColor ? " defaultColor" : "") +
                        (element.check ? " selected" : "") +
                        (element.disabled ? " disabled" : "")
                    }
                    onClick={(event) => this.onClick(event, index, indexOption, !element.check)}
                >
                    <div className="PophoverOrbit_select_value_un_label">
                        {element.label}
                    </div>
                    {element.info && (<div className="PophoverOrbit_select_value_un_info">
                        {element.info}
                    </div>)}
                </div>
            )
        })
    }

    render() {
        const contentMenu = this.renderMenu();
        return (
            <div className={"PophoverOrbit"}>
                <Popover placement={this.props.placement} title={""} content={contentMenu} trigger="click" onVisibleChange={this.onVisibleChange}>
                    <div
                        className={"PophoverOrbitlink" + (this.props.image.length > 0 ? " image" : "")}
                    >{this.props.text}</div>
                </Popover>
            </div>
        )
    }
}


PophoverOrbit.propTypes = {
    empty:PropTypes.string,
    options: PropTypes.array,
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    selectAllEnable: PropTypes.bool,
    deselectAllEnable: PropTypes.bool,
    loaded: PropTypes.bool,
    defaultColor: PropTypes.bool,
    placement: PropTypes.string,
    onClick: PropTypes.func,
    onVisibleChange: PropTypes.func
};

PophoverOrbit.defaultProps = {
    empty: "No suggestion found",
    options: [],
    text: "",
    image: "",
    placement: "right",
    selectAllEnable: false,
    deselectAllEnable: false,
    defaultColor: true,
    loaded: true,
    onClick: null,
    onVisibleChange: () => { }
};

export default PophoverOrbit
