import React, { Component } from 'react';
import PARSER from 'html-react-parser';
import PropTypes from 'prop-types';
import Constante from '../Constante.js';
import CommentaireAction from './CommentaireAction.js';
import FamilyMenu from './dialogprojet/FamilyMenu.js';

import DialogComment from './DialogComment';
import RichComment from './rechercheavanceeparam/RichComment.js';
import DatePicker from 'react-datepicker';
import CPManage from './CPManage.js';
import SelectPerso from './rechercheavanceeparam/SelectPerso.js';
import Highlights from './../helpers/Highlights';
import './DocumentInfo.css';
import "react-datepicker/dist/react-datepicker.css";
import { type } from 'jquery';


class DocumentInfo extends Component {


    constructor() {
        super()
        this.CPManageRef = React.createRef();
        this.familyMenuref = React.createRef();
        this.dialogComment = React.createRef();
        this.vapl_color = 0;
        this.state = {
            commentExpand: false,
            comment_open: false,
            famille_open: false,
            patent_cited_open: false,
            patent_citing_open: false,
            patent_cib_open: false,
            patent_legal_open: false,
            classification_open: false,
            description_open: false,
            description_originale_open: false,
            information_cle_open: false,
            claims_open: false,
            claims_independate_open: false,
            patent_cp_open: false,
            dialogCommentType: false,
            commentEditOpen: 0,
            commentIndex: 0,
            CPManageOpen: false,
            CPInfosTemps: []
        }

        this.LOAD_NEW_INFO = Constante.etat.initial; //
        this.HEIGHT_CHANGE = false;
    }

    static propTypes = {
        langueDialog: PropTypes.array,
        langue: PropTypes.array,
        affichage: PropTypes.object,
        EmplacementRecherche: PropTypes.string,
        NomRecherche: PropTypes.string,
        documentUn: PropTypes.object,
        highlightsProfile: PropTypes.object,
        PatentCitation: PropTypes.array,
        CitedBy: PropTypes.array,
        LegalEvent: PropTypes.array,
        permalink: PropTypes.bool,
        base: PropTypes.string,
        requete_highlight: PropTypes.array,
        changeSelectOuMode: PropTypes.func,
        PatentCitationLoad: PropTypes.func,
        PatentCitingLoad: PropTypes.func,
        commentUpdate: PropTypes.func,
        commentDelete: PropTypes.func,
        similariteSearchUnDoc: PropTypes.func,
        priorArtSearchUnDoc: PropTypes.func,
        commentMenuAction: PropTypes.func,
        changeImageDocUn: PropTypes.func,
        cp_edit_mode: PropTypes.func,
        cp_option_mode: PropTypes.func,
        cp_save: PropTypes.func,
        cp_create_valide: PropTypes.func,
        cp_delete_valide: PropTypes.func,
        cp_modif_valide: PropTypes.func,
        cp_value_valide: PropTypes.func
    };

    componentDidMount() {

        this.setState({
            CPInfosTemps: this.props.documentUn.data.CP
        });
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevProps.documentUn !== this.props.documentUn) {
            if (this.props.documentUn.data.CP !== null && typeof this.props.documentUn.data.CP !== "undefined") {
                this.setState({
                    CPInfosTemps: this.props.documentUn.data.CP
                });
            }
            if (this.props.documentUn.data.etatPatentCiting === Constante.etat.initial && this.state.patent_cited_open===true) {
                this.props.PatentCitingLoad();
            }
            if (this.props.documentUn.data.etatPatentCitation === Constante.etat.initial && this.state.patent_citing_open===true) {
                this.props.PatentCitationLoad();
            }
        }
    }

    PDFDownload = (P_pdf_url) => {
        window.open(P_pdf_url);
    }

    changeOpen = (P_nom_code) => {
        switch (P_nom_code) {
            case 'cp':
                this.setState({ patent_cp_open: !this.state.patent_cp_open });
                break;
            case 'desc_orginale':
                this.setState({ description_originale_open: !this.state.description_originale_open });
                break;
            case 'information_cle':
                this.setState({ information_cle_open: !this.state.information_cle_open });
                break;
            case 'claims_independante':
                this.setState({ claims_independate_open: !this.state.claims_independate_open });
                break;
            case 'cib':
                this.setState({ patent_cib_open: !this.state.patent_cib_open });
                break;
            case 'comment':
                this.setState({ comment_open: !this.state.comment_open });
                break;
            case 'claims':
                this.setState({ claims_open: !this.state.claims_open });
                break;
            case 'desc':
                this.setState({ description_open: !this.state.description_open });
                break;
            case 'famille':
                this.setState({ famille_open: !this.state.famille_open });
                break;
            case 'legal':
                this.setState({ patent_legal_open: !this.state.patent_legal_open });
                break;
            case 'cited':
                if (this.props.documentUn.data.etatPatentCiting !== Constante.etat.charge) {
                    this.props.PatentCitingLoad();
                }
                this.setState({ patent_cited_open: !this.state.patent_cited_open });
                break;
            case 'citing':
                if (this.props.documentUn.data.etatPatentCitation !== Constante.etat.charge) {
                    this.props.PatentCitationLoad();
                }
                this.setState({ patent_citing_open: !this.state.patent_citing_open });
                break;
            default:
                break;
        }
    }


    commentMenuAction = (P_variable) => {
        P_variable.id_comment = this.state.commentIndex;
        this.setState({
            commentEditOpen: 0
        }, this.props.commentMenuAction(P_variable));
    }

    commentAdd = () => {
        this.setState({
            comment_open: true,
            commentEditOpen: Constante.dialogMode.add,
            commentIndex: 0
        });
    }

    commentUpdate = (P_index) => {
        this.dialogComment.current.dialogVisible(true,
            this.props.documentUn.data.Comment[P_index].uid,
            this.props.documentUn.data.Comment[P_index].title,
            this.props.documentUn.data.Comment[P_index].text);
        this.setState({
            comment_open: true,
            commentEditOpen: Constante.dialogMode.rename,
            commentIndex: P_index
        });
        document.getElementById('D_dd_info_doc_haut_gauche_commentaire_liste').scrollIntoView(true);
    }

    commentDelete = (P_index) => {
        this.dialogComment.current.dialogVisible(true,
            this.props.documentUn.data.Comment[P_index].uid,
            this.props.documentUn.data.Comment[P_index].title,
            this.props.documentUn.data.Comment[P_index].text);
        this.setState({
            comment_open: true,
            commentEditOpen: Constante.dialogMode.delete,
            commentIndex: P_index
        });
    }

    commentEditFermer = () => {
        this.setState({
            commentEditOpen: 0,
            commentIndex: 0
        });
    }

    commentExpand = () => {
        let L_expand = !this.state.commentExpand;
        this.setState({
            commentExpand: L_expand
        });
    }

    rankUpdate(rank) {
        this.props.noteDoc(rank, this.props.documentUn.data.ETOILE, this.props.documentUn.data.ID);
    }


    /**
     * Add some hightlights to the provided content
     *
     * @param {JSX} content The text/html to hightlight
     *
     * @return JSX
     */
    decorateWithHighlights(content) {
        const { highlightsProfile } = this.props;

        return (
            <Highlights key={Math.random(0, 10)} profile={highlightsProfile}>
                {content}
            </Highlights>
        );
    }


    openMenuVAPLPDF = (e, P_pub) => {
        this.familyMenuref.current.openMenu(e, P_pub);
    }

    renderCPValue = (P_cp, P_valeur) => {
        if (P_valeur === null) {
            return;
        }
        let L_valeur = P_valeur.split(';')
        if (P_cp.length > 20) {
            P_cp = P_cp.substring(0, 20) + '...';
        }
        return (
            <div key={P_cp} className="D_dd_info_doc_haut_gauche_cp_info">
                <span className="S_dd_info_doc_haut_gauche_cp_titre">{P_cp + ' :'}</span>
                <div className="D_dd_info_doc_haut_gauche_cp_info_valeurs_liste">
                    {
                        L_valeur.map((P_val, P_index) => {
                            let L_virgule = '&nbsp; &#8226; &nbsp;';
                            if (P_index === 0) {
                                L_virgule = '';
                            }
                            if (P_val.length > 20) {
                                P_val = P_val.substring(0, 20) + '...';
                            }
                            return (
                                <span key={P_index} className="S_dd_info_doc_haut_gauche_cp_valeur">{PARSER(L_virgule) + P_val}</span>
                            )
                        })
                    }
                </div>
            </div>
        );
    }

    v_apl = (P_v_apl, P_level) => {
        if (P_v_apl === null || typeof P_v_apl === 'undefined') {
            return '';
        }
        return P_v_apl.map((P_v_apl_info, P_index) => {
            const { PERMALINK } = P_v_apl_info;
            let L_pays = '';
            if (P_v_apl_info.PUB.length > 0) {
                L_pays = P_v_apl_info.PUB[0].PN.substring(0, 2);
            }
            let L_status = P_v_apl_info.ACT_STATUS;
            if (L_status.length > 0) {
                L_status = L_status[0] + L_status.slice(1).toLowerCase();
            }
            let L_pn = '';
            if (P_v_apl_info.PUB.length > 0) {
                L_pn = P_v_apl_info.PUB[P_v_apl_info.PUB.length - 1].PN;
            }
            let L_date_app = '';
            if (P_v_apl_info.APD !== null && typeof P_v_apl_info.APD !== 'undefined') {
                if (P_v_apl_info.APD.length > 3) {
                    L_date_app = P_v_apl_info.APD.substring(0, 4);
                }
            }
            this.vapl_color = (this.vapl_color + 1) % 2;
            return (
                <div key={P_v_apl_info.APID} className="D_vapl_principal">
                    <div className={'D_vapl_principal_info color' + this.vapl_color}>
                        <div className="D_v_apl_td_1">
                            <div style={{ width: P_level + 'rem' }}></div>
                            <img
                                src={P_index === P_v_apl.length - 1 ? "./images/famille_dot_fin.svg" : "./images/famille_dot.svg"}
                                alt=""
                                className="I_v_apl_image_dot"
                                style={P_level > 0 ? {} : { display: 'none' }}
                            />
                            <img className="I_v_apl_image" src={"./images/flag/" + L_pays.toLowerCase() + ".svg"} alt="' + L_pays + '" />
                            <div>
                                {L_pays}
                            </div>
                        </div>
                        <div className="D_v_apl_td_2">
                            <a href={PERMALINK} target="_blank">
                                {L_pn}
                            </a>
                        </div>
                        <div className="D_v_apl_td_3">
                            {L_date_app}
                        </div>
                        <div className="D_v_apl_td_4" >
                            <div className={P_v_apl_info.ACT_STATUS}>
                            </div>
                            <div className="D_v_apl_td_4_label">
                                {L_status}
                            </div>
                        </div>
                        <div className="D_v_apl_td_5">
                            {this.v_apl_pub(P_v_apl_info.PUB)}
                        </div>
                    </div>
                    <div className="D_vapl_principal_fils">
                        {this.v_apl(P_v_apl_info.V_APL, P_level + 1)}
                    </div>
                </div>
            )
        })
    }

    v_apl_pub = (P_pub) => {

        if (P_pub.length > 0) {
            return (
                <img className="I_v_apl_image_pdf" src="./images/pdffile.svg" alt="" onClick={(e) => this.openMenuVAPLPDF(e, P_pub)} />
            );
        }
    }

    voirCIB = (P_cib) => {
        P_cib = P_cib.replace(/-/gi, '0').replace(/\//gi, '');
        if (P_cib.length > 4) {
            while (P_cib.length < 14) {
                P_cib = P_cib + '0';
            }
        }
        window.open('https://www.wipo.int/classifications/ipc/ipcpub/?notion=scheme&symbol=' + P_cib +
            '&menulang=FR&lang=en&viewmode=f&fipcpc=no&showdeleted=yes&indexes=no&headings=yes&notes=yes&direction=o2n&initial=A&cwid=none&tree=yes',
            'express_cib');
    }

    voirCPC = (P_cpc) => {
        P_cpc = P_cpc.replace(/-[0]*/gi, '');
        let L_cpc_cut = P_cpc.split('/');
        P_cpc = L_cpc_cut[0];
        if (L_cpc_cut.length > 1) {
            P_cpc += '/' + L_cpc_cut[1];
            for (let L_parcours_cpc_cut = 2; L_parcours_cpc_cut < L_cpc_cut.length; L_parcours_cpc_cut++) {
                P_cpc += L_cpc_cut[L_parcours_cpc_cut];
            }
        }
        window.open('http://worldwide.espacenet.com/classification?locale=en_EP#!/CPC=' + P_cpc,
            'express_cpc');
    }

    CPManageClick = () => {
        this.setState({
            CPManageOpen: !this.state.CPManageOpen
        });
    }

    CP_manage_close = () => {
        this.setState({
            CPManageOpen: false
        });
    }

    CP_manage_valide = (P_valide_choix, P_index_CP) => {
        if (this.CPManageRef.current != null) {
            this.CPManageRef.current.CP_manage_valide(P_valide_choix, P_index_CP);
        }
    }

    cp_change = (P_index_cp, P_code, P_event) => {
        let L_CPInfosTemps = this.state.CPInfosTemps;
        switch (L_CPInfosTemps[P_index_cp].type) {
            case 'DAT':
                let L_days = '';
                if (P_event !== null && P_event !== -1) {
                    let L_month = (P_event.getMonth() + 1).toString();
                    L_days = P_event.getDate().toString();
                    if (L_month.length < 2) {
                        L_month = '0' + L_month;
                    }
                    if (L_days.length < 2) {
                        L_days = '0' + L_days;
                    }
                    L_days = P_event.getFullYear().toString() + '-' +
                        L_month + '-' +
                        L_days;
                }

                //this.props.cp_save(P_index_cp, [L_days]);
                L_CPInfosTemps[P_index_cp].data[0].value = L_days;
                //L_CPInfosTemps[P_index_cp].Open = false;
                this.setState({
                    CPInfosTemps: L_CPInfosTemps
                }, () => {
                    if (P_event === -1) {
                        this.cp_click(P_index_cp, 0, { key: "Enter" });
                    }
                });
                break;
            case 'NUM':
                L_CPInfosTemps[P_index_cp].data[0].value = P_event.target.value;
                this.setState({
                    CPInfosTemps: L_CPInfosTemps
                });
                break;
            case 'LNK':
                L_CPInfosTemps[P_index_cp].data[0].value = P_event.target.value;
                this.setState({
                    CPInfosTemps: L_CPInfosTemps
                });
                break;
            default:
                break;
        }
    }

    cp_click = (P_index_cp, P_code, P_event) => {
        if (P_event.key === 'Enter') {
            if (this.state.CPInfosTemps[P_index_cp].type !== "LNK") {
                this.props.cp_save(P_index_cp, [this.state.CPInfosTemps[P_index_cp].data[0].value]);
                return false;
            }
            let targetValue = this.state.CPInfosTemps[P_index_cp].data[0].value;
            if (targetValue.length > 0) {
                if (/^https?:[\\/]{2}/gi.test(targetValue) == false) {
                    targetValue = 'http://' + targetValue;
                }
            }
            this.props.cp_save(P_index_cp, [targetValue]);
            return false;
        }
        P_event.stopPropagation();
    }

    voirCP = () => {
        if (!this.state.patent_cp_open) {
            return (<div style={{ display: 'none' }}></div>)
        }
        return this.state.CPInfosTemps.map((P_cp_valeur, P_index_cp) => {
            let L_mode_lecture_seul = "";
            if (P_cp_valeur.right[1] !== "W") {
                L_mode_lecture_seul = " grise";
            }
            switch (P_cp_valeur.type) {
                case 'CLA':
                case 'DIC':
                    return (
                        <div key={"CP_" + P_index_cp} className={"D_dd_info_doc_haut_gauche_cp_liste_un_cla" + L_mode_lecture_seul}>
                            <div className="D_dd_info_doc_haut_gauche_cp_liste_un_cla_label">
                                {P_cp_valeur.display}
                            </div>
                            <div
                                className="D_dd_info_doc_haut_gauche_cp_liste_un_cla_valeur"
                                style={P_cp_valeur.right[1] === "W" ? {} : { pointerEvents: 'none' }}
                            >
                                <SelectPerso
                                    id={"select_perso_cp_" + P_index_cp}
                                    SelectMode={1} // 0 -> simple     1 -> Multiple
                                    TexteApply={this.props.langue[98].LIB}
                                    TextePlaceHolder={P_cp_valeur.right[1] === "W" ? this.props.langue[110].LIB : this.props.langue[53].LIB}
                                    SelectValue={P_cp_valeur.data}
                                    SelectValueName="value" // champs vide signif que les var sont en tableau de string sinon tableau d'object
                                    SelectDescName="description" // champs vide signif que les var sont en tableau de string sinon tableau d'object
                                    SelectSelected={null} // champs utile si les var son sous tableau simple ou non contenu dans l'objet ayant les value
                                    SelectselectedName="select" // champs vide signifie que les var sont dans le tableau simplement sinon dans le tableau d'object
                                    select_valide={(P_selectNums) => {
                                        let L_retour_tab = [];
                                        for (let L_parcours_select = 0; L_parcours_select < P_selectNums.length; L_parcours_select++) {
                                            if (P_selectNums[L_parcours_select]) {
                                                L_retour_tab.push(P_cp_valeur.data[L_parcours_select].value);
                                            }
                                        }
                                        this.props.cp_save(P_index_cp, L_retour_tab);
                                    }
                                    }
                                />
                            </div>
                        </div>
                    )
                case 'LST':
                    return (
                        <div key={"CP_" + P_index_cp} className={"D_dd_info_doc_haut_gauche_cp_liste_un_lst" + L_mode_lecture_seul}>
                            <div className="D_dd_info_doc_haut_gauche_cp_liste_un_lst_label">
                                {P_cp_valeur.display}
                            </div>
                            <div
                                className="D_dd_info_doc_haut_gauche_cp_liste_un_lst_valeur"
                                style={P_cp_valeur.right[1] === "W" ? {} : { pointerEvents: 'none' }}>
                                <SelectPerso
                                    id={"select_perso_cp_" + P_index_cp}
                                    SelectMode={0} // 0 -> simple     1 -> Multiple
                                    TexteApply={this.props.langue[98].LIB}
                                    TextePlaceHolder={P_cp_valeur.right[1] === "W" ? this.props.langue[110].LIB : this.props.langue[53].LIB}
                                    SelectValue={P_cp_valeur.data}
                                    SelectValueName="value" // champs vide signif que les var sont en tableau de string sinon tableau d'object
                                    SelectDescName="description" // champs vide signif que les var sont en tableau de string sinon tableau d'object
                                    SelectSelected={null} // champs utile si les var son sous tableau simple ou non contenu dans l'objet ayant les value
                                    SelectselectedName="select" // champs vide signifie que les var sont dans le tableau simplement sinon dans le tableau d'object
                                    select_valide={(P_selectNums) => {
                                        if (P_selectNums !== null) {
                                            this.props.cp_save(P_index_cp, [P_cp_valeur.data[P_selectNums].value])
                                        }
                                        else {
                                            this.props.cp_save(P_index_cp, null)
                                        }
                                    }
                                    }
                                />
                            </div>
                        </div>
                    )
                case 'TXT':
                    return (
                        <div key={"CP_" + P_index_cp} className={"D_dd_info_doc_haut_gauche_cp_liste_un_txt" + L_mode_lecture_seul}>
                            <div className="D_dd_info_doc_haut_gauche_cp_liste_un_txt_titre">
                                <div className="D_dd_info_doc_haut_gauche_cp_liste_un_txt_label">
                                    {P_cp_valeur.display}
                                </div>
                                <div className="D_dd_info_doc_haut_gauche_cp_liste_un_txt_save"
                                    onClick={() => this.props.cp_save(P_index_cp, [P_cp_valeur.Ref.current.get_comment()])}
                                    style={P_cp_valeur.EditMode ? {} : { display: 'none' }}>
                                    {this.props.langue[109].LIB}
                                </div>
                                <div className="D_dd_info_doc_haut_gauche_cp_liste_un_txt_options"
                                    onClick={() => this.props.cp_option_mode(P_index_cp)}
                                    style={P_cp_valeur.EditMode ? {} : { display: 'none' }}>
                                </div>
                                <div className="D_dd_info_doc_haut_gauche_cp_liste_un_txt_display"
                                    onClick={() => this.props.cp_edit_mode(P_index_cp)}
                                    style={(P_cp_valeur.EditMode || P_cp_valeur.right[1] !== "W") ? { display: 'none' } : {}}>
                                </div>
                            </div>
                            <div className="D_dd_info_doc_haut_gauche_cp_liste_un_txt_valeur"
                            >
                                <RichComment
                                    ref={P_cp_valeur.Ref}
                                    Id={"CP_" + P_index_cp}
                                    PlaceHolder={this.props.langue[53].LIB}
                                    CommentHtml={P_cp_valeur.data[0].value}
                                    EditMode={P_cp_valeur.EditMode}
                                    OptionMode={P_cp_valeur.OptionMode}
                                    ListOption={1}
                                />
                            </div>
                        </div>
                    )
                case 'DAT':
                    return (
                        <div key={"CP_" + P_index_cp} className={"D_dd_info_doc_haut_gauche_cp_liste_un_dat" + L_mode_lecture_seul}>
                            <div className="D_dd_info_doc_haut_gauche_cp_liste_un_dat_label">
                                {P_cp_valeur.display}
                            </div>
                            <div className="D_dd_info_doc_haut_gauche_cp_liste_un_dat_valeur">
                                <div
                                    className="D_avancee_date_debut_CP_affichage"
                                    style={P_cp_valeur.Open ? { display: 'none' } : {}}
                                    onClick={(event) => {
                                        this.state.CPInfosTemps[P_index_cp].Ref.current.setOpen(true);
                                        //this.state.CPInfosTemps[P_index_cp].Ref.current.focus();
                                        setTimeout(() => document.getElementById("D_avancee_date_debut_CP_" + P_index_cp).focus(), 500);
                                        let L_CPInfosTemps = this.state.CPInfosTemps;
                                        L_CPInfosTemps[P_index_cp].Open = true;
                                        this.setState({
                                            CPInfosTemps: L_CPInfosTemps
                                        });
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }}
                                >
                                    <div
                                        className="D_avancee_date_debut_CP_affichage_label"
                                    >
                                        {P_cp_valeur.data[0].value.length > 0 ? P_cp_valeur.data[0].value : this.props.langue[53].LIB}
                                    </div>
                                    <div
                                        className="D_avancee_date_debut_CP_affichage_croix"
                                        style={P_cp_valeur.data[0].value.length > 0 ? {} : { display: "none" }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            this.cp_change(P_index_cp, 0, -1);
                                        }}
                                    >
                                    </div>
                                </div>
                                <DatePicker
                                    ref={P_cp_valeur.Ref}
                                    id={"D_avancee_date_debut_CP_" + P_index_cp}
                                    className={P_cp_valeur.Open ? "D_dd_info_doc_haut_gauche_cp_liste_un_dat_valeur_date" : "D_dd_info_doc_haut_gauche_cp_liste_un_dat_valeur_date hide"}
                                    placeholderText="YYYY/MM/DD"
                                    dateFormat="yyyy/MM/dd"
                                    minDate={new Date(1750, 1, 1)}
                                    maxDate={new Date()}
                                    showMonthDropdown={true}
                                    showYearDropdown={true}
                                    scrollableYearDropdown={true}
                                    selected={P_cp_valeur.data[0].value.length > 0 ? new Date(P_cp_valeur.data[0].value) : null}
                                    onChange={(event) => this.cp_change(P_index_cp, 0, event)}
                                    /*onKeyDown={(event) => this.cp_click(P_index_cp, 0, event)}*/
                                    onCalendarClose={() => {
                                        this.cp_click(P_index_cp, 0, { key: "Enter" })
                                    }}
                                    onBlur={(event) => {
                                        let L_CPInfosTemps = this.state.CPInfosTemps;
                                        L_CPInfosTemps[P_index_cp].Open = false;
                                        L_CPInfosTemps[P_index_cp].data[0].value = L_CPInfosTemps[P_index_cp].data[0].valueDefault;
                                        this.setState({
                                            CPInfosTemps: L_CPInfosTemps
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )
                case 'NUM':
                    return (
                        <div key={"CP_" + P_index_cp} className={"D_dd_info_doc_haut_gauche_cp_liste_un_num" + L_mode_lecture_seul}>
                            <div className="D_dd_info_doc_haut_gauche_cp_liste_un_num_label">
                                {P_cp_valeur.display}
                            </div>
                            <div
                                className="D_dd_info_doc_haut_gauche_cp_liste_un_num_valeur"
                                style={P_cp_valeur.right[1] === "W" ? {} : { pointerEvents: 'none' }}>
                                <div
                                    className="D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage"
                                    style={P_cp_valeur.Open ? { display: 'none' } : {}}
                                    onClick={(event) => {
                                        setTimeout(() => document.getElementById("S_dd_info_doc_haut_gauche_cp_liste_un_num_valeur_" + P_index_cp).focus(), 500);
                                        let L_CPInfosTemps = this.state.CPInfosTemps;
                                        L_CPInfosTemps[P_index_cp].Open = true;
                                        this.setState({
                                            CPInfosTemps: L_CPInfosTemps
                                        });
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }}
                                >
                                    <div
                                        className="D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage_label"
                                    >
                                        {P_cp_valeur.data[0].value.length > 0 ? P_cp_valeur.data[0].value : this.props.langue[53].LIB}
                                    </div>
                                    <div
                                        className="D_dd_info_doc_haut_gauche_cp_liste_un_num_affichage_croix"
                                        style={P_cp_valeur.data[0].value.length > 0 ? {} : { display: "none" }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            this.props.cp_save(P_index_cp, ['']);
                                        }}
                                    >
                                    </div>
                                </div>
                                <input
                                    id={"S_dd_info_doc_haut_gauche_cp_liste_un_num_valeur_" + P_index_cp}
                                    type="number"
                                    className="S_dd_info_doc_haut_gauche_cp_liste_un_num_valeur"
                                    style={P_cp_valeur.Open ? {} : { display: 'none' }}
                                    placeholder="none"
                                    value={P_cp_valeur.data[0].value}
                                    onChange={(event) => this.cp_change(P_index_cp, 0, event)}
                                    onKeyDown={(event) => this.cp_click(P_index_cp, 0, event)}
                                    onBlur={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        let L_CPInfosTemps = this.state.CPInfosTemps;
                                        L_CPInfosTemps[P_index_cp].Open = false;
                                        L_CPInfosTemps[P_index_cp].data[0].value = L_CPInfosTemps[P_index_cp].data[0].valueDefault;
                                        this.setState({
                                            CPInfosTemps: L_CPInfosTemps
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )
                case 'LNK':
                    return (
                        <div key={"CP_" + P_index_cp} className={"D_dd_info_doc_haut_gauche_cp_liste_un_lnk" + L_mode_lecture_seul}>
                            <div className="D_dd_info_doc_haut_gauche_cp_liste_un_lnk_label">
                                {P_cp_valeur.display}
                            </div>
                            <div className="D_dd_info_doc_haut_gauche_cp_liste_un_lnk_valeur"
                                style={P_cp_valeur.right[1] === "W" ? {} : { pointerEvents: 'none' }}
                            >
                                <div
                                    className="D_dd_info_doc_haut_gauche_cp_liste_un_lnk_affichage"
                                    style={P_cp_valeur.Open ? { display: 'none' } : {}}
                                >
                                    <div
                                        className="D_dd_info_doc_haut_gauche_cp_liste_un_lnk_affichage_label"
                                    >
                                        <a
                                            href={P_cp_valeur.data[0].value}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={P_cp_valeur.data[0].value.length > 0 ? {} : { visibility: 'hidden' }}
                                        >
                                            {P_cp_valeur.data[0].value.length > 0 ? P_cp_valeur.data[0].value : "none"}
                                        </a>
                                    </div>
                                    <div
                                        className="D_dd_info_doc_haut_gauche_cp_liste_un_lnk_affichage_edit"
                                        onClick={(event) => {
                                            setTimeout(() => document.getElementById("S_dd_info_doc_haut_gauche_cp_liste_un_lnk_valeur_" + P_index_cp).focus(), 500);
                                            let L_CPInfosTemps = this.state.CPInfosTemps;
                                            L_CPInfosTemps[P_index_cp].Open = true;
                                            this.setState({
                                                CPInfosTemps: L_CPInfosTemps
                                            });
                                            event.stopPropagation();
                                            event.preventDefault();
                                        }}
                                    >
                                    </div>
                                </div>
                                <input
                                    id={"S_dd_info_doc_haut_gauche_cp_liste_un_lnk_valeur_" + P_index_cp}
                                    type="text"
                                    className="S_dd_info_doc_haut_gauche_cp_liste_un_lnk_valeur"
                                    style={P_cp_valeur.Open ? {} : { display: 'none' }}
                                    placeholder="none"
                                    value={P_cp_valeur.data[0].value}
                                    onChange={(event) => this.cp_change(P_index_cp, 0, event)}
                                    onKeyDown={(event) => this.cp_click(P_index_cp, 0, event)}
                                    onBlur={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        let L_CPInfosTemps = this.state.CPInfosTemps;
                                        L_CPInfosTemps[P_index_cp].Open = false;
                                        L_CPInfosTemps[P_index_cp].data[0].value = L_CPInfosTemps[P_index_cp].data[0].valueDefault;
                                        this.setState({
                                            CPInfosTemps: L_CPInfosTemps
                                        });
                                    }}
                                />
                            </div>
                        </div>
                    )
                default:
                    break;
            }
            return (
                <div key={"CP_" + P_index_cp} className="D_dd_info_doc_haut_gauche_cp_liste_un"
                    style={{ display: 'none' }}>
                </div>
            );
        });
    }

    render() {
        const { highlightsProfile } = this.props;

        if (this.props.documentUn.etat === Constante.etat.encours) {
            return (
                <div id="D_droite_document" className="loadgif"
                    style={{ display: 'none' }}
                // style={(this.props.affichage.document === 1) ? {} : { display: 'none' }}
                >
                </div>
            );
        }
        if (this.props.documentUn.etat === Constante.etat.erreur) {
            return (
                <div id="D_droite_document">
                    <span id="S_error">
                        {'Permalink Error!'}
                    </span>
                </div>
            );
        }
        if (this.props.EmplacementRecherche === Constante.EmplacementRecherche.NPL) {
            if (this.props.documentUn.data.title === null || typeof this.props.documentUn.data.title === 'undefined') {
                return (
                    <div id="D_droite_document" style={{ display: 'none' }}></div>
                );
            }
        }
        else {
            if (this.props.documentUn.data.TI === null || typeof this.props.documentUn.data.TI === 'undefined') {
                return (
                    <div id="D_droite_document" style={{ display: 'none' }}></div>
                );
            }
        }
        if (Object.keys(this.props.documentUn.data).length === 0) {
            return (
                <div id="D_droite_document" style={(this.props.affichage.document === 1) ? {} : { display: 'none' }}>
                </div>
            );
        }
        let L_style_doc = {};
        if (this.props.affichage.document !== 1) {
            L_style_doc.display = 'none';
        }
        if (this.props.affichage.visioneuse !== 1 && this.props.affichage.mode === Constante.affichageMode.listedoc) {
            L_style_doc.flexBasis = 'auto';
        }
        if (this.props.EmplacementRecherche === Constante.EmplacementRecherche.NPL) {
            return (
                <div id="D_droite_document"
                    style={L_style_doc}
                >
                    <div id="D_dd_titre">
                        {this.decorateWithHighlights(this.props.documentUn.data.title)}
                    </div>
                    <div id="D_dd_info_doc_picture">
                        <div id="D_dd_info_doc">
                            <div id="D_dd_info_doc_haut">
                                <div id="D_dd_info_doc_haut_droite">
                                    <div id="D_dd_info_doc_haut_droite_etage_1_NPL">
                                        <div id="D_dd_info_doc_haut_droite_etage_1_date_pub" >
                                            {this.props.langue[48].LIB}
                                            <span id="S_dd_info_doc_haut_droite_etage_1_date_pub">
                                                {this.props.documentUn.data.date_publication.substring(0, 10)}
                                            </span>
                                        </div>
                                        <div id="D_dd_info_doc_haut_droite_etage_1_date_start"
                                            style={this.props.documentUn.data.start.length > 0 ? {} : { display: 'none' }}
                                        >
                                            {this.props.langue[62].LIB}
                                            <span id="S_dd_info_doc_haut_droite_etage_1_date_start">
                                                {this.props.documentUn.data.start.substring(0, 10)}
                                            </span>
                                        </div>
                                        <div id="D_dd_info_doc_haut_droite_etage_1_date_end"
                                            style={this.props.documentUn.data.end.length > 0 ? {} : { display: 'none' }}
                                        >
                                            {this.props.langue[63].LIB}
                                            <span id="S_dd_info_doc_haut_droite_etage_1_date_end">
                                                {this.props.documentUn.data.end.substring(0, 10)}
                                            </span>
                                        </div>
                                    </div>
                                    <div id="D_dd_info_doc_haut_droite_etage_2" className="etage2_NPL">
                                        <div id="D_dd_info_doc_haut_droite_etage_2_subtype">
                                            {this.props.documentUn.data.subtype + '(' + this.props.documentUn.data.type + ')'}
                                        </div>
                                        <div id="D_dd_info_doc_haut_droite_etage_2_source_name">
                                            {PARSER('&nbsp;&nbsp;&#8226;&nbsp;&nbsp;' + this.props.documentUn.data.source_name)}
                                        </div>
                                        <div id="D_dd_info_doc_haut_droite_etage_2_source_issn"
                                            style={this.props.documentUn.data.issn.length === 0 ? { display: 'none' } : {}}
                                        >
                                            {this.props.langue[64].LIB + ' ' + this.props.documentUn.data.issn}
                                        </div>
                                    </div>
                                    <div id="D_dd_info_doc_haut_droite_etage_2_source_doi"
                                        style={this.props.documentUn.data.doi.length === 0 ? { display: 'none' } : { display: 'none' }}
                                    >
                                        <div id="D_dd_info_doc_haut_droite_etage_2_source_doi_label">{this.props.langue[65].LIB}</div>
                                        {this.props.documentUn.data.doi}
                                    </div>
                                    <div id="D_dd_info_doc_haut_droite_etage_3_keywords"
                                        style={this.props.documentUn.data.listOfKeyword.length === 0 ? { display: 'none' } : {}}
                                    >
                                        <div id="D_dd_info_doc_haut_droite_etage_3_keywords_label">{this.props.langue[66].LIB}</div>
                                        {this.decorateWithHighlights('&nbsp;' + this.props.documentUn.data.listOfKeyword.join('&nbsp; &#8226; &nbsp;'))}
                                    </div>
                                    <div id="D_dd_info_doc_haut_droite_etage_3_affi"
                                        style={this.props.documentUn.data.organisations.length === 0 ? { display: 'none' } : {}}
                                    >
                                        <div id="D_dd_info_doc_haut_droite_etage_3_affi_label">{this.props.langue[67].LIB}</div>
                                        {PARSER('&nbsp;' + this.props.documentUn.data.organisations)}
                                    </div>
                                    <div id="D_dd_info_doc_haut_droite_etage_3_auteurs"
                                        style={this.props.documentUn.data.listOfPerson.length === 0 ? { display: 'none' } : {}}
                                    >
                                        <div id="D_dd_info_doc_haut_droite_etage_3_auteurs_label">{this.props.langue[68].LIB}</div>
                                        {PARSER('&nbsp;' + this.props.documentUn.data.listOfPerson.join('&nbsp; &#8226; &nbsp;'))}
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_gauche" >
                                    <div id="D_dd_info_doc_haut_gauche_abstract">
                                        <div id="D_dd_info_doc_haut_gauche_abstract_label">{this.props.langue[69].LIB}</div>
                                        {this.decorateWithHighlights(this.props.documentUn.data.abstract_description)}
                                    </div>
                                    <div id="D_dd_info_doc_haut_gauche_pubdomain"
                                        style={this.props.documentUn.data.listOfPubDomain.length === 0 ? { display: 'none' } : {}}
                                    >
                                        <div id="D_dd_info_doc_haut_gauche_pubdomain_label">{this.props.langue[70].LIB}</div>
                                        {PARSER('&nbsp;' + this.props.documentUn.data.listOfPubDomain.join('&nbsp; &#8226; &nbsp;'))}
                                    </div>

                                    <div id="D_dd_info_doc_haut_gauche_project"
                                        style={this.props.documentUn.data.project_acronym.length === 0 ? { display: 'none' } : {}}
                                    >
                                        <span id="S_dd_info_doc_haut_gauche_project_acronyme">{this.props.langue[71].LIB}:</span>
                                        {this.props.documentUn.data.project_acronym}<br />
                                        <span id="S_dd_info_doc_haut_gauche_project_cout">{this.props.langue[72].LIB}:</span>
                                        {this.props.documentUn.data.total_cost +
                                            this.props.documentUn.data.currencySymbol + '(' + this.props.documentUn.data.currency + ')'}<br />
                                        <span id="S_dd_info_doc_haut_gauche_project_financement">{this.props.langue[73].LIB}:</span>
                                        {this.props.documentUn.data.total_funding +
                                            this.props.documentUn.data.currencySymbol + '(' + this.props.documentUn.data.currency + ')'}<br />
                                    </div>
                                    <div id="D_dd_info_doc_haut_gauche_trial"
                                        style={this.props.documentUn.data.study_status.length === 0 ? { display: 'none' } : {}}
                                    >
                                        <div id="D_dd_info_doc_haut_gauche_trial_label">{this.props.langue[74].LIB}</div>
                                        <span id="S_dd_info_doc_haut_gauche_trial_etat">{this.props.langue[75].LIB}: </span>
                                        {this.props.documentUn.data.study_status}<br />
                                        <br />
                                        <span id="S_dd_info_doc_haut_gauche_trial_phase">l{this.props.langue[76].LIB}: </span>
                                        {PARSER('&nbsp;' + this.props.documentUn.data.listOfStudyPhase.join('&nbsp; &#8226; &nbsp;'))}<br />
                                        <br />
                                        <span id="S_dd_info_doc_haut_gauche_trial_intervention">{this.props.langue[77].LIB}: </span>
                                        {PARSER('&nbsp;' + this.props.documentUn.data.listOfInterventionType.join('&nbsp; &#8226; &nbsp;'))}<br />
                                        <br />
                                        <span id="S_dd_info_doc_haut_gauche_trial_condition">{this.props.langue[78].LIB}: </span>
                                        {PARSER('&nbsp;' + this.props.documentUn.data.listOfStudyCondition.join('&nbsp; &#8226; &nbsp;'))}<br />
                                        <br />
                                        <span id="S_dd_info_doc_haut_gauche_trial_resultat">{this.props.langue[79].LIB}: </span>
                                        {this.props.documentUn.data.has_results}<br />
                                        <br />
                                        <span id="S_dd_info_doc_haut_gauche_trial_choix">{this.props.langue[80].LIB}: </span>
                                        {this.props.documentUn.data.study_purpose}<br />
                                        <br />
                                        <span id="S_dd_info_doc_haut_gauche_trial_critere">{this.props.langue[81].LIB}: </span>
                                        {this.props.documentUn.data.study_masking}<br />
                                        <br />
                                        <span id="S_dd_info_doc_haut_gauche_trial_financement">{this.props.langue[82].LIB}: </span>
                                        {this.props.documentUn.data.funder_type}<br />
                                        <br />
                                        <div id="D_dd_info_doc_haut_gauche_trial_desc">{this.props.langue[83].LIB}</div>
                                        {this.props.documentUn.data.content_en}
                                    </div>
                                    <div id="D_dd_info_doc_haut_gauche_ext_ressource"
                                        style={this.props.documentUn.data.source_url.length === 0 && this.props.documentUn.data.subtype !== "Defensive Publication" ? { display: 'none' } : {}}
                                    >
                                        <div id="D_dd_info_doc_haut_gauche_ext_ressource_label">{this.props.langue[84].LIB}</div>
                                        <a href={this.props.documentUn.data.source_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={this.props.documentUn.data.source_url.length === 0 ? { display: 'none' } : {}}
                                        >{this.props.langue[85].LIB.replace("NAME_SITE", this.props.documentUn.data.source_name)}</a>
                                        <div
                                            style={ this.props.documentUn.data.source_url.length === 0 && this.props.documentUn.data.subtype === "Defensive Publication" ? {} : { display: 'none' }}
                                        >
                                            This PDF is not currently available please contact support. Learn more about Research Disclosure here :&nbsp;
                                            <a href="http://www.researchdisclosure.com"
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                www.researchdisclosure.com
                                                </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            );
        }
        //pas de la npl
        return (
            <div id="D_droite_document"
                style={L_style_doc}
            >
                <CPManage
                    ref={this.CPManageRef}
                    langue={this.props.langue}
                    ManageActif={this.state.CPManageOpen}
                    CP={this.props.documentUn.data.CP}
                    CP_manage_close={this.CP_manage_close}
                    cp_create_valide={this.props.cp_create_valide}
                    cp_delete_valide={this.props.cp_delete_valide}
                    cp_modif_valide={this.props.cp_modif_valide}
                    cp_value_valide={this.props.cp_value_valide}
                />
                <div id="D_dd_titre">
                    {this.decorateWithHighlights(this.props.documentUn.data.TI)}
                </div>
                <div id="D_dd_info_doc_picture">
                    <div id="D_dd_info_doc">
                        <div id="D_dd_info_doc_haut">
                            <div id="D_dd_info_doc_haut_droite">
                                <div id="D_dd_info_doc_haut_droite_etage_1">
                                    <span id="S_dd_info_doc_haut_droite_etage_1_REP">{PARSER(this.props.documentUn.data.REP.replace('_', ' '))}</span><br />
                                    <span id="S_dd_info_doc_haut_droite_etage_1_gr" >
                                        {this.props.permalink ? '' : this.props.langue[20].LIB + ' : '}
                                        {(this.props.documentUn.data.V_FACT.STATUS !== null) ? this.props.documentUn.data.V_FACT.STATUS : ''}
                                        {(this.props.documentUn.data.V_FACT.STATE !== null) ? ' ' + this.props.documentUn.data.V_FACT.STATE : ''}
                                    </span>
                                    <div id="D_dd_info_doc_haut_droite_priority_date_label">
                                        <div id="S_dd_info_doc_haut_droite_etage_3_priority_date_label">{this.props.langue[87].LIB} :</div>
                                        <div className="S_dd_info_doc_haut_droite_etage_3_priority_date">{PARSER(this.props.documentUn.data.EPRD)}</div>
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_droite_etage_2">
                                    <div id="D_dd_info_doc_haut_droite_etage_2_download_pdf" onClick={() => this.PDFDownload(this.props.documentUn.data.PDF)}>
                                        <div id="D_dd_info_doc_haut_droite_etage_2_download_pdf_image"></div>
                                        <span id="S_dd_info_doc_haut_droite_etage_2_download_pdf">{this.props.langue[14].LIB}</span>
                                    </div>
                                    <div id="D_dd_info_doc_haut_droite_etage_2_similar"
                                        style={this.props.permalink ? { display: 'none' } : {}}
                                        onClick={() => this.props.similariteSearchUnDoc()}
                                    >
                                        <div id="D_dd_info_doc_haut_droite_etage_2_similar_image"></div>
                                        <span id="S_dd_info_doc_haut_droite_etage_2_similarite_label">{this.props.langue[15].LIB}</span>
                                    </div>
                                    <div id="D_dd_info_doc_haut_droite_etage_2_prio_art"
                                        style={this.props.permalink ? { display: 'none' } : {}}
                                        onClick={() => this.props.priorArtSearchUnDoc()}
                                    >
                                        <div id="D_dd_info_doc_haut_droite_etage_2_prior_art_image"></div>
                                        <span id="S_dd_info_doc_haut_droite_etage_2_prior_art_label">{this.props.langue[86].LIB}</span>
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_droite_etage_3">
                                    <div id="D_dd_info_doc_haut_droite_etage_3_current_assignee">
                                        <div id="D_dd_info_doc_haut_droite_etage_3_current_assignee_label">{this.props.langue[47].LIB}</div>
                                        <div id="D_dd_info_doc_haut_droite_etage_3_current_assignee_liste">
                                            {
                                                this.props.documentUn.data.PA_S.map((P_valeur, P_index) => {
                                                    let L_virgule = '&nbsp;&nbsp;&#8226;&nbsp;&nbsp;';
                                                    if (P_index === 0) {
                                                        L_virgule = '';
                                                    }
                                                    return (
                                                        <span className="S_dd_info_doc_haut_droite_etage_3_current_assignee" key={P_index}>{PARSER(L_virgule + P_valeur)}</span>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div id="D_dd_info_doc_haut_droite_etage_3_inventeur">
                                        <div id="D_dd_info_doc_haut_droite_etage_3_inventeur_label">{this.props.langue[16].LIB}</div>
                                        <div id="D_dd_info_doc_haut_droite_etage_3_inventeur_liste">
                                            {
                                                this.props.documentUn.data.IN_S.map((P_valeur, P_index) => {
                                                    let L_virgule = '&nbsp;&nbsp;&#8226;&nbsp;&nbsp;';
                                                    if (P_index === 0) {
                                                        L_virgule = '';
                                                    }
                                                    return (
                                                        <span className="S_dd_info_doc_haut_droite_etage_3_inventeur" key={P_index}>{PARSER(L_virgule + P_valeur)}</span>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_droite_etage_4">
                                    <div id="D_dd_info_doc_haut_droite_etage_4_famille">
                                        <div id="D_dd_info_doc_haut_droite_etage_4_famille_label">{this.props.langue[45].LIB}</div>
                                        <div id="D_dd_info_doc_haut_droite_etage_4_famille_liste">
                                            <span
                                                style={Object.keys(this.props.documentUn.data.FPN_CODE).length < 1 ? {} : { display: 'none' }}
                                                className="S_dd_info_doc_haut_droite_etage_4_famille"
                                            >
                                                {this.props.langue[53].LIB}
                                            </span>
                                            {
                                                Object.keys(this.props.documentUn.data.FPN_CODE).map((P_key, P_index) => {
                                                    let L_virgule = '&#8226; &nbsp;';
                                                    if (P_index === 0) {
                                                        L_virgule = '';
                                                    }
                                                    return (
                                                        <span className="S_dd_info_doc_haut_droite_etage_4_famille" key={P_index} >{PARSER(L_virgule)}
                                                            <img className="I_dd_info_doc_haut_droite_etage_4_famille" src={"./images/flag/" + P_key.toLowerCase() + ".svg"} alt={P_key} />{P_key}
                                                        </span>
                                                    );
                                                })

                                            }
                                        </div>
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_droite_etage_a">
                                    <div id="D_dd_info_doc_haut_droite_etage_a_famille_label">{this.props.base === 'fullpat' ? this.props.langue[54].LIB : this.props.langue[46].LIB}
                                    </div>
                                    <div id="D_dd_info_doc_haut_droite_etage_a_famille_infos">
                                        {this.props.langue[38].LIB} &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                                    {this.props.langue[51].LIB} &nbsp;&nbsp;&#8226;&nbsp;&nbsp;
                                    {this.props.langue[52].LIB}
                                    </div>
                                    <div id="D_dd_info_doc_haut_droite_etage_a_famille_open"
                                        className={this.state.famille_open ? 'div_close' : 'div_open'}
                                        onClick={() => { this.changeOpen('famille') }}></div>
                                    <br />
                                    <div
                                        style={this.state.famille_open ? {} : { display: 'none' }}
                                    >
                                        <div className="D_vapl_principal_info head">
                                            <div className="D_v_apl_td_1 head">
                                                {this.props.langue[38].LIB}
                                            </div>
                                            <div className="D_v_apl_td_2 head">
                                                {this.props.langue[39].LIB}
                                            </div>
                                            <div className="D_v_apl_td_3 head">
                                                {this.props.langue[40].LIB}
                                            </div>
                                            <div className="D_v_apl_td_4 head">
                                                {this.props.langue[41].LIB}
                                            </div>
                                            <div className="D_v_apl_td_5 head">
                                                &nbsp;&nbsp;
                                        </div>
                                        </div>
                                        {this.v_apl(this.props.documentUn.data.V_APL, 0)}
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_gauche_commentaire" style={this.props.EmplacementRecherche === Constante.EmplacementRecherche.WORKFILE ? {} : { display: 'none' }}>
                                    <div id="D_dd_info_doc_haut_gauche_commentaire_etoile" >
                                        <span id="D_dd_info_doc_haut_gauche_commentaire_etoile_lib">{this.props.langue[42].LIB}</span>
                                        <img src={this.props.documentUn.data.ETOILE > 0 ? './images/etoile_select.svg' : "./images/etoile_vide.svg"}
                                            className="D_dd_info_doc_haut_gauche_commentaire_etoile_1"
                                            alt="1"
                                            onClick={() => this.rankUpdate(1)} />
                                        <img src={this.props.documentUn.data.ETOILE > 1 ? "./images/etoile_select.svg" : "./images/etoile_vide.svg"} alt="2" onClick={() => this.rankUpdate(2)} />
                                        <img src={this.props.documentUn.data.ETOILE > 2 ? "./images/etoile_select.svg" : "./images/etoile_vide.svg"} alt="3" onClick={() => this.rankUpdate(3)} />
                                        <img src={this.props.documentUn.data.ETOILE > 3 ? "./images/etoile_select.svg" : "./images/etoile_vide.svg"} alt="4" onClick={() => this.rankUpdate(4)} />
                                        <img src={this.props.documentUn.data.ETOILE > 4 ? "./images/etoile_select.svg" : "./images/etoile_vide.svg"} alt="5" onClick={() => this.rankUpdate(5)} />
                                        <div id="D_dd_info_doc_haut_gauche_nb_add_open">
                                            <span
                                                id="D_dd_info_doc_haut_gauche_commentaire_nb_comment"
                                                style={(this.props.documentUn.data.Comment.length > 0 && this.props.user.acces.comment.read) ? {} : { display: 'none' }}>
                                                {this.props.documentUn.data.Comment.length + ' ' + this.props.langue[5].LIB}
                                            </span>
                                            <div id="D_dd_info_doc_haut_gauche_commentaire_add"
                                                style={(this.props.user.acces.comment.write && this.state.commentEditOpen === 0) ? {} : { display: 'none' }}
                                                onClick={() => this.commentAdd()}>
                                                {this.props.langue[43].LIB}
                                            </div>
                                            <div id="D_dd_info_doc_haut_gauche_commentaire_open"
                                                className={this.state.comment_open ? 'div_close' : 'div_open'}
                                                style={(!this.props.user.acces.comment.read || this.props.documentUn.data.Comment.length < 1) ? { display: 'none' } : {}}
                                                onClick={() => { this.changeOpen('comment') }}></div>
                                        </div>
                                    </div>
                                    <div id="D_dd_info_doc_haut_gauche_commentaire_liste" style={this.state.comment_open ? {} : { display: 'none' }}>
                                        <DialogComment
                                            ref={this.dialogComment}
                                            langue={this.props.langueDialog}
                                            open={this.state.commentEditOpen}
                                            type={this.state.dialogCommentType}
                                            menuAction={this.commentMenuAction}
                                            commentEditFermer={this.commentEditFermer}
                                        />
                                        {
                                            this.props.documentUn.data.Comment.map((P_note, P_index) => {
                                                if (P_note.title === null || typeof P_note.title === 'undefined') {
                                                    P_note.title = '';
                                                }
                                                if (P_note.text === null || typeof P_note.text === 'undefined') {
                                                    P_note.text = '';
                                                }
                                                return (
                                                    <div key={P_index} className="D_dd_info_doc_haut_gauche_commentaire_un">
                                                        <div className="D_dd_info_doc_haut_gauche_commentaire_un_edit"
                                                            style={(this.props.user.acces.comment.write && this.props.user.logon === P_note.createdBy) ? {} : { display: 'none' }}>
                                                            <CommentaireAction
                                                                langue={this.props.langue}
                                                                editComment={() => { this.commentUpdate(P_index) }}
                                                                deleteComment={() => this.commentDelete(P_index)}
                                                            />
                                                        </div>
                                                        <div className="D_dd_info_doc_haut_gauche_commentaire_un_date">{P_note.createdDate}</div>
                                                        <div className="D_dd_info_doc_haut_gauche_commentaire_un_createur">{P_note.createdBy}</div>
                                                        <div className="D_dd_info_doc_haut_gauche_commentaire_un_title">{PARSER(P_note.title)}</div>
                                                        <div className="D_dd_info_doc_haut_gauche_commentaire_un_comment">{PARSER(P_note.text)}</div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_gauche_cp"
                                    style={(Object.keys(this.props.documentUn.data.CP).length > 0 && this.props.EmplacementRecherche === Constante.EmplacementRecherche.WORKFILE && this.props.user.acces.cp.read) ? {} : { display: 'none' }}
                                >
                                    <div id="D_dd_info_doc_haut_gauche_cp_titre">
                                        <div id="D_dd_info_doc_haut_gauche_cp_lib">{this.props.langue[44].LIB}</div>
                                        <div id="D_dd_info_doc_haut_gauche_cp_nb">{"(" + this.props.documentUn.data.CP_REMPLI + "/" + Object.keys(this.props.documentUn.data.CP).length + ")"}</div>
                                        <div
                                            id="D_dd_info_doc_haut_gauche_cp_manage"
                                            /* manage field is disaled ! */
                                            style={(this.state.patent_cp_open && this.props.user.acces.cp.write) ? { display: 'none' } : { display: 'none' }}
                                            onClick={this.CPManageClick}
                                        >
                                            {this.props.langue[108].LIB}
                                        </div>
                                        <div id="D_dd_info_doc_haut_gauche_cp_open"
                                            className={this.state.patent_cp_open ? 'div_close' : 'div_open'}
                                            onClick={() => { this.changeOpen('cp') }}>
                                        </div>
                                    </div>
                                    <div id="D_dd_info_doc_haut_gauche_cp_liste"
                                        style={this.state.patent_cp_open ? {} : { display: 'none' }}
                                    >
                                        {
                                            this.voirCP()
                                        }
                                    </div>

                                </div>
                            </div>
                            <div id="D_dd_info_doc_haut_gauche" >
                                <div id="D_dd_info_doc_haut_gauche_abstract">
                                    <span id="S_dd_info_doc_haut_gauche_abstract">{this.props.langue[11].LIB}</span><br />
                                    <p id="P_dd_info_doc_haut_gauche_abstract">
                                        {this.decorateWithHighlights(this.props.documentUn.data.AB.replace(/\(.*\)<br\/>/, ''))}
                                    </p>
                                </div>
                                <div id="D_dd_info_doc_haut_gauche_images" style={this.props.documentUn.data.IMAGE === '' ? { display: 'none' } : {}}>
                                    <span id="S_dd_info_doc_haut_gauche_images" >
                                        {this.props.langue[12].LIB + ' (' + this.props.documentUn.data.IMG.length + ')'}</span>
                                    <div id="D_dd_info_doc_haut_gauche_images_visionneuse">
                                        {
                                            this.props.documentUn.data.IMG?.map((P_img, P_index) => {
                                                return (
                                                    <div className="D_dd_info_doc_haut_gauche_images_visionneuse"
                                                        key={P_index}
                                                        onClick={() => {
                                                            this.props.changeImageDocUn(P_index);
                                                            if (this.props.affichage.mode !== Constante.affichageMode.listedoc) {
                                                                this.props.changeSelectOuMode(Constante.AffichageSelect.visioneuse, null);
                                                            }
                                                            else {
                                                                this.props.changeSelectOuMode(Constante.AffichageSelect.documentEtVisioneuse, null);
                                                            }
                                                        }
                                                        }
                                                        style={{ backgroundImage: 'url(' + P_img + ')' }}
                                                    ></div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_gauche_classification"
                                    style={(this.props.documentUn.data.IC_S.length > 0 || this.props.documentUn.data.CPC_S.length > 0) ? {} : { display: 'none' }}
                                >
                                    <div id="D_dd_info_doc_haut_gauche_classification_label">
                                        {this.props.langue[13].LIB}
                                    </div>
                                    <div id="D_dd_info_doc_haut_gauche_classification_open"
                                        className={this.state.patent_cib_open ? 'div_close' : 'div_open'}
                                        onClick={() => { this.changeOpen('cib') }}>
                                    </div>
                                    <div id="D_dd_info_doc_haut_gauche_classification_liste"
                                        style={(this.state.patent_cib_open && this.props.documentUn.data.IC_S.length > 0) ? {} : { display: 'none' }}
                                    >
                                        {this.props.langue[55].LIB} :
                                        {
                                            this.props.documentUn.data.IC_S.map((P_valeur, P_index) => {
                                                return (
                                                    <div
                                                        className="S_dd_info_doc_haut_gauche_classification_une"
                                                        key={P_index}
                                                        onClick={() => { this.voirCIB(P_valeur) }}
                                                    >{PARSER(P_valeur)}</div>
                                                );
                                            })
                                        }
                                    </div>
                                    <div id="D_dd_info_doc_haut_gauche_classification_liste_cpc"
                                        style={(this.state.patent_cib_open && this.props.documentUn.data.CPC_S.length > 0) ? {} : { display: 'none' }}
                                    >
                                        {this.props.langue[56].LIB} :
                                        {
                                            this.props.documentUn.data.CPC_S.map((P_valeur, P_index) => {
                                                return (
                                                    <div
                                                        className="S_dd_info_doc_haut_gauche_classification_une"
                                                        key={P_index}
                                                        onClick={() => { this.voirCPC(P_valeur) }}
                                                    >{PARSER(P_valeur)}</div>
                                                );
                                            })
                                        }
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_gauche_info_cle"
                                    style={(this.props.documentUn.data.OBJ.length > 10 || this.props.documentUn.data.ADB.length > 10) ? {} : { display: 'none' }}
                                >
                                    <div id="D_dd_info_doc_haut_gauche_info_cle_label">{this.props.langue[57].LIB}</div>
                                    <div id="D_dd_info_doc_haut_gauche_info_cle_open"
                                        className={this.state.information_cle_open ? 'div_close' : 'div_open'}
                                        onClick={() => { this.changeOpen('information_cle') }}></div>
                                    <div id="D_dd_info_doc_haut_gauche_info_cle_info"
                                        style={(this.state.information_cle_open && this.props.documentUn.data.OBJ.length > 10) ? {} : { display: 'none' }}
                                    >
                                        {this.decorateWithHighlights(this.props.langue[60].LIB + ': ' + this.props.documentUn.data.OBJ)}
                                    </div>
                                    <div id="D_dd_info_doc_haut_gauche_info_cle_info_2"
                                        style={(this.state.information_cle_open && this.props.documentUn.data.ADB.length > 10) ? {} : { display: 'none' }}
                                    >
                                        {this.decorateWithHighlights(this.props.langue[61].LIB + ': ' + this.props.documentUn.data.ADB)}
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_gauche_claims"
                                    style={(this.props.documentUn.data.CLMS.length > 10) ? {} : { display: 'none' }}
                                >
                                    <div id="D_dd_info_doc_haut_gauche_claims_label">{this.props.langue[29].LIB}</div>
                                    <div id="D_dd_info_doc_haut_gauche_claims_open"
                                        className={this.state.claims_open ? 'div_close' : 'div_open'}
                                        onClick={() => { this.changeOpen('claims') }}></div>
                                    <div id="D_dd_info_doc_haut_gauche_claims_info"
                                        style={this.state.claims_open ? {} : { display: 'none' }}
                                    >
                                        {this.decorateWithHighlights(this.props.documentUn.data.CLMS)}
                                    </div>
                                </div>

                                <div id="D_dd_info_doc_haut_gauche_claims_independante"
                                    style={(this.props.documentUn.data.ICLM.length > 10) ? {} : { display: 'none' }}
                                >
                                    <div id="D_dd_info_doc_haut_gauche_claims_independante_label">{this.props.langue[59].LIB}</div>
                                    <div id="D_dd_info_doc_haut_gauche_claims_independante_open"
                                        className={this.state.claims_independate_open ? 'div_close' : 'div_open'}
                                        onClick={() => { this.changeOpen('claims_independante') }}></div>
                                    <div id="D_dd_info_doc_haut_gauche_claims_independante_info"
                                        style={this.state.claims_independate_open ? {} : { display: 'none' }}
                                    >
                                        {this.decorateWithHighlights(this.props.documentUn.data.ICLM)}
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_gauche_desc"
                                    style={(this.props.documentUn.data.DESC.length > 10) ? {} : { display: 'none' }}
                                >
                                    <div id="D_dd_info_doc_haut_gauche_desc_label">{this.props.langue[28].LIB}</div>
                                    <div id="D_dd_info_doc_haut_gauche_desc_open"
                                        className={this.state.description_open ? 'div_close' : 'div_open'}
                                        onClick={() => { this.changeOpen('desc') }}></div>
                                    <div id="D_dd_info_doc_haut_gauche_desc_info"
                                        style={this.state.description_open ? {} : { display: 'none' }}
                                    >
                                        {this.decorateWithHighlights(this.props.documentUn.data.DESC)}
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_gauche_desc_orginale"
                                    style={(this.props.documentUn.data.ODES.length > 10) ? {} : { display: 'none' }}
                                >
                                    <div id="D_dd_info_doc_haut_gauche_desc_orginale_label">{this.props.langue[58].LIB}</div>
                                    <div id="D_dd_info_doc_haut_gauche_desc_orginale_open"
                                        className={this.state.description_originale_open ? 'div_close' : 'div_open'}
                                        onClick={() => { this.changeOpen('desc_orginale') }}></div>
                                    <div id="D_dd_info_doc_haut_gauche_desc_orginale_info"
                                        style={this.state.description_originale_open ? {} : { display: 'none' }}
                                    >
                                        {this.decorateWithHighlights(this.props.documentUn.data.ODES)}
                                    </div>
                                </div>
                                <div id="D_dd_info_doc_haut_droite_etage_cited_citing_legal"
                                    style={
                                        (
                                            this.props.documentUn.data.CITED_BY > 0 ||
                                            this.props.documentUn.data.CTN.length > 3
                                        )
                                        ? {}
                                        : { display: 'none' }
                                    }
                                >
                                    <div id="D_dd_info_doc_haut_droite_etage_cited"
                                        style={(this.props.documentUn.data.CITED_BY > 0 && !this.props.permalink) ? {} : { display: 'none' }}
                                    >
                                        <div id="D_dd_info_doc_haut_droite_etage_cited_label">{this.props.langue[25].LIB}</div>
                                        <div id="D_dd_info_doc_haut_droite_etage_cited_open"
                                            className={this.state.patent_cited_open ? 'div_close' : 'div_open'}
                                            onClick={() => { this.changeOpen('cited') }}></div>
                                        <div
                                            id="D_dd_info_doc_bas_patent_cited_encours"
                                            style={(this.props.documentUn.data.etatPatentCiting === Constante.etat.encours && this.props.permalink === false && this.state.patent_cited_open === true) ? {} : { display: 'none' }}
                                        >
                                        </div>
                                        <div
                                            id="D_dd_info_doc_bas_patent_citing"
                                            style={(this.props.documentUn.data.CITED_BY > 0 && this.props.permalink === false && this.state.patent_cited_open === true) ? {} : { display: 'none' }}
                                        >
                                            {
                                                this.props.documentUn.data.PatentCiting.map((P_pc, P_index) => {
                                                    if (P_pc.PA === null) {
                                                        P_pc.PA = '';
                                                    }
                                                    if (P_pc.EPRD === null) {
                                                        P_pc.EPRD = '';
                                                    }
                                                    return (
                                                        <div key={'D_dd_info_doc_bas_patent_citation' + P_index}>
                                                            <div className="D_dd_info_doc_bas_patent_citation_haut">
                                                                <div className="D_dd_info_doc_bas_patent_citation_haut_PN">
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_PN">{this.props.langue[48].LIB}</div>
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_PN_INFO">
                                                                        {P_pc.REP.replace('_', ' ')}
                                                                    </div>
                                                                </div>
                                                                <div className="D_dd_info_doc_bas_patent_citation_haut_PD">
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_PD">{this.props.langue[49].LIB}</div>
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_PD_INFO">
                                                                        {P_pc.EPRD.split('<br/>')[0]}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={P_pc.CTGN.length > 1 ? {} : { display: 'none' }}
                                                                    className="D_dd_info_doc_bas_patent_citation_haut_APP"
                                                                >
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_APP">{this.props.langue[50].LIB}</div>
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_APP_INFO">
                                                                        {P_pc.CTGN}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="D_dd_info_doc_bas_patent_citation_milieu">
                                                                <div className="S_dd_info_doc_bas_patent_citation_haut_titre">{this.props.langue[33].LIB}</div>
                                                                <div className="S_dd_info_doc_bas_patent_citation_haut_titre_INFO">
                                                                    {this.decorateWithHighlights(P_pc.TI.toLowerCase())}
                                                                </div>
                                                            </div>
                                                            <div className="D_dd_info_doc_bas_patent_citation_bas">
                                                                <div className="S_dd_info_doc_bas_patent_citation_haut_ass">{this.props.langue[32].LIB}</div>
                                                                <div className="S_dd_info_doc_bas_patent_citation_haut_ass_INFO">
                                                                    {PARSER(P_pc.PA.replace(/<br\/>/gi, '&nbsp;&bull;&nbsp;'))}
                                                                </div>
                                                            </div>
                                                            <hr className="HR_dd_info_doc_bas_patent_citation_ligne" />
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div id="D_dd_info_doc_haut_droite_etage_citing"
                                        style={(this.props.documentUn.data.CTN.length > 3 && !this.props.permalink) ? {} : { display: 'none' }}
                                    >
                                        <div id="D_dd_info_doc_haut_droite_etage_citing_label">
                                            {this.props.langue[26].LIB}
                                        </div>
                                        <div id="D_dd_info_doc_haut_droite_etage_citing_open"
                                            className={this.state.patent_citing_open ? 'div_close' : 'div_open'}
                                            onClick={() => { this.changeOpen('citing') }}></div>
                                        <div
                                            id="D_dd_info_doc_bas_patent_citation_encours"
                                            style={(this.props.documentUn.data.etatPatentCitation === Constante.etat.encours && this.props.permalink === false && this.state.patent_citing_open === true) ? {} : { display: 'none' }}
                                        >

                                        </div>
                                        <div
                                            id="D_dd_info_doc_bas_patent_citation"
                                            style={(this.props.documentUn.data.PatentCitation.length > 0 && this.props.permalink === false && this.state.patent_citing_open === true) ? {} : { display: 'none' }}
                                        >
                                            {
                                                this.props.documentUn.data.PatentCitation.map((P_pc, P_index) => {
                                                    if (P_pc.PA === null) {
                                                        P_pc.PA = '';
                                                    }
                                                    if (P_pc.EPRD === null) {
                                                        P_pc.EPRD = '';
                                                    }
                                                    if (P_pc.TI === null) {
                                                        P_pc.TI = '';
                                                    }
                                                    return (
                                                        <div key={'D_dd_info_doc_bas_patent_citation' + P_index}>
                                                            <div className="D_dd_info_doc_bas_patent_citation_haut">
                                                                <div className="D_dd_info_doc_bas_patent_citation_haut_PN">
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_PN">{this.props.langue[48].LIB}</div>
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_PN_INFO">
                                                                        {P_pc.REP.replace('_', ' ')}
                                                                    </div>
                                                                </div>
                                                                <div className="D_dd_info_doc_bas_patent_citation_haut_PD">
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_PD">{this.props.langue[49].LIB}</div>
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_PD_INFO">
                                                                        {P_pc.EPRD}
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={P_pc.CTN.length > 1 ? {} : { display: 'none' }}
                                                                    className="D_dd_info_doc_bas_patent_citation_haut_APP"
                                                                >
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_APP">{this.props.langue[50].LIB}</div>
                                                                    <div className="S_dd_info_doc_bas_patent_citation_haut_APP_INFO">
                                                                        {P_pc.CTN}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="D_dd_info_doc_bas_patent_citation_milieu">
                                                                <div className="S_dd_info_doc_bas_patent_citation_haut_titre">{this.props.langue[33].LIB}</div>
                                                                <div className="S_dd_info_doc_bas_patent_citation_haut_titre_INFO">
                                                                    {this.decorateWithHighlights(P_pc.TI.toLowerCase())}
                                                                </div>
                                                            </div>
                                                            <div className="D_dd_info_doc_bas_patent_citation_bas">
                                                                <div className="S_dd_info_doc_bas_patent_citation_haut_ass">{this.props.langue[32].LIB}</div>
                                                                <div className="S_dd_info_doc_bas_patent_citation_haut_ass_INFO">
                                                                    {P_pc.PA.replace(/<br\/>/gi, ' ,')}
                                                                </div>
                                                            </div>
                                                            <hr className="HR_dd_info_doc_bas_patent_citation_ligne" />
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FamilyMenu ref={this.familyMenuref} />
            </div >
        );
    }
}

DocumentInfo.defaultProps = {
    documentUn: {},
    affichage: {},
    PatentCitation: null,
    CitedBy: null,
    LegalEvent: null,
    changeSelectOuMode: () => null,
    PatentCitationLoad: () => null,
}

export default DocumentInfo;
