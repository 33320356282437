import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PARSER from 'html-react-parser';
import './SelectPerso.css';

class SelectPerso extends Component {

    constructor() {
        super()
        this.state = {
            SelectNB: 0,
            SelectNums: [],
            SelectVisible: false,
            SelectApplied: ''
        }
    }

    static propTypes = {
        id: PropTypes.string,
        SelectMode: PropTypes.number, // 0 -> simple     1 -> Multiple
        TexteApply: PropTypes.string,
        TextePlaceHolder: PropTypes.string,
        SelectValue: PropTypes.array,
        SelectValueName: PropTypes.string, // champs vide signif que les var sont en tableau de string sinon tableau d'object
        SelectDescName: PropTypes.string,
        SelectSelected: PropTypes.array, // champs utile si les var son sous tableau simple ou non contenu dans l'objet ayant les value
        SelectselectedName: PropTypes.string, // champs vide signifie que les var sont dans le tableau simplement sinon dans le tableau d'object
        select_valide: PropTypes.func
    }

    componentDidMount() {
        this.select_default_nums();
    }

    componentDidUpdate(prevprops) {
        if (prevprops.SelectValue !== this.props.SelectValue) {
            this.select_default_nums();
        }
    }

    select_default_nums = () => {
        let L_select_nums = [];
        let L_select_count = 0;
        let selectValues = '';
        const virgule = '&nbsp; &#8226; &nbsp;';
        for (let L_parcours_value = 0; L_parcours_value < this.props.SelectValue.length; L_parcours_value++) {
            if (this.props.SelectValue[L_parcours_value][this.props.SelectselectedName]) {
                L_select_nums.push(true);
                L_select_count++;
                selectValues += (selectValues.length > 0 ? virgule : "") + this.props.SelectValue[L_parcours_value][this.props.SelectValueName];
            }
            else {
                L_select_nums.push(false);
            }
        }
        this.setState({
            SelectNums: L_select_nums,
            SelectNB: L_select_count,
            SelectApplied: selectValues
        })
    }

    set_select_visible = () => {
        if (this.state.SelectVisible) {
            this.select_default_nums();
        }
        this.setState({
            SelectVisible: !this.state.SelectVisible
        },
            () => { setTimeout(this.verif_scroll(), 300) });
    }

    verif_scroll() {
        let L_elem = document.getElementById(this.props.id);
        if (L_elem !== null) {
            let L_rect = L_elem.getBoundingClientRect();
            let L_viewHeight = document.getElementById("D_droite_document").clientHeight;
            if (L_rect.top < 0 || L_rect.bottom - L_viewHeight >= 0) {
                L_elem.scrollIntoView(false);
            }
        }
    }
    select_hide = (e) => {
        const currentTarget = e.currentTarget;

        // Check the newly focused element in the next tick of the event loop
        setTimeout(() => {
            // Check if the new activeElement is a child of the original container
            if (!currentTarget.contains(document.activeElement)) {
                // You can invoke a callback or add custom logic here
                this.select_default_nums();
                this.setState({
                    SelectVisible: false
                })
            }
        }, 100);
    }

    select_value = (P_index) => {
        if (this.props.SelectMode === 1) {
            let L_select_nums = this.state.SelectNums;
            L_select_nums[P_index] = !L_select_nums[P_index];

            this.setState({
                SelectNums: L_select_nums
            });
        }
        else {
            let L_select_nums = this.state.SelectNums;
            if (L_select_nums[P_index]) {
                P_index=null;
            }
            L_select_nums.fill(false);
            if (P_index!==null) {
                L_select_nums[P_index] = true;
            }
            this.setState({
                SelectNums: L_select_nums,
                SelectVisible: false
            });
            this.props.select_valide(P_index);
        }
    }

    select_perso_selected = () => {
        let L_hover = '';
        if (this.state.SelectVisible) {
            L_hover = ' hover';
        }
        let L_first = true;
        if (this.state.SelectNB < 1) {
            return (
                <div
                    onClick={this.set_select_visible}
                    className={"D_select_perso_select placeholder" + L_hover}
                >
                    {this.props.TextePlaceHolder}
                </div>
            )
        }
        return (
            <div className={"D_select_perso_select" + L_hover}
                onClick={this.set_select_visible}
            >
                {/*this.state.SelectNums.map((P_value, P_index) => {
                    if (!P_value) {
                        return '';
                    }
                    let L_virgule = '&nbsp;&nbsp;&#8226;&nbsp;&nbsp;';
                    if (L_first) {
                        L_virgule = '';
                        L_first = false;
                    }
                    return PARSER(L_virgule + this.props.SelectValue[P_index][this.props.SelectValueName]);
                })*/
                    this.state.SelectApplied.length > 0 ? PARSER(this.state.SelectApplied) : ''
                }
            </div>
        )
    }

    select_perso_value = () => {
        if (!this.state.SelectVisible) {
            return (
                <div style={{ display: 'none' }}>

                </div>
            );
        }
        return (
            <div id={this.props.id} className="D_select_perso_select_value">
                <div className="D_select_perso_select_value_lst">
                    {this.props.SelectValue.map((P_value, P_index) => {
                        const value     = P_value && P_value[this.props.SelectValueName],
                            description = P_value && P_value[this.props.SelectDescName];
                        let L_select = '';
                        if (this.state.SelectNums[P_index] === true) {
                            L_select = ' selected'
                        }
                        return value && (
                            <div
                                key={"Select_Perso_" + P_index}
                                className={"D_select_perso_select_value_un" + L_select}
                                onClick={() => { this.select_value(P_index) }}
                            >
                                {value}{description?.length > 0 ? " (desc.: " + description + ")" : ""}
                            </div>
                        )
                    })
                    }
                </div>
                <div
                    className="D_select_perso_select_value_apply"
                    style={this.props.SelectMode === 1 ? {} : { display: 'none' }}
                    onClick={() => {
                        this.props.select_valide(this.state.SelectNums);
                        this.setState({
                            SelectVisible: false
                        });
                    }
                    }
                >
                    {this.props.TexteApply}
                </div>
            </div>
        );
    }

    render() {
        let L_mode_class = "select_perso_simple";
        if (this.props.SelectMode === 1) {
            L_mode_class = "select_perso_multiple";

        }
        return (
            <div
                tabIndex="1"
                className={"D_select_perso " + L_mode_class}
                onBlur={this.select_hide}
            >
                {this.select_perso_selected()}
                {this.select_perso_value()}
            </div>

        )
    }
}

export default SelectPerso;