import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Constante from '../Constante.js';
import { Treebeard } from 'react-treebeard';
import { Decorators } from './TreebeardDeco';
import TreebeardMenu from './Treebeardmenu';
import DialogProjet from './DialogProjet';
import AjaxGestion from '../AjaxGestion';
import './MesProjets.css';


class MesProjets extends Component {

  constructor() {
    super()
    this.dialogAdd = React.createRef();
    this.treebeardMenuRef = React.createRef();
    this.state = {
      etat: Constante.etat.encours,
      treeData: null,
      treenode: { name: '', id: 'a' },
      dialogType: 0,
      dialogHidden: '',
      attenteActive: false
    }
  }

  static propTypes = {
    ticket: PropTypes.string.isRequired,
    shard: PropTypes.string.isRequired,
    user: PropTypes.object,
    langue: PropTypes.array,
    userfield_load: PropTypes.func
  }


  componentDidMount() {
    this.treeloadbranche(0);
  }

  menuAction = (P_variables) => {
    switch (P_variables.type) {
      case Constante.dialogMode.add:
        this.nodeAddValider(P_variables);
        break;
      case Constante.dialogMode.rename:
        this.nodeRenameValider(P_variables);
        break;
      case Constante.dialogMode.delete:
        this.nodeDeleteValider();
        break;
      default:
        break;
    }
  }

  nodeDelete = (P_node) => {
    this.dialogAdd.current.dialogVisible(true, P_node.name);
    this.setState({
      treenode: P_node,
      dialogType: Constante.dialogMode.delete
    });
  }

  nodeDeleteValider = () => {
    this.setState({
      attenteActive: true
    })
    let L_type = this.state.treenode.type.toUpperCase();
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard,
      DocumentsSelect: ["*"],
      IdRecherche: '/' + this.state.treenode.id,
      emplacement: L_type,
    }
    if (L_type === Constante.EmplacementRecherche.FOLDER) {
      this.nodeDeleteValider2(L_variable);
    }
    else {
      AjaxGestion(Constante.AjaxChoix.listDelete, L_variable).then((data) => {
        if (data === null || typeof data === 'undefined') {
          console.log('Erreur data !');
          return;
        }
        if (typeof data.erreur !== 'undefined') {
          console.log('Erreur code :' + data.erreur + ' info :' + data.log);
          return;
        }
        else {
          this.nodeDeleteValider2(L_variable);
        }
      });
    }
  }

  nodeDeleteValider2 = (P_variable) => {
    AjaxGestion(Constante.AjaxChoix.ProjetsDelete, P_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
      }
      else if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
      }
      else if (data.message.indexOf('deleted') > 0) {
        let L_data = { children: this.state.treeData }
        this.nodeDeleteRecursif(L_data.children, P_variable.IdRecherche.substring(1))
        this.forceUpdate();
      }
      this.setState({
        attenteActive: false
      })
    });
  }

  nodeDeleteRecursif = (P_data, P_id) => {
    if (P_data.children === null || typeof P_data.children === 'undefined') {
      return false;
    }
    for (let L_parcours_data = 0; L_parcours_data < P_data.children.length; L_parcours_data++) {
      if (P_data.children[L_parcours_data].id === P_id) {
        P_data.children.splice(L_parcours_data, 1);
        return true;
      }
      if (this.nodeDeleteRecursif(P_data.children[L_parcours_data], P_id)) {
        return true;
      }
    }
    return false;
  }

  nodeRename = (P_node) => {
    this.dialogAdd.current.dialogVisible(true, P_node.name);
    this.setState({
      treenode: P_node,
      dialogType: Constante.dialogMode.rename
    })
  }

  nodeRenameValider = (P_variables) => {
    this.setState({
      attenteActive: true
    })
    P_variables.id = this.state.treenode.id;
    P_variables.type_folder_list = this.state.treenode.type;
    P_variables.ticket = this.props.ticket;
    P_variables.shard = this.props.shard;
    P_variables.parentID = this.state.treenode.parent;
    AjaxGestion(Constante.AjaxChoix.ProjetsRename, P_variables).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
      }
      else if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
      }
      else if (data.data != null) {
        let L_data = { children: this.state.treeData }
        this.nodeRenameRecursif(L_data.children, P_variables.id, P_variables.name);
        this.forceUpdate();
      }
      this.setState({
        attenteActive: false
      })
    });
  }

  nodeRenameRecursif = (P_data, P_id, P_name) => {
    if (P_data.children === null || typeof P_data.children === 'undefined') {
      return false;
    }
    for (let L_parcours_data = 0; L_parcours_data < P_data.children.length; L_parcours_data++) {
      if (P_data.children[L_parcours_data].id === P_id) {
        P_data.children[L_parcours_data].name = P_name;
        return true;
      }
      if (this.nodeRenameRecursif(P_data.children[L_parcours_data], P_id, P_name)) {
        return true;
      }
    }
    return false;
  }

  nodeAdd = (P_node, P_type_folder_list) => {
    this.dialogAdd.current.dialogVisible(true, '');
    this.setState({
      treenode: P_node,
      dialogType: Constante.dialogMode.add,
      dialogHidden: P_type_folder_list
    })
  }

  nodeAddValider(P_variables) {
    this.setState({
      attenteActive: true
    })
    P_variables.parentID = this.state.treenode.id;
    if (this.state.dialogHidden === Constante.EmplacementRecherche.RACINE) {
      P_variables.type_folder_list = Constante.EmplacementRecherche.FOLDER;
    }
    else {
      P_variables.type_folder_list = this.state.dialogHidden;
    }
    P_variables.ticket = this.props.ticket;
    P_variables.shard = this.props.shard;
    AjaxGestion(Constante.AjaxChoix.ProjetsAdd, P_variables).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
      }
      else if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
      }
      else if (data.data != null) {
        data.data.children = [];
        this.state.treenode.children.splice(0, 0, data.data);
        if (this.state.treenode.type === Constante.EmplacementRecherche.FOLDER && !this.state.treenode.toggled) {
          this.onToggle(this.state.treenode, true);
        }
        else {
          this.forceUpdate();
        }
      }
      this.setState({
        attenteActive: false
      })
    });
  }

  openMenu = (e) => {
    this.treebeardMenuRef.current.openMenu(e);
  }

  activeNode = (P_node) => {
    this.setState({
      treenode: P_node
    });
  }

  voirListe = (P_node) => {
    if (P_node.type === Constante.EmplacementRecherche.LIST || P_node.type === Constante.EmplacementRecherche.WORKFILE) {
      this.props.voirListe(P_node.type, '/' + P_node.id, P_node.name);
    }
  }


  onToggle = (node, toggled) => {
    if (node.children) {
      if (node.children.length > 0 || node.type === Constante.EmplacementRecherche.FOLDER) {
        node.toggled = toggled;
      }
    }
    this.forceUpdate();
  }

  treeloadbranche = (P_id, P_node) => {
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard,
      type: "ALL",
      id: P_id
    }
    AjaxGestion(Constante.AjaxChoix.ProjetsGet, L_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
        return;
      }
      if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
        return;
      }
      let L_racine = {
        id: '',
        name: 'Projects',
        numberOfDocument: 0,
        parent: '',
        shared: false,
        type: 'RACINE',
        toggled: true,
        children: data
      }
      this.setState({
        treeData: L_racine,
        etat: Constante.etat.charge
      },this.props.userfield_load());
    });
  }

  render() {
    if (this.state.etat === Constante.etat.encours) {
      return (
        <div id="D_centre_projet" className="loadgif">
        </div>
      );
    }
    let L_decorator = Decorators;
    L_decorator.nodeDelete = this.nodeDelete;
    L_decorator.nodeRename = this.nodeRename;
    L_decorator.nodeAdd = this.nodeAdd;
    L_decorator.voirListe = this.voirListe;
    L_decorator.user = this.props.user;
    L_decorator.activeNode = this.activeNode;
    L_decorator.langue = this.props.langue;
    L_decorator.openMenu = this.openMenu;
    if (this.state.treenode !== null && typeof this.state.treenode !== 'undefined') {
      L_decorator.nodeId = this.state.treenode.id;
    }
    else {
      L_decorator.nodeId = ' inconnu';
    }
    return (
      <div id="D_centre_projet" className={(this.state.attenteActive) ? 'TreeInactif' : ''}>
        <DialogProjet
          ref={this.dialogAdd}
          name={this.state.treenode.name}
          type={this.state.dialogType}
          nodeType={this.state.treenode.type}
          addType={this.state.dialogHidden}
          menuAction={this.menuAction}
          langue={this.props.langue}
        />
        <div id="D_centre_projet_visuel">
          <div id="D_centre_projet_haut">
            <div
              id="D_centre_projet_haut_add_folder"
              style={(this.state.treenode.type === Constante.EmplacementRecherche.FOLDER ||
                this.state.treenode.type === Constante.EmplacementRecherche.RACINE) ? {} : { pointerEvents: 'none', color: 'grey', borderColor: 'grey' }}
              onClick={() => this.nodeAdd(this.state.treenode, this.state.treenode.type)}
            >
              {this.props.langue[17].LIB}
            </div>
            <div
              id="D_centre_projet_haut_add_list"
              style={this.state.treenode.type === Constante.EmplacementRecherche.FOLDER ? {} : { pointerEvents: 'none', color: 'grey', borderColor: 'grey' }}
              onClick={() => this.nodeAdd(this.state.treenode, Constante.EmplacementRecherche.LIST)}
            >
              {this.props.langue[18].LIB}
            </div>
          </div>
          <div id="D_centre_projet_bas">
            <Treebeard
              animations={false}
              data={this.state.treeData}
              decorators={L_decorator}
              onToggle={this.onToggle}
            />
            <TreebeardMenu
              ref={this.treebeardMenuRef}
              langue={this.props.langue}
              user={this.props.user}
              node={this.state.treenode}
              idmenu='projet'
              voirListe={this.voirListe}
              nodeAdd={this.nodeAdd}
              nodeRename={this.nodeRename}
              nodeDelete={this.nodeDelete} />
          </div>
        </div>
        <div id="D_centre_projet_attente" style={(this.state.attenteActive) ? {} : { display: 'none' }}></div>
      </div>
    );
  }
}

export default MesProjets;