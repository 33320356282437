import React, { Component } from 'react';
import Menu, { SubMenu, Divider, Item as MenuItem } from 'rc-menu';
import '../../rc_menu_css/index.css';
import PropTypes from 'prop-types';
import './avanceeparam.css';


class avanceeparam extends Component {

    constructor() {
        super()
        this.state = {

        }
    }

    static propTypes = {
        langue: PropTypes.array,
        titre: PropTypes.string,
        code: PropTypes.string,
        idfield: PropTypes.string,
        posField: PropTypes.number,
        menuText: PropTypes.string,
        menuId: PropTypes.string,
        element: PropTypes.object,
        styledisplay: PropTypes.string,
        selectElement: PropTypes.func

    };

    ItemChoix = () => {
        if (this.props.code === "checkbox") {
            return (Object.keys(this.props.element).map((P_key, P_index) => {
                let L_inactif = '';
                if (this.props.element[P_key].actif === false) {
                    L_inactif = ' inactif';
                }
                return (
                    <MenuItem
                        key={"avancee-" + this.props.idfield + '_' + P_index}
                        className={"SM_avancee_param_menuitem" + L_inactif}
                        style={this.props.element[P_key].visible === false ? { display: "none" } : {}}
                        onClick={(event) => this.props.selectElement(event, P_key, this.props.idfield, this.props.posField)}
                    >
                        <label>
                            <input
                                type="checkbox"
                                value=""
                                checked={this.props.element[P_key].check}
                                onChange={() => { }}
                            />
                            <span></span>
                        </label>
                        {this.props.element[P_key].lib}
                    </MenuItem>
                );
            })
            );
        }
        else {//radio input
            return (Object.keys(this.props.element).map((P_key, P_index) => {
                return (
                    <MenuItem
                        key={"avancee-" + this.props.idfield + '_' + P_index}
                        className="SM_avancee_param_menuitem_radio"
                        style={this.props.element[P_key].visible === false ? { display: "none" } : {}}
                        onClick={(event) => this.props.selectElement(event, P_key, this.props.idfield, this.props.posField)} >
                        <label>
                            <input
                                type="radio"
                                value=""
                                name={"avance_radio"}
                                checked={this.props.element[P_key].check}
                                onChange={() => { }}
                            />
                            <span></span>
                        </label>
                        {this.props.element[P_key].lib}
                    </MenuItem>
                );
            })
            );
        }
    }

    render() {
        return (
            <Menu mode="horizontal" triggerSubMenuAction="click" selectable={false}>
                <SubMenu title={<div id={this.props.menuId}>{this.props.menuText}</div>} key="1" className="SM_avancee_param">
                    <MenuItem key={"avancee-" + this.props.idfield + '_t'} className="SM_avancee_param_menuitem_text" >
                        {this.props.titre}
                    </MenuItem>
                    <Divider />
                    {
                        this.ItemChoix()
                    }
                </SubMenu>
            </Menu>
        );
    }
}

avanceeparam.defaultProps = {
    langue: null
}

export default avanceeparam;