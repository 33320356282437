//VERSION 3.2.1
import React, { Component } from 'react';
import _ from 'lodash';
import { KeepAlive } from 'react-keep-alive';
import { fetch as fetchPolyfill } from 'whatwg-fetch'
import { generateProfile, generateProfileForWorkfile } from './utils/highlights';
import { initialize as initializeMember } from './utils/auth';
import Recherche from './rechercheavancee/Recherche';
import Entete from './accueil/Entete';
import MesProjets from './mes_projets/MesProjets';
import MesAlertes from './mes_alertes/MesAlertes';
import AjaxGestion from './AjaxGestion';
import Constante from './Constante';
import RechercheSimple from './accueil/recherchesimple';

import './Express.css';

const WORD_BOUNDARY_REGEXP = new RegExp("[\u0021-\u002C\u003A-\u003F\u005B\u005D\u007B-\u007F\u00A0\u00AE\u00B7\u1680\u2000-\u206F\u3000-\u303F\uFF01-\uFF0F\uFF1A-\uFF20\uFF3B-\uFF40\uFF5B-\uFF65\n]| and | or ", 'gi');

class Express extends Component {

    constructor() {

        super();

        _.bindAll(this, 'submitSearch', 'updateHighlightsProfile', 'setHighlightsProfile');

        this.Recherche = React.createRef();
        this.entete = React.createRef();
        this.state = {
            clientLiens: [{
                nom: 'safran',
                url_academy: 'https://safran-university.360learning.com/program/5ccc423c62127b0c016bda3a',
                name_academy: 'MOOC PI Safran'
            }],
            ticket: '',
            tokenNPL: '',
            shard: '',
            highlightsProfile: {
                enabled: true,
                hls: []
            },
            permalink: false,
            xpn_permalink: '',
            base_permalink: 'fampat',
            RequeteInfosListe: [],
            RequeteInfos: this.getNewSearchObject(Constante.EmplacementRecherche.FAMPAT, false),
            //RequeteInfosActif: null,
            langue: null,
            RechercheInfo: {
                Choix: '1',
                EmplacementRecherche1: Constante.EmplacementRecherche.FAMPAT,
                NomRecherche: '',
                IdRecherche: '',
                EmplacementRecherche2: Constante.EmplacementRecherche.NPL,
                EmplacementHistorique: 0,
                EmplacementHistoriquePrecedent: -1,
                EmplacementHistoriquePrecedentType: Constante.EmplacementRecherche.FAMPAT
            },
            affichagePage: Constante.affichagePage.recherchesimple,
            affichageMode: Constante.affichageMode.rechercheavancee,
            watchingListId: null,
            userField: null
        }
    }

    beforeunloadfct = (event) => {
        const { NODE_ENV } = process.env;

        // Disable the unload confirmation when it's dev env.
        if (NODE_ENV === 'development') {
            return;
        }

        let confirmationMessage = 'Attention, you are going to lose your search history.';
        (event || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }

    active_beforeunloadfct = (P_active) => {
        if (P_active) {
            window.addEventListener("beforeunload", this.beforeunloadfct);
        }
        else {
            window.removeEventListener("beforeunload", this.beforeunloadfct, false);
        }
    }


    /**
     * When component has been fully loaded.
     *
     * @return void
     */
    componentDidMount() {

        //@TODO: Antipattern, should be managed by the render function.
        this.changeClassBody(Constante.EmplacementRecherche.FAMPAT, "");

        // Initialize the member gathering process
        initializeMember(memberStateParams => {

            // Set the current state with member information
            this.setState({
                ...memberStateParams
            }, () => {
                this.setState({
                    RequeteInfos: this.getNewSearchObject(this.state.RechercheInfo.EmplacementRecherche1, false)
                })
            });
        });
    }


    changeClassBody = (P_class, P_id) => {
        if (P_id.replace('/', '') === this.state.watchingListId) {
            P_class = Constante.EmplacementRecherche.ALERT;
        }
        if (P_class !== Constante.EmplacementRecherche.NPL) {

            if (P_class === Constante.EmplacementRecherche.PRIOR_ART) {
                document.getElementById('root').className = Constante.EmplacementRecherche.SIMILARITY;
            }
            else {
                document.getElementById('root').className = P_class;
            }
        }
    }

    /*
    *
    *function call when an alert is deleted
    *
    */
    alertDelete = () => {
        if (this.Recherche.current !== null) {
            this.Recherche.current.alertGetCount();
        }
    }

    changePage = (P_page, P_mode) => {
        if (P_mode === null || typeof P_mode === 'undefined') {
            P_mode = Constante.affichageMode.rechercheavancee;
        }
        if (P_page !== Constante.affichagePage.Recherche) {
            this.entete.current.requete_change('');
        }
        if (P_page === Constante.affichagePage.alertes) {
            this.changeClassBody(Constante.EmplacementRecherche.ALERT, '');
        }
        else if (P_page === Constante.affichagePage.projets) {
            this.changeClassBody(Constante.EmplacementRecherche.LIST, '');
        }
        if (P_page === Constante.affichagePage.Recherche && this.Recherche.current !== null) {
            this.setState({
                affichagePage: P_page,
                affichageMode: P_mode
            }, () => this.Recherche.current.changeSelectOuMode(Constante.AffichageSelect.document, P_mode));
        }
        else {
            this.setState({
                affichagePage: P_page,
                affichageMode: P_mode
            });
        }
    }

    choixPage = () => {
        const { highlightsProfile } = this.state;

        switch (this.state.affichagePage) {
            case Constante.affichagePage.projets:
                return (
                    <MesProjets
                        ticket={this.state.ticket}
                        shard={this.state.shard}
                        user={this.state.user}
                        voirListe={(P_emplacement_recherche, P_id, P_nom) => this.voirListe(P_emplacement_recherche, P_id, P_nom)}
                        alertDelete={this.alertDelete}
                        langue={this.state.langue.projets}
                        userfield_load={this.userfield_load}
                    />
                );
            case Constante.affichagePage.alertes:
                return (
                    <MesAlertes
                        ticket={this.state.ticket}
                        shard={this.state.shard}
                        user={this.state.user}
                        voirListe={(P_emplacement_recherche, P_id, P_nom) => this.voirListe(P_emplacement_recherche, P_id, P_nom)}
                        langue={this.state.langue.alertes}
                        setwatchingListId={this.setwatchingListId}
                    />
                );
            case Constante.affichagePage.Recherche:
                return (
                    <KeepAlive name="Recherche">
                        <Recherche
                            ref={this.Recherche}
                            langue={this.state.langue}
                            langueEntete={this.state.langue.entete}
                            langueRechercheavancee={this.state.langue.rechercheavancee || []}
                            affichageMode={this.state.affichageMode}
                            updateHighlightsProfile={this.updateHighlightsProfile}
                            highlightsProfile={highlightsProfile}
                            ticket={this.state.ticket}
                            tokenNPL={this.state.tokenNPL}
                            shard={this.state.shard}
                            user={this.state.user}
                            RequeteInfosListe={this.state.RequeteInfosListe}
                            RequeteInfos={this.state.RequeteInfos}
                            RequeteInfosActif={this.state.RequeteInfos}
                            RechercheInfo={this.state.RechercheInfo}
                            permalink={this.state.permalink}
                            xpn={this.state.xpn_permalink}
                            base={this.state.base_permalink}
                            watchingListId={this.state.watchingListId}
                            langueProjets={this.state.langue.projets}
                            affichage_page_mode={this.state.affichageMode}
                            clearRechercheEmplacement={this.clearRechercheEmplacement}
                            changeOnglet={this.changePage}
                            changement_requete_avancee={this.submitAdvancedSearch}
                            setwatchingListId={this.setwatchingListId}
                            RequeteInfosSave={this.RequeteInfosSave}
                            requeteHistoryAction={this.requeteHistoryAction}
                            set_emplacement_recherche={this.set_emplacement_recherche}
                            active_beforeunloadfct={this.active_beforeunloadfct}
                        />
                    </KeepAlive>
                );
            default:
                return (
                    <RechercheSimple
                        langue={this.state.langue.entete}
                        useremail={this.state.user.email}
                        recherche_click={this.submitSearch}
                        requete_change={this.requete_change}
                        changePage={this.changePage}
                        goSemantique={this.goSemantique}
                        goAdvanceSearch={this.goAdvanceSearch}
                        clientLiens={this.state.clientLiens}
                    />
                );
        }
    }

    clearRechercheEmplacement = (P_mode) => {
        if (this.state.user.acces.fampat) {
            this.setState({
                RequeteInfos: this.getNewSearchObject(Constante.EmplacementRecherche.FAMPAT, false),
                highlightsProfile: {},
                highlightsProfileFromWorkfile: {},
                RechercheInfo: {
                    Choix: '1',
                    EmplacementRecherche1: Constante.EmplacementRecherche.FAMPAT,
                    NomRecherche: '',
                    IdRecherche: '',
                    EmplacementRecherche2: Constante.EmplacementRecherche.NPL,
                    EmplacementHistorique: this.state.RechercheInfo.EmplacementHistorique,
                    EmplacementHistoriquePrecedent: this.state.RechercheInfo.EmplacementHistoriquePrecedent,
                    EmplacementHistoriquePrecedentType: this.state.RechercheInfo.EmplacementHistoriquePrecedentType
                }
            }, () => {
                this.changeClassBody(Constante.EmplacementRecherche.FAMPAT, '');
                if (this.Recherche.current !== null) {
                    this.Recherche.current.reset_documents_all(null);
                    this.Recherche.current.reset_document_un();
                }
                this.changePage(Constante.affichagePage.Recherche, P_mode)
            });
        }
        else {
            if (this.state.user.acces.liste || this.state.user.acces.workfile) {
                this.changePage(Constante.affichagePage.projets, null);
                this.changeClassBody(Constante.EmplacementRecherche.LIST, '');
            }
            else if (this.state.user.acces.alerte) {
                this.changeClassBody(Constante.EmplacementRecherche.ALERT, '');
                this.changePage(Constante.affichagePage.alertes, null);
            }
        }
    }


    Deconnexion = () => {
        this.active_beforeunloadfct(false);

        // Unlog user.
        localStorage.removeItem('OE');

        let L_href = window.location.href.split('/?');
        let L_url = "https://" + process.env.REACT_APP_IORBIT_HOST + "/logout?ticket=" + this.state.ticket +
            "&delegateFor=" + encodeURIComponent(L_href[0]);
        window.location = L_url;
    }

    getSearch = (P_choix, P_doc_info, P_reset_select, P_new_search) => {
        if (this.Recherche.current != null && this.state.affichagePage === Constante.affichagePage.Recherche) {
            setTimeout(() => {
                this.Recherche.current.getSearch(P_choix, P_doc_info, P_reset_select, null, P_new_search);
            }, 200);
        }
        else {
            this.changePage(Constante.affichagePage.Recherche, null);
            setTimeout(() => {
                this.getSearch(P_choix, P_doc_info, P_reset_select, null, P_new_search);
            }, 200);
        }
    }

    set_emplacement_recherche = (P_choix, P_EmplacementRecherche1, P_nom_emplacement_recherche_1, P_id_emplacement_recherche1, P_EmplacementRecherche2, P_relance_search) => {
        if (P_choix !== '2') {
            P_choix = '1';
        }
        if (P_EmplacementRecherche1 === null) {
            P_EmplacementRecherche1 = this.state.RechercheInfo.EmplacementRecherche1;
        }
        if (P_nom_emplacement_recherche_1 === null) {
            P_nom_emplacement_recherche_1 = this.state.RechercheInfo.NomRecherche;
        }
        if (P_id_emplacement_recherche1 === null) {
            P_id_emplacement_recherche1 = this.state.RechercheInfo.IdRecherche;
        }
        if (P_EmplacementRecherche2 === null) {
            P_EmplacementRecherche2 = this.state.RechercheInfo.EmplacementRecherche2;
        }
        let L_requete_infos = JSON.parse(JSON.stringify(this.state.RequeteInfos));
        //L_requete_infos.requete_avancee = JSON.parse(JSON.stringify(L_requete_infos.requete_avancee_valide));
        this.setState({
            RequeteInfos: L_requete_infos,
            RechercheInfo: {
                Choix: P_choix.toString(),
                EmplacementRecherche1: P_EmplacementRecherche1,
                EmplacementRecherche2: P_EmplacementRecherche2,
                NomRecherche: P_nom_emplacement_recherche_1,
                IdRecherche: P_id_emplacement_recherche1,
                EmplacementHistorique: this.state.RechercheInfo.EmplacementHistorique,
                EmplacementHistoriquePrecedent: this.state.RechercheInfo.EmplacementHistoriquePrecedent,
                EmplacementHistoriquePrecedentType: this.state.RechercheInfo.EmplacementHistoriquePrecedentType
            }
        }, () => {
            if (P_relance_search) {
                this.getSearch(P_choix, null, true, false);
            }
            else {
                if (this.Recherche !== null) {
                    setTimeout(() => { this.Recherche.current.voirDoc(null, null, null) }, 200);
                }
            }
        });
    }




    goSemantique = () => {
        const recherche = Object.assign({}, this.state.RequeteInfos);

        recherche.choixRecherche = 2;

        this.setState({
            RequeteInfos: recherche
        });

        this.changePage(Constante.affichagePage.Recherche);
    }


    goAdvanceSearch = () => {
        let L_recherche = Object.assign({}, this.state.RequeteInfos);
        L_recherche.choixRecherche = 1;
        this.setState({
            RequeteInfos: L_recherche
        });
        this.changePage(Constante.affichagePage.Recherche, Constante.affichageMode.Recherche);
    }


    requete_change = P_val => {
        P_val = P_val.replace('*', '+');
        let L_requete_infos = Object.assign({}, this.state.RequeteInfos);
        L_requete_infos.requete = P_val;
        this.setState({
        });
    }


    requeteHistoryAction = (P_code, P_index, P_activeOngletBrevet) => {
        if (P_index >= this.state.RequeteInfosListe.length) {
            return;
        }
        let L_requete_infos_history = JSON.parse(JSON.stringify(this.state.RequeteInfosListe[P_index]));
        L_requete_infos_history.RequeteInfos.requete_avancee.RESULTAT_NB = 0;
        L_requete_infos_history.RequeteInfos.ChangementDetecte = true;
        if (P_activeOngletBrevet) {
            L_requete_infos_history.RechercheInfo.Choix = "1";
        }
        if (P_code === 1) {
            let L_code_requete = Constante.RequeteAvancee.valider;
            if (L_requete_infos_history.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SEMANTIQUE) {
                L_code_requete = Constante.RequeteAvancee.validerSemantique;
            }
            this.changeClassBody(L_requete_infos_history.RechercheInfo.EmplacementRecherche1, L_requete_infos_history.RechercheInfo.IdRecherche);
            this.changePage(Constante.affichagePage.Recherche, Constante.affichageMode.rechercheavancee);
            this.setState({
                RequeteInfos: L_requete_infos_history.RequeteInfos,
                RechercheInfo: L_requete_infos_history.RechercheInfo,
            }, () => this.submitAdvancedSearch(L_code_requete, { REQUETE: this.state.RequeteInfos.requete_avancee, QP_MODE: null }));
        }
        else {
            this.changeClassBody(L_requete_infos_history.RechercheInfo.EmplacementRecherche1, L_requete_infos_history.RechercheInfo.IdRecherche);
            this.setState({
                RequeteInfos: L_requete_infos_history.RequeteInfos,
                RechercheInfo: L_requete_infos_history.RechercheInfo,
            });
        }
    }

    requeteInfosListeSet = P_id => {
        if (P_id !== null) {
            this.setState({
                RequeteInfos: Object.assign({}, this.state.RequeteInfosListe[P_id]).RequeteInfos,
                RechercheInfo: Object.assign({}, this.state.RequeteInfosListe[P_id]).RechercheInfo
            }, () => {
                this.changeClassBody(Constante.EmplacementRecherche.LIST, this.state.RequeteInfosListe[P_id].RechercheInfo.IdRecherche);
            });
        }
    }


    RequeteInfosSave = (P_nb_result, P_requete_termes, P_isExecutedQuery, P_save) => {
        let L_requete_infos = JSON.parse(JSON.stringify(this.state.RequeteInfos));
        //let L_requete_infos_actif = JSON.parse(JSON.stringify(this.state.RequeteInfosActif));
        let L_requete_reafecte = ""; // permet de reafecter la requete dans la sauvegarde pour la rejouer
        if (P_isExecutedQuery) {
            L_requete_infos.executedQuery = P_requete_termes;
        }
        if (L_requete_infos.is_recherche_avancee === false && P_isExecutedQuery) {
            if (L_requete_infos.requete_avancee.SMART_SEARCH === "1") {
                L_requete_infos.requete_avancee.SMART_SEARCH = P_requete_termes;
                L_requete_infos.requete_npl.query = P_requete_termes;
            }
            if (this.state.RechercheInfo['EmplacementRecherche' + this.state.RechercheInfo.Choix] === Constante.EmplacementRecherche.SEMANTIQUE) {
                L_requete_infos.requete_avancee.SEMANTIQUE = L_requete_infos.requete;
                L_requete_infos.requete_avancee.TERMES_AND.LIST_ELEM = [['']];
            }
            else if (L_requete_infos.requete.length > 0) {
                L_requete_infos.requete_avancee.TERMES_AND.LIST_ELEM = [[L_requete_infos.requete, '']];
            }
            else {
                L_requete_infos.requete_avancee.TERMES_AND.LIST_ELEM = [['']];
            }
            L_requete_reafecte = L_requete_infos.requete;
            if (this.entete.current !== null) {
                this.entete.current.requete_change('');
            }
            if (this.Recherche.current != null) {
                this.Recherche.current.NouvelleRecherche(L_requete_infos.requete_avancee);
            }
        }

        let L_requete_infos_liste = this.state.RequeteInfosListe;
        let L_RechercheInfo = JSON.parse(JSON.stringify(this.state.RechercheInfo));
        L_requete_infos.requete_avancee_valide = JSON.parse(JSON.stringify(L_requete_infos.requete_avancee));
        if (P_save !== false) {
            L_RechercheInfo.EmplacementHistorique = L_requete_infos_liste.length + 1;
            L_requete_infos_liste.unshift({ RequeteInfos: JSON.parse(JSON.stringify(L_requete_infos)), RechercheInfo: JSON.parse(JSON.stringify(L_RechercheInfo)) });
            if (L_requete_infos_liste.length > 0) {
                let L_date = new Date(Date.now());
                L_requete_infos_liste[0].RequeteInfos.requete = L_requete_reafecte;
                L_requete_infos_liste[0].RequeteInfos.requete_avancee.RESULTAT_NB = P_nb_result;
                L_requete_infos_liste[0].RequeteInfos.requete_avancee.DATE = L_date.getHours() + ':' + L_date.getMinutes();
            }
        }
        this.setState({
            RequeteInfosListe: L_requete_infos_liste,
            RequeteInfos: L_requete_infos,
            //RequeteInfosActif: L_requete_infos_actif,
            RechercheInfo: L_RechercheInfo
        });
    }

    retourRechercheFampat = () => {
        this.changeClassBody(Constante.EmplacementRecherche.FAMPAT, '');
        if (this.state.RechercheInfo.EmplacementRecherche1 !== Constante.EmplacementRecherche.FAMPAT) {
            this.clearRechercheEmplacement(null);
        }
        else {
            this.changePage(Constante.affichagePage.Recherche);
        }
    }

    setwatchingListId = (P_watchinglist_id) => {
        this.setState({
            watchingListId: P_watchinglist_id
        });
    }

    userfield_load = () => {
        if (this.state.userField !== null) {
            return;
        }
        let L_var = {
            ticket: this.state.ticket,
            shard: this.state.shard
        };
        AjaxGestion(Constante.AjaxChoix.userFieldGet, L_var).then((data) => {
            if (typeof data === 'undefined') {
                return;
            }
            if (typeof data.data === 'undefined') {
                return;
            }
        });
    }


    voirListe = (searchMode, id, P_nom) => {

        if (this.Recherche.current != null) {
            this.Recherche.current.clearRechercheEmplacement(null);
        }

        // Empty search mode: reset?
        if (searchMode == '') {
            this.setState({
                RechercheInfo: {
                    Choix: '1',
                    EmplacementRecherche1: this.state.RechercheInfo.EmplacementRecherche1,
                    NomRecherche: P_nom,
                    IdRecherche: id,
                    EmplacementRecherche2: Constante.EmplacementRecherche.NPL,
                    EmplacementHistoriquePrecedent: this.state.RequeteInfosListe.length,
                    EmplacementHistoriquePrecedentType: this.state.RechercheInfo.EmplacementRecherche1
                }
            }, () => {
                this.getSearch(null, null, false, true);
            });
            return;
        }

        // Set the request
        this.setState({
            RequeteInfos: this.getNewSearchObject(searchMode, false),
            //RequeteInfosActif: this.getNewSearchObject(P_emplacement_recherche, false),
            RechercheInfo: {
                Choix: '1',
                EmplacementRecherche1: searchMode,
                NomRecherche: P_nom,
                IdRecherche: id,
                EmplacementRecherche2: Constante.EmplacementRecherche.NPL,
                EmplacementHistorique: this.state.RechercheInfo.EmplacementHistorique,
                EmplacementHistoriquePrecedent: this.state.RechercheInfo.EmplacementHistorique,
                EmplacementHistoriquePrecedentType: this.state.RechercheInfo.EmplacementRecherche1
            }
        }, () => {
            this.changeClassBody(searchMode, id);
            this.getSearch(null, null, false, true);
        });

        // Special case for workfiles
        if (searchMode === 'WORKFILE' || searchMode === 'LIST') {
            this.setHighlightsProfileForWorkfiles(id);
        }
    }

    /**
     * Create a fresh request object
     *
     * @param {string} searchTarget                 Where do we want to look for data?
     * @param {boolean} preserveNplCompliantsParams We have to preserve NPL compliant parameters for the next search.
     *
     * @return {object}
     */
    getNewSearchObject = (searchTarget, preserveNplCompliantsParams) => {
        const advancedSearchObject = this.getNewAdvancedSearchObject(searchTarget, preserveNplCompliantsParams);

        return {
            requete: '',
            requete_avancee: advancedSearchObject,
            requete_avancee_valide: advancedSearchObject,
            requete_highlight: [],
            executedQuery: '',
            requete_npl: { query: '' },
            ChangementDetecte: false,
            changementCancel: false,
            choixRecherche: 1, // mode simple ou mode semantique
            is_recherche_avancee: false
        };
    }



    /**
     * Create an advanced search fresh request object
     *
     * @param {string} searchTarget                    Where do we want to look for data?
     * @param {boolean} preserveNplCompliantsParams We have to preserve NPL compliant parameters for the next search.
     *
     * @return {object}
     */
    getNewAdvancedSearchObject = (searchTarget, preserveNplCompliantsParams) => {
        const L_null = this.state === null || typeof this.state === 'undefined' || this.state.langue === null;

        if (L_null) {
            return {
                SCOPE: "",
                RESULTAT_NB: 0,
                DATE: Date.now(),
                SMART_SEARCH: "",
                SEMANTIQUE: "",
                SIMILARITE: "",
                NPLConcept: "",
                NPL_OTHER_FIELDS: false,
                PATENT_OTHER_FIELDS: false,
                FIELDS: [{
                    disabled: false,
                    labelVisible: false,
                    label: "",
                    options: [
                        { visible: true, actif: true, check: false, label: '', value: Constante.RequeteAvanceeCode.DATES }
                    ]
                },
                {
                    disabled: false,
                    labelVisible: true,
                    label: "PATENT SPECIFIC",
                    options: [
                        { visible: true, actif: true, check: false, label: '', value: Constante.RequeteAvanceeCode.ASSIGNEE },
                        { visible: true, actif: true, check: false, label: '', value: Constante.RequeteAvanceeCode.INVENTOR },
                        { visible: true, actif: true, check: false, label: '', value: Constante.RequeteAvanceeCode.CLASSIFICATION },
                        { visible: true, actif: true, check: false, label: '', value: Constante.RequeteAvanceeCode.PN },
                        { visible: true, actif: true, check: false, label: '', value: Constante.RequeteAvanceeCode.PUBLICATION_COUNTRY },
                        { visible: true, actif: true, check: false, label: '', value: Constante.RequeteAvanceeCode.LEGAL }
                    ]
                },
                {
                    disabled: true,
                    labelVisible: true,
                    label: "NON-PATENT LITERATURE SPECIFIC",
                    options: [
                        { visible: true, actif: true, check: false, label: '', value: Constante.RequeteAvanceeCode.TYPENPL },
                        { visible: true, actif: true, check: false, label: '', value: Constante.RequeteAvanceeCode.RDNUMBER }
                    ]
                }
                ],
                TERMES_AND: {
                    CODE: Constante.RequeteAvanceeCode.TERMES_AND,
                    SEARCH_IN: {
                        elem1: { check: true, lib: '', code: 'TI' },
                        elem2: { check: true, lib: '', code: 'AB' },
                        elem3: { check: true, lib: '', code: 'CLMS' },
                        elem4: { check: false, lib: '', code: 'DESC/ODES' }
                    },
                    LIST_ELEM: [['']]
                },
                TERMES_NOT: {
                    CODE: Constante.RequeteAvanceeCode.TERMES_NOT,
                    SEARCH_IN: {
                        elem1: { check: true, lib: '', code: 'TI' },
                        elem2: { check: true, lib: '', code: 'AB' },
                        elem3: { check: true, lib: '', code: 'CLMS' },
                        elem4: { check: false, lib: '', code: 'DESC/ODES' }
                    },
                    LIST_ELEM: []
                },
                OTHERS: []
            };
        }

        let L_termes_and_list_elem = [['']];
        let L_fields = [
            {
                disabled: false,
                labelVisible: false,
                label: "",
                options: [
                    { visible: true, actif: true, check: true, info: this.state.langue.rechercheavancee[123].LIB, label: this.state.langue.rechercheavancee[3].LIB, value: Constante.RequeteAvanceeCode.DATES }
                ]
            },
            {
                disabled: (searchTarget === Constante.EmplacementRecherche.NPL ? true : false),
                labelVisible: true,
                label: this.state.langue.rechercheavancee[132].LIB,
                options: [
                    { visible: true, actif: true, check: true, info: this.state.langue.rechercheavancee[124].LIB, label: this.state.langue.rechercheavancee[8].LIB, value: Constante.RequeteAvanceeCode.ASSIGNEE },
                    { visible: true, actif: true, check: false, info: this.state.langue.rechercheavancee[125].LIB, label: this.state.langue.rechercheavancee[7].LIB, value: Constante.RequeteAvanceeCode.INVENTOR },
                    { visible: true, actif: true, check: true, info: this.state.langue.rechercheavancee[126].LIB, label: this.state.langue.rechercheavancee[50].LIB, value: Constante.RequeteAvanceeCode.CLASSIFICATION },
                    { visible: true, actif: true, check: true, label: this.state.langue.rechercheavancee[64].LIB, value: Constante.RequeteAvanceeCode.PN },
                    { visible: true, actif: true, check: false, info: this.state.langue.rechercheavancee[127].LIB, label: this.state.langue.rechercheavancee[9].LIB, value: Constante.RequeteAvanceeCode.PUBLICATION_COUNTRY },
                    { visible: true, actif: true, check: false, info: this.state.langue.rechercheavancee[128].LIB, label: this.state.langue.rechercheavancee[65].LIB, value: Constante.RequeteAvanceeCode.LEGAL }
                ]
            },
            {
                disabled: (searchTarget === Constante.EmplacementRecherche.NPL ? false : true),
                labelVisible: true,
                label: this.state.langue.rechercheavancee[133].LIB,
                options: [
                    { visible: true, actif: false, check: true, info: this.state.langue.rechercheavancee[129].LIB, label: this.state.langue.rechercheavancee[119].LIB, value: Constante.RequeteAvanceeCode.TYPENPL },
                    { visible: true, actif: true, check: false, info: this.state.langue.rechercheavancee[130].LIB, label: this.state.langue.rechercheavancee[131].LIB, value: Constante.RequeteAvanceeCode.RDNUMBER }
                ]
            }
        ];
        if (searchTarget === Constante.EmplacementRecherche.WORKFILE) {
            L_fields[1].options[5].disable = true;
        }
        else if (searchTarget === Constante.EmplacementRecherche.SIMILARITY ||
            searchTarget === Constante.EmplacementRecherche.PRIOR_ART) {
            L_termes_and_list_elem = [];
        }
        let L_similarite = '';
        let L_conceptNPL = '';
        let L_date_fin = null;
        let L_check_PD = true;
        let L_check_EPRD = false;

        // Preserve some NPL compliant settings
        if (preserveNplCompliantsParams) {
            L_similarite = this.state.RequeteInfos.requete_avancee.SIMILARITE;
            L_conceptNPL = this.state.RequeteInfos.requete_avancee.NPLConcept;
            if (searchTarget === Constante.EmplacementRecherche.PRIOR_ART ||
                (searchTarget === Constante.EmplacementRecherche.NPL && this.state.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.PRIOR_ART)) {
                for (let L_parcours_other = 0; L_parcours_other < this.state.RequeteInfos.requete_avancee.OTHERS.length; L_parcours_other++) {
                    if (this.state.RequeteInfos.requete_avancee.OTHERS[L_parcours_other].CODE === Constante.RequeteAvanceeCode.DATES) {
                        L_date_fin = this.state.RequeteInfos.requete_avancee.OTHERS[L_parcours_other].LIST_ELEM[1];
                        L_check_PD = false;
                        L_check_EPRD = true;
                    }
                }
            }
        }

        return {
            SCOPE: "",
            RESULTAT_NB: 0,
            DATE: Date.now(),
            FIELDS: L_fields,
            SMART_SEARCH: "",
            SIMILARITE: L_similarite,
            SEMANTIQUE: "",
            NPLConcept: L_conceptNPL,
            NPL_OTHER_FIELDS: true,
            PATENT_OTHER_FIELDS: true,
            TERMES_AND: {
                CODE: Constante.RequeteAvanceeCode.TERMES_AND,
                SEARCH_IN: {
                    elem1: { check: true, lib: this.state.langue.rechercheavancee[56].LIB, code: 'TI' },
                    elem2: { check: true, lib: this.state.langue.rechercheavancee[57].LIB, code: 'AB' },
                    elem3: { check: true, lib: this.state.langue.rechercheavancee[59].LIB, code: 'CLMS' },
                    elem4: { check: false, lib: this.state.langue.rechercheavancee[58].LIB, code: 'DESC/ODES' }
                },
                LIST_ELEM: L_termes_and_list_elem
            },
            TERMES_NOT: {
                CODE: Constante.RequeteAvanceeCode.TERMES_NOT,
                SEARCH_IN: {
                    elem1: { check: true, lib: this.state.langue.rechercheavancee[56].LIB, code: 'TI' },
                    elem2: { check: true, lib: this.state.langue.rechercheavancee[57].LIB, code: 'AB' },
                    elem3: { check: true, lib: this.state.langue.rechercheavancee[59].LIB, code: 'CLMS' },
                    elem4: { check: false, lib: this.state.langue.rechercheavancee[58].LIB, code: 'DESC/ODES' }
                },
                LIST_ELEM: []
            },
            OTHERS: [
                {
                    ID: '0_0',
                    CODE: Constante.RequeteAvanceeCode.DATES,
                    SEARCH_IN: [
                        {
                            labelVisible: false,
                            label: "",
                            options: [
                                { check: L_check_PD, label: this.state.langue.rechercheavancee[5].LIB, value: 'PD' },
                                { check: false, label: this.state.langue.rechercheavancee[60].LIB, value: 'APD' },
                                { check: false, label: this.state.langue.rechercheavancee[4].LIB, value: 'PRD' },
                                { check: L_check_EPRD, label: this.state.langue.rechercheavancee[115].LIB, value: 'EPRD' }
                            ]
                        }
                    ],
                    LIST_ELEM: [null, L_date_fin]
                },
                {
                    ID: '1_0',
                    CODE: Constante.RequeteAvanceeCode.ASSIGNEE,
                    SEARCH_IN: {
                        elem1: { check: true, lib: this.state.langue.rechercheavancee[8].LIB, code: 'PA/OPA' },
                    },
                    LIST_ELEM: ['']
                },
                {
                    ID: '1_2',
                    CODE: Constante.RequeteAvanceeCode.CLASSIFICATION,
                    SEARCH_IN: {
                        elem1: { check: true, lib: this.state.langue.rechercheavancee[68].LIB, code: 'IPC/CPC' },
                        elem2: { check: false, lib: this.state.langue.rechercheavancee[66].LIB, code: 'IPC' },
                        elem3: { check: false, lib: this.state.langue.rechercheavancee[67].LIB, code: 'CPC' }
                    },
                    LIST_ELEM: ['']
                },
                {
                    ID: '1_3',
                    CODE: Constante.RequeteAvanceeCode.PN,
                    SEARCH_IN: {
                        elem1: { check: true, lib: this.state.langue.rechercheavancee[64].LIB, code: 'PN/XPN' },
                    },
                    LIST_ELEM: ['']
                }, {
                    ID: '2_0',
                    CODE: Constante.RequeteAvanceeCode.TYPENPL,
                    SEARCH_IN: {
                        elem1: { check: true, lib: this.state.langue.rechercheavancee[119].LIB, code: 'typeNPL' },
                    },
                    LIST_ELEM: []
                }
            ]
        };
    }


    reset_recherche_info = () => {
        return {
            Choix: '1',
            EmplacementRecherche1: Constante.EmplacementRecherche.FAMPAT,
            NomRecherche: '',
            IdRecherche: '',
            EmplacementRecherche2: Constante.EmplacementRecherche.NPL,
            EmplacementHistorique: this.state.RechercheInfo.EmplacementHistorique,
            EmplacementHistoriquePrecedent: this.state.RechercheInfo.EmplacementHistoriquePrecedent,
            EmplacementHistoriquePrecedentType: this.state.RechercheInfo.EmplacementHistoriquePrecedentType,
        };
    }

    /**
     * Create and set state with a hightlight profile
     *
     * @param {string|array} searchStringOrConcepts
     *
     * @return void
     */
    setHighlightsProfile = (searchStringOrConcepts) => {
        const { highlightsProfile: currentProfile, RechercheInfo, highlightsProfileFromWorkfile } = this.state,
            { EmplacementRecherche1 } = RechercheInfo;

        generateProfile({
            currentProfile,
            searchStringOrConcepts,
            highlightsProfileFromWorkfile
        }).then(highlightsProfile => {
            this.setState({ highlightsProfile });
        });
    }


    /**
     * Retrieve hightlight from iorbit for a given workspace, then convert and apply
     *
     * @param {string} workfileId The workfile identifier
     *
     * @return void
     */
    setHighlightsProfileForWorkfiles = (workfileId) => {
        generateProfileForWorkfile(workfileId)
            .then(highlightsProfile => {
                this.setState({
                    highlightsProfile,
                    highlightsProfileFromWorkfile: highlightsProfile
                })
            });
    }


    /**
     * Update the current state with a hightlight profile
     *
     * @param {string|array} searchStringOrConcepts
     *
     * @return void
     */
    updateHighlightsProfile = highlightsProfile => {
        this.setState({ highlightsProfile });
    }


    /**
    * Update the state with a new search
    *
    * @param {string} value
    *
    * @return void
    */
    submitSearch = value => {
        const newSearch = this.getNewSearchObject(Constante.EmplacementRecherche.FAMPAT, false),
            RequeteInfos = {
                ...newSearch,
                requete: value,
                requete_npl: { query: value },
            };

        // 1) Set a new hightlight profile
        this.setHighlightsProfile(value);

        // 2) Perform a simple search
        RequeteInfos.requete_avancee.SMART_SEARCH = "1";

        this.setState({
            RequeteInfos,
            RechercheInfo: this.reset_recherche_info()
        }, () => {
            this.changeClassBody(Constante.EmplacementRecherche.FAMPAT, '');
            this.getSearch('1', null, null, true);
        });
    }


    /**
     * Update advanced search with new parameters.
     *
     * @param {*} searchCode The kind of action to executre
     * @param {*} searchData Provided data to update the advanced search
     *
     * @return void
     */
    submitAdvancedSearch = (searchCode, searchData) => {
        const { REQUETE }  = searchData,
            { TERMES_AND } = REQUETE    || {},
            { LIST_ELEM }  = TERMES_AND || {};

        // 1) Inject highlight to the current state
        if (LIST_ELEM) {
            this.setHighlightsProfile(LIST_ELEM);
        }

        // 2) Do some kind of advanced search
        let L_recherche = Object.assign({}, this.state.RequeteInfos);
        let L_recherche_lance = false; // permet de savoir si on doit lancer la Recherche
        switch (searchCode) {
            case Constante.RequeteAvancee.ConceptNPLUpdatePrevious:
                L_recherche.requete_avancee.NPLConcept = searchData.concept;
                this.setState({
                    RequeteInfos: L_recherche,
                    //RequeteInfosActif: JSON.parse(JSON.stringify(L_recherche)),
                });
                return;
            case Constante.RequeteAvancee.ConceptNPLUpdate:
                L_recherche.requete_avancee.NPLConcept = searchData.concept;
                this.setState({
                    RequeteInfos: L_recherche,
                    //RequeteInfosActif: JSON.parse(JSON.stringify(L_recherche)),
                }, () => {
                    this.getSearch(null, null, null, null);
                });
                return;
            case Constante.RequeteAvancee.requete:
                L_recherche.requete_avancee = searchData.REQUETE;
                if (searchData.QP_MODE === false) {
                    L_recherche.executedQuery = '';
                }
                L_recherche.ChangementDetecte = true;
                L_recherche.changementCancel = true;
                break;
            case Constante.RequeteAvancee.modeSemantique:
                L_recherche.choixRecherche = 2;
                this.changePage(Constante.affichagePage.Recherche, Constante.affichageMode.rechercheavancee);
                break;
            case Constante.RequeteAvancee.modeSimple:
            case Constante.RequeteAvancee.modeAvancee:
                let L_mode = Constante.affichageMode.rechercheavancee;
                if (searchCode === Constante.RequeteAvancee.modeSimple) {
                    L_recherche.choixRecherche = 1;
                    L_mode = Constante.affichageMode.rechercheavancee;
                    this.changePage(Constante.affichagePage.Recherche, Constante.affichageMode.rechercheavancee);
                }
                else if (searchCode === Constante.RequeteAvancee.modeAvancee) {
                    L_recherche.choixRecherche = 1;
                    L_mode = Constante.affichageMode.Recherche;
                    this.changePage(Constante.affichagePage.Recherche, Constante.affichageMode.Recherche);
                }
                if (this.state.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SEMANTIQUE) {
                    this.clearRechercheEmplacement(L_mode);
                    if (this.Recherche.current != null) {
                        this.Recherche.current.reset_documents_all(null);
                        this.Recherche.current.reset_document_un();
                    }
                    return;
                }
                break;
            case Constante.RequeteAvancee.cancel:
                //let L_requete_temp = L_recherche.requete;
                let L_rechercheInfo = Object.assign({}, this.state.RechercheInfo);
                let L_garde = false;
                if (this.state.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SIMILARITY ||
                    this.state.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.PRIOR_ART) {
                    L_garde = true;
                }
                L_recherche = this.getNewSearchObject(this.state.RechercheInfo.EmplacementRecherche1, L_garde);
                L_recherche.requete = "";
                if (this.state.RechercheInfo['EmplacementRecherche' + this.state.RechercheInfo.Choix] === Constante.EmplacementRecherche.LIST ||
                    this.state.RechercheInfo['EmplacementRecherche' + this.state.RechercheInfo.Choix] === Constante.EmplacementRecherche.WORKFILE) {
                    searchCode = Constante.RequeteAvancee.valider; // pour les listes ou workfiles sur clear on visualise tout les elements
                    L_recherche.requete = "";
                    L_recherche_lance = true;
                }
                L_rechercheInfo.Choix = '1';
                this.setState({
                    RequeteInfos: L_recherche,
                    //RequeteInfosActif: JSON.parse(JSON.stringify(L_recherche)),
                    RechercheInfo: L_rechercheInfo
                }, () => {
                    if (L_garde || this.state.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.LIST ||
                        this.state.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.WORKFILE) {
                        this.submitAdvancedSearch(Constante.RequeteAvancee.valider,
                            { REQUETE: JSON.parse(JSON.stringify(this.state.RequeteInfos.requete_avancee)) });
                    }
                    if (this.Recherche.current != null) {
                        this.Recherche.current.reset_documents_all(null);
                        this.Recherche.current.reset_document_un();
                    }
                });
                return;
            case Constante.RequeteAvancee.valider:
                if (searchData.REQUETE !== null && typeof searchData.REQUETE !== "undefined") {
                    // if (L_recherche.requete_avancee !== P_info.REQUETE) {
                    L_recherche.requete_avancee = searchData.REQUETE;
                    L_recherche.ChangementDetecte = true;
                    L_recherche.changementCancel = true;
                    // }
                }
                if (searchData.QP_MODE === false) {
                    L_recherche.executedQuery = '';
                }
                if (this.state.RechercheInfo.Choix === "1") {
                    L_recherche.requete_avancee.NPLConcept = "";
                }
                if (L_recherche.ChangementDetecte === false) {
                    return;
                }
                break;
            case Constante.RequeteAvancee.cancelSemantique:
                L_recherche.requete_avancee.SEMANTIQUE = '';
                break;
            case Constante.RequeteAvancee.validerSemantique:
                if (searchData.REQUETE !== null && typeof searchData.REQUETE !== "undefined") {
                    L_recherche = this.getNewSearchObject(Constante.EmplacementRecherche.SEMANTIQUE, false);
                    L_recherche.choixRecherche = 2;
                    L_recherche.requete_avancee.SEMANTIQUE = searchData.REQUETE.SEMANTIQUE;
                    if (L_recherche.requete_avancee.SEMANTIQUE.length > 1) {
                        if (L_recherche.requete_avancee.SEMANTIQUE.charCodeAt(L_recherche.requete_avancee.SEMANTIQUE.length - 1) === 10) {
                            L_recherche.requete_avancee.SEMANTIQUE = L_recherche.requete_avancee.SEMANTIQUE.substring(0, L_recherche.requete_avancee.SEMANTIQUE.length - 1);
                        }
                    }
                    L_recherche.requete = L_recherche.requete_avancee.SEMANTIQUE;
                    L_recherche.requete_avancee.NPLConcept = "";
                    let L_recherche_infos = Object.assign({}, this.state.RechercheInfo);
                    L_recherche.Choix = '1';
                    L_recherche_infos.EmplacementRecherche1 = Constante.EmplacementRecherche.SEMANTIQUE;
                    L_recherche_infos.IdRecherche = '';
                    L_recherche_infos.NomRecherche = '';
                    L_recherche.EmplacementHistorique = this.state.RechercheInfo.EmplacementHistorique;
                    L_recherche_infos.EmplacementHistoriquePrecedent = this.state.RechercheInfo.EmplacementHistorique;
                    L_recherche_infos.EmplacementHistoriquePrecedentType = this.state.RechercheInfo.EmplacementRecherche1;
                    this.changeClassBody(Constante.EmplacementRecherche.SEMANTIQUE, '');
                    this.setState({
                        RequeteInfos: L_recherche,
                        //RequeteInfosActif: JSON.parse(JSON.stringify(L_recherche)),
                        RechercheInfo: L_recherche_infos
                    }, () => this.getSearch(null, null, false, true));
                }
                return;
            case Constante.RequeteAvancee.ModePriorArt:
                L_recherche.requete_avancee = this.getNewAdvancedSearchObject(Constante.EmplacementRecherche.PRIOR_ART, false);
                L_recherche.executedQuery = "";
                L_recherche.requete = "";
                L_recherche.requete_npl = { query: '' };
                L_recherche.requete_avancee.NPLConcept = "";
                L_recherche.requete_avancee.SIMILARITE = searchData.data.REP;
                for (let L_parcours_other = 0; L_parcours_other < L_recherche.requete_avancee.OTHERS.length; L_parcours_other++) {
                    if (L_recherche.requete_avancee.OTHERS[L_parcours_other].CODE === Constante.RequeteAvanceeCode.DATES) {
                        L_recherche.requete_avancee.OTHERS[L_parcours_other].LIST_ELEM[1] = searchData.data.EPRD.replace('<b>', '').replace('</b>', '');
                        L_recherche.requete_avancee.OTHERS[L_parcours_other].SEARCH_IN[0].options[0].check = false;
                        L_recherche.requete_avancee.OTHERS[L_parcours_other].SEARCH_IN[0].options[1].check = false;
                        L_recherche.requete_avancee.OTHERS[L_parcours_other].SEARCH_IN[0].options[2].check = false;
                        L_recherche.requete_avancee.OTHERS[L_parcours_other].SEARCH_IN[0].options[3].check = true;
                    }
                }
                //ajoute date
                break;
            case Constante.RequeteAvancee.ModeSimilariteUn:
                L_recherche.requete_avancee = this.getNewAdvancedSearchObject(Constante.EmplacementRecherche.SIMILARITY, false);
                L_recherche.executedQuery = "";
                L_recherche.requete = "";
                L_recherche.requete_npl = { query: '' };
                L_recherche.requete_avancee.NPLConcept = "";
                L_recherche.requete_avancee.SIMILARITE = searchData.data.REP;
                break;
            case Constante.RequeteAvancee.ModeSimilarite:
                L_recherche.requete_avancee = this.getNewAdvancedSearchObject(Constante.EmplacementRecherche.SIMILARITY, false);
                L_recherche.executedQuery = "";
                L_recherche.requete = "";
                let L_doc_info = Object.assign({}, searchData);
                L_recherche.requete_avancee.SIMILARITE = L_doc_info['Recherche' + this.state.RechercheInfo.Choix].DocumentsSelectREP.join(',');
                L_recherche.choixRecherche = 1;
                L_recherche.requete_npl = { query: '' };
                L_recherche.requete_avancee.NPLConcept = "";
                L_recherche.ChangementDetecte = true;
                break;
            default:
                break;
        }
        if (searchCode === Constante.RequeteAvancee.valider ||
            searchCode === Constante.RequeteAvancee.ModeSimilarite ||
            searchCode === Constante.RequeteAvancee.ModeSimilariteUn ||
            searchCode === Constante.RequeteAvancee.ModePriorArt) {
            L_recherche.is_recherche_avancee = true;
            L_recherche.requete_avancee.NPL_OTHER_FIELDS = false;
            let L_requete_easy = '';
            let L_requete = '';
            let L_requete_npl = { query: '' };
            let L_requete_or_ligne = '';
            //recup des in dans and et not
            let L_in = '';
            Object.keys(L_recherche.requete_avancee.TERMES_AND.SEARCH_IN).map((P_key, Pindex) => {
                if (L_recherche.requete_avancee.TERMES_AND.SEARCH_IN[P_key].check) {
                    L_in += '/' + L_recherche.requete_avancee.TERMES_AND.SEARCH_IN[P_key].code;
                }
                return true;
            });
            //termes and
            for (let L_index0 = 0; L_index0 < L_recherche.requete_avancee.TERMES_AND.LIST_ELEM.length; L_index0++) {
                L_requete_or_ligne = '';
                L_requete_easy = '';
                for (let L_index1 = 0; L_index1 < L_recherche.requete_avancee.TERMES_AND.LIST_ELEM[L_index0].length; L_index1++) {
                    if (L_index0 === 0 && L_index1 === 0 && L_recherche.requete_avancee.SMART_SEARCH.length > 1) {
                        L_requete_easy = L_recherche.requete_avancee.SMART_SEARCH;
                    }
                    else if (L_recherche.requete_avancee.TERMES_AND.LIST_ELEM[L_index0][L_index1].length > 0) {
                        L_requete_or_ligne += L_recherche.requete_avancee.TERMES_AND.LIST_ELEM[L_index0][L_index1] + ' OR ';
                    }
                }
                if (L_requete_or_ligne.length > 1 || L_requete_easy.length > 1) {
                    if (L_requete_easy.length > 1 && L_requete_or_ligne.length > 1) {
                        L_requete += '(' + L_requete_easy + ' OR ( ' + L_requete_or_ligne.substring(0, L_requete_or_ligne.length - 4) + ' )' + L_in + ' ) AND ';
                    }
                    else if (L_requete_easy.length > 1) {
                        L_requete += '(' + L_requete_easy + ' ) AND ';
                    }
                    else if (L_requete_or_ligne.length > 1) {
                        L_requete += '(' + L_requete_or_ligne.substring(0, L_requete_or_ligne.length - 4) + ' )' + L_in + ' AND ';
                    }
                }
            }

            if (L_requete.length > 5) {
                L_recherche_lance = true;
            }
            L_requete_npl.query = L_requete;

            L_recherche.requete_avancee.OTHERS.map((P_key, P_index) => {
                let L_in_others = '';
                let L_requete_or_other = '';
                Object.keys(P_key.SEARCH_IN).map((P_key_2) => {
                    if (P_key.SEARCH_IN[P_key_2].check) {
                        L_in_others += '/' + P_key.SEARCH_IN[P_key_2].code;
                    }
                    return true;
                });
                switch (P_key.CODE) {
                    case Constante.RequeteAvanceeCode.LEGAL:
                        L_recherche.requete_avancee.NPL_OTHER_FIELDS = true;
                        if (P_key.LIST_ELEM[1].length > 1) {
                            switch (P_key.LIST_ELEM[1]) {
                                case 'GRANTED':
                                    L_requete += '( (STATE/ACT=ALIVE) AND STATUS/ACT=GRANTED ) AND ';
                                    break;
                                case 'PENDING':
                                    L_requete += '( (STATE/ACT=ALIVE) AND ((STATUS/ACT=PENDING) NOT (STATUS/ACT=GRANTED)) ) AND ';
                                    break;
                                case 'EXPIRED':
                                    L_requete += '( ( (STATE/ACT=DEAD NOT STATE/ACT=ALIVE) ) AND STATUS/ACT=EXPIRED ) AND ';
                                    break;
                                case 'LAPSED':
                                    L_requete += '( ( (STATE/ACT=DEAD NOT STATE/ACT=ALIVE) ) AND (STATUS/ACT=LAPSED NOT STATUS/ACT=EXPIRED)  ) AND ';
                                    break;
                                case 'REVOKED':
                                    L_requete += '( ( (STATE/ACT=DEAD NOT STATE/ACT=ALIVE) ) AND STATUS/ACT=REVOKED ) AND ';
                                    break;
                                default:
                                    break;
                            }
                        }
                        else if (P_key.LIST_ELEM[0].length > 1) {
                            switch (P_key.LIST_ELEM[0]) {
                                case 'ALIVE':
                                    L_requete += '( STATE/ACT=ALIVE ) AND ';
                                    break;
                                case 'DEAD':
                                    L_requete += '( (STATE/ACT=DEAD NOT STATE/ACT=ALIVE) ) AND ';
                                    break;
                                default:
                                    break;
                            }
                        }
                        break;
                    case Constante.RequeteAvanceeCode.PUBLICATION_COUNTRY:
                        L_recherche.requete_avancee.NPL_OTHER_FIELDS = true;
                        if (P_key.LIST_ELEM.length > 0) {
                            L_requete += '(' + P_key.LIST_ELEM.map(function (P_office) {
                                return P_office;
                            }).join(" OR ") + ')' + L_in_others + ' AND ';
                        }
                        break;
                    case Constante.RequeteAvanceeCode.TYPENPL:
                        L_recherche.requete_avancee.PATENT_OTHER_FIELDS = true;
                        if (P_key.LIST_ELEM.length > 0) {
                            L_requete_npl.types = [];
                            L_requete_npl.subtypes = [];
                            P_key.LIST_ELEM.map(function (P_office) {
                                const TypeSubtype = P_office.split('__');
                                TypeSubtype.forEach(element => {
                                    const elementInfo = element.split('--');
                                    switch (elementInfo[0]) {
                                        case 't':
                                            L_requete_npl.types.push(elementInfo[1]);
                                            break;
                                        case 's':
                                            L_requete_npl.subtypes.push(elementInfo[1]);
                                            break;
                                    }
                                });
                            });
                        }
                        break;
                    case Constante.RequeteAvanceeCode.DATES:
                        L_in_others = P_key.SEARCH_IN[0].options.find(x => x.check).value;
                        if (P_key.LIST_ELEM[0] !== null && P_key.LIST_ELEM[1] !== null) {
                            L_requete += '(' + L_in_others + '=' + P_key.LIST_ELEM[0] + ':' + P_key.LIST_ELEM[1] + ') AND ';
                            if (L_requete_npl.query.length > 0) {
                                L_requete_npl.query += '(' + L_in_others + '>=' + P_key.LIST_ELEM[0] + ') AND (' + L_in_others + '<=' + P_key.LIST_ELEM[1] + ') AND ';
                            }
                        }
                        else if (P_key.LIST_ELEM[0] !== null) {
                            //L_recherche.requete_avancee.NPL_OTHER_FIELDS = true;
                            L_requete += '(' + L_in_others + '>=' + P_key.LIST_ELEM[0] + ') AND ';
                            if (L_requete_npl.query.length > 0) {
                                L_requete_npl.query += '(' + L_in_others + '>=' + P_key.LIST_ELEM[0] + ') AND ';
                            }
                        }
                        else if (P_key.LIST_ELEM[1] !== null) {
                            //L_recherche.requete_avancee.NPL_OTHER_FIELDS = true;
                            L_requete += '(' + L_in_others + '<=' + P_key.LIST_ELEM[1] + ') AND ';
                            if (L_requete_npl.query.length > 0) {
                                L_requete_npl.query += '(' + L_in_others + '<=' + P_key.LIST_ELEM[1] + ') AND ';
                            }
                        }
                        break;
                    case Constante.RequeteAvanceeCode.INVENTOR:
                        for (let L_index = 0; L_index < P_key.LIST_ELEM.length; L_index++) {
                            if (P_key.LIST_ELEM[L_index].length > 0) {
                                L_requete_or_other += ((P_key.LIST_ELEM[L_index].match(WORD_BOUNDARY_REGEXP)) ? P_key.LIST_ELEM[L_index] : P_key.LIST_ELEM[L_index].trim().replace(/[ ]+/gi, " 1D ")) + ' OR ';
                            }
                        }
                        L_recherche.requete_avancee.NPL_OTHER_FIELDS = true;
                        if (L_requete_or_other.length > 1) {
                            L_recherche_lance = true;
                            L_requete += ' ( ' + L_requete_or_other.substring(0, L_requete_or_other.length - 4) + ' )' + L_in_others + ' AND ';
                        }
                        break;
                    case Constante.RequeteAvanceeCode.ASSIGNEE:
                    case Constante.RequeteAvanceeCode.CLASSIFICATION:
                    case Constante.RequeteAvanceeCode.PN:
                        for (let L_index = 0; L_index < P_key.LIST_ELEM.length; L_index++) {
                            if (P_key.LIST_ELEM[L_index].length > 0) {
                                L_requete_or_other += P_key.LIST_ELEM[L_index] + ' OR ';
                            }
                        }
                        L_recherche.requete_avancee.NPL_OTHER_FIELDS = true;
                        if (L_requete_or_other.length > 1) {
                            L_recherche_lance = true;
                            L_requete += ' ( ' + L_requete_or_other.substring(0, L_requete_or_other.length - 4) + ' )' + L_in_others + ' AND ';
                        }
                        break;
                    case Constante.RequeteAvanceeCode.RDNUMBER:
                        for (let L_index = 0; L_index < P_key.LIST_ELEM.length; L_index++) {
                            if (P_key.LIST_ELEM[L_index].length > 0) {
                                L_requete_or_other += P_key.LIST_ELEM[L_index] + ' OR ';
                            }
                        }
                        L_recherche.requete_avancee.PATENT_OTHER_FIELDS = true;
                        if (L_requete_or_other.length > 1) {
                            L_recherche_lance = true;
                            L_requete_npl.query += ' ( ' + L_requete_or_other.substring(0, L_requete_or_other.length - 4) + ' )' + L_in_others + ' AND ';
                        }
                        break;

                    default:
                        break;
                }
                return true;
            });

            //termes NOT
            let L_requete_not = '';
            for (let L_index0 = 0; L_index0 < L_recherche.requete_avancee.TERMES_NOT.LIST_ELEM.length; L_index0++) {
                L_requete_or_ligne = '';
                for (let L_index1 = 0; L_index1 < L_recherche.requete_avancee.TERMES_NOT.LIST_ELEM[L_index0].length; L_index1++) {
                    if (L_recherche.requete_avancee.TERMES_NOT.LIST_ELEM[L_index0][L_index1].length > 0) {
                        L_requete_or_ligne += L_recherche.requete_avancee.TERMES_NOT.LIST_ELEM[L_index0][L_index1] + ' OR ';
                    }
                }

                if (L_requete_or_ligne.length > 1) {
                    L_requete_not += ' ( ' + L_requete_or_ligne.substring(0, L_requete_or_ligne.length - 4) + ' ) AND ';
                }
            }

            if (L_requete_not.length > 1) {
                if (L_requete.length > 4) {
                    L_requete = "( " + L_requete.substring(0, L_requete.length - 4) + " ) ";
                }

                if (L_requete_npl.query.length > 4) {
                    L_requete_npl.query = "( " + L_requete_npl.query.substring(0, L_requete_npl.query.length - 4) + " ) ";
                }
                L_requete += 'NOT ( ' + L_requete_not.substring(0, L_requete_not.length - 4) + ' )' + L_in + ' AND ';

                if (L_requete_npl.query.length > 0) {
                    L_requete_npl.query += 'NOT ( ' + L_requete_not.substring(0, L_requete_not.length - 4) + ' )' + L_in + ' AND ';
                }
            }

            if (L_requete_npl.query.length > 5) {
                L_requete_npl.query = L_requete_npl.query.substring(0, L_requete_npl.query.length - 5);
            }

            if (L_requete.length > 5) {
                L_requete = L_requete.substring(0, L_requete.length - 5);
            }
            if (L_requete.length > 1) {
                L_recherche.requete = L_requete;
                L_recherche.requete_npl = L_requete_npl;
            }

            if (L_recherche.requete_avancee.SIMILARITE.length > 0 &&
                ((searchCode === Constante.RequeteAvancee.ModeSimilarite ||
                    searchCode === Constante.RequeteAvancee.ModeSimilariteUn) ||
                    this.state.RechercheInfo['EmplacementRecherche' + this.state.RechercheInfo.Choix] === Constante.EmplacementRecherche.SIMILARITY)) { // si on est en mode similarite
                L_recherche_lance = true;
            }
            if (L_recherche.requete_avancee.SIMILARITE.length > 0 &&
                (searchCode === Constante.RequeteAvancee.ModePriorArt ||
                    this.state.RechercheInfo['EmplacementRecherche' + this.state.RechercheInfo.Choix] === Constante.EmplacementRecherche.PRIOR_ART)) { // si on est en mode prior art
                L_recherche_lance = true;
            }
            L_recherche.ChangementDetecte = false;
            if (L_recherche_lance ||
                this.state.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.WORKFILE ||
                this.state.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.LIST ||
                this.state.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.PRIOR_ART ||
                this.state.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SIMILARITY) {
                L_recherche.requete = L_requete;
                L_recherche.requete_npl = L_requete_npl;
                if (searchCode === Constante.RequeteAvancee.ModeSimilarite ||
                    searchCode === Constante.RequeteAvancee.ModeSimilariteUn ||
                    searchCode === Constante.RequeteAvancee.ModePriorArt) {
                    let L_emplacement_recherche = Constante.EmplacementRecherche.SIMILARITY;
                    if (searchCode === Constante.RequeteAvancee.ModePriorArt) {
                        L_emplacement_recherche = Constante.EmplacementRecherche.PRIOR_ART;
                    }
                    let L_recherche_infos = Object.assign({}, this.state.RechercheInfo);
                    L_recherche_infos.Choix = '1';
                    L_recherche_infos.EmplacementRecherche1 = L_emplacement_recherche;
                    L_recherche_infos.IdRecherche = '';
                    L_recherche_infos.NomRecherche = '';
                    L_recherche_infos.EmplacementHistorique = this.state.RechercheInfo.EmplacementHistorique;
                    L_recherche_infos.EmplacementHistoriquePrecedent = this.state.RechercheInfo.EmplacementHistorique;
                    L_recherche_infos.EmplacementHistoriquePrecedentType = this.state.RechercheInfo.EmplacementRecherche1;
                    this.changeClassBody(L_emplacement_recherche, '');
                    this.setState({
                        RequeteInfos: L_recherche,
                        //RequeteInfosActif: JSON.parse(JSON.stringify(L_recherche)),
                        RechercheInfo: L_recherche_infos,
                        affichageMode: Constante.affichageMode.rechercheavancee,
                    }, () => this.getSearch(null, null, true, true));
                }
                else {
                    this.setState({
                        RequeteInfos: L_recherche,
                        //RequeteInfosActif: JSON.parse(JSON.stringify(L_recherche)),
                        affichageMode: Constante.affichageMode.rechercheavancee,
                    }, () => this.getSearch(null, null, false, true));
                }
            }
            else {
                L_recherche.requete = "";
                L_recherche.requete_npl = { query: '' };
                L_recherche.executedQuery = "";
                L_recherche.requete_avancee_valide = JSON.parse(JSON.stringify(L_recherche.requete_avancee));
                this.setState({
                    RequeteInfos: L_recherche,
                    //RequeteInfosActif: JSON.parse(JSON.stringify(L_recherche)),
                    affichageMode: Constante.affichageMode.rechercheavancee,
                }, () => {
                    if (this.Recherche.current !== null) {
                        this.Recherche.current.reset_documents_all(null);
                        this.Recherche.current.reset_document_un();
                    }
                });
            }
        }
        else {
            this.setState({
                RequeteInfos: L_recherche
            });
        }

    }

    test_connexion = () => {
        let L_var = {
            'token': this.props.tokenNPL,
            'ticket': this.props.ticket,
            'shard': this.props.shard,
            'query': 'elephants/TI',
            'date': null
        };
        AjaxGestion(Constante.AjaxChoix.TestConnexion, L_var).then((data) => { });
    }


    /**
     * Render the whole Application
     *
     * @return JSX
     */
    render = () => {

        if (!this.state.user) {
            return false;
        }

        if (this.state.user.etat === Constante.etat.permalink && this.state.langue === null) {
            return (
                <div id="D_tous">
                    loading permalink ...
                </div>
            );
        }
        if (this.state.user.etat === Constante.etat.permalink) {
            return (
                <div id="D_tous">
                    <div id="D_head_permalink">
                        <img id="I_logo_questel" src=".\images\orbitexpress_icone.svg" alt="Questel" />
                    </div>
                    {this.choixPage()}
                </div>
            );
        }

        if (this.state.user.etat === Constante.etat.initial || this.state.langue === null) {
            return false;
        }

        return (
            <div id="D_tous" className={this.state.affichagePage}>
                <Entete
                    ref={this.entete}
                    langue={this.state.langue.entete}
                    changement_requete_avancee={this.submitAdvancedSearch}
                    requete={this.state.RequeteInfos.requete}
                    RequeteInfos={this.state.RequeteInfos}
                    affichageMode={this.state.affichageMode}
                    affichagePage={this.state.affichagePage}
                    RechercheInfo={this.state.RechercheInfo}
                    requeteInfosListe={this.state.RequeteInfosListe}
                    user={this.state.user}
                    requete_change={this.requete_change}
                    retourRechercheFampat={this.retourRechercheFampat}
                    requeteInfosListeSet={this.requeteInfosListeSet}
                    changePage={this.changePage}
                    recherche_click={this.submitSearch}
                    Deconnexion={this.Deconnexion}
                    clientLiens={this.state.clientLiens}
                />
                {this.choixPage()}
            </div>
        );
    }
}

export default Express;
