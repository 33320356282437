
import { getSession } from '../../utils/auth.js'


/*
value: word that use for synomys are search
*/
export const synonymsLoad = async (value) => {
    if(value.length<2){
        return [];
    }
    try {
        const elemList = await synonymsFetch(value,10);
        return elemList.map((element, index) => {
            return { label: element , labelShort: element, value: element };
        });
    } catch (error) {
        return [];
    }
}

const synonymsFetch = (value, limit) => new Promise((resolve, reject) => {
    const { tokenNPL } = getSession(),
        { REACT_APP_OINNO_ROOT }      = process.env,
        HeaderParam = {
            method: 'get',
            headers: {
                'Content-Type' : 'application/json',
                'application'  : 'Express',
                'Authorization': `Bearer ${tokenNPL}`
            },
            body: null
        };

    fetch(REACT_APP_OINNO_ROOT + "/public-data-api/concepts?as="+encodeURIComponent("'"+value+"'")+"&limit[max]="+limit, HeaderParam)
    .then(res => {
        const { status } = res; 
        if(status === 206) {
            setTimeout(
                async () => resolve(await synonymsFetch(value, limit)),
                1000
            );
            return false; 
        }
        if(status === 204) {
            return [];
        }
        return res.json();
    })
    .then((responseJson) => {
        // Prevent resolving the promise for 206 status 
        if(!responseJson) {
            return; 
        }
        resolve(responseJson);
    }).catch((error) => {
        reject([]);
    });
});