import React, { Component } from 'react';
import PARSER from 'html-react-parser';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import ListeDocAction from './ListeDocAction.js';
import Highlights from '../helpers/Highlights';
import HighlightConfigurator from '../highlight/HighlightConfigurator.js';
import SelectCustom from '../helpers/SelectCustom/SelectCustom.js';
import Constante from '../Constante.js';

import './ListeDoc.css';



class ListeDoc extends Component {

    constructor() {
        super()
        this.state = {
            APID_CHANGE_STAR: null,
            LISTE_DOC_CANGE: false
        }
    }

    static propTypes = {
        user: PropTypes.object,
        affichage: PropTypes.object,
        languelistedocaction: PropTypes.array,
        langue: PropTypes.array,
        DocumentsInfo: PropTypes.object,
        highlightsProfile: PropTypes.object,
        RechercheInfo: PropTypes.object, // RechercheInfo
        requete_highlight: PropTypes.array,
        NPLConcept: PropTypes.string,
        alertCount: PropTypes.number,
        changeNbresultat: PropTypes.func,
        selectDoc: PropTypes.func,
        changePage: PropTypes.func,
        changeOrdre: PropTypes.func,
        changeAffichageMode: PropTypes.func,
        exportDoc: PropTypes.func,
        voirDoc: PropTypes.func,
        changeDroiteAffichage: PropTypes.func,
        changeDroiteRechercheAvancee: PropTypes.func,
        clearRechercheEmplacement: PropTypes.func,
        noteDoc: PropTypes.func,
        addDocListWorkfile: PropTypes.func,
        deleteDocListWorkfile: PropTypes.func,
        openInnovationDashboard: PropTypes.func
    };

    componentDidUpdate(prevProps, prevState) {

    }

    notedoc = (event, P_note, P_note_en_cours, P_id, P_apid) => {
        if (this.props.user.acces.note.write) {
            event.stopPropagation();
            /*this.setState({
                APID_CHANGE_STAR: P_apid
            });*/
            this.props.noteDoc(P_note, P_note_en_cours, P_id);
        }
        return false;
    }


    /**
     * Add some hightlights to the provided content 
     * 
     * @param {JSX} content The text/html to hightlight
     * 
     * @return JSX
     */
    decorateWithHighlights(content) {
        const { highlightsProfile } = this.props;

        return (
            <Highlights key={Math.random(0, 10)} profile={highlightsProfile}>
                {content}
            </Highlights>
        );
    }


    render_document_patent = () => {
        const elementDecode= document.createElement('div');
        return (
            this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].Documents.map((P_doc) => {
                elementDecode.innerHTML =  P_doc.AB || "";

                const abstractWithoutHtml= elementDecode.textContent || elementDecode.innerText;

                if (P_doc.TI === null || typeof P_doc.TI === 'undefined') {
                    return (
                        <div id="" style={{ display: 'none' }}></div>
                    );
                }
                let L_select = "";
                for (let L_parcours_select = 0; L_parcours_select < this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect.length; L_parcours_select++) {
                    if (this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect[L_parcours_select] === P_doc.APID) {
                        L_select = " selectDoc";
                        break;
                    }
                }
                let L_actif = "";
                if (this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentActif === P_doc.APID) {
                    // couleur en fonction du contexte (search, projets, alertes)
                    L_actif = " activeDoc";
                }
                return (
                    <div className={"D_liste_doc_liste_doc" + L_actif} key={P_doc.APID} id={'D_liste_doc_liste_doc_' + P_doc.APID} >
                        <div className='D_liste_doc_liste_select' onClick={() => this.props.selectDoc(P_doc.APID, P_doc.REP, false, false)}>
                            <div className={"D_liste_doc_liste_select_case" + L_select}>
                            </div>
                        </div>
                        <div className="D_liste_doc_liste_info"
                            onClick={() => { this.props.activeDoc(P_doc.APID); this.props.voirDoc(P_doc.POSITION, Constante.AffichageSelect.document) }}>
                            <div
                                data-for="listedocTooltip"
                                data-tip={this.props.langue[21].LIB}
                                className="D_liste_doc_liste_titre"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    this.props.activeDoc(0);
                                    this.props.voirDoc(P_doc.POSITION, Constante.AffichageSelect.documentEtVisioneuse, Constante.affichageMode.listedoc);
                                    return false;
                                }}>
                                <p>{this.decorateWithHighlights(P_doc.POSITION.toString() + ' - ' + P_doc.TI)}</p>
                            </div>
                            <span
                                data-for="listedocTooltip"
                                data-tip={this.props.langue[22].LIB}
                                className={(P_doc.IMAGE !== '') ? "D_liste_doc_liste_image" : ""}
                                style={{ backgroundImage: 'url(' + P_doc.IMAGE + ')' }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (P_doc.IMAGE !== '') this.props.voirDoc(P_doc.POSITION, Constante.AffichageSelect.visioneuse);
                                    return false;
                                }}>
                            </span>
                            <div className="D_liste_doc_liste_pn_affi">
                                <span className="S_liste_doc_liste_pn">{PARSER(P_doc.REP)} &#8226; </span>
                                <span className="S_liste_doc_liste_affi">{PARSER(P_doc.PA)}</span>

                            </div>
                            <div className="D_liste_doc_liste_dates">
                                <span className="S_liste_doc_liste_date">{this.props.langue[30].LIB} {PARSER(P_doc.EPRD)} &#8226; </span>
                                <span className="S_liste_doc_liste_date">{this.props.langue[16].LIB} {P_doc.DATE}</span>
                                <div className="D_liste_doc_liste_etoile" {...(P_doc.ETOILE < 0 || !this.props.user.acces.note.read) && { style: { display: 'none' } }}>
                                    <img src={P_doc.ETOILE > 0 ? "./images/etoile_select.svg" : "./images/etoile_vide.svg"} alt="1" onClick={(event) => this.notedoc(event, 1, P_doc.ETOILE, P_doc.id, P_doc.APID)} />
                                    <img src={P_doc.ETOILE > 1 ? "./images/etoile_select.svg" : "./images/etoile_vide.svg"} alt="2" onClick={(event) => this.notedoc(event, 2, P_doc.ETOILE, P_doc.id, P_doc.APID)} />
                                    <img src={P_doc.ETOILE > 2 ? "./images/etoile_select.svg" : "./images/etoile_vide.svg"} alt="3" onClick={(event) => this.notedoc(event, 3, P_doc.ETOILE, P_doc.id, P_doc.APID)} />
                                    <img src={P_doc.ETOILE > 3 ? "./images/etoile_select.svg" : "./images/etoile_vide.svg"} alt="4" onClick={(event) => this.notedoc(event, 4, P_doc.ETOILE, P_doc.id, P_doc.APID)} />
                                    <img src={P_doc.ETOILE > 4 ? "./images/etoile_select.svg" : "./images/etoile_vide.svg"} alt="5" onClick={(event) => this.notedoc(event, 5, P_doc.ETOILE, P_doc.id, P_doc.APID)} />
                                </div>
                            </div>
                            <div className="D_liste_doc_liste_abstract">
                                <p>{this.decorateWithHighlights(abstractWithoutHtml)}</p>
                            </div>
                        </div>
                    </div>
                );
            })
        );
    }

    render_document_npl = () => {
        return (
            this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].Documents.map((P_doc) => {
                if (P_doc.title === null || typeof P_doc.title === 'undefined') {
                    return (
                        <div id="" style={{ display: 'none' }}></div>
                    );
                }
                let L_select = "";
                for (let L_parcours_select = 0; L_parcours_select < this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect.length; L_parcours_select++) {
                    if (this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect[L_parcours_select] === P_doc.id) {
                        L_select = " selectDoc";
                        break;
                    }
                }
                let L_actif = "";
                if (this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentActif === P_doc.id) {
                    // couleur en fonction du contexte (search, projets, alertes)
                    L_actif = " activeDoc";
                }
                return (
                    <div className={"D_liste_doc_liste_doc" + L_actif} key={P_doc.id} id={'D_liste_doc_liste_doc_' + P_doc.id} >
                        <div
                            style={{ display: 'none' }}
                            className='D_liste_doc_liste_select'
                            onClick={() => this.props.selectDoc(P_doc.id, P_doc.id, false, false)}
                        >
                            <div className={"D_liste_doc_liste_select_case" + L_select}>
                            </div>
                        </div>
                        <div className="D_liste_doc_liste_info_NPL"
                            onClick={() => { this.props.activeDoc(P_doc.id); this.props.voirDoc(P_doc.POSITION, Constante.AffichageSelect.document) }}>
                            <div
                                data-for="listedocTooltip"
                                data-tip={this.props.langue[21].LIB}
                                className="D_liste_doc_liste_titre"
                                onClick={(event) => {
                                    event.stopPropagation();
                                    this.props.activeDoc(0);
                                    this.props.voirDoc(P_doc.POSITION, Constante.AffichageSelect.document, Constante.affichageMode.listedoc);
                                    return false;
                                }}>
                                <p>{this.decorateWithHighlights(P_doc.POSITION.toString() + ' - ' + P_doc.title)}</p>
                            </div>
                            <div className="D_liste_doc_liste_date_aff_source">
                                <div className="D_liste_doc_liste_date_aff_source_date">{P_doc.year}</div>
                                <div className="D_liste_doc_liste_date_aff_source_date_source">
                                    {P_doc.subtype}
                                </div>
                                <div className="D_liste_doc_liste_date_aff_source_date_aff">{PARSER(P_doc.organisations_court)}</div>

                            </div>
                            <div className="D_liste_doc_liste_abstract">
                                <p>{this.decorateWithHighlights(P_doc.abstract_description.length > 0 ? P_doc.abstract_description : this.props.langue[29].LIB)}</p>
                            </div>
                        </div>
                    </div>
                );
            })
        );
    }


    renderDocument = () => {
        return (
            <div id="D_liste_doc_liste">
                {this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.NPL ?
                    this.render_document_npl() : this.render_document_patent()
                }
                <div id="D_liste_doc_gestion_page" style={this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBDocs > 0 ? {} : { display: 'none' }}>
                    <img id="I_liste_doc_gestion_page_debut"
                        src=".\images\page_debut.svg"
                        alt={this.props.langue[12].LIB}
                        style={this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours > 1 ? {} : { display: 'none' }}
                        onClick={() => this.props.changePage(-100000000)}
                    />
                    <img id="I_liste_doc_gestion_page_prec"
                        src=".\images\page_prec.svg"
                        alt={this.props.langue[13].LIB}
                        style={this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours > 1 ? {} : { display: 'none' }}
                        onClick={() => this.props.changePage(-1)}
                    />
                    {this.props.langue[11].LIB}&nbsp;{this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].PageEnCours}
                    <img id="I_liste_doc_gestion_page_next"
                        src=".\images\page_next.svg"
                        alt={this.props.langue[14].LIB}
                        style={this.props.DocumentsInfo.Recherche1.PageEnCours < this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBPages ? {} : { display: 'none' }}
                        onClick={() => this.props.changePage(1)}
                    />
                    <img id="I_liste_doc_gestion_page_fin"
                        src=".\images\page_fin.svg"
                        alt={this.props.langue[15].LIB}
                        style={this.props.DocumentsInfo.Recherche1.PageEnCours < this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBPages ? {} : { display: 'none' }}
                        onClick={() => this.props.changePage(100000000)}
                    />
                </div>
            </div>
        )
    }

    render() {
        const { highlightsProfile, updateHighlightsProfile } = this.props;

        if (this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].etat === Constante.etat.encours) {
            return (
                <div id="D_liste_doc_centre" style={{ visibility: 'hidden' }}>
                </div>
            );
        }
        return (
            <div id="D_liste_doc_centre">
                <ReactTooltip className="tooltipcsslistedoc" id="listedocTooltip" />
                <div id="D_avancee_close"
                    onClick={() => this.props.changeDroiteRechercheAvancee()}
                    className={this.props.affichage.RechercheAvancee === 1 ? 'expansesvg' : 'reduitsvg'}
                ></div>
                <div id="D_droite_close"
                    onClick={() => this.props.changeDroiteAffichage()}
                    className={this.props.affichage.droite === 0 ? 'expansesvg' : 'reduitsvg'}
                >
                </div>
                <div
                    id="D_liste_doc_NPL_info_concept"
                    style={(this.props.NPLConcept.length > 0 &&
                        this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.NPL) ? {} : { display: 'none' }}
                >
                    {this.props.langue[25].LIB}
                    <span
                        id="S_liste_doc_NPL_info_concept"
                        data-for="listedocTooltip"
                        data-tip={this.props.NPLConcept.replace(/1D/gi, ' ').replace(/ OR /gi, ' , ')}
                    >
                        &nbsp;{this.props.langue[26].LIB}&nbsp;
                    </span>
                    {this.props.langue[27].LIB}.
                    </div>
                <div id="D_liste_doc"
                    style={(this.props.NPLConcept.length > 0 &&
                        this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.NPL) ? { height: "calc( 100% - 2rem)" } : {}}
                >
                    <div id="D_liste_doc_option">
                        <div id="D_liste_doc_option_gauche">
                            <span id="S_liste_doc_nb_resultat">{this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBDocs + ' ' + this.props.langue[0].LIB}</span>
                            <span
                                id="S_liste_doc_sort_by"
                            >{this.props.langue[3].LIB} :
                            </span>
                            <div id="SO_liste_doc_sort_by">
                                <SelectCustom
                                    onChange={(P_val) => this.props.changeOrdre(P_val)}
                                    itemSelected={[this.props.DocumentsInfo.Ordre]}
                                    defaultValue={[this.props.DocumentsInfo.Ordre]}
                                    size="small"
                                    options={[
                                        {
                                            labelVisible: false,
                                            label: '',
                                            options: [
                                                {
                                                    label: this.props.langue[4].LIB, value: Constante.RequeteOrdre.Relevance
                                                },
                                                {
                                                    label: this.props.langue[18].LIB, value: Constante.RequeteOrdre.priorite,
                                                    disable: (this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBDocs > Constante.RequeteOrdre.max ||
                                                        this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.SIMILARITY ||
                                                        this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.SEMANTIQUE ||
                                                        this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.PRIOR_ART) ? true : ''
                                                },
                                                {
                                                    label: this.props.langue[6].LIB, value: Constante.RequeteOrdre.oldest,
                                                    disable: (this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBDocs > Constante.RequeteOrdre.max ||
                                                        this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.SIMILARITY ||
                                                        this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.SEMANTIQUE ||
                                                        this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.PRIOR_ART) ? true : ''
                                                },
                                            ]
                                        }
                                    ]}
                                    placeHolder={this.props.langue[4].LIB}
                                    multiple={false}
                                    itemCreate={false}
                                    defaultColor={false}
                                    allowClear={false}
                                />
                            </div>
                            <span id="S_liste_doc_grouped_by" style={{ display: 'none' }}>{this.props.langue[7].LIB + ' :'}</span>
                            <select id="SO_liste_doc_grouped_by" style={{ display: 'none' }}>
                                <option>{this.props.langue[8].LIB}</option>
                                <option>{this.props.langue[9].LIB}</option>
                            </select>
                            <span id="S_liste_doc_results_page">{this.props.langue[10].LIB} : </span>
                            <div id="SO_liste_doc_sort_by">
                                <SelectCustom
                                    onChange={(P_val) => this.props.changeNbresultat(P_val)}
                                    itemSelected={[this.props.DocumentsInfo.NBResultat]}
                                    defaultValue={[this.props.DocumentsInfo.NBResultat]}
                                    size="small"
                                    options={[
                                        {
                                            labelVisible: false,
                                            label: '',
                                            options: Constante.ResultatPage.map((P_nb) => {
                                                return { label: P_nb, value: P_nb };

                                            })
                                        }
                                    ]}
                                    placeHolder={"xxx"}
                                    multiple={false}
                                    itemCreate={false}
                                    defaultColor={false}
                                    allowClear={false}
                                />
                            </div>
                            <div id="D_liste_doc_action_liste_doc">
                                <span
                                    id="S_liste_doc_nb_select"
                                    style={(this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect.length > 0) ? {} : { display: 'none' }}
                                >{this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect.length + ' ' + this.props.langue[1].LIB}</span>
                                <div id="D_liste_doc_ajouter" style={{ display: 'none' }}>+ {this.props.langue[2].LIB}</div>
                                {this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBDocs > 0 && (
                                    <HighlightConfigurator
                                        profile={highlightsProfile}
                                        updateProfile={updateHighlightsProfile}
                                        translations={this.props.translations}
                                    />
                                )}
                                <div
                                    /* just to hide in NPL */
                                    {...(this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].NBDocs < 1) && { style: { display: 'none' } }}
                                >
                                    <ListeDocAction
                                        langue={this.props.languelistedocaction}
                                        nbSelectionne={this.props.DocumentsInfo['Recherche' + this.props.RechercheInfo.Choix].DocumentsSelect.length}
                                        selectDoc={this.props.selectDoc}
                                        exportDoc={this.props.exportDoc}
                                        addAlert={this.props.addAlert}
                                        alertCount= {this.props.alertCount}
                                        EmplacementRecherche={this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix]}
                                        addDocListWorkfile={this.props.addDocListWorkfile}
                                        deleteDocListWorkfile={this.props.deleteDocListWorkfile}
                                        similariteSearch={this.props.similariteSearch}
                                        openInnovationDashboard={this.props.openInnovationDashboard}
                                        user={this.props.user}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    {this.renderDocument()}
                </div>
            </div>
        );
    }
}

export default ListeDoc; 