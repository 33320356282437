
import Constante from '../../Constante';
import { navigateToLoginPage, getSession } from '../../utils/auth.js'


/*
maybe later

export const inventorLoad = async (value, limit) => {
    if (value.length < 2) {
        return [];
    }
    try {
        const elemList = await inventorFetch(value, limit);
        const elemLogo = await inventorLogoFetch(elemList);
        return elemList.map((element, index) => {
            return {
                type: "org", label: element.label,
                labelShort: element.label,
                value: element.label,
                info: element.org_city,
                country: element.country,
                info2: ((element.isHQ === false && element.partOf !== null) ? element.partOf[0].label : null),
                logo: ((elemLogo[element.id] === null || typeof elemLogo[element.id] === "undefined") ? null : elemLogo[element.id].base64)
            };
        });
    } catch (error) {
        console.log(error);
        return [];
    }
}*/

/*
value: word with the substring name of a company
limit: number max of result return
*/
export const inventorLoad = async (value, limit) => {
    if (value.length < 2) {
        return [];
    }
    try {
        const elemList = await inventorFetch(value, limit);
        return elemList.map((element, index) => {
            return {
                type: "default", label: element.label,
                labelShort: element.label,
                value: element.label
            };
        });
    } catch (error) {
        console.log(error);
        return [];
    }
}


/*
load logos for this list of organisation
*/
const inventorLogoFetch = (value) => new Promise((resolve, reject) => {
    const { ticket, shard, tokenNPL } = getSession(),
        { REACT_APP_OINNO_ROOT } = process.env,
        HeaderParam = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'application': 'Express',
                'Authorization': `Bearer ${tokenNPL}`
            },
            body: null
        },
        valueParam = value.map((element, index) => {
            return "ids[" + index + "]=" + element.id;
        }).join("&");
        if(valueParam.length<10){
            resolve([]);
        }
    console.log(REACT_APP_OINNO_ROOT + "/data-api/logo?" + valueParam);
    fetch(REACT_APP_OINNO_ROOT + "/data-api/logo?" + valueParam, HeaderParam)
        .then(res => res.json())
        .then((responseJson) => {
            resolve(responseJson);
        }).catch((error) => {
            reject({});
        });
}
);

/*
search the organisation
*/
const inventorFetch = (value, limit) => new Promise((resolve, reject) => {
    const { ticket, shard, tokenNPL } = getSession(),
        { REACT_APP_OINNO_ROOT } = process.env,
        HeaderParam = {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'application': 'Express',
                'Authorization': `Bearer ${tokenNPL}`
            },
            body: null
        };

    fetch(REACT_APP_OINNO_ROOT + "/public-data-api/orgunits?limit[max]=" + limit + "&q=" + encodeURIComponent(value), HeaderParam)
        .then(res => {
            const { status } = res; 
            if(status === 206) {
                setTimeout(
                    async () => resolve(await inventorFetch(value, limit)),
                    1000
                );
                return false; 
            }
            return res.json();
        })
        .then((responseJson) => {
            // Prevent resolving the promise for 206 status 
            if(!responseJson) {
                return; 
            }
            resolve(responseJson);
        }).catch((error) => {
            reject([]);
        });
}
);