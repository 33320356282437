import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PARSER from 'html-react-parser';
import Constante from '../Constante';
import SelectCustom from '../helpers/SelectCustom/SelectCustom.js';
import PophoverOrbit from '../helpers/Pophover/PophoverOrbit.js';
import AutoComplete from '../helpers/autocomplete/autocomplete.js';
import { DatePicker, Space } from 'antd';
import moment from 'moment';
import AvanceeParam from './rechercheavanceeparam/avanceeparam.js';
import { countryLoad } from '../helpers/filters/country.js';
import { synonymsLoad } from '../helpers/filters/synonyms.js';
import { conceptLoad } from '../helpers/filters/concept.js';
import { inventorLoad } from '../helpers/filters/inventor.js';
import './RechercheAvancee.css';
import "react-datepicker/dist/react-datepicker.css";


class RechercheAvancee extends Component {

    constructor(props) {
        super();

        this.state = {
            Requete_avancee: props.RequeteInfos.requete_avancee,
            History_aide_index: -1,
            qp_mode: true,
            Resize_all: false,
            synonymsLoaded:false,
            synonyms: [{
                labelVisible: true,
                label: "SUGGESTIONS",
                options: []
            }
            ],
            conceptList: [],
            inventorList: []
        }
        this.typeNPL = [{
            labelVisible: false,
            label: "",
            options: []
        }
        ];
        this.OFFICE_OPTIONS = [{
            labelVisible: true,
            label: "MOST POPULAR",
            options: []
        }
        ];
        this.Keyenterdown = Date.now();

    }


    static propTypes = {
        langue: PropTypes.array,
        affichage: PropTypes.object,
        RequeteInfosListe: PropTypes.array,
        RequeteInfos: PropTypes.object,
        RechercheInfo: PropTypes.object,
        changement_requete_avancee: PropTypes.func,
        changeDroiteRechercheAvancee: PropTypes.func,
        changeDroiteRechercheAvanceeClose: PropTypes.func,
        requeteHistoryAction: PropTypes.func
    };

    async componentDidMount() {
        this.addEventListener();
        this.loadListOfficeTYPENPL();
    }

    addEventListener = () => {
        window.addEventListener('resize', () => {
            if (window.innerWidth < 1365 && this.props.affichage.mode !== Constante.affichageMode.Recherche) {
                this.props.changeDroiteRechercheAvanceeClose();
            }
        }, false);
    }

    loadListOfficeTYPENPL = async () => {
        this.typeNPL = [{
            labelVisible: false,
            label: "",
            options: [
                { value: 't--scientific', label: this.props.langue[141].LIB, info: this.props.langue[137].LIB },
                { value: 't--project', label: this.props.langue[142].LIB, info: this.props.langue[138].LIB },
                { value: 't--trial', label: this.props.langue[143].LIB, info: this.props.langue[139].LIB },
                { value: 's--defensive_publication_1', label: this.props.langue[144].LIB, info: this.props.langue[140].LIB }]
        }
        ];

        this.OFFICE_OPTIONS = [{
            labelVisible: true,
            label: this.props.langue[134].LIB,
            options: [
                { label: 'WO - WIPO  World Intellectual Property Organization (PCT)', value: 'WO', labelShort: 'WO' },
                { label: 'US - United States of America', value: 'US', labelShort: 'US' },
                { label: 'EP - EPO European Patent Office', value: 'EP', labelShort: 'EP' },
                { label: 'FR - France', value: 'FR', labelShort: 'FR' },
                { label: 'DE - Germany', value: 'DE', labelShort: 'DE' },
                { label: 'GB - United Kingdom UK', value: 'GB', labelShort: 'GB' },
                { label: 'JP - Japan', value: 'JP', labelShort: 'JP' },
                { label: 'CN - China', value: 'CN', labelShort: 'CN' }
            ]
        },
        {
            labelVisible: true,
            label: this.props.langue[135].LIB,
            options: await countryLoad()
        }
        ];
    }


    conceptListLoad = async (value) => {
        const conceptList = await conceptLoad(value);
        this.setState({
            conceptList: [{
                labelVisible: false,
                label: "",
                options: conceptList
            }
            ]
        });
    }

    inventorListLoad = async (value) => {
        this.setState({
            inventorList: []
        });
        const inventorList = await inventorLoad(value, 5);
        this.setState({
            inventorList: [{
                labelVisible: false,
                label: "",
                options: inventorList
            }
            ]
        });
    }

    componentDidUpdate(oldProps, oldstate) {
        if (oldProps.RequeteInfos === null) {
            return;
        }
        if (oldProps.RequeteInfos.requete_avancee !== this.props.RequeteInfos.requete_avancee) {
            let L_requete_avancee = this.props.RequeteInfos.requete_avancee;
            L_requete_avancee.SCOPE = this.scope_create(L_requete_avancee, this.props.RechercheInfo);
            if (oldProps.RechercheInfo !== this.props.RechercheInfo && oldProps.RechercheInfo !== null && this.props.RechercheInfo !== null) {
                if (oldProps.RechercheInfo['EmplacementRecherche' + oldProps.RechercheInfo.Choix] !== this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix]) {
                    L_requete_avancee.FIELDS = this.set_fields_actif(L_requete_avancee.FIELDS, this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix]);
                    L_requete_avancee.SCOPE = '';
                }
            }
            this.setState({
                Requete_avancee: L_requete_avancee,
                qp_mode: true
            }, () => this.resizeAll());
        }
        else {
            if (oldProps.RechercheInfo !== this.props.RechercheInfo && oldProps.RechercheInfo !== null && this.props.RechercheInfo !== null) {
                if (oldProps.RechercheInfo['EmplacementRecherche' + oldProps.RechercheInfo.Choix] !== this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix]) {
                    let L_requete_avancee = this.props.RequeteInfos.requete_avancee;
                    L_requete_avancee.FIELDS = this.set_fields_actif(L_requete_avancee.FIELDS, this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix]);
                    L_requete_avancee.SCOPE = '';
                    this.setState({
                        Requete_avancee: L_requete_avancee
                    });
                }
            }
            else if (this.props.RequeteInfos.requete_avancee.SCOPE === "") {
                let L_requete_avancee = this.props.RequeteInfos.requete_avancee;
                L_requete_avancee.SCOPE = this.scope_create(L_requete_avancee, this.props.RechercheInfo);
                this.setState({
                    Requete_avancee: L_requete_avancee
                });

            }
            if (oldProps.affichage.mode !== this.props.affichage.mode) {
                this.resizeAll();
            }
            else if (this.state.Resize_all) {
                this.setState({
                    Resize_all: false
                });
                this.resizeAll();
            }
        }
    }

    synonymsLoad = async (emplacement, index0, index1, value) => {
        let synonymsOptions = (await synonymsLoad(value)).map((element) => {
            element.check = false;
            const elementFoundIndex = (emplacement === 0) ? this.state.Requete_avancee.TERMES_AND.LIST_ELEM[index0].indexOf(element.value) :
                this.state.Requete_avancee.TERMES_NOT.LIST_ELEM[index0].indexOf(element.value);
            if (elementFoundIndex >= 0 && elementFoundIndex !== index1) {
                element.check = true;
            }
            return element;
        });

        this.setState({
            synonymsLoaded:true,
            synonyms: [{
                labelVisible: true,
                label: "SUGGESTIONS",
                options: synonymsOptions
            }
            ]
        });
    }

    set_fields_actif = (fields, position) => {
        switch (position) {
            case Constante.EmplacementRecherche.NPL:
                fields[1].disabled = true;
                fields[1].options = fields[1].options.map(element => {
                    element.actif = false;
                    return element;
                });
                fields[2].disabled = false;
                fields[2].options = fields[2].options.map(element => {
                    element.actif = true;
                    return element;
                });
                break;
            default:
                fields[1].disabled = false;
                fields[1].options = fields[1].options.map(element => {
                    element.actif = true;
                    return element;
                });
                fields[2].disabled = true;
                fields[2].options = fields[2].options.map(element => {
                    element.actif = false;
                    return element;
                });
                break;
        }
        return fields;
    }

    history = () => {
        return this.props.RequeteInfosListe.map((P_value, P_index) => {
            if (P_value.RequeteInfos.requete_avancee.SCOPE.length < 70) {
                P_value.RequeteInfos.requete_avancee.SCOPE = this.scope_create(P_value.RequeteInfos.requete_avancee, P_value.RechercheInfo);
            }
            return (
                <div
                    key={"history_" + P_index.toString()}
                    className="D_history_elem_un"
                    onMouseEnter={() => this.history_set_index(P_index)}
                >
                    <div className="D_history_elem_un_entete">
                        <div className="D_history_elem_un_entete_gauche">
                            {this.props.langue[97].LIB + (this.props.RequeteInfosListe.length - P_index).toString() + " - " + P_value.RequeteInfos.requete_avancee.RESULTAT_NB + " " + this.props.langue[77].LIB}
                        </div>
                        <div className="D_history_elem_un_entete_droite">
                            {P_value.RequeteInfos.requete_avancee.DATE}
                        </div>
                    </div>
                    <div className="D_history_elem_un_corps">
                        <div className="D_history_elem_un_corps_action">
                            <div
                                className="D_history_elem_un_corps_action_view"
                                onClick={() => this.props.requeteHistoryAction(1, P_index, false)}
                            >{this.props.langue[75].LIB}</div>
                            <div
                                className="D_history_elem_un_corps_action_edit"
                                onClick={() => {
                                    this.historyAideDisplay(false);
                                    this.props.requeteHistoryAction(2, P_index, false);
                                }
                                }
                            >{this.props.langue[76].LIB}</div>
                        </div>
                        <p>{PARSER(P_value.RequeteInfos.requete_avancee.SCOPE)}</p>
                    </div>
                </div>
            )
        });
    }

    history_aide = () => {
        if (this.state.History_aide_index < 0 || this.props.RequeteInfosListe.length < 1) {
            return (
                <div id="D_recherche_avancee_simple_history_aide"></div>
            );
        }
        let L_requete_avancee = this.props.RequeteInfosListe[this.state.History_aide_index].RequeteInfos.requete_avancee;
        let L_rechercheInfo = this.props.RequeteInfosListe[this.state.History_aide_index].RechercheInfo;
        return (
            <div id="D_recherche_avancee_simple_history_aide">
                <div id="D_recherche_avancee_simple_history_aide_entete">
                    <div id="D_recherche_avancee_simple_history_aide_titre">
                        {this.props.langue[97].LIB + (this.props.RequeteInfosListe.length - this.state.History_aide_index).toString() + " - " +
                            L_requete_avancee.RESULTAT_NB + " " + this.props.langue[77].LIB}
                    </div>
                    <div id="D_recherche_avancee_simple_history_aide_date">
                        {L_requete_avancee.DATE}
                    </div>
                </div>
                <div id="D_recherche_avancee_simple_history_aide_corps">
                    <div id="D_recherche_avancee_simple_history_aide_corps_scope">
                        <div id="D_recherche_avancee_simple_history_aide_corps_scope_label" >{this.props.langue[71].LIB}</div>
                        <div id="D_recherche_avancee_simple_history_aide_corps_scope_info" >{PARSER(L_requete_avancee.SCOPE)}</div>
                    </div>
                    <div id="D_recherche_avancee_simple_history_aide_corps_elements">
                        {PARSER(this.hisoty_aide_create(L_requete_avancee, L_rechercheInfo))}
                    </div>
                </div>
            </div>
        );
    }

    hisoty_aide_create = (P_requete_avancee_temp, P_recherche_info_temp) => {
        let L_scope_texte = "";
        let L_and = "";
        if (P_requete_avancee_temp.SIMILARITE.length > 0) {
            if (P_recherche_info_temp.EmplacementRecherche1 === Constante.EmplacementRecherche.SIMILARITY) {
                L_scope_texte += ' <div className="D_history_aide_input" >' + this.props.langue[103].LIB + ': ' + P_requete_avancee_temp.SIMILARITE + '</div> ';
            }
            else {
                L_scope_texte += ' <div className="D_history_aide_input" >' + this.props.langue[104].LIB + ': ' + P_requete_avancee_temp.SIMILARITE + '</div> ';
            }
        }
        for (let L_parcours_and = 0; L_parcours_and < P_requete_avancee_temp.TERMES_AND.LIST_ELEM.length; L_parcours_and++) {
            let L_and_sous = "";
            for (let L_parcours_and_sous = 0; L_parcours_and_sous < P_requete_avancee_temp.TERMES_AND.LIST_ELEM[L_parcours_and].length; L_parcours_and_sous++) {
                if (P_requete_avancee_temp.TERMES_AND.LIST_ELEM[L_parcours_and][L_parcours_and_sous].length > 1) {
                    if (L_and_sous.length > 1) {
                        L_and_sous += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                    }
                    L_and_sous += P_requete_avancee_temp.TERMES_AND.LIST_ELEM[L_parcours_and][L_parcours_and_sous];
                }
            }
            if (L_and_sous.length > 1) {
                if (L_and.length > 1) {
                    L_and += ' <div className="D_history_aide_and" >' + this.props.langue[52].LIB + '</div> ';
                }
                L_and += '<div className="D_history_aide_input" >' + L_and_sous + '</div>';
            }
        }
        if (L_and.length > 1) {
            L_scope_texte += L_and;
        }
        L_and = "";
        for (let L_parcours_and = 0; L_parcours_and < P_requete_avancee_temp.TERMES_NOT.LIST_ELEM.length; L_parcours_and++) {
            let L_and_sous = "";
            for (let L_parcours_and_sous = 0; L_parcours_and_sous < P_requete_avancee_temp.TERMES_NOT.LIST_ELEM[L_parcours_and].length; L_parcours_and_sous++) {
                if (P_requete_avancee_temp.TERMES_NOT.LIST_ELEM[L_parcours_and][L_parcours_and_sous].length > 1) {
                    if (L_and_sous.length > 1) {
                        L_and_sous += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                    }
                    L_and_sous += P_requete_avancee_temp.TERMES_NOT.LIST_ELEM[L_parcours_and][L_parcours_and_sous];
                }
            }
            if (L_and_sous.length > 1) {
                if (L_and.length > 1) {
                    L_and += ' <div className="D_history_aide_and" >' + this.props.langue[52].LIB + '</div> ';
                }
                L_and += '<div className="D_history_aide_input" >' + L_and_sous + '</div>';
            }
        }
        if (L_and.length > 1) {
            L_scope_texte += '<div className="D_history_aide_input_exclude" >' +
                '<div className="D_history_aide_input_exclude_label" >' + this.props.langue[53].LIB + '</div>' +
                '<div className="D_history_aide_input_exclude_trait" ></div>' +
                '</div>' + L_and;
        }
        let L_scope_texte_other = "";
        for (let L_parcours_other = 0; L_parcours_other < P_requete_avancee_temp.OTHERS.length; L_parcours_other++) {
            switch (P_requete_avancee_temp.OTHERS[L_parcours_other].CODE) {
                case Constante.RequeteAvanceeCode.LEGAL:
                    if (P_recherche_info_temp.Choix === "2") {
                        continue;
                    }
                    if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[1].length > 1) {
                        L_scope_texte_other += '<div className="D_history_aide_input" > ' +
                            '<font className="F_scope_gris" >' + this.props.langue[88].LIB + "</font> " +
                            ' <font className="F_scope_bleu" >' + this.props.langue[82].LIB + "</font> " +
                            P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[1] + '</div>';
                    }
                    else if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[0].length > 1) {
                        L_scope_texte_other += '<div className="D_history_aide_input" > ' +
                            ' <font className="F_scope_gris" >' + this.props.langue[88].LIB + "</font> " +
                            ' <font className="F_scope_bleu" >' + this.props.langue[83].LIB + "</font> " +
                            P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[0] + '</div>';
                    }
                    break;
                case Constante.RequeteAvanceeCode.PUBLICATION_COUNTRY:
                    if (P_recherche_info_temp.Choix === "2") {
                        continue;
                    }
                    L_and = P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.map(function (P_office) {
                        return P_office;
                    }).join(' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ');
                    if (L_and.length > 1) {
                        L_scope_texte_other += '<div className="D_history_aide_input" > ' +
                            ' <font className="F_scope_bleu" >' + this.props.langue[85].LIB + "</font> " + L_and +
                            '</div>';
                    }
                    break;

                case Constante.RequeteAvanceeCode.TYPENPL:
                    const typeNPL = this.typeNPL;
                    if (P_recherche_info_temp.Choix === "1") {
                        continue;
                    }
                    L_and = P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.map(function (P_office) {
                        return typeNPL.find(element => element.id === P_office).name;
                    }).join(' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ');
                    if (L_and.length > 1) {
                        L_scope_texte_other += '<div className="D_history_aide_input" > ' +
                            ' <font className="F_scope_bleu" >' + this.props.langue[121].LIB + "</font> " + L_and +
                            '</div>';
                    }
                    break;
                case Constante.RequeteAvanceeCode.DATES:
                    L_and = "";
                    if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[0] !== null) {
                        L_and = " " + this.props.langue[37].LIB + " " + P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[0];
                    }
                    if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[1] !== null) {
                        L_and += " " + this.props.langue[38].LIB + " " + P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[1];
                    }
                    if (L_and.length > 4) {
                        let L_date_lib = "";

                        if (P_recherche_info_temp.Choix === "2") {
                            L_date_lib += this.props.langue[5].LIB;
                        }
                        else {
                            Object.keys(P_requete_avancee_temp.OTHERS[L_parcours_other].SEARCH_IN).map((P_key_2) => {
                                if (P_requete_avancee_temp.OTHERS[L_parcours_other].SEARCH_IN[P_key_2].check) {
                                    L_date_lib += P_requete_avancee_temp.OTHERS[L_parcours_other].SEARCH_IN[P_key_2].lib;
                                }
                                return true;
                            });
                        }
                        L_scope_texte_other += '<div className="D_history_aide_input" > ' +
                            ' <font className="F_scope_bleu" >' + L_date_lib + '</font>' + L_and +
                            '</div>';
                    }

                    break;
                case Constante.RequeteAvanceeCode.ASSIGNEE:
                    L_and = "";
                    if (P_recherche_info_temp.Choix === "2") {
                        continue;
                    }
                    for (let L_parcours_elem = 0; L_parcours_elem < P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.length; L_parcours_elem++) {
                        if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem].length > 1) {
                            if (L_and.length > 1) {
                                L_and += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                            }
                            L_and += P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem];
                        }
                    }
                    if (L_and.length > 1) {
                        L_scope_texte_other += '<div className="D_history_aide_input" > ' +
                            ' <font className="F_scope_bleu" >' + this.props.langue[86].LIB + "</font> " + L_and + '</div>';
                    }
                    break;
                case Constante.RequeteAvanceeCode.INVENTOR:
                    L_and = "";
                    if (P_recherche_info_temp.Choix === "2") {
                        continue;
                    }
                    for (let L_parcours_elem = 0; L_parcours_elem < P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.length; L_parcours_elem++) {
                        if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem].length > 1) {
                            if (L_and.length > 1) {
                                L_and += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                            }
                            L_and += P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem];
                        }
                    }
                    if (L_and.length > 1) {
                        L_scope_texte_other += '<div className="D_history_aide_input" > ' +
                            ' <font className="F_scope_bleu" >' + this.props.langue[87].LIB + "</font> " + L_and + '</div>';
                    }
                    break;
                case Constante.RequeteAvanceeCode.CLASSIFICATION:
                    L_and = "";
                    if (P_recherche_info_temp.Choix === "2") {
                        continue;
                    }
                    for (let L_parcours_elem = 0; L_parcours_elem < P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.length; L_parcours_elem++) {
                        if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem].length > 1) {
                            if (L_and.length > 1) {
                                L_and += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                            }
                            L_and += P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem];
                        }
                    }
                    if (L_and.length > 1) {
                        L_scope_texte_other += '<div className="D_history_aide_input" > ' +
                            ' <font className="F_scope_gris" >' + this.props.langue[88].LIB + '</font>' +
                            ' <font className="F_scope_bleu" >' + this.props.langue[89].LIB + "</font> " + L_and + '</div>';
                    }
                    break;
                case Constante.RequeteAvanceeCode.PN:
                    L_and = "";
                    if (P_recherche_info_temp.Choix === "2") {
                        continue;
                    }
                    for (let L_parcours_elem = 0; L_parcours_elem < P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.length; L_parcours_elem++) {
                        if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem].length > 1) {
                            if (L_and.length > 1) {
                                L_and += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                            }
                            L_and += P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem];
                        }
                    }
                    if (L_and.length > 1) {
                        L_scope_texte_other += '<div className="D_history_aide_input" > ' +
                            ' <font className="F_scope_gris" >' + this.props.langue[88].LIB + '</font> ' +
                            ' <font className="F_scope_bleu" >' + this.props.langue[90].LIB + "</font> " + L_and + '</div>';
                    }
                    break;
                case Constante.RequeteAvanceeCode.RDNUMBER:
                    L_and = "";
                    if (P_recherche_info_temp.Choix === "1") {
                        continue;
                    }
                    for (let L_parcours_elem = 0; L_parcours_elem < P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.length; L_parcours_elem++) {
                        if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem].length > 1) {
                            if (L_and.length > 1) {
                                L_and += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                            }
                            L_and += P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem];
                        }
                    }
                    if (L_and.length > 1) {
                        L_scope_texte_other += '<div className="D_history_aide_input" > ' +
                            ' <font className="F_scope_gris" >' + this.props.langue[88].LIB + '</font> ' +
                            ' <font className="F_scope_bleu" >' + this.props.langue[118].LIB + "</font> " + L_and + '</div>';
                    }
                    break;
                default:
                    break;
            }
        }
        if (L_scope_texte_other.length > 5) {
            L_scope_texte += '<div className="D_history_aide_input_exclude" >' +
                '<div className="D_history_aide_input_exclude_label" >' + this.props.langue[114].LIB + '</div>' +
                '<div className="D_history_aide_input_exclude_trait" ></div>' +
                '</div>' + L_scope_texte_other;
        }
        return L_scope_texte;
    }

    historyAideDisplay = (P_display) => {
        if (P_display && this.props.RequeteInfosListe.length > 0) {
            document.getElementById('D_recherche_avancee_simple_history_aide').style.display = 'initial';
        }
        else {
            document.getElementById('D_recherche_avancee_simple_history_aide').style.display = 'none';
        }
    }

    history_set_index = (P_index) => {
        this.setState({
            History_aide_index: P_index
        });
    }

    NouvelleRecherche = (P_search) => {
        this.setState({
            Requete_avancee: P_search,
            qp_mode: true
        });
    }

    onVisibleChangeSynonyms = (isVisible, changeOccured, emplacement, index0, index1, value) => {
        if (isVisible) {
            this.setState({
                synonymsLoaded:false,
                synonyms: [{
                    labelVisible: true,
                    label: "SUGGESTIONS",
                    options: []
                }
                ]
            });
            this.synonymsLoad(emplacement, index0, index1, value);
        }
        if (!isVisible && changeOccured ) {
            this.requeteSend();
        }
    }

    onClickSynonyms = (emplacement, index0, index1, groupOption, option, check) => {
        let requeteAvancee = this.state.Requete_avancee;
        let synonyms = this.state.synonyms;
        let listElem = this.state.Requete_avancee.TERMES_AND.LIST_ELEM;
        switch (emplacement) {
            case 0:
                listElem = this.state.Requete_avancee.TERMES_AND.LIST_ELEM;
                break;
            case 1:
                listElem = this.state.Requete_avancee.TERMES_NOT.LIST_ELEM;
                break;
            default:
                break;
        }
        const textElem = synonyms[groupOption].options[option].value;
        synonyms[groupOption].options[option].check = check;
        if (check) {
            listElem[index0][listElem[index0].length - 1] = textElem;
            listElem[index0].push('');
        }
        if (!check) {
            listElem[index0].splice(listElem[index0].indexOf(textElem), 1);
        }
        if (emplacement === 0 && index0 === 0 && index1 === 0 && !check) {
            requeteAvancee.SMART_SEARCH = "";
        }
        switch (emplacement) {
            case 0:
                requeteAvancee.TERMES_AND.LIST_ELEM = listElem;
                break;
            case 1:
                requeteAvancee.TERMES_NOT.LIST_ELEM = listElem;
                break;
            default:
                break;
        }

        requeteAvancee.SCOPE = this.scope_create(requeteAvancee, this.props.RechercheInfo);
        this.setState({
            Requete_avancee: requeteAvancee,
            synonyms: synonyms
        });
    }

    ElementNext = (P_index0, P_index1, P_emplacement) => {
        let lastTextarea = 1;
        switch (P_emplacement) {
            case 0:
                lastTextarea = this.state.Requete_avancee.TERMES_AND.LIST_ELEM[P_index0].length - 1;
                break;
            case 1:
                lastTextarea = this.state.Requete_avancee.TERMES_NOT.LIST_ELEM[P_index0].length - 1;
                break;
            default:
                lastTextarea = this.state.Requete_avancee.OTHERS[P_index0].LIST_ELEM.length - 1;
                break;
        }

        const element = document.getElementById("IT_avancee_requete_" + P_emplacement + "_" + P_index0 + "_" + (lastTextarea).toString());
        if (element !== null) {
            element.getElementsByTagName("textarea")[0].focus();
        }
    }

    requeteChange = (event, P_index0, P_index1, P_emplacement) => {

        let L_requete = null;
        let L_requete_avancee = this.state.Requete_avancee;
        let L_mode_qp = this.state.qp_mode;
        let L_resize_all = false;
        let L_lance_search = false;
        if (this.props.affichage.mode === Constante.affichageMode.Recherche) {
            L_mode_qp = false;
        }
        if (this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] !== Constante.EmplacementRecherche.NPL) {
            L_requete_avancee.NPLConcept = "";
        }
        switch (P_emplacement) {
            case 0:
            case 1:
                let L_value = '';
                if (typeof event !== 'string') {
                    if (event.target.value.length > 0) {
                        if (event.target.value.charCodeAt(event.target.value.length - 1) === 10) {
                            return true;
                        }
                    }

                    L_value = event.target.value.replace('*', '+').replace(/\n|\r\n|\r/g, '')
                }
                else {
                    L_value = event;
                }
                if (P_emplacement === 0) {
                    L_requete = this.state.Requete_avancee.TERMES_AND.LIST_ELEM;
                    if (P_index0 === 0 && P_index1 === 0) {
                        L_requete_avancee.SMART_SEARCH = "";
                    }
                }
                else {
                    L_requete = this.state.Requete_avancee.TERMES_NOT.LIST_ELEM;
                }
                if (P_index0 === 0 && P_index1 === 0 && P_emplacement === 0) {
                    L_mode_qp = false;
                }
                //let L_elem = document.getElementById("IT_avancee_requete_" + P_emplacement + "_" + P_index0 + '_' + P_index1);
                if (L_value.length === 0) {
                    L_requete[P_index0].splice(P_index1, 1);
                    if (P_index1 === 0 && L_requete[P_index0].length === 1 && L_requete.length > P_index0) {
                        L_requete.splice(P_index0, 1);
                    }
                    if (L_requete.length === 0) {
                        L_requete.push(['']);
                    }
                    if (L_requete.length > P_index0) {
                        if (L_requete[P_index0].length > 0) {
                            if (L_requete[P_index0][L_requete[P_index0].length - 1] != "") {
                                L_requete[P_index0].push('');
                            }
                        }
                    }
                    //L_elem.style.height = 'auto';
                    //L_elem.style.width = 'auto';
                    //L_resize_all = true;
                }
                else {
                    if (P_index1 + 1 >= L_requete[P_index0].length) {
                        L_requete[P_index0].push('');
                    }
                    L_requete[P_index0][P_index1] = L_value;
                    //this.resizeSearch("IT_avancee_requete_" + P_emplacement + "_" + P_index0 + '_' + P_index1, 'D_avancee_termes', L_value);
                }
                //L_elem.focus();
                if (P_emplacement === 0) {
                    L_requete_avancee.TERMES_AND.LIST_ELEM = L_requete;
                }
                else {
                    L_requete_avancee.TERMES_NOT.LIST_ELEM = L_requete;
                }

                break;
            case 3:
                L_requete = this.state.Requete_avancee.OTHERS[P_index0].LIST_ELEM;
                switch (this.state.Requete_avancee.OTHERS[P_index0].CODE) {
                    case Constante.RequeteAvanceeCode.LEGAL:
                        event = event ? event : '';
                        if (P_index1 === 0) {
                            L_requete[0] = event;
                            L_requete[1] = "";
                        }
                        if (P_index1 !== 0) {
                            L_requete[1] = event;
                        }
                        if (this.props.affichage.mode === Constante.affichageMode.rechercheavancee) {
                            L_lance_search = true;
                        }
                        break;
                    case Constante.RequeteAvanceeCode.TYPENPL:
                        L_requete = (event ? event : []);
                        L_lance_search = true;
                        break;
                    case Constante.RequeteAvanceeCode.PUBLICATION_COUNTRY:
                        L_requete = event;
                        if (this.props.affichage.mode === Constante.affichageMode.rechercheavancee) {
                            L_lance_search = true;
                        }
                        break;
                    case Constante.RequeteAvanceeCode.DATES:
                        L_requete[(P_index1 === 0) ? 0 : 1] = (event ? event.format('YYYY-MM-DD') : null);
                        if (event === null && this.props.affichage.mode === Constante.affichageMode.rechercheavancee) {
                            L_lance_search = true;
                        }
                        break;
                    case Constante.RequeteAvanceeCode.ASSIGNEE:
                    case Constante.RequeteAvanceeCode.INVENTOR:
                    case Constante.RequeteAvanceeCode.CLASSIFICATION:
                    case Constante.RequeteAvanceeCode.PN:
                    case Constante.RequeteAvanceeCode.RDNUMBER:
                        let L_termes = '';
                        if (typeof event !== 'string') {
                            if (event.target.value.length > 0) {
                                if (event.target.value.charCodeAt(event.target.value.length - 1) === 10) {
                                    return true;
                                }
                            }
                            L_termes = event.target.value.replace('*', '+').replace(/\n|\r\n|\r/g, '');
                        }
                        else {
                            L_termes = event;
                        }
                        if (L_termes.length === 0) {
                            L_requete.splice(P_index1, 1);
                            if (L_requete.length === 0) {
                                L_requete.push('');
                            }
                            if (L_requete[L_requete.length - 1] != "") {
                                L_requete.push('');
                            }
                            let L_elem = document.getElementById("IT_avancee_requete_" + P_emplacement + "_" + P_index0 + '_' + P_index1);
                            L_elem.style.height = 'auto';
                            L_elem.style.width = 'auto';
                            L_resize_all = true;

                        }
                        else {
                            if (P_index1 + 1 >= L_requete.length) {
                                L_requete.push('');
                            }
                            L_requete[P_index1] = L_termes;
                            this.resizeSearch("IT_avancee_requete_" + P_emplacement + "_" + P_index0 + '_' + P_index1, 'D_avancee_termes', L_termes);
                        }
                        break;
                    default:
                        break;
                }
                L_requete_avancee.OTHERS[P_index0].LIST_ELEM = L_requete;
                break;
            default:
                break;
        };

        L_requete_avancee.SCOPE = this.scope_create(L_requete_avancee, this.props.RechercheInfo);

        this.setState({
            Requete_avancee: L_requete_avancee,
            qp_mode: L_mode_qp,
            Resize_all: L_resize_all
        }, () => {
            this.requete_send(L_lance_search)
        });


    }

    requete_send = (P_send) => {
        if (P_send) {
            this.props.changement_requete_avancee(Constante.RequeteAvancee.valider, { REQUETE: this.state.Requete_avancee, QP_MODE: this.state.qp_mode });
        }
    }

    requeteSend = () => {
        if (this.props.affichage.mode === Constante.affichageMode.rechercheavancee) {
            this.props.changement_requete_avancee(Constante.RequeteAvancee.valider, { REQUETE: this.state.Requete_avancee, QP_MODE: this.state.qp_mode });
        }
    }

    /*
    * trigger occured when a key is pressed
    *
    */
    requete_key_press = (event) => {
        if (event.key === 'Enter') {
            //if (Date.now() - this.Keyenterdown < 500) {
                this.props.changement_requete_avancee(Constante.RequeteAvancee.valider, { REQUETE: this.state.Requete_avancee, QP_MODE: this.state.qp_mode });
            //}
            //this.Keyenterdown = Date.now();
        }
        return false;
    }

    onKeyDownTextArea = (event) => {
        if (event.key === 'Enter' && this.props.affichage.mode === Constante.affichageMode.rechercheavancee) {
            event.preventDefault();
            this.props.changement_requete_avancee(Constante.RequeteAvancee.valider, { REQUETE: this.state.Requete_avancee, QP_MODE: this.state.qp_mode });
            return false;
        }
        return true;
    }

    scope_create = (P_requete_avancee_temp, P_recherche_info) => {
        let L_scope_texte = "";
        let L_and = "";
        for (let L_parcours_and = 0; L_parcours_and < P_requete_avancee_temp.TERMES_AND.LIST_ELEM.length; L_parcours_and++) {
            let L_and_sous = "";
            for (let L_parcours_and_sous = 0; L_parcours_and_sous < P_requete_avancee_temp.TERMES_AND.LIST_ELEM[L_parcours_and].length; L_parcours_and_sous++) {
                if (P_requete_avancee_temp.TERMES_AND.LIST_ELEM[L_parcours_and][L_parcours_and_sous].length > 1) {
                    if (L_and_sous.length > 1) {
                        L_and_sous += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                    }
                    L_and_sous += P_requete_avancee_temp.TERMES_AND.LIST_ELEM[L_parcours_and][L_parcours_and_sous];
                }
            }
            if (L_and_sous.length > 1) {
                if (L_and.length > 1) {
                    L_and += ' <font className="F_scope_bleu" >' + this.props.langue[52].LIB + '</font> ';
                }
                /*if (L_and_sous.indexOf('F_scope_reduit') > 0) {
                     L_and_sous = ' <font className="F_scope_reduit" >( </font>' + L_and_sous + '<font className="F_scope_reduit" > )</font>';
                 }*/
                L_and += L_and_sous;
            }
        }
        if (L_and.length > 1) {
            L_scope_texte += ' <font className="F_scope_gris" >' + this.props.langue[78].LIB + "</font> " + L_and;
        }

        L_and = "";
        for (let L_parcours_and = 0; L_parcours_and < P_requete_avancee_temp.TERMES_NOT.LIST_ELEM.length; L_parcours_and++) {
            let L_and_sous = "";
            for (let L_parcours_and_sous = 0; L_parcours_and_sous < P_requete_avancee_temp.TERMES_NOT.LIST_ELEM[L_parcours_and].length; L_parcours_and_sous++) {
                if (P_requete_avancee_temp.TERMES_NOT.LIST_ELEM[L_parcours_and][L_parcours_and_sous].length > 1) {
                    if (L_and_sous.length > 1) {
                        L_and_sous += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                    }
                    L_and_sous += P_requete_avancee_temp.TERMES_NOT.LIST_ELEM[L_parcours_and][L_parcours_and_sous];
                }
            }
            if (L_and_sous.length > 1) {
                if (L_and.length > 1) {
                    L_and += ', <font className="F_scope_gris" >' + this.props.langue[79].LIB + '</font>' +
                        ' <font className="F_scope_bleu" >' + this.props.langue[80].LIB + "</font> " +
                        ' <font className="F_scope_gris" >' + this.props.langue[81].LIB + "</font>";
                }
                /*if (L_and_sous.indexOf('F_scope_reduit') > 0) {
                    L_and_sous = ' <font className="F_scope_reduit" >( </font>' + L_and_sous + '<font className="F_scope_reduit" > )</font>';
                }*/
                L_and += '&nbsp;' + L_and_sous;
            }
        }
        if (L_and.length > 1) {
            if (L_scope_texte.length > 5) {
                L_scope_texte += ',';
            }
            L_scope_texte += ' <font className="F_scope_bleu" >' + this.props.langue[80].LIB + "</font> " +
                ' <font className="F_scope_gris" >' + this.props.langue[81].LIB +
                '</font>' + L_and;
        }
        let L_scope_texte_other = "";
        for (let L_parcours_other = 0; L_parcours_other < P_requete_avancee_temp.OTHERS.length; L_parcours_other++) {
            switch (P_requete_avancee_temp.OTHERS[L_parcours_other].CODE) {
                case Constante.RequeteAvanceeCode.LEGAL:
                    if (this.props.RechercheInfo.Choix === "2") {
                        continue;
                    }
                    if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[1].length > 1) {
                        if (L_scope_texte_other.length > 5) {
                            L_scope_texte_other += ',';
                        }
                        L_scope_texte_other += ' <font className="F_scope_gris" >' + this.props.langue[88].LIB + "</font> " +
                            ' <font className="F_scope_bleu" >' + this.props.langue[82].LIB + "</font> " +
                            P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[1];
                    }
                    else if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[0].length > 1) {
                        if (L_scope_texte_other.length > 5) {
                            L_scope_texte_other += ',';
                        }
                        L_scope_texte_other += ' <font className="F_scope_gris" >' + this.props.langue[88].LIB + "</font> " +
                            ' <font className="F_scope_bleu" >' + this.props.langue[83].LIB + "</font> " +
                            P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[0];
                    }
                    break;
                case Constante.RequeteAvanceeCode.TYPENPL:
                    const typeNPL = this.typeNPL;
                    if (this.props.RechercheInfo.Choix === "1") {
                        continue;
                    }
                    L_and = P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.map(function (P_office) {
                        return typeNPL[0].options.find(element => element.value === P_office).label;
                    }).join(' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ');
                    if (L_and.length > 1) {
                        if (L_scope_texte_other.length > 5) {
                            L_scope_texte_other += ',';
                        }
                        /*if (L_and.indexOf('F_scope_reduit') > 0) {
                            L_and = ' <font className="F_scope_reduit" >( </font>' + L_and + '<font className="F_scope_reduit" > )</font>';
                        }*/
                        L_scope_texte_other += ' <font className="F_scope_bleu" >' + this.props.langue[121].LIB + "</font> " + L_and;
                    }
                    break;
                case Constante.RequeteAvanceeCode.PUBLICATION_COUNTRY:
                    if (this.props.RechercheInfo.Choix === "2") {
                        continue;
                    }
                    L_and = P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.map(function (P_office) {
                        return P_office;
                    }).join(' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ');
                    if (L_and.length > 1) {
                        if (L_scope_texte_other.length > 5) {
                            L_scope_texte_other += ',';
                        }
                        /*if (L_and.indexOf('F_scope_reduit') > 0) {
                            L_and = ' <font className="F_scope_reduit" >( </font>' + L_and + '<font className="F_scope_reduit" > )</font>';
                        }*/
                        L_scope_texte_other += ' <font className="F_scope_bleu" >' + this.props.langue[85].LIB + "</font> " + L_and;
                    }
                    break;
                case Constante.RequeteAvanceeCode.DATES:
                    L_and = "";
                    if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[0] !== null) {
                        L_and = " " + this.props.langue[37].LIB + " " + P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[0];
                    }
                    if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[1] !== null) {
                        L_and += " " + this.props.langue[38].LIB + " " + P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[1];
                    }
                    if (L_and.length > 4) {
                        let L_date_lib = "";
                        if (this.props.RechercheInfo.Choix === "2") {
                            L_date_lib += this.props.langue[5].LIB;
                        }
                        else {
                            Object.keys(P_requete_avancee_temp.OTHERS[L_parcours_other].SEARCH_IN).map((P_key_2) => {
                                if (P_requete_avancee_temp.OTHERS[L_parcours_other].SEARCH_IN[P_key_2].check) {
                                    L_date_lib += P_requete_avancee_temp.OTHERS[L_parcours_other].SEARCH_IN[P_key_2].lib;
                                }
                                return true;
                            });
                        }
                        if (L_scope_texte_other.length > 5) {
                            L_scope_texte_other += ',';
                        }
                        L_scope_texte_other += '<font className="F_scope_gris" >' + this.props.langue[88].LIB + this.props.langue[96].LIB + "</font> " +
                            ' <font className="F_scope_bleu" >' + L_date_lib + '</font>' + L_and;
                    }

                    break;
                case Constante.RequeteAvanceeCode.ASSIGNEE:
                    if (this.props.RechercheInfo.Choix === "2") {
                        continue;
                    }
                    L_and = "";
                    for (let L_parcours_elem = 0; L_parcours_elem < P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.length; L_parcours_elem++) {
                        if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem].length > 1) {
                            if (L_and.length > 1) {
                                L_and += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                            }
                            L_and += P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem];
                        }
                    }
                    if (L_and.length > 1) {
                        if (L_scope_texte_other.length > 5) {
                            L_scope_texte_other += ',';
                        }
                        /*if (L_and.indexOf('F_scope_reduit') > 0) {
                            L_and = ' <font className="F_scope_reduit" >( </font>' + L_and + '<font className="F_scope_reduit" > )</font>';
                        }*/
                        L_scope_texte_other += ' <font className="F_scope_bleu" >' + this.props.langue[86].LIB + "</font> " + L_and;
                    }
                    break;
                case Constante.RequeteAvanceeCode.INVENTOR:
                    if (this.props.RechercheInfo.Choix === "2") {
                        continue;
                    }
                    L_and = "";
                    for (let L_parcours_elem = 0; L_parcours_elem < P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.length; L_parcours_elem++) {
                        if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem].length > 1) {
                            if (L_and.length > 1) {
                                L_and += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                            }
                            L_and += P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem];
                        }
                    }
                    if (L_and.length > 1) {
                        if (L_scope_texte_other.length > 5) {
                            L_scope_texte_other += ',';
                        }
                        /*if (L_and.indexOf('F_scope_reduit') > 0) {
                            L_and = ' <font className="F_scope_reduit" >( </font>' + L_and + '<font className="F_scope_reduit" > )</font>';
                        }*/
                        L_scope_texte_other += ' <font className="F_scope_bleu" >' + this.props.langue[87].LIB + "</font> " + L_and;
                    }
                    break;
                case Constante.RequeteAvanceeCode.CLASSIFICATION:
                    if (this.props.RechercheInfo.Choix === "2") {
                        continue;
                    }
                    L_and = "";
                    for (let L_parcours_elem = 0; L_parcours_elem < P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.length; L_parcours_elem++) {
                        if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem].length > 1) {
                            if (L_and.length > 1) {
                                L_and += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                            }
                            L_and += P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem];
                        }
                    }
                    if (L_and.length > 1) {
                        if (L_scope_texte_other.length > 5) {
                            L_scope_texte_other += ',';
                        }
                        /*if (L_and.indexOf('F_scope_reduit') > 0) {
                            L_and = ' <font className="F_scope_reduit" >( </font>' + L_and + '<font className="F_scope_reduit" > )</font>';
                        }*/
                        L_scope_texte_other += ' <font className="F_scope_gris" >' + this.props.langue[88].LIB + '</font>' +
                            ' <font className="F_scope_bleu" >' + this.props.langue[89].LIB + "</font> " + L_and;
                    }
                    break;
                case Constante.RequeteAvanceeCode.PN:
                    if (this.props.RechercheInfo.Choix === "2") {
                        continue;
                    }
                    L_and = "";
                    for (let L_parcours_elem = 0; L_parcours_elem < P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.length; L_parcours_elem++) {
                        if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem].length > 1) {
                            if (L_and.length > 1) {
                                L_and += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                            }
                            L_and += P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem];
                        }
                    }
                    if (L_and.length > 1) {
                        if (L_scope_texte_other.length > 5) {
                            L_scope_texte_other += ',';
                        }
                        /*if (L_and.indexOf('F_scope_reduit') > 0) {
                            L_and = ' <font className="F_scope_reduit" >( </font>' + L_and + '<font className="F_scope_reduit" > )</font>';
                        }*/
                        L_scope_texte_other += ' <font className="F_scope_gris" >' + this.props.langue[88].LIB + '</font> ' +
                            ' <font className="F_scope_bleu" >' + this.props.langue[90].LIB + "</font> " + L_and;
                    }
                    break;

                case Constante.RequeteAvanceeCode.RDNUMBER:
                    if (this.props.RechercheInfo.Choix === "1") {
                        continue;
                    }
                    L_and = "";
                    for (let L_parcours_elem = 0; L_parcours_elem < P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM.length; L_parcours_elem++) {
                        if (P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem].length > 1) {
                            if (L_and.length > 1) {
                                L_and += ' <font className="F_scope_reduit" >' + this.props.langue[1].LIB + '</font> ';
                            }
                            L_and += P_requete_avancee_temp.OTHERS[L_parcours_other].LIST_ELEM[L_parcours_elem];
                        }
                    }
                    if (L_and.length > 1) {
                        if (L_scope_texte_other.length > 5) {
                            L_scope_texte_other += ',';
                        }
                        /*if (L_and.indexOf('F_scope_reduit') > 0) {
                            L_and = ' <font className="F_scope_reduit" >( </font>' + L_and + '<font className="F_scope_reduit" > )</font>';
                        }*/
                        L_scope_texte_other += ' <font className="F_scope_bleu" >' + this.props.langue[120].LIB + "</font> " + L_and;
                    }
                    break;
                default:
                    break;
            }
        }
        if (L_scope_texte_other.length > 5) {
            let L_limit_patents_brevets = this.props.langue[95].LIB;
            if (P_recherche_info.Choix === "2") {
                L_limit_patents_brevets = this.props.langue[117].LIB;
            }
            L_scope_texte += '<br/>' +
                '<font className="F_scope_gris" >' + L_limit_patents_brevets + '</font> ' + L_scope_texte_other;
        }
        let L_in = "";
        let L_in_brevet_npl = this.props.langue[94].LIB;
        if (P_recherche_info !== null) {
            if (P_recherche_info['EmplacementRecherche' + P_recherche_info.Choix] !== Constante.EmplacementRecherche.FAMPAT) {
                switch (P_recherche_info['EmplacementRecherche' + P_recherche_info.Choix]) {
                    case Constante.EmplacementRecherche.NPL:
                        L_in = "";
                        L_in_brevet_npl = this.props.langue[105].LIB;
                        switch (P_recherche_info.EmplacementRecherche1) {
                            case Constante.EmplacementRecherche.SEMANTIQUE:
                                L_in = " " + this.props.langue[101].LIB + ": " + P_requete_avancee_temp.SEMANTIQUE;
                                L_scope_texte = "";
                                break;
                            case Constante.EmplacementRecherche.SIMILARITY:
                                L_in = " " + this.props.langue[103].LIB + " : " + P_requete_avancee_temp.SIMILARITE;
                                break;
                            case Constante.EmplacementRecherche.PRIOR_ART:
                                L_in = " " + this.props.langue[104].LIB + " : " + P_requete_avancee_temp.SIMILARITE;
                                break;
                            default:
                                break;
                        }
                        break;
                    case Constante.EmplacementRecherche.LIST:
                        L_in = " " + this.props.langue[100].LIB + " " + this.props.langue[99].LIB + " " + this.props.langue[92].LIB + ": " + P_recherche_info.NomRecherche;
                        break;
                    case Constante.EmplacementRecherche.WORKFILE:
                        L_in = " " + this.props.langue[100].LIB + " " + this.props.langue[98].LIB + " " + this.props.langue[93].LIB + ": " + P_recherche_info.NomRecherche;
                        break;
                    case Constante.EmplacementRecherche.SEMANTIQUE:
                        L_in = " " + this.props.langue[101].LIB + ": " + P_requete_avancee_temp.SEMANTIQUE;
                        L_scope_texte = "";
                        break;
                    case Constante.EmplacementRecherche.SIMILARITY:
                        L_in = " " + this.props.langue[103].LIB + " : " + P_requete_avancee_temp.SIMILARITE;
                        break;
                    case Constante.EmplacementRecherche.PRIOR_ART:
                        L_in = " " + this.props.langue[104].LIB + " : " + P_requete_avancee_temp.SIMILARITE;
                        break;
                    default:
                        break;
                }
            }
        }
        if (L_in.length > 0) {
            L_scope_texte = ' <font className="F_scope_gris" >' + L_in_brevet_npl + '</font>' + L_in + " " + L_scope_texte;
        }
        else {
            L_scope_texte = ' <font className="F_scope_gris" >' + L_in_brevet_npl + "</font> " + L_scope_texte;
        }
        return L_scope_texte;
    }

    selectElementAnd = (event, P_key, P_id, P_pos) => {
        let L_requete_avancee = this.state.Requete_avancee;

        let L_mode_qp = this.state.qp_mode;
        if (this.props.affichage.mode === Constante.affichageMode.Recherche) {
            L_mode_qp = false;
        }
        switch (P_id) {
            case "1":
                L_requete_avancee.TERMES_AND.SEARCH_IN[P_key].check = !L_requete_avancee.TERMES_AND.SEARCH_IN[P_key].check;
                break;
            case "3":
                Object.keys(L_requete_avancee.OTHERS[P_pos].SEARCH_IN).forEach((L_key) => {
                    L_requete_avancee.OTHERS[P_pos].SEARCH_IN[L_key].check = false;
                })
                L_requete_avancee.OTHERS[P_pos].SEARCH_IN[P_key].check = true;
                break;
            case "4":
                Object.keys(L_requete_avancee.OTHERS[P_pos].SEARCH_IN[0].options).forEach((L_key) => {
                    L_requete_avancee.OTHERS[P_pos].SEARCH_IN[0].options[L_key].check = false;
                    if (L_requete_avancee.OTHERS[P_pos].SEARCH_IN[0].options[L_key].value === P_key) {
                        L_requete_avancee.OTHERS[P_pos].SEARCH_IN[0].options[L_key].check = true;
                    }
                })
                break;
            default:
                break;

        }
        L_requete_avancee.SCOPE = this.scope_create(L_requete_avancee, this.props.RechercheInfo);
        this.setState({
            Requete_avancee: L_requete_avancee,
            qp_mode: L_mode_qp
        });
        this.setState({
            Requete_avancee: L_requete_avancee,
            qp_mode: L_mode_qp
        });
        if (event.domEvent !== null && typeof event.domEvent !== 'undefined') {
            event.domEvent.stopPropagation();
            event.domEvent.preventDefault();
        }
    }

    SendRequeteAavancee = () => {
        this.props.changement_requete_avancee(Constante.RequeteAvancee.valider, { REQUETE: this.state.Requete_avancee, QP_MODE: this.state.qp_mode });
    }

    CancelRequeteAavancee = () => {
        this.props.changement_requete_avancee(Constante.RequeteAvancee.cancel, {});
    }

    requete_semantique_modif = () => {
        let L_requete_avancee = this.state.Requete_avancee;
        L_requete_avancee.SEMANTIQUE = document.getElementById('T_search_semanique').value;
        let L_recherche_info = JSON.parse(JSON.stringify(this.props.RechercheInfo));
        //L_recherche_info.EmplacementRecherche1 = Constante.EmplacementRecherche.SEMANTIQUE;
        L_requete_avancee.SCOPE = this.scope_create(L_requete_avancee, L_recherche_info);
        this.setState({
            Requete_avancee: L_requete_avancee
        });
        //this.props.changement_requete_avancee(Constante.RequeteAvancee.requete_semantique, { semantique: document.getElementById('T_search_semanique').value });
    }

    requete_key_press_semantique = (event) => {
        if (event.key === 'Enter') {
            if (document.getElementById('T_search_semanique').value.length > 1) {
                this.props.changement_requete_avancee(Constante.RequeteAvancee.validerSemantique, { REQUETE: this.state.Requete_avancee });
            }
            event.stopPropagation();
            event.preventDefault();
            return false;
        }
    }

    resizeAll = () => {
    }

    resizeSearchSemantique = (P_nom_div, P_nom_parent) => {
        let L_elem = document.getElementById(P_nom_div);
        if (L_elem === null) {
            return;
        }
        if (L_elem.value.length < 1) {
            // L_elem.style.width = '115px';
            L_elem.style.height = 'auto';
            return;
        }
        L_elem.style.height = 'auto';
        L_elem.style.height = Math.max(22, L_elem.scrollHeight) + 'px';
    }

    resizeSearch = (P_nom_div, P_nom_parent, textvalue) => {
        let L_elem = document.getElementById(P_nom_div);
        if (L_elem === null) {
            return;
        }
        const text = (textvalue !== null && typeof (textvalue) !== "undefined") ? textvalue :
            ((L_elem.value === null || typeof L_elem.value === "undefined") && L_elem.parentNode.attributes.value !== null) ? L_elem.parentNode.attributes.value.value : L_elem.value;

        if (text.length < 1) {
            L_elem.style.width = '150px';
            L_elem.style.height = 'auto';
            return;
        }
        let L_parent_width = document.getElementsByClassName(P_nom_parent)[0].clientWidth;
        let L_width_elem = parseInt(getComputedStyle(L_elem).fontSize);

        let L_span = document.getElementById('S_mesure_texte');
        L_span.innerHTML = text;
        L_width_elem = L_span.offsetWidth * 1.02;
        if (L_width_elem + 20 < L_parent_width - 70) {
            let L_witdh_avance = L_width_elem;
            L_elem.style.width = (L_witdh_avance + 20) + 'px';
            let L_iteration = 0;
            while (L_iteration < 20 && L_elem.clientWidth < L_width_elem && L_witdh_avance + 20 < L_parent_width - 70) {
                L_witdh_avance += 20;
                L_iteration++;
                L_elem.style.width = (L_witdh_avance) + 'px';
            }
        }
        else {
            L_elem.style.width = (L_parent_width - 70) + 'px';
        }
        // set the height to 0 in case of it has to be shrinked
        L_elem.style.height = 'auto';
        L_elem.style.height = Math.max(22, L_elem.scrollHeight) + 'px';
    }

    selectMoreFields = (event, posField, posOption, value) => {
        event.preventDefault();
        const L_key = posField.toString() + '_' + posOption.toString();
        let L_relance_search = false;
        let L_requete_avancee = this.state.Requete_avancee;
        let L_trouve = -1;
        let L_code_recu = this.state.Requete_avancee.FIELDS[posField].options[posOption].value;

        let L_mode_qp = this.state.qp_mode;
        if (this.props.affichage.mode === Constante.affichageMode.Recherche) {
            L_mode_qp = false;
        }
        for (let L_parcours_other = 0; L_parcours_other < L_requete_avancee.OTHERS.length; L_parcours_other++) {
            if (L_requete_avancee.OTHERS[L_parcours_other].CODE === L_code_recu) {
                L_trouve = L_parcours_other;
                break;
            }
        }
        L_requete_avancee.FIELDS[posField].options[posOption].check = !L_requete_avancee.FIELDS[posField].options[posOption].check;
        if (L_trouve >= 0) {
            switch (L_code_recu) {
                case Constante.RequeteAvanceeCode.DATES:
                    if (L_requete_avancee.OTHERS[L_trouve].LIST_ELEM[0] !== null || L_requete_avancee.OTHERS[L_trouve].LIST_ELEM[1] !== null) {
                        L_relance_search = true;
                    }
                    break;
                case Constante.RequeteAvanceeCode.ASSIGNEE:
                    if (L_requete_avancee.OTHERS[L_trouve].LIST_ELEM[0].length > 0) {
                        L_relance_search = true;
                    }
                    break;
                case Constante.RequeteAvanceeCode.INVENTOR:
                case Constante.RequeteAvanceeCode.RDNUMBER:
                    if (L_requete_avancee.OTHERS[L_trouve].LIST_ELEM[0].length > 0) {
                        L_relance_search = true;
                    }
                    break;
                case Constante.RequeteAvanceeCode.PN:
                    if (L_requete_avancee.OTHERS[L_trouve].LIST_ELEM[0].length > 0) {
                        L_relance_search = true;
                    }
                    break;
                case Constante.RequeteAvanceeCode.TYPENPL:
                    if (L_requete_avancee.OTHERS[L_trouve].LIST_ELEM.length > 0) {
                        L_relance_search = true;
                    }
                    break;
                case Constante.RequeteAvanceeCode.PUBLICATION_COUNTRY:
                    if (L_requete_avancee.OTHERS[L_trouve].LIST_ELEM.length > 0) {
                        L_relance_search = true;
                    }
                    break;
                case Constante.RequeteAvanceeCode.LEGAL:
                    if (L_requete_avancee.OTHERS[L_trouve].LIST_ELEM[0].length > 1 || L_requete_avancee.OTHERS[L_trouve].LIST_ELEM[1].length > 1) {
                        L_relance_search = true;
                    }
                    break;
                case Constante.RequeteAvanceeCode.CLASSIFICATION:
                    if (L_requete_avancee.OTHERS[L_trouve].LIST_ELEM[0].length > 0) {
                        L_relance_search = true;
                    }
                    break;
                default:
                    break;

            }
            L_requete_avancee.OTHERS.splice(L_trouve, 1);
        }
        else {
            switch (L_code_recu) {
                case Constante.RequeteAvanceeCode.DATES:
                    L_requete_avancee.OTHERS.push({
                        ID: L_key,
                        CODE: Constante.RequeteAvanceeCode.DATES,
                        SEARCH_IN: [
                            {
                                labelVisible: false,
                                label: "",
                                options: [
                                    { check: true, label: this.props.langue[5].LIB, value: 'PD' },
                                    { check: false, label: this.props.langue[60].LIB, value: 'APD' },
                                    { check: false, label: this.props.langue[4].LIB, value: 'PRD' },
                                    { check: false, label: this.props.langue[115].LIB, value: 'EPRD' }
                                ]
                            }
                        ],
                        LIST_ELEM: [null, null]
                    });
                    break;
                case Constante.RequeteAvanceeCode.ASSIGNEE:
                    L_requete_avancee.OTHERS.push({
                        ID: L_key,
                        CODE: Constante.RequeteAvanceeCode.ASSIGNEE,
                        SEARCH_IN: {
                            elem1: { check: true, lib: this.props.langue[8].LIB, code: 'PA/OPA' },
                        },
                        LIST_ELEM: ['']
                    });
                    break;
                case Constante.RequeteAvanceeCode.INVENTOR:
                    L_requete_avancee.OTHERS.push({
                        ID: L_key,
                        CODE: Constante.RequeteAvanceeCode.INVENTOR,
                        SEARCH_IN: {
                            elem1: { check: true, lib: this.props.langue[7].LIB, code: 'IN/OIN/INH/INV' },
                        },
                        LIST_ELEM: ['']
                    });
                    break;
                case Constante.RequeteAvanceeCode.PN:
                    L_requete_avancee.OTHERS.push({
                        ID: L_key,
                        CODE: Constante.RequeteAvanceeCode.PN,
                        SEARCH_IN: {
                            elem1: { check: true, lib: this.props.langue[64].LIB, code: 'PN/XPN' },
                        },
                        LIST_ELEM: ['']
                    });
                    break;
                case Constante.RequeteAvanceeCode.RDNUMBER:
                    L_requete_avancee.OTHERS.push({
                        ID: L_key,
                        CODE: Constante.RequeteAvanceeCode.RDNUMBER,
                        SEARCH_IN: {
                            elem1: { check: true, lib: this.props.langue[118].LIB, code: 'AB' },
                        },
                        LIST_ELEM: ['']
                    });
                    break;
                case Constante.RequeteAvanceeCode.TYPENPL:
                    L_requete_avancee.OTHERS.push({
                        ID: L_key,
                        CODE: Constante.RequeteAvanceeCode.TYPENPL,
                        SEARCH_IN: {
                            elem1: { check: true, lib: this.props.langue[119].LIB, code: 'typeNPL' },
                        },
                        LIST_ELEM: []
                    });
                    break;
                case Constante.RequeteAvanceeCode.PUBLICATION_COUNTRY:
                    L_requete_avancee.OTHERS.push({
                        ID: L_key,
                        CODE: Constante.RequeteAvanceeCode.PUBLICATION_COUNTRY,
                        SEARCH_IN: {
                            elem1: { check: true, lib: this.props.langue[9].LIB, code: 'PN' },
                        },
                        LIST_ELEM: []
                    });
                    break;
                case Constante.RequeteAvanceeCode.LEGAL:
                    L_requete_avancee.OTHERS.push({
                        ID: L_key,
                        CODE: Constante.RequeteAvanceeCode.LEGAL,
                        SEARCH_IN: {
                            elem1: { check: true, lib: this.props.langue[65].LIB, code: 15 },
                        },
                        LIST_ELEM: [' ', ' ']
                    });
                    break;
                case Constante.RequeteAvanceeCode.CLASSIFICATION:
                    L_requete_avancee.OTHERS.push({
                        ID: L_key,
                        CODE: Constante.RequeteAvanceeCode.CLASSIFICATION,
                        SEARCH_IN: {
                            elem1: { check: true, lib: this.props.langue[68].LIB, code: 'IPC/CPC' },
                            elem2: { check: false, lib: this.props.langue[66].LIB, code: 'IPC' },
                            elem3: { check: false, lib: this.props.langue[67].LIB, code: 'CPC' },
                        },
                        LIST_ELEM: ['']
                    });
                    break;
                default:
                    break;

            }
        }
        this.setState({
            Requete_avancee: L_requete_avancee,
            qp_mode: L_mode_qp
        }, () => {
            if (L_relance_search) {
                this.requeteSend();
            }
        });
        event.stopPropagation();
    }

    ShowFields = (P_pos) => {
        const posfield = parseInt(this.state.Requete_avancee.OTHERS[P_pos].ID.split('_')[0]),
            posOption = parseInt(this.state.Requete_avancee.OTHERS[P_pos].ID.split('_')[1]);
        if (this.state.Requete_avancee.FIELDS[posfield].options[posOption].visible === false) {
            return;
        }
        switch (this.state.Requete_avancee.OTHERS[P_pos].CODE) {
            case Constante.RequeteAvanceeCode.DATES:
                const valueSelect = this.state.Requete_avancee.OTHERS[P_pos].SEARCH_IN[0].options.find(x => x.check);
                return (
                    <div key={"AVANCEE_GLOBAL_" + P_pos}
                        className={this.state.Requete_avancee.FIELDS[posfield].options[posOption].actif ? "D_avancee_field" : "D_avancee_field inactif"}
                    >
                        <div className="D_avancee_termes">
                            <div id="D_avancee_date_select">
                                <span
                                    id="S_avancee_date_select_npl"
                                    style={this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.NPL ?
                                        {} : { display: 'none' }} >
                                    {this.props.langue[5].LIB}
                                </span>
                                {this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] !== Constante.EmplacementRecherche.NPL &&
                                    <SelectCustom
                                        size="small"
                                        onChange={(P_val) => this.selectElementAnd("", P_val, '4', P_pos)}
                                        itemSelected={valueSelect ? [valueSelect.value] : []}
                                        defaultValue={valueSelect ? [valueSelect.value] : []}
                                        options={this.state.Requete_avancee.OTHERS[P_pos].SEARCH_IN}
                                        placeHolder={this.props.langue[136].LIB}
                                        multiple={false}
                                        itemCreate={false}
                                        defaultColor={false}
                                        allowClear={false}
                                    />}
                            </div>
                            <div id="D_avancee_date_dates">
                                <div id="D_avancee_date_debut">
                                    <div id="S_avancee_date_avant">{this.props.langue[37].LIB}</div>
                                    <DatePicker
                                        disabledDate={d => !d || d.isAfter(moment().format("YYYY-MM-DD")) || d.isSameOrBefore("1750-01-01")}
                                        suffixIcon={null}
                                        id="ID_avancee_date_debut"
                                        placeholder={this.props.langue[116].LIB}
                                        onChange={(event, dateString) => this.requeteChange(event, P_pos, 0, 3)}
                                        format={["YYYY/MM/DD", "DD/MM/YYYY", "YYYY-MM-DD", "DD-MM-YYYY", "YYYY MM DD", "DD MM YYYY"]}
                                        value={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0] === null ? null : moment(this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0].replace(/-/gi, '/'), "YYYY/MM/DD")}
                                    />
                                </div>
                                <div id="S_avancee_date_tiret" ></div>
                                <div id="D_avancee_date_fin">
                                    <div id="S_avancee_date_apres">{this.props.langue[38].LIB}</div>
                                    <DatePicker
                                        disabledDate={d => !d || d.isAfter(moment().format("YYYY-MM-DD")) || d.isSameOrBefore("1750-01-01")}
                                        suffixIcon={null}
                                        id="ID_avancee_date_debut"
                                        placeholder={this.props.langue[116].LIB}
                                        onChange={(event) => this.requeteChange(event, P_pos, 1, 3)}
                                        format={["YYYY/MM/DD", "DD/MM/YYYY", "YYYY-MM-DD", "DD-MM-YYYY", "YYYY MM DD", "DD MM YYYY"]}
                                        value={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[1] === null ? null : moment(this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[1].replace(/-/gi, '/'), "YYYY/MM/DD")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case Constante.RequeteAvanceeCode.ASSIGNEE:
            case Constante.RequeteAvanceeCode.INVENTOR:
            case Constante.RequeteAvanceeCode.CLASSIFICATION:
            case Constante.RequeteAvanceeCode.PN:
            case Constante.RequeteAvanceeCode.RDNUMBER:
                if (this.props.RechercheInfo.Choix === "2" && this.props.affichage.mode !== Constante.affichageMode.rechercheavancee &&
                    this.state.Requete_avancee.OTHERS[P_pos].CODE !== Constante.RequeteAvanceeCode.RDNUMBER) {
                    return;
                }
                if (this.props.RechercheInfo.Choix === "1" && this.props.affichage.mode !== Constante.affichageMode.rechercheavancee &&
                    this.state.Requete_avancee.OTHERS[P_pos].CODE === Constante.RequeteAvanceeCode.RDNUMBER) {
                    return;
                }
                let L_label_texte = '';
                switch (this.state.Requete_avancee.OTHERS[P_pos].CODE) {
                    case Constante.RequeteAvanceeCode.ASSIGNEE:
                        L_label_texte = '+ ' + this.props.langue[8].LIB;
                        break;
                    case Constante.RequeteAvanceeCode.INVENTOR:
                        L_label_texte = '+ ' + this.props.langue[7].LIB;
                        break;
                    case Constante.RequeteAvanceeCode.CLASSIFICATION:
                        L_label_texte = '+ ' + this.props.langue[50].LIB;
                        break;
                    case Constante.RequeteAvanceeCode.PN:
                        L_label_texte = '+ ' + this.props.langue[64].LIB;
                        break;
                    case Constante.RequeteAvanceeCode.RDNUMBER:
                        L_label_texte = '+ ' + this.props.langue[118].LIB;
                        break;
                    case Constante.RequeteAvanceeCode.TYPENPL:
                        L_label_texte = '+ ' + this.props.langue[119].LIB;
                        break;
                    default:
                        break;
                }
                /* if (this.state.Requete_avancee.FIELDS[posfield].options[posOption].actif === false) {
                     if (this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0] === '') {
                         return;
                     }
                 }


                 */
                return (
                    <div key={"AVANCEE_GLOBAL_" + P_pos}
                        className={this.state.Requete_avancee.FIELDS[posfield].options[posOption].actif ? "D_avancee_field" : "D_avancee_field inactif"}
                    >
                        <div className="D_avancee_termes">
                            {
                                this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM.map((P_requete2, P_index1) => {
                                    return (
                                        <div className="I_avancee_d" key={'REQUETE_3_' + P_pos + '_' + P_index1}>
                                            <span
                                                //id={"S_avancee_requete_or_" + P_index1}
                                                className="S_avancee_or"
                                                style={P_index1 > 0 ? {} : { display: 'none' }}
                                            >{this.props.langue[1].LIB}
                                            </span>
                                            <div
                                                className={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[P_index1].length > 0 ? "I_avancee_d_infos rempli" : "I_avancee_d_infos vide"}
                                                value={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[P_index1]}
                                            >
                                                <textarea
                                                    rows='1'
                                                    type="text"
                                                    id={"IT_avancee_requete_3_" + P_pos + "_" + P_index1}
                                                    className="I_avancee"
                                                    placeholder={P_index1 === 0 ? L_label_texte.substring(2) : L_label_texte}
                                                    onChange={(event) => this.requeteChange(event, P_pos, P_index1, 3)}
                                                    value={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[P_index1]}
                                                    onKeyDown={this.onKeyDownTextArea}
                                                    data-resizable="true"
                                                ></textarea>
                                                <div
                                                    className="D_avancee_croix"
                                                    onClick={() => this.termedelete(P_pos, P_index1, 3)}
                                                    style={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[P_index1].length > 0 ? {} : { display: 'none' }}
                                                ></div>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                        <div
                            className="D_avancee_global_param"
                            style={(Object.keys(this.state.Requete_avancee.OTHERS[P_pos].SEARCH_IN).length > 1 && this.props.affichage.mode === Constante.affichageMode.Recherche) ? {} : { display: 'none' }}
                        >
                            <AvanceeParam
                                langue={this.props.langue}
                                titre={L_label_texte.substring(2)}
                                code="radio"
                                idfield="3"
                                posField={P_pos}
                                menuText=""
                                menuId="D_avanceeparam_icon"
                                element={this.state.Requete_avancee.OTHERS[P_pos].SEARCH_IN}
                                selectElement={this.selectElementAnd}
                            />
                        </div>
                    </div>
                );
            case Constante.RequeteAvanceeCode.TYPENPL:
                if (this.props.RechercheInfo.Choix === "1" && this.props.affichage.mode !== Constante.affichageMode.rechercheavancee) {
                    return;
                }
                /*if (this.state.Requete_avancee.FIELDS[posfield].options[posOption].actif === false) {
                    if (this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM.length < 1) {
                        return;
                    }
                }*/
                return (
                    <div key={"AVANCEE_GLOBAL_" + P_pos}
                        className={this.state.Requete_avancee.FIELDS[posfield].options[posOption].actif ? "D_avancee_field" : "D_avancee_field inactif"}
                    >

                        <div className="D_avancee_termes">
                            <div className="SO_avancee_type_doc" >
                                <SelectCustom
                                    onBlur={(P_val) => this.requeteChange(P_val, P_pos, 0, 3)}
                                    onDeselect={(P_val) => this.requeteChange(P_val, P_pos, 0, 3)}
                                    itemSelected={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM}
                                    options={this.typeNPL}
                                    placeHolder={this.props.langue[136].LIB}
                                    multiple={true}
                                    itemCreate={false}
                                    defaultColor={true}
                                    allowClear={true}
                                    showArrow={true}
                                />
                            </div>
                        </div>
                        <div
                            className="D_avancee_global_param"
                            style={(Object.keys(this.state.Requete_avancee.OTHERS[P_pos].SEARCH_IN).length > 1 && this.props.affichage.mode === Constante.affichageMode.Recherche) ? {} : { display: 'none' }}
                        >
                            <AvanceeParam
                                langue={this.props.langue}
                                titre={this.props.langue[69].LIB}
                                code="radio"
                                idfield="3"
                                posField={P_pos}
                                menuText=""
                                menuId="D_avanceeparam_icon"
                                element={this.state.Requete_avancee.OTHERS[P_pos].SEARCH_IN}
                                selectElement={this.selectElementAnd}
                            />
                        </div>
                    </div >
                );
            case Constante.RequeteAvanceeCode.PUBLICATION_COUNTRY:
                if (this.props.RechercheInfo.Choix === "2" && this.props.affichage.mode !== Constante.affichageMode.rechercheavancee) {
                    return;
                }
                /*if (this.state.Requete_avancee.FIELDS[posfield].options[posOption].actif === false) {
                    if (this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM.length < 1) {
                        return;
                    }
                }*/
                return (
                    <div key={"AVANCEE_GLOBAL_" + P_pos}
                        className={this.state.Requete_avancee.FIELDS[posfield].options[posOption].actif ? "D_avancee_field" : "D_avancee_field inactif"}
                    >

                        <div className="D_avancee_termes">
                            <div className="SO_avancee_patent_office" >
                                <SelectCustom
                                    onBlur={(P_val) => this.requeteChange(P_val, P_pos, 0, 3)}
                                    onDeselect={(P_val) => this.requeteChange(P_val, P_pos, 0, 3)}
                                    itemSelected={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM}
                                    //onKeyDown={this.requete_key_press}
                                    options={this.OFFICE_OPTIONS}
                                    placeHolder={this.props.langue[9].LIB}
                                    multiple={true}
                                    itemCreate={true}
                                    defaultColor={false}
                                    allowClear={true}
                                />
                            </div>
                        </div>
                        <div
                            className="D_avancee_global_param"
                            style={(Object.keys(this.state.Requete_avancee.OTHERS[P_pos].SEARCH_IN).length > 1 && this.props.affichage.mode === Constante.affichageMode.Recherche) ? {} : { display: 'none' }}
                        >
                            <AvanceeParam
                                langue={this.props.langue}
                                titre={this.props.langue[69].LIB}
                                code="radio"
                                idfield="3"
                                posField={P_pos}
                                menuText=""
                                menuId="D_avanceeparam_icon"
                                element={this.state.Requete_avancee.OTHERS[P_pos].SEARCH_IN}
                                selectElement={this.selectElementAnd}
                            />
                        </div>
                    </div>
                );
            case Constante.RequeteAvanceeCode.LEGAL:
                if (this.props.RechercheInfo.Choix === "2" && this.props.affichage.mode !== Constante.affichageMode.rechercheavancee) {
                    return;
                }
                const etat = this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0].length > 1 ? [this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0]] : ['ALIVE', 'DEAD'],
                    status = [
                        { label: this.props.langue[23].LIB, value: 'GRANTED', type: 'ALIVE' },
                        { label: this.props.langue[26].LIB, value: 'PENDING', type: 'ALIVE' },
                        { label: this.props.langue[35].LIB, value: 'EXPIRED', type: 'DEAD' },
                        { label: this.props.langue[24].LIB, value: 'LAPSED', type: 'DEAD' },
                        { label: this.props.langue[34].LIB, value: 'REVOKED', type: 'DEAD' }
                    ].filter((x) => {
                        return (typeof etat.find(y => y === x.type) === 'undefined') ? false : true;
                    });
                /*if (this.state.Requete_avancee.FIELDS[posfield].options[posOption].actif === false) {
                    if (this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0] === ' ' && this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[1] === ' ') {
                        return;
                    }
                }*/

                return (
                    <div key={"AVANCEE_GLOBAL_" + P_pos}
                        className={this.state.Requete_avancee.FIELDS[posfield].options[posOption].actif ? "D_avancee_field" : "D_avancee_field inactif"}
                    >

                        <div className="D_avancee_termes">
                            <SelectCustom
                                onChange={(P_val) => this.requeteChange(P_val, P_pos, 0, 3)}
                                // itemSelected={[this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0]]}
                                //value={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0].length > 1 ? [this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0]] : []}
                                //onKeyDown={this.requete_key_press}
                                itemSelected={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0] === " " ? undefined : this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0]}
                                options={[
                                    {
                                        labelVisible: false,
                                        label: '',
                                        options: [
                                            {
                                                label: this.props.langue[27].LIB, value: 'ALIVE', disabled: (this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.WORKFILE) ? true : false
                                            },
                                            { label: this.props.langue[28].LIB, value: 'DEAD' }
                                        ]
                                    }
                                ]}
                                placeHolder={this.props.langue[25].LIB}
                                multiple={false}
                                itemCreate={false}
                                defaultColor={false}
                                allowClear={true}
                            />

                            <SelectCustom
                                onChange={(P_val) => this.requeteChange(P_val, P_pos, 1, 3)}
                                //itemSelected={[this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[1]]}
                                //value={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[1].length > 1 ? [this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[1]] : []}
                                itemSelected={this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0] === " " ? undefined : this.state.Requete_avancee.OTHERS[P_pos].LIST_ELEM[0]}
                                options={[
                                    {
                                        labelVisible: false,
                                        label: '',
                                        options: status
                                    }
                                ]}
                                placeHolder={this.props.langue[22].LIB}
                                multiple={false}
                                itemCreate={false}
                                defaultColor={false}
                                allowClear={true}
                            />
                        </div>
                        <div
                            className="D_avancee_global_param"
                            style={(Object.keys(this.state.Requete_avancee.OTHERS[P_pos].SEARCH_IN).length > 1 && this.props.affichage.mode === Constante.affichageMode.Recherche) ? {} : { display: 'none' }}
                        >
                            <AvanceeParam
                                langue={this.props.langue}
                                titre={this.props.langue[69].LIB}
                                code="radio"
                                idfield="3"
                                posField={P_pos}
                                menuText=""
                                menuId="D_avanceeparam_icon"
                                element={this.state.Requete_avancee.OTHERS[P_pos].SEARCH_IN}
                                selectElement={this.selectElementAnd}
                            />
                        </div>
                    </div>
                );
            default:
                break;
        }
    }

    termesAdd = () => {
        let L_mode_qp = this.state.qp_mode;
        if (this.props.affichage.mode === Constante.affichageMode.Recherche) {
            L_mode_qp = false;
        }
        let L_requete_avancee = this.state.Requete_avancee;
        L_requete_avancee.TERMES_AND.LIST_ELEM.push(['']);
        this.setState({
            Requete_avancee: L_requete_avancee,
            qp_mode: L_mode_qp
        });
    }

    termesAndDeleteRow = (P_index) => {
        let L_mode_qp = this.state.qp_mode;
        if (this.props.affichage.mode === Constante.affichageMode.Recherche) {
            L_mode_qp = false;
        }
        let L_requete_avancee = this.state.Requete_avancee;
        L_requete_avancee.TERMES_AND.LIST_ELEM.splice(P_index, 1);
        L_requete_avancee.SCOPE = this.scope_create(L_requete_avancee, this.props.RechercheInfo);
        this.setState({
            Requete_avancee: L_requete_avancee,
            qp_mode: L_mode_qp
        });
    }

    termesNot = () => {
        let L_mode_qp = this.state.qp_mode;
        if (this.props.affichage.mode === Constante.affichageMode.Recherche) {
            L_mode_qp = false;
        }
        let L_requete_avancee = this.state.Requete_avancee;
        L_requete_avancee.TERMES_NOT.LIST_ELEM.push(['']);
        this.setState({
            Requete_avancee: L_requete_avancee,
            qp_mode: L_mode_qp
        });
    }

    termedelete = (P_index0, P_index1, P_emplacement) => {
        this.requeteChange('', P_index0, P_index1, P_emplacement);
        setTimeout(() => this.requeteSend(), 500);
    }

    termesNotDeleteRow = (P_index) => {
        let L_mode_qp = this.state.qp_mode;
        if (this.props.affichage.mode === Constante.affichageMode.Recherche) {
            L_mode_qp = false;
        }
        let L_requete_avancee = this.state.Requete_avancee;
        L_requete_avancee.TERMES_NOT.LIST_ELEM.splice(P_index, 1);
        L_requete_avancee.SCOPE = this.scope_create(L_requete_avancee, this.props.RechercheInfo);
        this.setState({
            Requete_avancee: L_requete_avancee,
            qp_mode: L_mode_qp
        });
    }


    render() {
        const L_mode_semantique = this.props.RequeteInfos.choixRecherche === 2
            || this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.SEMANTIQUE;

        let L_termes_and_trouve = false;
        if (this.props.RequeteInfos.requete_avancee.TERMES_AND.LIST_ELEM.length > 0) {
            if (this.props.RequeteInfos.requete_avancee.TERMES_AND.LIST_ELEM[0].length > 0) {
                L_termes_and_trouve = true;
            }
        }
        return (
            <div id="D_recherche_avancee"
                onKeyDown={L_mode_semantique === true ? this.requete_key_press_semantique : this.requete_key_press}
                tabIndex="1"
            >
                <span id="S_mesure_texte"></span>
                <div id="D_recherche_avancee_semantique" style={L_mode_semantique === true ? {} : { display: 'none' }}>
                    <span id="S_search_semantique_lab">{this.props.langue[46].LIB}</span><br />
                    <textarea
                        id="T_search_semanique"
                        value={this.props.RequeteInfos.requete_avancee.SEMANTIQUE}
                        onChange={this.requete_semantique_modif}
                        placeholder={this.props.langue[45].LIB}
                        onKeyUp={() => this.resizeSearchSemantique('T_search_semanique')}
                        onKeyPress={this.requete_key_press_semantique}
                        data-resizable="true"
                    ></textarea><br />
                </div>
                <div id="D_recherche_avancee_simple" style={L_mode_semantique === false ? {} : { display: 'none' }}>
                    <div id="D_recherche_avancee_simple_entete">
                        <span id="S_recherche_avancee_simple_entete_adv">{this.props.langue[0].LIB}</span>
                    </div>
                    <div id="D_recherche_avancee_simple_scope_search_history">
                        <div id="D_recherche_avancee_simple_scope_search">
                            {this.history_aide()}
                            <div id="D_recherche_avancee_simple_scope">
                                <div id="D_recherche_avancee_simple_scope_label" >{this.props.langue[71].LIB}</div>
                                <div id="D_recherche_avancee_simple_scope_infos" >{PARSER(this.state.Requete_avancee.SCOPE)}</div>
                            </div>
                            <div id="D_recherche_avancee_simple_search">
                                <div id="D_recherche_avancee_simple_search_criteres">
                                    <span id="S_recherche_avancee_simple"
                                        style={this.props.affichage.mode === Constante.affichageMode.Recherche ? { display: 'none' } : {}}>
                                        {this.props.langue[33].LIB}
                                    </span>
                                    <div id="D_recherche_avancee_simple_search_similarite"
                                        style={this.state.Requete_avancee.SIMILARITE.length > 0 ? {} : { display: 'none' }}>
                                        <div id="D_recherche_avancee_simple_search_similarite_cadre">
                                            {this.props.RechercheInfo.EmplacementRecherche1 === Constante.EmplacementRecherche.SIMILARITY ? this.props.langue[103].LIB : this.props.langue[104].LIB}
                                            <div id="D_recherche_avancee_simple_search_similarite_liste">
                                                {this.state.Requete_avancee.SIMILARITE}
                                            </div>
                                        </div>
                                        {this.state.Requete_avancee.TERMES_AND.LIST_ELEM.length > 0 ? this.props.langue[52].LIB : ''}
                                    </div>
                                    {
                                        this.state.Requete_avancee.TERMES_AND.LIST_ELEM.map((P_requete, P_index0) => {
                                            return (
                                                <div key={'REQUETE_' + P_index0} className="D_avancee_and_global">
                                                    <div className="D_avancee_and" >
                                                        <div className="D_avancee_termes">
                                                            {
                                                                this.state.Requete_avancee.TERMES_AND.LIST_ELEM[P_index0].map((P_requete2, P_index1) => {
                                                                    let L_place_holder_and = '';
                                                                    if (this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.FAMPAT ||
                                                                        this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.NPL ||
                                                                        this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.SIMILARITY ||
                                                                        this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.PRIOR_ART) {
                                                                        if (P_index1 === 0) {
                                                                            L_place_holder_and = this.props.langue[47].LIB;
                                                                        }
                                                                        else {
                                                                            L_place_holder_and = '+ ' + this.props.langue[55].LIB;
                                                                        }
                                                                    } else {
                                                                        L_place_holder_and = this.props.langue[40].LIB + this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix]
                                                                    }
                                                                    return (
                                                                        <div className="I_avancee_d" key={'REQUETE_' + P_index0 + '_' + P_index1}>
                                                                            <span
                                                                                //id={"S_avancee_requete_or_" + P_index0 + '_' + P_index1}
                                                                                className="S_avancee_or"
                                                                                style={P_index1 > 0 ? {} : { display: 'none' }}
                                                                            >{this.props.langue[1].LIB}
                                                                            </span>
                                                                            <div
                                                                                className={this.state.Requete_avancee.TERMES_AND.LIST_ELEM[P_index0][P_index1].length > 0 ? "I_avancee_d_infos rempli" : "I_avancee_d_infos vide"}
                                                                                value={this.state.Requete_avancee.TERMES_AND.LIST_ELEM[P_index0][P_index1]}
                                                                            >
                                                                                {(this.state.Requete_avancee.TERMES_AND.LIST_ELEM[P_index0][P_index1].length > 1) &&
                                                                                    (
                                                                                        <div className="D_avancee_synonyms" >
                                                                                            <PophoverOrbit
                                                                                                text=""
                                                                                                image="/images/plus-circle.svg"
                                                                                                options={this.state.synonyms}
                                                                                                loaded={this.state.synonymsLoaded}
                                                                                                selectAllEnable={true}
                                                                                                deselectAllEnable={true}
                                                                                                defaultColor={false}
                                                                                                placement="bottomLeft"
                                                                                                onClick={(event, index, subindex, check) => this.onClickSynonyms(0, P_index0, P_index1, index, subindex, check)}
                                                                                                onVisibleChange={(isVisible, changeOccured) => this.onVisibleChangeSynonyms(isVisible, changeOccured, 0, P_index0, P_index1, this.state.Requete_avancee.TERMES_AND.LIST_ELEM[P_index0][P_index1])}
                                                                                            />
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                <AutoComplete
                                                                                    onChange={(P_val) => this.requeteChange(P_val, P_index0, P_index1, 0)}
                                                                                    value={this.state.Requete_avancee.TERMES_AND.LIST_ELEM[P_index0][P_index1]}
                                                                                    options={this.state.conceptList}
                                                                                    placeholder={L_place_holder_and}
                                                                                    id={"IT_avancee_requete_0_" + P_index0 + '_' + P_index1}
                                                                                    itemCreate={true}
                                                                                    defaultColor={true}
                                                                                    getOptions={this.conceptListLoad}
                                                                                    ElementNext={() => this.ElementNext(P_index0, P_index1, 0)}
                                                                                    validate={() => this.requete_send(true)}
                                                                                />
                                                                                <div
                                                                                    className="D_avancee_croix"
                                                                                    onClick={() => this.termedelete(P_index0, P_index1, 0)}
                                                                                    style={this.state.Requete_avancee.TERMES_AND.LIST_ELEM[P_index0][P_index1].length > 0 ? {} : { display: 'none' }}
                                                                                >
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                        <div
                                                            className="D_avancee_and_param"
                                                            style={(P_index0 === 0 && this.props.affichage.mode === Constante.affichageMode.Recherche) ? {} : { display: 'none' }}
                                                        >
                                                            <AvanceeParam
                                                                langue={this.props.langue}
                                                                titre={this.props.langue[70].LIB}
                                                                code="checkbox"
                                                                idfield="1"
                                                                posField={-1}
                                                                menuText=""
                                                                menuId="D_avanceeparam_icon"
                                                                element={this.state.Requete_avancee.TERMES_AND.SEARCH_IN}
                                                                selectElement={this.selectElementAnd}
                                                            />
                                                        </div>
                                                        <div
                                                            className="D_avancee_and_poubelle"
                                                            onClick={() => this.termesAndDeleteRow(P_index0)}
                                                            style={(P_index0 > 0 && this.props.affichage.mode === Constante.affichageMode.Recherche) ? {} : { display: 'none' }}
                                                        ></div>
                                                    </div>
                                                    <div
                                                        className="D_avancee_and_terme"
                                                        style={P_index0 < this.state.Requete_avancee.TERMES_AND.LIST_ELEM.length - 1 ? {} : { display: 'none' }}>
                                                        {this.props.langue[52].LIB}
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                    <div
                                        id="D_search_terme_add_exclude"
                                        style={this.state.Requete_avancee.TERMES_NOT.LIST_ELEM.length < 1 ? {} : { display: 'none' }}
                                    >
                                        <span id="S_search_terme_exclude" onClick={() => this.termesNot()} >&nbsp;{this.props.langue[53].LIB}</span>
                                        <div id="S_search_terme_add_exclude_barre" >|</div>
                                        <span id="S_search_terme_add" onClick={() => this.termesAdd()}>{this.props.langue[51].LIB}&nbsp;</span>
                                    </div>
                                    <div
                                        id="D_search_terme_add_only"
                                        style={this.state.Requete_avancee.TERMES_NOT.LIST_ELEM.length > 0 ? {} : { display: 'none' }}
                                    >
                                        <span id="S_search_terme_add_only_add" onClick={() => this.termesAdd()} >{this.props.langue[51].LIB}</span>
                                        <div id="D_search_terme_add_only_exclude">
                                            <div id="D_search_terme_add_only_exclude_label">{this.props.langue[53].LIB}</div>
                                            <div id="D_search_terme_add_only_exclude_trait"></div>
                                        </div>
                                    </div>
                                    <div id="D_avancee_termes_exclude" style={this.state.Requete_avancee.TERMES_NOT.LIST_ELEM.length > 0 ? {} : { display: 'none' }}>
                                        {
                                            this.state.Requete_avancee.TERMES_NOT.LIST_ELEM.map((P_requete, P_index0) => {
                                                return (
                                                    <div key={'REQUETE_NOT_' + P_index0} className="D_avancee_and_global" >
                                                        <div className="D_avancee_and" >
                                                            <div className="D_avancee_termes">
                                                                {
                                                                    this.state.Requete_avancee.TERMES_NOT.LIST_ELEM[P_index0].map((P_requete2, P_index1) => {
                                                                        let L_place_holder_and = '';
                                                                        if (this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.FAMPAT ||
                                                                            this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.NPL ||
                                                                            this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.SIMILARITY ||
                                                                            this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.PRIOR_ART) {
                                                                            if (P_index1 === 0) {
                                                                                L_place_holder_and = this.props.langue[47].LIB;
                                                                            }
                                                                            else {
                                                                                L_place_holder_and = '+ ' + this.props.langue[55].LIB;
                                                                            }
                                                                        } else {
                                                                            L_place_holder_and = this.props.langue[40].LIB + this.props.RechercheInfo.EmplacementRecherche1
                                                                        }
                                                                        return (
                                                                            <div className="I_avancee_d" key={'REQUETE_NOT_' + P_index0 + '_' + P_index1}>
                                                                                <span
                                                                                    id={"S_avancee_requete_not_or_" + P_index0 + '_' + P_index1}
                                                                                    className="S_avancee_or"
                                                                                    style={P_index1 > 0 ? {} : { display: 'none' }}
                                                                                >{this.props.langue[1].LIB}
                                                                                </span>
                                                                                <div
                                                                                    className={this.state.Requete_avancee.TERMES_NOT.LIST_ELEM[P_index0][P_index1].length > 0 ? "I_avancee_d_infos rempli" : "I_avancee_d_infos vide"}
                                                                                    value={this.state.Requete_avancee.TERMES_NOT.LIST_ELEM[P_index0][P_index1]}
                                                                                >
                                                                                    {(this.state.Requete_avancee.TERMES_NOT.LIST_ELEM[P_index0][P_index1].length > 1) &&
                                                                                        (
                                                                                            <div className="D_avancee_synonyms" >
                                                                                                <PophoverOrbit
                                                                                                    text=""
                                                                                                    image="image"
                                                                                                    options={this.state.synonyms}
                                                                                                    loaded={this.state.synonymsLoaded}
                                                                                                    selectAllEnable={true}
                                                                                                    deselectAllEnable={true}
                                                                                                    defaultColor={false}
                                                                                                    placement="bottomLeft"
                                                                                                    onClick={(event, index, subindex, check) => this.onClickSynonyms(1, P_index0, P_index1, index, subindex, check)}
                                                                                                    onVisibleChange={(isVisible, changeOccured) => this.onVisibleChangeSynonyms(isVisible, changeOccured, 1, P_index0, P_index1, this.state.Requete_avancee.TERMES_NOT.LIST_ELEM[P_index0][P_index1])}
                                                                                                />
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    <AutoComplete
                                                                                        onChange={(P_val) => this.requeteChange(P_val, P_index0, P_index1, 1)}
                                                                                        itemSelected={this.state.Requete_avancee.TERMES_NOT.LIST_ELEM[P_index0][P_index1]}
                                                                                        value={this.state.Requete_avancee.TERMES_NOT.LIST_ELEM[P_index0][P_index1]}
                                                                                        options={this.state.conceptList}
                                                                                        placeholder={L_place_holder_and}
                                                                                        id={"IT_avancee_requete_1_" + P_index0 + '_' + P_index1}
                                                                                        itemCreate={true}
                                                                                        defaultColor={true}
                                                                                        getOptions={this.conceptListLoad}
                                                                                        ElementNext={() => this.ElementNext(P_index0, P_index1, 1)}
                                                                                        validate={() => this.requete_send(true)}
                                                                                    />
                                                                                    <div
                                                                                        className="D_avancee_croix"
                                                                                        onClick={() => this.termedelete(P_index0, P_index1, 1)}
                                                                                        style={this.state.Requete_avancee.TERMES_NOT.LIST_ELEM[P_index0][P_index1].length > 0 ? {} : { display: 'none' }}
                                                                                    >
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })
                                                                }
                                                            </div>
                                                            <div
                                                                style={this.props.affichage.mode === Constante.affichageMode.Recherche ? {} : { display: 'none' }}
                                                                className="D_avancee_and_poubelle"
                                                                onClick={() => this.termesNotDeleteRow(P_index0)}></div>
                                                        </div>
                                                        <div
                                                            className="D_avancee_not_terme"
                                                            style={P_index0 < this.state.Requete_avancee.TERMES_NOT.LIST_ELEM.length - 1 ? {} : { display: 'none' }}>
                                                            {this.props.langue[52].LIB}
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                    <div
                                        id="D_search_terme_exclude_only"
                                        style={this.state.Requete_avancee.TERMES_NOT.LIST_ELEM.length > 0 ? {} : { display: 'none' }}
                                    >
                                        <span id="S_search_terme_exclude_only_exclude" onClick={() => this.termesNot()} >{this.props.langue[53].LIB}</span>
                                    </div>

                                    <span id="S_recherche_avancee_simple_fields"
                                        style={this.props.affichage.mode === Constante.affichageMode.Recherche ? { display: 'none' } : {}}>
                                        {this.props.langue[2].LIB}
                                    </span>
                                    {
                                        this.state.Requete_avancee.OTHERS.map((P_infos, P_index) => {
                                            return this.ShowFields(P_index);
                                        })
                                    }
                                    <div
                                        id="D_search_terme_NPL_not_available_label"
                                        style={(this.props.affichage.mode === Constante.affichageMode.rechercheavancee &&
                                            this.state.Requete_avancee.NPL_OTHER_FIELDS === true &&
                                            this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.NPL) ? {} : { display: 'none' }}
                                    >
                                        {this.props.langue[102].LIB}
                                    </div>
                                    <div
                                        id="D_search_terme_NPL_not_available_label"
                                        style={(this.props.affichage.mode === Constante.affichageMode.rechercheavancee &&
                                            this.state.Requete_avancee.PATENT_OTHER_FIELDS === true &&
                                            this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] !== Constante.EmplacementRecherche.NPL) ? {} : { display: 'none' }}
                                    >
                                        {this.props.langue[122].LIB}
                                    </div>
                                </div>
                                <div id="D_avancee_action">
                                    <span
                                        id="B_avancee_cancel"
                                        disabled={this.props.RequeteInfos.changementCancel === true ? false : true}
                                        onClick={() => this.CancelRequeteAavancee()}
                                    >{this.props.langue[63].LIB}</span>
                                    <div className="D_avancee_morefields" >
                                        <PophoverOrbit
                                            text={this.props.langue[73].LIB}
                                            options={this.state.Requete_avancee.FIELDS}
                                            onClick={this.selectMoreFields}
                                        />
                                    </div>
                                    <div
                                        id="D_avancee_valider"
                                        style={this.props.affichage.mode === Constante.affichageMode.Recherche ? {} : { display: 'none' }}
                                        /* disabled={(this.props.RequeteInfos.ChangementDetecte === true &&
                                             ((L_termes_and_trouve ||
                                                 this.props.RequeteInfos.inventeur.length > 0 ||
                                                 this.props.RequeteInfos.deposant.length > 0) ||
                                                 (this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.LIST ||
                                                     this.props.RechercheInfo['EmplacementRecherche' + this.props.RechercheInfo.Choix] === Constante.EmplacementRecherche.WORKFILE))) ? false : true}*/
                                        onClick={() => this.SendRequeteAavancee()}
                                    >
                                        <img id="I_avancee_valider" alt="search" src="./images/recherche_blanche.png" />
                                        {this.props.langue[29].LIB}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="D_recherche_avancee_simple_history">
                            <div id="D_recherche_avancee_simple_history_label">
                                {this.props.langue[72].LIB}
                            </div>
                            <div
                                onMouseEnter={() => this.historyAideDisplay(true)}
                                onMouseLeave={() => this.historyAideDisplay(false)}
                                id="D_recherche_avancee_simple_history_liste">
                                {this.history()}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default RechercheAvancee;