
import { getSession } from '../../utils/auth.js'

export const countryLoad = async () => {
    try {
        const countryList = await countryFetch();
        return countryList.map((element, index) => {
            return { label: element.id + ' - ' + element.label, labelShort: element.id, value: element.id };
        });
    } catch (error) {
        return [];
    }
}

const countryFetch = () => new Promise((resolve, reject) => {
    const { tokenNPL } = getSession(),
        { REACT_APP_OINNO_ROOT }      = process.env,
        HeaderParam = {
            method: 'get',
            headers: {
                'Content-Type' : 'application/json',
                'application'  : 'Express',
                'Authorization': `Bearer ${tokenNPL}`
            },
            body: null
        };

    fetch(REACT_APP_OINNO_ROOT + "/public-api/knowledges?type=countries", HeaderParam)
    .then(res => {
        const { status } = res; 
        if(status === 206) {
            setTimeout(
                async () => resolve(await countryFetch()),
                1000
            );
            return false; 
        }
        if(status === 204) {
            return [];
        }
        return res.json();
    })
    .then((responseJson) => {
        // Prevent resolving the promise for 206 status 
        if(!responseJson) {
            return; 
        }
        resolve(responseJson);
    }).catch((error) => {
        reject([]);
    });
});