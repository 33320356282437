import React, { Component } from 'react';
import './MesAlertes.css';
import PropTypes from 'prop-types';
import Constante from '../Constante';
import AlertMenu from './AlertMenu';
import DialogAlert from './DialogAlert';
import AjaxGestion from '../AjaxGestion';


class MesAlertes extends Component {

  constructor() {
    super()

    this.dialogAlert = React.createRef();
    this.menuAlert = React.createRef();
    this.state = {
      etat: Constante.etat.encours,
      alertData: null,
      alertIndex: -1,
      alertName: '',
      alertPeriod: '',
      dialogType: 0,
      nbWatch: 0,
      dialogHidden: '',
      watch_list: '',
    }
  }

  static propTypes = {
    ticket: PropTypes.string.isRequired,
    shard: PropTypes.string.isRequired,
    langue: PropTypes.array,
    user: PropTypes.object,
    setwatchingListId: PropTypes.func
  }


  componentDidMount() {
    this.alertCheckWatchingList();
  }

  createAlertData = (P_data) => {
    // Recherche alert watching list (la derniere)
    // console.log(JSON.stringify(P_data));
    let listid = this.state.watch_list;
    this.setState({
      alertData: P_data,
      etat: Constante.etat.encours
    });
    this.loadWatchingList(listid);
  }
  displayAlertData = (P_data) => {
    this.setState({
      nbWatch: P_data.nb,
      etat: Constante.etat.charge
    });
  }
  refreshAlertData = () => {
    this.setState({
      etat: Constante.etat.charge
    });
  }
  alertDelete = (P_name, P_index) => {
    this.dialogAlert.current.dialogVisible(true, P_name, this.state.alertData[P_index].surveyCode);
    this.setState({
      alertName: P_name,
      alertIndex: P_index,
      dialogType: Constante.dialogMode.delete
    })
  }

  alertDeleteValider = () => {
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard,
      //id: "_ORBITEXPRESSPATENTWATCHING_"
      id: this.state.alertName
    }
    this.setState({
      etat: Constante.etat.encours
    });

    AjaxGestion(Constante.AjaxChoix.AlerteDelete, L_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Erreur data !');
        this.setState({ etat: Constante.etat.charge });
        return;
      }
      if (typeof data.erreur !== 'undefined') {
        console.log('Erreur code :' + data.erreur + ' info :' + data.log);
        this.setState({ etat: Constante.etat.charge });
        return;
      }
      // data contient la liste des alertes ! mais pas completes hélas
      // console.log(JSON.stringify(data));
      // remove from array
      let L_data = this.state.alertData;
      L_data.splice(this.state.alertIndex, 1);
      this.setState({
        alertData: L_data
      });
      this.refreshAlertData();
      //this.createAlertData(data);
    });
  }

  alertRename = (P_name, P_index) => {
    this.dialogAlert.current.dialogVisible(true, P_name, this.state.alertData[P_index].surveyCode);
    this.setState({
      alertName: P_name,
      alertIndex: P_index,
      alertPeriod: this.state.alertData[P_index].surveyCode,
      dialogType: Constante.dialogMode.rename
    })
  }
  alertRenameValider = (P_new_name, P_new_period) => {
    //console.log("alertRenameValider" + this.state.alertIndex + ", " + this.state.alertName + " ==> " + P_new_name + "," + P_new_period);
    if (this.state.alertIndex === -1) return;
    this.setState({
      etat: Constante.etat.encours
    });
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard,
      id: this.state.alertName,
      alertObj: this.state.alertData[this.state.alertIndex],
      name: P_new_name,
      period: P_new_period
    }

    AjaxGestion(Constante.AjaxChoix.AlerteUpdate, L_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Erreur data !');
        this.setState({ etat: Constante.etat.charge });
        return;
      }
      if (typeof data.erreur !== 'undefined') {
        console.log('Erreur code :' + data.erreur + ' info :' + data.log);
        this.setState({ etat: Constante.etat.charge });
        return;
      }
      //console.log(JSON.stringify(data));
      // data contient l'alerte ajoutée ou erreur
      // {"message":"Search Strategy Name already exists!","data":null}
      if (data.data === null) {
        console.log(data.message);
        return;
      }
      if (this.state.alertIndex > -1) {
        let L_data = this.state.alertData;
        L_data[this.state.alertIndex] = data;
        this.setState({
          alertData: L_data
        });
      }
      this.refreshAlertData();
    });

  }
  loadAlertes = () => {
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard
    }
    AjaxGestion(Constante.AjaxChoix.alertes, L_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Erreur data !');
        return;
      }
      if (typeof data.erreur !== 'undefined') {
        console.log('Erreur code :' + data.erreur + ' info :' + data.log);
        return;
      }
      const alertes = data.filter(alerte => alerte.name.trim().toUpperCase() !== "_ORBITEXPRESSPATENTWATCHING_");
      this.createAlertData(alertes);
    });
  }
  alertCheckWatchingList = () => {
    // verification presence liste watching list
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard,
      type: "LIST",
      id: 0
    }
    AjaxGestion(Constante.AjaxChoix.ProjetsGet, L_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
        return;
      }
      if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
        return;
      }
      //console.log(JSON.stringify(data));
      if (data.data === null) {
        console.log(data.message);
        return;
      }
      // INBOX en premier
      let inbox = data[0].children;
      let inbox_id = data[0].id;
      for (let i = 0; i < inbox.length; i++) {
        let obj = inbox[i];
        //console.log(obj.id + "," + obj.name);
        if (obj.name === "_OrbitExpressPatentWatching_") {
          this.setState({
            watch_list: obj.id,
            etat: Constante.etat.encours
          });
          this.loadAlertes();
        }
      }
      if (this.state.watch_list === '') {
        // creation liste et alerte
        this.alertAddWatchingList(inbox_id);
      }
      return null;
    });
  }
  alertAddWatchingList = (P_parent) => {
    // ajout alerte watching list
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard,
      parentID: P_parent,
      type_folder_list: "LIST",
      name: "_OrbitExpressPatentWatching_"
    }
    AjaxGestion(Constante.AjaxChoix.ProjetsAdd, L_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
        return;
      }
      if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
        return;
      }
      //console.log(JSON.stringify(data));
      if (data.data === null) {
        console.log(data.message);
      }
      else {
        //creation alerte
        console.log("LIST:" + data.data.id + " HAS BEEN CREATED");
        this.setState({ watch_list: data.data.id });
        this.alertAddMonitor(data.data.id);
      }
    });
  }

  alertAddMonitor = (P_ID) => {
    // ajout alerte watching list
    let l_query = "*MEM \"" + P_ID.toUpperCase() + "\"/XPN/APID";
    var L_variable = {
      ticket: this.props.ticket,
      shard: this.props.shard,
      query: l_query,
      email: this.props.user.email,
      name: "_ORBITEXPRESSPATENTWATCHING_",
      period: "UE"
    }
    AjaxGestion(Constante.AjaxChoix.AlerteAdd, L_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Error data !');
        return;
      }
      if (typeof data.erreur !== 'undefined') {
        console.log('Error code :' + data.erreur + ' info :' + data.log);
        return;
      }
      // console.log(JSON.stringify(data));
      if (data.data === null) console.log(data.message);
      console.log("MONITOR ALERT HAS BEEN CREATED");
      this.loadAlertes();
    });
  }

  loadWatchingList = (P_listid) => {
    var L_variable = {
      ticket: this.props.ticket,
      id: P_listid,
      shard: this.props.shard
    }
    AjaxGestion(Constante.AjaxChoix.AlerteMonitor, L_variable).then((data) => {
      if (data === null || typeof data === 'undefined') {
        console.log('Erreur data !');
        return;
      }
      if (typeof data.erreur !== 'undefined') {
        console.log('Erreur code :' + data.erreur + ' info :' + data.log);
        return;
      }
      //console.log(JSON.stringify(data));
      this.displayAlertData(data);
    });
  }

  openMenu=(e,i,P_name)=>{
    this.menuAlert.current.openMenu(e,i, P_name);
  }

  showWatchingList = () => {
    this.props.setwatchingListId(this.state.watch_list);
    this.props.voirListe('LIST', '/' + this.state.watch_list, this.props.langue[4].LIB)
  }

  renderDivs = () => {
    let json = this.state.alertData;
    let divs = [];
    // watching list
    let lab_monitored = "";

    switch (this.state.nbWatch) {
      case 0:
        lab_monitored = this.props.langue[24].LIB;
        break;
      case 1:
        lab_monitored = this.props.langue[25].LIB;
        break;
      default:
        lab_monitored = this.props.langue[5].LIB.replace("{NB}", this.state.nbWatch);
        break;
    }

    divs.push(
      <div key="-1" id="-1" className="D_monitoring2">
        <div className="S_titre_alerte">{lab_monitored}</div>
        <button style={this.state.nbWatch === 0 ? { display: 'none' } : {}} className="B_view" onClick={() => this.showWatchingList()}>
          {this.props.langue[19].LIB}
        </button>
        <div className="S_stitre1_alerte">{this.props.langue[16].LIB}</div>
        <div className="S_stitre2_alerte">{this.props.langue[17].LIB}</div>
        <div className="S_stitre2_alerte">{this.props.langue[18].LIB}</div>
      </div>
    )

    // les alertes
    let lab_alerts = "";
    switch (json.length) {
      case 0:
        lab_alerts = this.props.langue[27].LIB;
        break;
      case 1:
        lab_alerts = this.props.langue[26].LIB;
        break;
      default:
        lab_alerts = this.props.langue[20].LIB.replace("{NB}", json.length);
        break;
    }

    divs.push(
      <div key="-2" id="-1" className="D_liste-alertes">
        <div className="S_titre_alerte">{lab_alerts}</div>
        <div className="S_stitre1_alerte">{this.props.langue[21].LIB}</div>
        <div className="S_stitre2_alerte">{this.props.langue[22].LIB}</div>
      </div>
    )

    json.forEach((obj,i) => {
      const name = obj.name.trim();
      divs.push(
        <div key={name} id={i} className="D_une_alerte">
          <span className="S_nom_alerte">{name}</span>
          <span className="S_date_alerte">{this.props.langue[23].LIB + " " + obj.creationDate}</span>
          <span className="S_type_alerte">{obj.surveyCode === "QM" ? this.props.langue[8].LIB : this.props.langue[7].LIB}</span>
          <span className="S_menu_alerte" style={this.props.user.acces.alerte.write ? {} : { display: 'none' }}>

            <div id="D_alerte_query_action">
              <div id="D_alerte_query" onClick={(e)=>this.openMenu(e,i,name)}></div>
            </div>
           </span>
        </div>
      )
    });

    return divs;
  }

  render() {
    if (this.state.etat === Constante.etat.encours) {
      return (
        <div id="D_centre_alertes" className="loadgif">
        </div>
      );
    }
    return (
      <div id="D_centre_alertes">
        <div id="D_liste_alertes">
          <AlertMenu ref={this.menuAlert} langue={this.props.langue} alertDelete={this.alertDelete} alertRename={this.alertRename} />
          <DialogAlert langue={this.props.langue}
            ref={this.dialogAlert}
            name={this.state.alertName}
            period={this.state.alertPeriod}
            renameAction={this.alertRenameValider}
            deleteAction={this.alertDeleteValider}
            type={this.state.dialogType}
          />
          {this.renderDivs()}
        </div>
      </div>
    );
  }
}

export default MesAlertes;

